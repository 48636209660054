import { isCanadianHoliday } from './holidays';

export const getDateTimeString = (date) => {
    if (!date) return "";

    if (typeof date === 'string') {
        date = new Date(date);
    }
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    var hour = date.getHours();
    var minute = date.getMinutes();

    var result = '';
    if (month < 10) result += '0';
    result += month.toString();
    result += '/'

    if (day < 10) result += '0';
    result += day.toString();
    result += '/'

    result += year.toString();

    result += " ";
    if (hour < 10) result += '0';
    result += hour.toString();
    result += ':'

    if (minute < 10) result += '0';
    result += minute.toString();
    return result;
}

export const getNiceFormatDateString = (date, locale = "en") => {
    if (!date) return '';

    if (typeof date === 'string') {
        date = new Date(date);
    }

    const options = {
        month: "long",
        day: "numeric"
    };
    return new Intl.DateTimeFormat(locale, options).format(date);
}

export const getWMDDateString = (date, locale = 'en') => {
    if (!date) return '';

    if (typeof date === 'string') {
        date = new Date(date);
    }

    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    return {
        heading: new Intl.DateTimeFormat(locale, { weekday: 'long', month: 'long', day: 'numeric' }).format(date),
        label: `${year}-${month}-${day}`,
        date: date.toISOString()
    }
}

export const getNiceFormatDateTimeString = (date, locale = "en", type = "normal") => {
    if (!date) return '';

    if (typeof date === 'string') {
        date = new Date(date);
    }

    return Intl.DateTimeFormat(locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
    }).format(date);
}

export const getDateFormat_mmddyyyy = (date) => {
    if (!date) return '';

    if (typeof date === 'string') {
        date = new Date(date);
    }

    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    var result = '';
    if (month < 10) result += '0';
    result += month.toString();
    result += '/'

    if (day < 10) result += '0';
    result += day.toString();
    result += '/'

    result += year.toString();
    return result;
}

export const getDateAndTimeString = (date) => {
    if (!date) return '';

    if (typeof date === 'string') {
        date = new Date(date);
    }

    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();

    var hour = date.getHours();
    var minute = date.getMinutes();

    var dayStr = '';
    dayStr += year.toString();
    if (month < 10) dayStr += '0'; dayStr += month.toString();
    if (day < 10) dayStr += '0'; dayStr += day.toString();

    var timeStr = '';
    if (hour < 10) timeStr += '0'; timeStr += hour.toString();
    if (minute < 10) timeStr += '0'; timeStr += minute.toString();

    return { day: dayStr, time: timeStr };
}

export const isSameDay = (day1, day2) => {
    if (!day1 || !day2) return false;
    if (day1.getFullYear() !== day2.getFullYear()) return false;
    if (day1.getMonth() !== day2.getMonth()) return false;
    if (day1.getDate() !== day2.getDate()) return false;
    return true;
}

export const isTodayORTomorrowSession = (date) => {
    if (!date) return false;

    if (typeof date === 'string') {
        date = new Date(date);
    }

    const now = new Date();

    const year = now.getFullYear(), month = now.getMonth(), day = now.getDate();
    const tomorrow = new Date(year, month, day + 2, 0, 0, 0);

    if (date >= new Date() && date < tomorrow) return true;
    return false;
}

export const getLabelGroupByMonth = (date, locale = "en") => {
    if (!date) return '';

    if (typeof date === 'string') {
        date = new Date(date);
    }
    const options = {
        year: "numeric",
        month: "long",
    };
    return new Intl.DateTimeFormat(locale, options).format(date);
}

export const getLabelGroupByDay = (date, locale = "en") => {
    if (!date) return '';

    if (typeof date === 'string') {
        date = new Date(date);
    }
    const options = {
        year: "numeric",
        month: "long",
        day: "numeric"
    };
    return new Intl.DateTimeFormat(locale, options).format(date);
}

export const delayTime = (t) => {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve();
        }, t)
    })
}

export const getSchoolYear = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth() + 1;

    if (currentMonth < 7) {
        const schoolYearStart = new Date(currentYear - 1, 7, 1);
        const schoolYearEnd = new Date(currentYear, 6, 30);
        return { start: schoolYearStart, end: schoolYearEnd };
    } else {
        const schoolYearStart = new Date(currentYear, 7, 1);
        const schoolYearEnd = new Date(currentYear + 1, 6, 30);
        return { start: schoolYearStart, end: schoolYearEnd };
    }
}

export const isValidDateOnPicker = (dayLimitations, currentDate, dayRestrictionConfig, userInfo) => {
    // Check if it's a holiday
    if (isCanadianHoliday(currentDate.toDate(), userInfo)) {
        return false;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set the time to midnight to compare only dates, not times

    // Check if the currentDate is in the past
    if (currentDate.isBefore(today, 'day')) {
        return false; // Disable past dates
    }

    // Calculate the restricted date based on the dayRestrictionConfig
    const restrictedDate = new Date();
    restrictedDate.setDate(today.getDate() + dayRestrictionConfig);

    // Check if the currentDate is before the restricted date
    if (currentDate.isBefore(restrictedDate, 'day')) {
        return false; // Disable dates before the restricted date
    }
    
    const checkday = currentDate.day();
    const isWeekday = checkday !== 0 && checkday !== 6; // 0 is Sunday, 6 is Saturday
    
    // Return false immediately if it's a weekend
    if (!isWeekday) {
        return false;
    }

    // Return true immediately if there are no day limitations
    if (!dayLimitations || dayLimitations.length === 0) return true;

    // Otherwise, consider the day limitations
    const day = currentDate.day();
    if (dayLimitations.indexOf(day) >= 0) return true;
    
    return false;
};

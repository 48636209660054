import supabase from "../../../database";
import { transformResponse } from "../util/transform-response";

export const createFormResponse = async (formId, userId, schoolIds) => {
	try {
		const response = await supabase.formResponses.create({
			form_id: formId,
			user_id: userId,
			status: "draft",
			school_ids: schoolIds,
		});
		
		if (!response && response.length === 0) return {};
		return transformResponse(response[0]);
	} catch (e) {
		console.log("Error:", e);
		return {};
	}
};

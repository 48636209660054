
const FIELD_KEYS = {
	ID: "id",
	GROUP_ID: "group_id",
	TYPE: "type",
	FIELD_ORDER: "field_order",
	AUTO_POPULATE: "auto_populate",
	FILE_TYPES: "file_types",
	MAX_FILE_SIZE: "max_file_size",
	MAX_LENGTH: "max_length",
	MAX_VALUE: "max_value",
	MIN_VALUE: "min_value",
	REQUIRED: "required",
	UNIT: "unit",
	VALUE_OPTIONS: "value_options",
	TRANSLATION_ID: "translation_id",
	FUNCTION_OPTION: "function_option",
};

const PAGE_KEYS = {
	ID: "id",
	FORM_ID: "form_id",
	PAGE_ORDER: "page_order",
	TRANSLATION_ID: "translation_id",
	GROUPS: "groups",
};

const GROUP_KEYS = {
	ID: "id",
	PAGE_ID: "page_id",
	RESPONSE_ID: "response_id",
	GROUP_ORDER: "group_order",
	REPEATABLE: "repeatable",
	TRANSLATION_ID: "translation_id",
	INSTANCES: "instances",
	FIELDS: "fields",
};

const INSTANCE_KEYS = {
	INSTANCE_NUMBER: "instance_number",
	FIELDS: "fields",
};

const FORM_KEYS = {
	ID: "id",
	TEMPLATE_ID: "template_id",
	TRANSLATION_ID: "translation_id",
	STATUS: "status",
	PAGES: "pages",
	
	RESPONSE_ID: "response_id",
	RESPONSE_STATUS: "response_status",
	SCHOOL_IDS: "school_ids",
	USER_ID: "user_id",

	DEA_ID: "dea_user_id",
	DEA_COMMENT: "dea_comment",
	USER_COMMENT: "user_comment",

	SUBMITTED_AT: "submitted_at",
	CREATED_AT: "created_at",
	LAST_SAVED_AT: "last_saved_at",
};

export {
	FIELD_KEYS,
	PAGE_KEYS,
	GROUP_KEYS,
	INSTANCE_KEYS,
	FORM_KEYS
};

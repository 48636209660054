import { FIELD_KEYS } from "../constants/form-keys";

export const transformField = (rawField) => {
	const {
		ID,
		GROUP_ID,
		TYPE,
		FIELD_ORDER,
		AUTO_POPULATE,
		FILE_TYPES,
		MAX_FILE_SIZE,
		MAX_LENGTH,
		MAX_VALUE,
		MIN_VALUE,
		REQUIRED,
		UNIT,
		VALUE_OPTIONS,
		TRANSLATION_ID,
		FUNCTION_OPTION,
	} = FIELD_KEYS;
	const {
		id,
		group_id,
		translation_id,
		type,
		auto_populate,
		field_order,
		file_types,
		max_file_size,
		max_length,
		max_value,
		min_value,
		required,
		unit,
		value_options,
		function_option,
	} = rawField || {};

	return {
		[ID]: id,
		[GROUP_ID]: group_id,
		[TYPE]: type,
		[FIELD_ORDER]: field_order,
		[AUTO_POPULATE]: auto_populate,
		[FILE_TYPES]: file_types,
		[MAX_FILE_SIZE]: max_file_size,
		[MAX_LENGTH]: max_length,
		[MAX_VALUE]: max_value,
		[MIN_VALUE]: min_value,
		[REQUIRED]: required,
		[UNIT]: unit,
		[VALUE_OPTIONS]: value_options,
		[TRANSLATION_ID]: translation_id,
        [FUNCTION_OPTION]: function_option,
	};
};

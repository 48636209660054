import supabase from "../../../database";

export const deleteInstance = async (responseId, instance_number) => {

	try {
		
		const response = await supabase.formResponseValues.deleteInstance(responseId, instance_number);

		if(response.error) throw response.error;
		
	} catch (e) {
		console.log("Error:", e);
	}
};

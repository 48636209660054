import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "reactstrap";
import { Alert, CircularProgress, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { usePDLog } from "./hooks/use-pd-log.hook";
import { usePDData } from "./hooks/use-pd-data.hook";
import { PlanLog } from "./components/PlanLog";
import { Paper } from "./components/Paper";
import { Certificate } from "./components/Certificate";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import { ToastMessage } from "./components/ToastMessage";
import { PLAN_FIELDS } from "./constants/pd-fields";
import { MyPDHours } from "./components/MyPDHours";
import { PDFManager } from "../../libs/pdf";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import SVGIcon from "../../components/Icons/SVGIcons"
import PDWeekSession from "../modals/PDWeekSession";
const { GOALS } = PLAN_FIELDS;

function formatDate(dateString) {
  if (!dateString) return '';
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-US', options);
}

const PDLog = () => {
	const { teacher, teachers, updateCertificate, pdHours, pdLogs, hasSession, sessions, sessionsLoading, addPdLog, updatePdLog, deletePdLog, addSessionsToPDLog, isLoading: isLogLoading } = usePDLog();
	const { activePD, isLoading: isPDLoading } = usePDData();
	const [expandedLog, setExpandedLog] = useState(false);
	const [goals, setGoals] = useState([]);
	const [schoolTeachers, setSchoolTeachers] = useState([]);
	const [isPDSessionModalOpen, setPDSessionModalOpen] = useState(false);
	const userInfo = useSelector((state) => state.appInfo.userInfo);
	const isPrincipal = !!userInfo["Principal?"]

	let isLoading = isLogLoading && isPDLoading;
	const { t } = useTranslation();

	useEffect(() => {
		window.document.title = t("pd-plan.logs.page-title");
	}, [t]);

		useEffect(() => {
			if(!isPDLoading && activePD && activePD[GOALS]) {
				const goalsData = activePD[GOALS].map(goal => ({
					value: goal.id,
					label: goal.Specific
				}))
				setGoals(goalsData);
			}
		}, [activePD, isPDLoading])

		useEffect(() => {
			if (!isLogLoading && teachers && teachers.length > 0 && teacher) {
				const st = teachers
					.filter(t => t.id !== teacher.id)
					.map(t => ({value: t.id, label: t["Teacher Name"]}))
					.sort((a, b) => a.label.localeCompare(b.label));
				setSchoolTeachers(st);
			}
		}, [teachers, isLogLoading, teacher])

		const onToggleLog = (panel) => (_event, isExpanded) => {
				setExpandedLog(isExpanded ? panel : false);
		};

		const onAddLog = async () => {
			const added = await addPdLog();

			if (added) {
				toast.success(
					<ToastMessage>{t("pd-plan.logs.success.add")}</ToastMessage>
				);
				setExpandedLog(added.id);
				// Scroll the new log into view after a short delay to allow for rendering
				setTimeout(() => {
					document.getElementById(`pd-log-${added.id}`)?.scrollIntoView({ behavior: 'smooth' });
				}, 100);
			} else {
				toast.error(
					<ToastMessage>{t("pd-plan.logs.error.add")}</ToastMessage>
				);
			}
		};

		const onDeleteLog = async (logId) => {
			const deleteConfirmation = window.confirm(
				t("pd-plan.logs.delete-confirm")
			);

			if (logId && deleteConfirmation) {
				const log = pdLogs.find(log => log.id === logId)
				let res;
				if (log && log["Teacher"] && log["Teacher"].length > 1) {
					let params = {}
					params.Teacher = log.Teacher.filter(t => t !== teacher.id);
					res = await updatePdLog(logId, params);
				}
				else{
					res = await deletePdLog(logId);
				}

				if (res) {
					toast.success(
						<ToastMessage>{t("pd-plan.logs.success.delete")}</ToastMessage>
					);
				} else {
					toast.error(
						<ToastMessage>{t("pd-plan.logs.error.delete")}</ToastMessage>
					);
				}
			}
		};

		const importPDWeekSession = async (ids) => {
			setPDSessionModalOpen(false);
			const logs = await addSessionsToPDLog(ids);

			if (logs) {
				toast.success(
					<ToastMessage>{t("pd-plan.logs.success.add")}</ToastMessage>
				);
			} else {
				toast.error(
					<ToastMessage>{t("pd-plan.logs.error.add")}</ToastMessage>
				);
			}
		}

		const handleDownloadPdf = async () => {
			const pdfGenerator = new PDFManager();

			pdfGenerator.generateLogsPDF(pdLogs, goals, teacher);
		}

		const sortedPdLogs = pdLogs?.sort((a, b) => {
			const dateA = new Date(a["Start Date"] || 0);
			const dateB = new Date(b["Start Date"] || 0);
			return dateA - dateB;  // Changed to ascending order (oldest first)
		});

    return (
			<Box
				className="main-container"
				id="pd-plan"
				sx={{
					fontFamily: "inherit",
					color: "#0D1120",
					paddingBottom: "3rem",
					h2: {
						fontWeight: "bold",
						fontSize: "1.8rem",
						lineHeight: "1.3",
					},
					"*, h1, h2, h3, h4, p, button": {
						fontFamily: "inherit",
					},
					"i, i::before": {
						fontFamily: "FontAwesome",
					},
					"> .MuiPaper-root": {
						margin: "3.5rem 0",
					},
					"button:focus, a[href]:focus": {
						textDecoration: "underline",
						outline: "solid 0.15rem #0e6ba8",
						outlineOffset: "0.1rem",
					},
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						marginBottom: "3rem",
						gap: "2rem",
					}}
				>
					<Typography
						variant="h3"
						component="h1"
						sx={{
							fontSize: "2.5rem",
							lineHeight: "1.5",
						}}
					>
						{t("pd-plan.logs.title")}
					</Typography>
					<Box sx={{ display: "flex", gap: "1rem" }}>
						<Link to="/cn/pd-plan">
							<Button color="primary">
								{t("pd-plan.logs.pd-plan")}
							</Button>
						</Link>
						{
							hasSession && (
								<Button
									outline
									color="primary"
									onClick={() => setPDSessionModalOpen(true)}
									disabled={isLoading}
									style={{
										paddingLeft: "1rem",
										display: "flex",
										alignItems: "center",
										gap: "0.5rem",
										marginRight: 0,
									}}
								>
									<SVGIcon name="file-import" color="currentColor" /> {t("pd-plan.logs.import-pd-week")}
								</Button>
							)
						}
						<Button
							outline
							color="primary"
							onClick={onAddLog}
							disabled={isLoading}
							style={{
								paddingLeft: "1rem",
								display: "flex",
								alignItems: "center",
								gap: "0.5rem",
							}}
						>
							<AddIcon /> {t("pd-plan.logs.add-activity")}
						</Button>
					</Box>
				</Box>
				{(isLoading || !teacher) && <CircularProgress />}
				{teacher && (
					<Row className="pb-4">
						<Col xs="12" md="6" className="">
							<Certificate
								heading={isPrincipal ? "My Teaching Certificate" : t("pd-plan.certificate.title")}
								issueDate={formatDate(teacher["License Issued"])}
								expiryDate={formatDate(teacher["License Expiry"])}
								certificateNumber={teacher["Certificate Number"]}
								onUpdate={updateCertificate}
							/>
						</Col>
						{isPrincipal && (
							<Col xs="12" md="6" className="">
								<Certificate
									heading={"My Principal Certificate"}
									issueDate={formatDate(teacher["Principal License Issued"])}
									expiryDate={formatDate(teacher["Principal License Expiry"])}
									certificateNumber={teacher["Principal Certificate Number"]}
									onUpdate={updateCertificate}
									isPrincipal={isPrincipal}
								/>
							</Col>
						)}
						<Col className={isPrincipal ? "pt-4" : ""}>
							<MyPDHours
								hours={pdHours}
								issueDate={teacher?.["License Issued"]}
								expiryDate={teacher?.["License Expiry"]}
								isPrincipal={isPrincipal}
							/>
						</Col>
					</Row>
				)}
				{ !(pdLogs && pdLogs.length > 0) ?
					<Alert
						severity="info"
						sx={{
							justifyContent: "center",
							alignItems: "center",
							fontFamily: "inherit",
							".MuiAlert-message": {
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								gap: "1rem",
							},
						}}
					>
						<Typography variant="body1" component="p">
							{t("pd-plan.logs.no-logs")}
						</Typography>
						<Button color="primary" onClick={onAddLog}>
							{t("pd-plan.logs.add-first-activity")}
						</Button>
					</Alert>
					:
					<>
						<Paper
							title={
								<div className="d-flex justify-content-between align-items-center w-100">
									<div className="d-flex align-items-center">
										<Box>{t("pd-plan.logs.activities")}</Box>
									</div>
									<Button
										onClick={handleDownloadPdf}
										outline
										color="primary"
										style={{
											padding: "0.5rem 1rem",
											fontSize: "1rem",
										}}
									>
										{t("pd-plan.download-pdf")}
									</Button>
								</div>
							}
							className="mt-0"
						>
							{pdLogs && sortedPdLogs.map((log) => (
								<Box 
									key={log.id} 
									id={`pd-log-${log.id}`}
								>
									<PlanLog
										log={log}
										goals={goals}
										teachers={schoolTeachers}
										readOnly={isLoading}
										onUpdate={updatePdLog}
										onDelete={onDeleteLog}
										onToggleExpand={onToggleLog(log.id)}
										expanded={expandedLog === log.id}
										userInfo={userInfo}
										isAuthor={!!log["Added by"] && log["Added by"][0] === teacher.id}
									/>
								</Box>
							))}
						</Paper>
					</>
				}
				<PDWeekSession sessions={sessions} loading={isLoading || sessionsLoading} showModal={isPDSessionModalOpen} onToggle={() => setPDSessionModalOpen(!isPDSessionModalOpen)} onAddToPDLog={importPDWeekSession} />
			</Box>
    );
};

export default PDLog;

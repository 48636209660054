import { Button, Box } from "@mui/material";
import styled from "@mui/material/styles/styled";
import React from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useTranslation } from "react-i18next";

const StyledButton = styled(Button)({
	border: "1px solid #0E6BA8",
	width: "100%",
	backgroundColor: "#0E6BA8",
	color: "#FFF",
	borderRadius: "1rem",
	textTransform: "none",
	fontFamily: "Effra, sans-serif",
	fontSize: "1rem",
	fontWeight: "400",
	padding: "0.625rem",
	borderWidth: "0.15rem",
	position: "relative",
	"&:hover": {
		backgroundColor: "#085492",
		borderWidth: "0.15rem",
		transform: "translateY(-2px)",
	},
});

const StyledText = styled("span")({
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	width: "90%",
	textAlign: "center",
});

export const PrevNextButton = ({
	prevSectionKey,
	nextSectionKey,
	id,
	onPrevClick,
	onNextClick,
	hasNext = true, // TODO: Replace with actual logic in separate PR
	hasPrev = true,
	justifyContent = "space-between",

}) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: { xs: "column", sm: "row" },
				justifyContent: justifyContent,
				alignItems: "center",
				width: "100%",
				gap: { xs: "1rem", md: "1.5rem" },
			}}
		>
			{/* TODO: Replace the placeholder text with key values (separate PR) */}
			<StyledButton
				id={id}
				onClick={onPrevClick}
				disabled={!hasPrev}
				aria-disabled={!hasPrev}
				aria-label={`Go to previous section: ${prevSectionKey}`}
			>
				<KeyboardArrowLeftIcon
					sx={{
						position: "absolute",
						left: "0",
					}}
				/>
				<StyledText>{t(`form_buttons.prev`)}</StyledText>
			</StyledButton>
			<StyledButton
				id={id}
				onClick={onNextClick}
				disabled={!hasNext}
				aria-disabled={!hasPrev}
				aria-label={`Go to next section: ${nextSectionKey}`}
			>
				<StyledText>
					{t(`form_buttons.next`)}
				</StyledText>
				<KeyboardArrowRightIcon
					sx={{
						position: "absolute",
						right: "0",
					}}
				/>
			</StyledButton>
		</Box>
	);
};

import airtable from "../../../airtables/PDAirtable";
import {
	TEACHER_FIELDS,
} from "../constants/pd-fields";

export const getPDLog = async (teacherId) => {
	const { PD_LOGS } = TEACHER_FIELDS;

	const response = await airtable.nunavutTeachers.getTeacherByRecordId(
		teacherId,
		PD_LOGS
	);
	return response;
};

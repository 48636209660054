import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { setAppUserInfo } from "../../../redux/actions";
import { TEACHER_FIELDS } from "../constants/teacher-fields";
import airtable from "../../../airtables";
import { APP_ROOT } from "../routing";

export const useUpdateUserInfo = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const { userInfo } = useSelector((state) => state.appInfo);

	const updateUserInfo = async (
		updateBody = {},
		nextLink = "",
		onSuccess = undefined,
	) => {
		setLoading(true);

		try {
			const updateUserInfo = await airtable.teachers.update(
				userInfo.id,
				updateBody,
			);

			delete updateUserInfo[TEACHER_FIELDS.REPORT_MSG];
			dispatch(setAppUserInfo(updateUserInfo));

			if (onSuccess) {
				onSuccess();
			}

			setLoading(false);
			history.push(nextLink);
		} catch (error) {
			console.log(error.toString());
			toast.error(t("teacherOnboard.genericErrorMessage"), {
				position: "bottom-center",
			});
		}

		setLoading(false);
	};

	const confirmUserInfoUpdated = async () => {
		const logins = userInfo[TEACHER_FIELDS.LOGINS] + 1;

		const updateBody = {
			[TEACHER_FIELDS.COMPLETED]: true,
			[TEACHER_FIELDS.STATUS]: "Active",
			[TEACHER_FIELDS.LOGINS]: logins,
		};

		const onSuccess = () => {
			toast.success(t("teacherOnboard.completedSuccessMessage"), {
				position: "bottom-center",
			});
		};

		updateUserInfo(updateBody, APP_ROOT, onSuccess);
	};

	return {
		loading,
		userInfo,
		setLoading,
		updateUserInfo,
		confirmUserInfoUpdated,
	};
};

import { LOG_FIELDS } from "../constants/pd-fields";

const {
	TEACHER,
	ACTIVITY_NAME,
	DESCRIPTION,
	START_DATE,
	END_DATE,
	HOURS,
	RELATED_PD_PLAN_GOAL,
} = LOG_FIELDS;

export const defaultLog = {
	activityName: "",
	description: "",
	startDate: null,
	endDate: null,
	relatedPdPlanGoal: [],
	hours: 1,
};

export const requiredFields = [
	"activityName",
	"startDate",
	"hours"
];

export const mapFieldsFromLog = (log = {}) => ({
	activityName: log[ACTIVITY_NAME] || defaultLog.activityName,
	description: log[DESCRIPTION] || defaultLog.description,
	startDate: log[START_DATE] || defaultLog.startDate,
	endDate: log[END_DATE] || defaultLog.endDate,
	hours: log[HOURS] || defaultLog.hours,
	relatedPdPlanGoal: log[RELATED_PD_PLAN_GOAL] || defaultLog.relatedPdPlanGoal,
	teacher: log[TEACHER],
});

export const mapLogFromFields = (fields = {}) => ({
	[ACTIVITY_NAME]: fields.activityName.trim(),
	[DESCRIPTION]: fields.description,
	[START_DATE]: fields.startDate,
	[END_DATE]: fields.endDate,
	[HOURS]: fields.hours,
	[RELATED_PD_PLAN_GOAL]: fields.relatedPdPlanGoal,
	[TEACHER]: fields.teacher,
});

export const parseNumber = (value) => {
  const parsed = parseFloat(value);
  return isNaN(parsed) || parsed < 0 ? 0 : Math.round(parsed * 2) / 2;
}

export const truncateText = (text, wordLimit = 50) => {
  const words = text.split(" ");
  if (words.length <= wordLimit) {
    return text;
  }
  const truncated = words.slice(0, wordLimit).join(" ");
  return `${truncated} ...`;
}

export const formatPDLogDate = ({startDate, hours, endDate}) => {
	if (!startDate) return null;
	const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };
	const format = (date) => new Date(date).toLocaleDateString('en-US', options);

	let dateStr = format(startDate);
	if (endDate) {
	const start = new Date(new Date(startDate).toUTCString());
	const end = new Date(new Date(endDate).toUTCString());
	dateStr = (start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear())
		? `${dateStr.split(' ')[0]} ${start.getDate()}-${end.getDate()}, ${start.getFullYear()}`
		: `${dateStr} - ${format(endDate)}`;
	}

	return `${dateStr} (${hours} hours)`;
};

import { isCanadianHoliday } from '../../../utils/holidays';

/**
 * @param {Date} date
 * @param {string} providerTimezone
 */
export const checkProviderWorkingTime = (date, providerTimezone, earliestTime = "08:30") => {
  if (!date) return false;
  if (typeof date === 'string') date = new Date(date);

  const formatter = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
    timeZone: providerTimezone,
  });

  const formatter1 = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
    timeZone: providerTimezone,
  });

  const formatedTime = formatter.format(date);
  return {
    isInWorkingTime: formatedTime >= earliestTime,
    providerTime: formatter1.format(date)
  }
}

export const checkValidation = (arr) => {
  return arr.findIndex(item => item.startsWith("invalid"));
}

export const getDefaultPreferredTime = (dayRestrictionConfig, bookingRequirementDays, dayLimitations, userInfo) => {
  let offset = 14;
  if (dayRestrictionConfig) offset = dayRestrictionConfig;
  else if (bookingRequirementDays && bookingRequirementDays.length) offset = bookingRequirementDays[0]

  const now = new Date();
  let targetDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + offset, 10, 0, 0);
  
  // Keep trying dates until we find a valid one
  let attempts = 0;
  const maxAttempts = 30; // Prevent infinite loop
  
  while (attempts < maxAttempts) {
    attempts++;
    
    // Check if it's in Christmas week
    if (targetDate.getMonth() === 11) { // December
      const christmas = new Date(targetDate.getFullYear(), 11, 25);
      const christmasDay = christmas.getDay();
      
      // If Christmas is on weekend, check if date is in the following week
      if (christmasDay === 0 || christmasDay === 6) {
        const blockStartDate = new Date(targetDate.getFullYear(), 11, 27); // Monday after Christmas
        const blockEndDate = new Date(targetDate.getFullYear(), 11, 31); // End of following week
        
        if (targetDate >= blockStartDate && targetDate <= blockEndDate) {
          targetDate = new Date(targetDate.getFullYear(), 11, 31); // Skip to end of block
          targetDate.setDate(targetDate.getDate() + 1); // Move to next day
          continue;
        }
      } else {
        // For weekday Christmas, check if date is in Christmas week
        const mondayOfWeek = new Date(christmas);
        mondayOfWeek.setDate(christmas.getDate() - christmasDay + 1); // Get Monday of Christmas week
        
        const fridayOfWeek = new Date(mondayOfWeek);
        fridayOfWeek.setDate(mondayOfWeek.getDate() + 4); // Get Friday of Christmas week
        
        if (targetDate >= mondayOfWeek && targetDate <= fridayOfWeek) {
          targetDate = new Date(fridayOfWeek); // Skip to end of block
          targetDate.setDate(targetDate.getDate() + 1); // Move to next day
          continue;
        }
      }
    }

    // Adjust if target date falls on weekend
    const dayOfWeek = targetDate.getDay();
    if (dayOfWeek === 0) { // Sunday
      targetDate.setDate(targetDate.getDate() + 1); // Move to Monday
      continue;
    } else if (dayOfWeek === 6) { // Saturday
      targetDate.setDate(targetDate.getDate() + 2); // Move to Monday
      continue;
    }

    // Check if it's a holiday
    if (isCanadianHoliday(targetDate, userInfo)) {
      targetDate.setDate(targetDate.getDate() + 1);
      continue;
    }

    // Check for day limitations
    if (dayLimitations && dayLimitations.length) {
      if (dayLimitations.indexOf(dayOfWeek) >= 0) {
        return targetDate; // Valid date found
      }
      targetDate.setDate(targetDate.getDate() + 1);
      continue;
    }

    // If we get here and there are no day limitations, the date is valid
    return targetDate;
  }

  // If we couldn't find a valid date, return the last attempted date
  return targetDate;
}

export const getDefaultAlternativeTime = (dayRestrictionConfig, bookingRequirementDays, dayLimitations, userInfo) => {
  let offset = 28;
  if (dayRestrictionConfig) offset = dayRestrictionConfig + 14;
  else if (bookingRequirementDays && bookingRequirementDays.length) offset = bookingRequirementDays[0] + 14;

  const now = new Date();
  let targetDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + offset, 10, 0, 0);
  
  // Keep trying dates until we find a valid one
  let attempts = 0;
  const maxAttempts = 30; // Prevent infinite loop
  
  while (attempts < maxAttempts) {
    attempts++;
    
    // Check if it's in Christmas week
    if (targetDate.getMonth() === 11) { // December
      const christmas = new Date(targetDate.getFullYear(), 11, 25);
      const christmasDay = christmas.getDay();
      
      // If Christmas is on weekend, check if date is in the following week
      if (christmasDay === 0 || christmasDay === 6) {
        const blockStartDate = new Date(targetDate.getFullYear(), 11, 27); // Monday after Christmas
        const blockEndDate = new Date(targetDate.getFullYear(), 11, 31); // End of following week
        
        if (targetDate >= blockStartDate && targetDate <= blockEndDate) {
          targetDate = new Date(targetDate.getFullYear(), 11, 31); // Skip to end of block
          targetDate.setDate(targetDate.getDate() + 1); // Move to next day
          continue;
        }
      } else {
        // For weekday Christmas, check if date is in Christmas week
        const mondayOfWeek = new Date(christmas);
        mondayOfWeek.setDate(christmas.getDate() - christmasDay + 1); // Get Monday of Christmas week
        
        const fridayOfWeek = new Date(mondayOfWeek);
        fridayOfWeek.setDate(mondayOfWeek.getDate() + 4); // Get Friday of Christmas week
        
        if (targetDate >= mondayOfWeek && targetDate <= fridayOfWeek) {
          targetDate = new Date(fridayOfWeek); // Skip to end of block
          targetDate.setDate(targetDate.getDate() + 1); // Move to next day
          continue;
        }
      }
    }

    // Adjust if target date falls on weekend
    const dayOfWeek = targetDate.getDay();
    if (dayOfWeek === 0) { // Sunday
      targetDate.setDate(targetDate.getDate() + 1); // Move to Monday
      continue;
    } else if (dayOfWeek === 6) { // Saturday
      targetDate.setDate(targetDate.getDate() + 2); // Move to Monday
      continue;
    }

    // Check if it's a holiday
    if (isCanadianHoliday(targetDate, userInfo)) {
      targetDate.setDate(targetDate.getDate() + 1);
      continue;
    }

    // Check for day limitations
    if (dayLimitations && dayLimitations.length) {
      if (dayLimitations.indexOf(dayOfWeek) >= 0) {
        return targetDate; // Valid date found
      }
      targetDate.setDate(targetDate.getDate() + 1);
      continue;
    }

    // If we get here and there are no day limitations, the date is valid
    return targetDate;
  }

  // If we couldn't find a valid date, return the last attempted date
  return targetDate;
}

/**
 * 
 * @param {string} timezone 
 */
export const getJustTimeZone = (timezone) => {
  const arr = timezone.split("/");
  if (arr.length > 1) return arr[1];
  return timezone;
}

/**
 * 
 * @param {any} session
 */
export const getEarliestStartTimeFromProviderSession = (session) => {
  const startTime = session["Provider Earliest Start Time"] ? session["Provider Earliest Start Time"][0] : "8:30";
  if (startTime.length === 4) return "0" + startTime;
}
import { useEffect, useState } from "react"
import airtable from '../../airtables';
import { useSelector } from "react-redux";

export function useTeamPendingData() {
  const { userInfo, teamSchools } = useSelector(state => state.appInfo);
  const isAdmin = userInfo["Administrator"] ? true : false;

  const [requestedSessions, setRequestedSessions] = useState([]);
  const [planningSessions, setplanningSessions] = useState([]);
  const [devSessions, setDevSessions] = useState([]);
  const [wishSessions, setWishSessions] = useState([]);
  const [adminSessions, setAdminSessions] = useState([]);
  const [rLoading, setRLoading] = useState(false);
  const [ipLoading, setIpLoading] = useState(false);
  const [dLoading, setDLoading] = useState(false);
  const [aLoading, setALoading] = useState(false);
  const [wLoading, setWLoading] = useState(false);
  const [pendingTeacherSessions, setPendingTeacherSessions] = useState([]);
  const [ptLoading, setPtLoading] = useState(false);

  const [filterConditions, setFilterConditions] = useState({
    filterKeyword: "",
    filterDate: null,
    filterCILC: false,
    filterSchool: null
  })

  const [fresh, setfresh] = useState(0);

  useEffect(() => {
    loadRequestedSessions();
    loadPlanningSessions();
    loadDevelopmentSessions();
    loadPendingTeacherSessions();
    loadWishListSessions();
    if (isAdmin) loadAdminSessions();
  }, [fresh]) // eslint-disable-line react-hooks/exhaustive-deps

  const refresh = () => setfresh(fresh + 1);

  const loadRequestedSessions = async () => {
    setRLoading(true);
    try {
      const {
        filterKeyword,
        filterDate,
        filterCILC,
        filterSchool
      } = filterConditions;
      let fSchool = null;
      if (filterSchool && filterSchool.value) {
        fSchool = teamSchools.find(ts => filterSchool.value === ts.id)
      }

      const sessions = await airtable.sessions.getPendingSessionsForTeam(
        userInfo.id,
        userInfo['Name'],
        'Requested',
        {
          fSchool,
          filterKeyword,
          filterDate,
          filterCILC
        }
      );
      setRequestedSessions(sessions);
    } catch (error) { }
    setRLoading(false);
  }

  const loadPlanningSessions = async () => {
    setIpLoading(true);
    try {
      const {
        filterKeyword,
        filterDate,
        filterCILC,
        filterSchool
      } = filterConditions;
      let fSchool = null;
      if (filterSchool && filterSchool.value) {
        fSchool = teamSchools.find(ts => filterSchool.value === ts.id)
      }

      const sessions = await airtable.sessions.getPendingSessionsForTeam(
        userInfo.id,
        userInfo['Name'],
        'In Planning',
        {
          fSchool,
          filterKeyword,
          filterDate,
          filterCILC
        }
      );
      setplanningSessions(sessions);
    } catch (error) { }
    setIpLoading(false);
  }

  const loadDevelopmentSessions = async () => {
    setDLoading(true);
    try {
      const {
        filterKeyword,
        filterDate,
        filterCILC,
        filterSchool
      } = filterConditions;
      let fSchool = null;
      if (filterSchool && filterSchool.value) {
        fSchool = teamSchools.find(ts => filterSchool.value === ts.id)
      }

      const sessions = await airtable.sessions.getPendingSessionsForTeam(
        userInfo.id,
        userInfo['Name'],
        'Pending Provider Response',
        {
          fSchool,
          filterKeyword,
          filterDate,
          filterCILC
        }
      );
      setDevSessions(sessions);
    } catch (error) { }
    setDLoading(false);
  }

  const loadWishListSessions = async () => {
    setWLoading(true);
    try {
      const {
        filterKeyword,
        filterDate,
        filterCILC,
        filterSchool
      } = filterConditions;
      let fSchool = null;
      if (filterSchool && filterSchool.value) {
        fSchool = teamSchools.find(ts => filterSchool.value === ts.id)
      }

      const sessions = await airtable.sessions.getPendingSessionsForTeam(
        userInfo.id,
        userInfo['Name'],
        'Wish List',
        {
          fSchool,
          filterKeyword,
          filterDate,
          filterCILC
        }
      );
      setWishSessions(sessions);
    } catch (error) { }
    setWLoading(false);
  }

  const loadAdminSessions = async () => {
    setALoading(true);
    try {
      const {
        filterKeyword,
        filterDate,
        filterCILC,
        filterSchool
      } = filterConditions;
      let fSchool = null;
      if (filterSchool && filterSchool.value) {
        fSchool = teamSchools.find(ts => filterSchool.value === ts.id)
      }

      const sessions = await airtable.sessions.getPendingSessionsForAdmin(
        {
          fSchool,
          filterKeyword,
          filterDate,
          filterCILC
        }
      );
      setAdminSessions(sessions);
    } catch (error) { }
    setALoading(false);
  }

  const loadPendingTeacherSessions = async () => {
    setPtLoading(true);
    try {
      const {
        filterKeyword,
        filterDate,
        filterCILC,
        filterSchool
      } = filterConditions;
      let fSchool = null;
      if (filterSchool && filterSchool.value) {
        fSchool = teamSchools.find(ts => filterSchool.value === ts.id)
      }

      const sessions = await airtable.sessions.getPendingSessionsForTeam(
        userInfo.id,
        userInfo['Name'],
        'Pending Teacher Response',
        {
          fSchool,
          filterKeyword,
          filterDate,
          filterCILC
        }
      );
      setPendingTeacherSessions(sessions);
    } catch (error) { }
    setPtLoading(false);
  }

  return {
    schools: teamSchools,
    requestedSessions,
    setRequestedSessions,
    planningSessions,
    setplanningSessions,
    devSessions,
    setDevSessions,
    wishSessions,
    setWishSessions,
    adminSessions,
    setAdminSessions,
    rLoading,
    ipLoading,
    dLoading,
    aLoading,
    wLoading,
    refresh,
    pendingTeacherSessions,
    setPendingTeacherSessions,
    ptLoading,
    filterConditions,
    setFilterConditions
  }
}

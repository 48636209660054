import { useState, useEffect } from "react";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useTranslation } from 'react-i18next';
import {
	mapFieldsFromLog,
	mapLogFromFields,
	requiredFields,
	truncateText,
	formatPDLogDate
} from "../util/handle-log-forms";
import { Accordion } from "./Accordion";
import { ToastMessage } from "./ToastMessage";
import { GoalTextField, GoalSelectField } from "./GoalFields";
import { getTeacherById, getTeacherBySyncedId } from "../util/get-pd-data";

export const PlanLog = ({
	onUpdate,
	onDelete,
	onApprove,
	onToggleExpand,
	readOnly = false,
	expanded = false,
	log = null,
	goals = [],
	teachers = [],
	userInfo,
	isReviewer = false,
	isAuthor = false,
	isPrincipal = false,
	...props
}) => {
	const [logFields, setLogFields] = useState(mapFieldsFromLog(log));
	const [fieldErrors, setFieldErrors] = useState([]);
	const [multiDayActivity, setMultiDayActivity] = useState(
		!!logFields.endDate,
	);
	const [multiTeachers, setMultiTeachers] = useState(logFields.teacher && logFields.teacher.length > 1);
	const [teacherNames, setTeacherNames] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		const fetchTeacherNames = async () => {
			if (log && log.Teacher && log.Teacher.length > 1) {
				const currentTeacherId = window.location.pathname.split('/').pop();
				const currentTeacher = await getTeacherBySyncedId(currentTeacherId);
				const currentNunavutId = currentTeacher?.id;
				
				const names = await Promise.all(
					log.Teacher
						.filter(teacherId => teacherId !== currentNunavutId)
						.map(async (teacherId) => {
							const teacher = await getTeacherById(teacherId);
							return teacher?.["Teacher Name"] || '';
						})
				);
				setTeacherNames(names.filter(name => name));
			}
		};

		if (isReviewer) {
			fetchTeacherNames();
		}
	}, [log, isReviewer]);

	const onUpdateLogField = (e) => {
		if (readOnly) return;
		const { name, value, type } = e.target;
		const res = type === 'number' ? parseFloat(value) : value;
		setLogFields({ ...logFields, [name]: res });
	};

	const onSubmitLog = async (e) => {
		e.preventDefault();
		if (readOnly) return;

		const errors = requiredFields.filter((name) => {
			if (name === "hours") {
				const hours = parseFloat(logFields[name]);
				return isNaN(hours) || hours < 0 || hours % 0.5 !== 0;
			}
			const testValue =
				typeof logFields[name] === "string"
					? logFields[name].trim()
					: logFields[name];
			return !testValue || testValue.length === 0;
		});

		setFieldErrors(errors);
		if (errors.length > 0) return;

		const updated = await onUpdate(log.id, mapLogFromFields(logFields));
		if (updated) {
			onToggleExpand()
			toast.success(
				<ToastMessage>{t("pd-plan.logs.success.update")}</ToastMessage>,
			);
		} else {
			toast.error(
				<ToastMessage>
					{t("pd-plan.logs.error.update")}
				</ToastMessage>,
			);
		}
	};

	const getSubtitle = () => {
		const dateText = formatPDLogDate(logFields);
		if (isReviewer && teacherNames.length > 0) {
			return (
				<>
					{dateText}
					<br />
					<u>{`${teacherNames.length}`} other participants</u>: {teacherNames.join(', ')}
				</>
			);
		}
		return dateText;
	};

	return (
		<Accordion
			title={truncateText(logFields.activityName) || `${t("pd-plan.logs.activity")} ${log.ID}`}
			subtitle={getSubtitle()}
			onChange={onToggleExpand}
			expanded={expanded}
			description={truncateText(logFields.description)}
			isReviewer={isReviewer}
			isAuthor={isAuthor}
			approveButton={
				onApprove && (
					<Button
						outline
						color="success"
						onClick={() => onApprove(log.id)}
						style={{
							paddingLeft: "1rem",
							display: "flex",
							alignItems: "center",
							gap: "0.5rem",
						}}
					>
						{t("acknowledge")}
					</Button>
				)
			}
			{...props}
		>
			<Box onSubmit={onSubmitLog} component="form">
				<Box>
					<GoalTextField
						name="activityName"
						readOnly={readOnly}
						onChange={onUpdateLogField}
						value={logFields.activityName}
						error={fieldErrors.includes("activityName")}
						label={t("pd-plan.logs.labels.activity-name")}
						description={t("pd-plan.logs.descriptions.activity-name")}
					/>
					<GoalTextField
						name="description"
						readOnly={readOnly}
						onChange={onUpdateLogField}
						value={logFields.description}
						error={fieldErrors.includes("description")}
						label={t("pd-plan.logs.labels.description")}
						description={t("pd-plan.logs.descriptions.description")}
						rows="5"
						multiline
					/>
					{goals && goals.length > 0 && isAuthor && !isReviewer && (
						<GoalSelectField
							name="relatedPdPlanGoal"
							readOnly={readOnly}
							onChange={onUpdateLogField}
							value={logFields.relatedPdPlanGoal}
							error={fieldErrors.includes("relatedPdPlanGoal")}
							options={goals}
							required={false}
							label={t("pd-plan.logs.labels.related-goal")}
							description={t("pd-plan.logs.descriptions.related-goal")}
							multiple
						/>
					)}
					<GoalTextField
						name="startDate"
						readOnly={readOnly}
						onChange={onUpdateLogField}
						value={logFields.startDate}
						error={fieldErrors.includes("startDate")}
						label={multiDayActivity ? t("pd-plan.logs.labels.start-date") : t("pd-plan.logs.labels.activity-date")}
						type="date"
						inputProps={{
							max: "2999-12-31"
						}}
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={multiDayActivity}
								onChange={(e) =>
									setMultiDayActivity(e.target.checked)
								}
								disabled={readOnly}
							/>
						}
						label={t("pd-plan.logs.labels.multi-date-checkbox")}
					/>
					{multiDayActivity && (
						<GoalTextField
							name="endDate"
							readOnly={readOnly}
							onChange={onUpdateLogField}
							value={logFields.endDate}
							error={fieldErrors.includes("endDate")}
							label={t("pd-plan.logs.labels.end-date")}
							description={t("pd-plan.logs.descriptions.end-date")}
							type="date"
							inputProps={{
								max: "2999-12-31"
							}}
						/>
					)}
					<GoalTextField
						name="hours"
						readOnly={readOnly}
						onChange={onUpdateLogField}
						value={logFields.hours}
						error={fieldErrors.includes("hours")}
						label={t("pd-plan.logs.labels.hours")}
						type="number"
						inputProps={{
							step: "0.5",
							min: "0"
						}}
					/>
					{ isAuthor && (!isReviewer || isPrincipal) && teachers && teachers.length > 0 &&
						<>
							<FormControlLabel
								control={
									<Checkbox
										checked={multiTeachers}
										onChange={(e) =>
											setMultiTeachers(e.target.checked)
										}
										disabled={readOnly}
									/>
								}
								label={t("pd-plan.logs.labels.multi-teacher-checkbox", {teacher: isPrincipal ? 'this teacher' : 'you'})}
							/>
							{ multiTeachers &&
								<GoalSelectField
									name="teacher"
									readOnly={readOnly}
									onChange={onUpdateLogField}
									value={logFields.teacher}
									error={fieldErrors.includes("teacher")}
									options={teachers}
									required={false}
									label={t("pd-plan.logs.labels.teachers")}
									multiple
								/>
							}
						</>
					}
				</Box>
				<Box
					sx={{
						display: "flex",
						justifyContent: "space-between",
						mt: "3rem",
						}}
				>
					{ onDelete &&
						<Button
							outline
							color="primary"
							onClick={() => onDelete(log.id)}
							style={{
								paddingLeft: "1rem",
								display: "flex",
								alignItems: "center",
								gap: "0.5rem",
							}}
						>
							<DeleteForeverIcon /> {t("delete")}
						</Button>
					}
					{
					  onUpdate &&
						<Button color="primary" type="submit">
							{t("save")}
						</Button>
					}
				</Box>
			</Box>
		</Accordion>
	);
};

import { useMutation } from "@tanstack/react-query";
import { createInstance } from "../mutations/create-instance";
import { updateFormResponseValue } from "../mutations/update-response-value";
import { deleteInstance } from "../mutations/delete-instance";


export const useGroupHandler = (form) => {

  const updateField = useMutation({
    mutationFn:({ id, value }) => updateFormResponseValue(id, value, true),
    onSuccess:(newField) => {
      form.updateField(newField.id,newField.value);
    },
    onError: (error) => {
      console.error("Error updating field:", error);
    },
  });

  const createNewInstance = useMutation({
    mutationFn: (group) => createInstance(group, form.response_id),
    onSuccess: (newInstance, group) => { 
      group.addInstance(newInstance);
    },
    onError: (error) => {
      console.error("Error creating instance:", error);
    },
  });

  const deleteFormInstance = useMutation({
    mutationFn: ({ group, instance_number }) =>
      deleteInstance(form.response_id, instance_number),
      onSuccess: (newInstance, variables) => {
        const { group, instance_number } = variables;
        console.log('group', group);
        console.log('form', form);
        if (group && typeof group.deleteInstance === "function") {
          group.deleteInstance(instance_number);
        } else {
          console.error("Group or deleteInstance function is not defined");
        }
      },
      onError: (error) => {
        console.error("Error creating instance:", error);
      },
  })

  const handleDeleteInstance = (group, instance_number) => {
    deleteFormInstance.mutate({group, instance_number})
  }

  const handleAddInstance = (group) => {
    createNewInstance.mutate(group);
  }

  const handleUpdateField = (id, value) => {
    updateField.mutate({  id, value });
  };
  
  return {
    handleAddInstance,
    handleDeleteInstance,
		handleUpdateField,
	};
};

import { RESPONSE_KEYS } from "../constants/response-keys";

export const transformResponse = (rawResponse) => {
	const { ID, FORM_ID, USER_ID, SCHOOL_IDS, STATUS, VALUES, DEA_COMMENT, DEA_ID, USER_COMMENT, SUBMITTED_AT, CREATED_AT, LAST_SAVED_AT } = RESPONSE_KEYS;
	const { id, form_id, user_id, status, school_ids, dea_user_id, dea_comment, user_comment, submitted_at, created_at, last_saved_at } = rawResponse || {};

	return {
		[ID]: id,
		[FORM_ID]: form_id,
		[USER_ID]: user_id,
		[STATUS]: status,
		[SCHOOL_IDS]: school_ids,
		[VALUES]: [],
		[DEA_ID]: dea_user_id,
		[DEA_COMMENT]: dea_comment,
		[USER_COMMENT]: user_comment,

		[SUBMITTED_AT]: submitted_at,
		[CREATED_AT]: created_at,
		[LAST_SAVED_AT]: last_saved_at,
	};
};

import React, { useEffect, useState } from "react";
import { Button, InputGroup } from "reactstrap";
import InputSearch from "@mui/material/Input";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const SearchInput = ({ value, onSearch }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");

  useEffect(() => {
    setSearch(value);
  }, [value])

  return (
    <InputGroup>
      <InputSearch
        type="text"
        size="large"
        variant="solid"
        placeholder={t("search-by-name")}
        aria-label={t("search-by-name")}
        disableUnderline={true}
        sx={{
          borderRadius: "1.625rem",
          width: "100%",
          color: "black",
          backgroundColor: "white",
          padding: 0,
          paddingLeft: "1.5rem",
        }}
        endAdornment={
          <div className="search-suffix">
            {search && (
              <IconButton
                disableRipple
                disableFocusRipple
                onClick={() => setSearch("")}
                style={{
                  padding: 0,
                  paddingRight: "10px",
                }}
              >
                <ClearIcon />
              </IconButton>
            )}
            <Button
              color="secondary"
              style={{
                borderRadius: "1.625rem",
                padding: ".25rem 0.5rem 0.25rem",
                textTransform: "none",
                fontWeight: "400",
              }}
              onClick={() => onSearch(search)}
            >
              <SearchIcon />
            </Button>
          </div>
        }
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onKeyDown={(event) => {
          if (event.keyCode === 13) {
            onSearch(search)
          }
        }}
      />
    </InputGroup>
  )
}

export default SearchInput;
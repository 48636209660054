export const sections = [
	{ id: "01", label: "Section 1" },
	{ id: "02", label: "Section 2" },
	{ id: "03", label: "Section 3" },
	{ id: "04", label: "Section 4" },
	{ id: "05", label: "Section 5" },
	{ id: "06", label: "Section 6" },
	{ id: "07", label: "Section 7" },
	{ id: "08", label: "Section 8" },
	{ id: "09", label: "Section 9" },
	{ id: "10", label: "Section 10" },
];

export const errorSections = ["03", "05"];
export const disabledSections = ["04", "06"];

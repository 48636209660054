import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import { fetchFormById } from "../queries/fetch-form";
import { fetchFormFields } from "../queries/fetch-form-fields";
import { fetchFormResponseById } from "../queries/fetch-form-response";
import { fetchFormResponseValues } from "../queries/fetch-form-response-values";

export const useFormData = () => {
  const { userInfo } = useSelector((state) => state.appInfo);
  const { responseId: paramResponseId } = useParams();

  if (!paramResponseId) {
    throw new Error("paramResponseId is required");
  }

  // Fetch the form response by paramResponseId
  const formResponseQuery = useQuery({
    queryKey: ["formResponses", { responseId: paramResponseId }],
    queryFn: () => fetchFormResponseById(paramResponseId),
    enabled: !!paramResponseId,
    cacheTime: 0,
  });

  const formId = formResponseQuery.data?.form_id;

  // Restriction logic based on user info
  const restricted = useMemo(() => {
    if (formResponseQuery.data) {
      return !formResponseQuery.data?.school_ids?.some((value) =>
        userInfo.Cluster.includes(value) ||
        userInfo["School Name"].includes(value) ||
        userInfo.id === formResponseQuery.data.user_id
      );
    }
    return false;
  }, [formResponseQuery.data, userInfo]);

  // Fetch the form details using formId
  const formQuery = useQuery({
    queryKey: ["forms", { formId }],
    queryFn: () => fetchFormById(formId),
    enabled: !!formId && !restricted,
    cacheTime: 0,
  });

  const templateId = formQuery.data?.template_id;

  // Fetch the form fields using templateId
  const formFieldsQuery = useQuery({
    queryKey: ["formFields", { templateId }],
    queryFn: () => fetchFormFields(templateId),
    enabled: !!templateId && !restricted,
    cacheTime: 0,
  });

  // Fetch the response values for the current response
  const responseValuesQuery = useQuery({
    queryKey: ["responseValues", { responseId: paramResponseId }],
    queryFn: () => fetchFormResponseValues(paramResponseId),
    enabled: !!paramResponseId && !restricted,
    cacheTime: 0,
  });

  const form = useMemo(() => {
    if (!formQuery.data) return null;

    const baseForm = { ...formQuery.data };

    if (formFieldsQuery.data) {
      baseForm.updateWithPages(formFieldsQuery.data);
    }
    if (formResponseQuery.data) {
      baseForm.updateWithResponse(formResponseQuery.data);
    }
    if (responseValuesQuery.data) {
      baseForm.updateWithResponseValues(responseValuesQuery.data);
    }

    return baseForm;
  }, [
    formQuery.data,
    formFieldsQuery.data,
    formResponseQuery.data,
    responseValuesQuery.data,
  ]);

  const isLoading = useMemo(
    () =>
      [
        formQuery.isLoading,
        formFieldsQuery.isLoading,
        formResponseQuery.isLoading,
        responseValuesQuery.isLoading,
      ].some(Boolean),
    [
      formQuery.isLoading,
      formFieldsQuery.isLoading,
      formResponseQuery.isLoading,
      responseValuesQuery.isLoading,
    ]
  );

  const error = useMemo(() => {
    const errors = {
      formError: formQuery.error,
      formFieldsError: formFieldsQuery.error,
      formResponseError: formResponseQuery.error,
      responseValuesError: responseValuesQuery.error,
    };
    return Object.values(errors).some(Boolean) ? errors : null;
  }, [
    formQuery.error,
    formFieldsQuery.error,
    formResponseQuery.error,
    responseValuesQuery.error,
  ]);

  const submitted = form?.response_status
    ? form?.response_status === "submitted"
    : false;

  const editMode = !restricted && !submitted;

  return {
    form,
    loading: isLoading,
    editMode,
    submitted,
    restricted,
    error,
  };
};

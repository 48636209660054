import React from "react";
import { Box, Typography, List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Start } from "./Start";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { LongTextInput } from "./inputs";


//The page nav function will be passed in as a prop. The button will call this function when clicked.  This button will also be disabled till the 'privacy' checkbox is checked.
export const OverviewPage = (props) => {
	const { t } = useTranslation();
	const {
		handleFormStart,
		handleRouteNext,
		handleUpdateResponse,
		response
	} = props
	const standards = t("db_group_pages.overview.five_standards.standards", { returnObjects: true });
	const aboutPoints = t("db_group_pages.overview.about_this_report.description.purposes", { returnObjects: true });
	const started = response?.status === "not-started"
	const submitted = response?.status === "submitted"

	const startProps = started ? {
		handleButtonClick: handleFormStart,
		buttonLabel: t("principal.overview-page.button-label"),
		isChecked: false,
		isDisabled: true,
	} : {
		handleButtonClick: handleRouteNext,
		buttonLabel: t("principal.overview-page.button-label-continue"),
		isChecked: true,
		isDisabled: false,
	}

	return (
		<Box
			sx={{
				backgroundColor: "#FFF",
				border: "1px solid #9ce0f8",
				borderRadius: "1rem",
				padding: { xs: "1.25rem", md: "1.875rem" },
			}}
		>
			<Typography
				variant="h2"
				sx={{
					fontSize: "1.875rem",
					fontFamily: "Effra, sans-serif",
					fontWeight: "700",
					color: "#0D1120",
					marginBottom: "0.625rem",
				}}
			>
				{t(`db_group_pages.overview.heading`)}
			</Typography>
			{ !submitted ?
				<>
			<Typography
				variant="body1"
				sx={{
					fontSize: "1.125rem",
					fontFamily: "Effra, sans-serif",
					fontWeight: "400",
					color: "#0D1120",
				}}
			>
				{t(`db_group_pages.overview.about_this_report.description.intro`)}
			</Typography>
			
			<List sx={{marginBottom: "1.2rem"}}>
				{aboutPoints.map((aboutPoints, index) => (
					<ListItem key={index}
						sx={{
							padding: 0,
							color: "#0D1120",
							fontFamily: "Effra, sans-serif",
						}}
						>
							<ListItemIcon  sx={{ minWidth: "1.2rem" }}>
								<FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />
							</ListItemIcon>
							<ListItemText primary={aboutPoints}
								sx={{
								fontFamily: "Effra, sans-serif!important",
						}} />
					</ListItem>
				))}
			</List>

			
			<Typography
				variant="h3"
				sx={{
					fontSize: "1.56rem",
					fontFamily: "Effra, sans-serif",
					fontWeight: "700",
					color: "#0D1120",
				}}
			>
				{t(`db_group_pages.overview.five_standards.heading`)}
			</Typography>
			<List >
				{standards.map((standard, index) => (
					<ListItem key={index} py="1" 
						sx={{
							paddingLeft: "0", 
							color: "#0D1120",
							padding: 0,
						}}
						>
							<ListItemText  primary={`${index + 1}. ${standard}`} />
					</ListItem>
				))}
			</List>
			<Box
				sx={{
					backgroundColor: "#F3F9FF",
					padding: "1.25rem",
					marginY: "1.25rem",
				}}
			>
				<Typography
					variant="h3"
					sx={{
						fontSize: "1.56rem",
						fontFamily: "Effra, sans-serif",
						fontWeight: "700",
						color: "#0D1120",
						marginBottom: "0.625rem",
					}}
				>
					{t(`db_group_pages.overview.terms.heading`)}
				</Typography>
				<Typography
					variant="body1"
					sx={{
						fontSize: "1.125rem",
						fontFamily: "Effra, sans-serif",
						fontWeight: "400",
						color: "#0D1120",
						marginBottom: "1.2rem",
					}}
				>
					{t(`db_group_pages.overview.terms.reporting_requirement`)}
				</Typography>
				<Typography
					variant="h3"
					sx={{
						fontSize: "1.56rem",
						fontFamily: "Effra, sans-serif",
						fontWeight: "700",
						color: "#0D1120",
						marginBottom: "0.625rem",
					}}
				>
					{t(`db_group_pages.overview.terms.headingPrivacy`)}
				</Typography>
				<Typography
					variant="body1"
					sx={{
						fontSize: "1.125rem",
						fontFamily: "Effra, sans-serif",
						fontWeight: "400",
						color: "#0D1120",
						marginBottom: "1.2rem",
					}}
				>
					{t(`db_group_pages.overview.terms.privacy_notice`)}
				</Typography>
				<Start
					{...startProps}
					header="Test Header"
					description="Test Description"
					
				/>
			</Box>
			</> :
			 response && 
				(<>
					<h3>Comments and suggestions</h3>
					<p>Date: {response.submitted_at}</p>
					<p>Comment: {response.dea_comment}</p>
					<LongTextInput 
						value={response.dea_comment}					
						onChange = {(val) => {
							handleUpdateResponse({dea_comment: val})
						}}
						multiple = {true}
					/>
					<p>Comment By: {response.dea_user_id}</p>
					
					<p>User Comment: {response.user_comment}</p>
					<LongTextInput 
						value={response.user_comment}
						isDisabled={!!response.user_comment}					
						onChange = {(val) => {
							handleUpdateResponse({user_comment: val})
						}}
						multiple = {true}
					/>
				</>)
			}
		</Box>
	);
};

import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import './styles.scss'
import { useTranslation } from 'react-i18next';
import { DAY_LIMITATIONS_MAP, GradeLevels, MAX_SUBJECT_NUM, SELECTBOX_STYLE, WellBeingLinks } from '../../../utils/constants';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { distinctArray } from '../../../utils/array';
import Loading from '../../../components/Loading';
import { getDefaultAlternativeTime, getDefaultPreferredTime } from '../Request/utils';
import airtable from '../../../airtables';
import { isSameDay, isValidDateOnPicker } from '../../../utils/time';
import { toast } from 'react-toastify';
import SessionValidationChecker from '../../../components/SessionValidationChecker';

const StepFillInformation = ({
  previous,
  next,
  session,
  teachers,
  hosts,
  dayRestrictionConfig,
  presenters,
  providerId,
  loading,
  prefill = null
}) => {
  const { totalSubjects, appLocale } = useSelector(state => state.appInfo)
  const [sessionDate, setSessionDate] = useState(getDefaultPreferredTime(dayRestrictionConfig, session["Provider Advance Booking Requirement"]));
  const [alterSessionDate, setAlterSessionDate] = useState(getDefaultAlternativeTime(dayRestrictionConfig, session["Provider Advance Booking Requirement"]));
  const [status, setStatus] = useState("");
  const [length, setLength] = useState(session["Length (Minutes)"] || 0);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [cilcReqId, setCilcReqId] = useState("");
  const [studentNum, setStudentNum] = useState(0);
  const [host, setHost] = useState(null)
  const [wbLinks, setWBLinks] = useState([]);
  const [presenter, setPresenter] = useState(null);
  const [suppliesShipment, setSuppliesShipment] = useState(false);
  const [notes, setNotes] = useState("");
  const [hostNotes, setHostNotes] = useState("");
  const [providerData, setProviderData] = useState(null);
  const [dayLimitations, setDayLimitations] = useState([]);
  const [preferredTimeChanged, setPreferredTimeChanged] = useState(false);
  const [isStartTimeValid, setIsStartTimeValid] = useState("");
  const [isAlterTimeValid, setIsAlterTimeValid] = useState("");
  const [showAlternativeDate, setShowAlternativeDate] = useState(false);

  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const getSubjectName = (subject) => {
    return appLocale === 'fr' ? subject["Subject (FR)"] : subject["Subject"];
  }

  const isDateValid = (currentDate) => {
    // First check day limitations (existing functionality)
    if (!isValidDateOnPicker(dayLimitations, currentDate)) {
      return false;
    }

    // Only check advance booking requirement if there are supplies and shipment is needed
    if (session["Supplies"]?.length && !suppliesShipment) {
      // Check against advance booking requirement
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      const advanceRequirement = session["Provider Advance Booking Requirement"];
      if (advanceRequirement) {
        const minBookingDate = new Date(today);
        minBookingDate.setDate(today.getDate() + parseInt(advanceRequirement));
        return currentDate.valueOf() >= minBookingDate.valueOf();
      }
    }

    return true;
  }

  useEffect(() => {
    initialize();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prefill && prefill["Presenters"] && prefill["Presenters"].length) {
      const p = presenters.find(t => t.id === prefill["Presenters"][0]);
      if (p) {
        setPresenter({ value: p.id, label: p.Name });
      }
    } else if (session["Default Presenter"] && presenters) {
      const defaultPresenter = presenters.find(p => p.id === session["Default Presenter"][0]);
      if (defaultPresenter) {
        setPresenter({ value: defaultPresenter.id, label: defaultPresenter.Name });
      }
    }
  }, [prefill, session, presenters]);

  const initialize = async () => {
    const providerInfo = await airtable.providers.select(providerId);
    setProviderData(providerInfo);

    if (providerInfo["Day Limitations"]) {
      const dl = providerInfo["Day Limitations"].map(l => DAY_LIMITATIONS_MAP[l])
      setDayLimitations(dl)
      setSessionDate(getDefaultPreferredTime(dayRestrictionConfig, session["Provider Advance Booking Requirement"], dl));
      setAlterSessionDate(getDefaultAlternativeTime(dayRestrictionConfig, session["Provider Advance Booking Requirement"], dl));
    } else {
      setDayLimitations([]);
    }

    if (prefill && prefill["Well-being Link"]) {
      setWBLinks(prefill["Well-being Link"].map(link => ({ value: link, label: link })))
    } else if (session["Well-being Link"]) {
      setWBLinks(session["Well-being Link"].map(link => ({ value: link, label: link })))
    }

    const gs = teachers.reduce((result, teacher, i) => {
      return {
        grades: [...result.grades, ...(teacher["Grade(s) Taught"] || [])],
        subIds: [...result.subIds, ...(teacher["Subject(s) Taught"] || [])]
      }
    }, { grades: [], subIds: [] });

    if (prefill && prefill["Grade(s)"]) {
      setGrades(prefill["Grade(s)"])
    } else {
      setGrades(distinctArray(gs.grades))
    }

    if (prefill && prefill["Subject/Curriculum"]) {
      const subIds = prefill["Subject/Curriculum"];
      const ss = totalSubjects.filter(sub => subIds.indexOf(sub.id) >= 0);
      setSubjects(ss.map(s => ({ value: s.id, label: getSubjectName(s) })))
    } else {
      const subIds = gs.subIds;
      const ss = totalSubjects.filter(sub => subIds.indexOf(sub.id) >= 0);
      setSubjects(ss.map(s => ({ value: s.id, label: getSubjectName(s) })))
    }

    if (prefill) {
      if (prefill["Notes"]) setNotes(prefill["Notes"]);
      if (prefill["Session Host Notes"]) setHostNotes(prefill["Session Host Notes"]);
      if (prefill["Students"]) setStudentNum(prefill["Students"]);
      if (prefill["Length (Minutes)"]) setLength(prefill["Length (Minutes)"])
      if (prefill["Presenters"] && prefill["Presenters"].length) {
        const p = presenters.find(t => t.id === prefill["Presenters"][0]);
        setPresenter({ value: p.id, label: p.Name });
      }

      if (prefill["Supplies Shipment Not Required"]) setSuppliesShipment(prefill["Supplies Shipment Not Required"]);
      if (prefill["CILC Request ID"]) setCilcReqId(prefill["CILC Request ID"]);
    }
  }

  const onNext = () => {
    let errList = {}
    if (!preferredTimeChanged) {
      errList.sessionDate = t("sessionrequest.confirm-msg");
    }

    if (!length) errList.length = "Invalid Number";
    if (!studentNum) errList.studentNum = "Invalid Number";
    if (!grades || grades.length === 0) errList.grades = "Grade(s) field is required.";
    if (!subjects || subjects.length === 0) errList.subjects = "Subject(s) field is required.";

    if (status === 'Requested' || status === 'Wish List' || status === 'In Planning' || status === 'Pending Provider Response') {
      if (showAlternativeDate && !alterSessionDate) {
        errList.alterSessionDate = "Alternative Date/Time is required when selected";
      }
    }

    if (Object.keys(errList).length) {
      setErrors(errList);
      return;
    }

    let infos = {
      sessionDate,
      alterSessionDate,
      status,
      length,
      studentNum,
      grades,
      subjects: subjects.map(sub => sub.value),
      cilcReqId,
      host: host ? host.value : null,
      wbLinks: wbLinks.map(l => l.value),
      suppliesShipment,
      presenter: presenter?.value || null,
      notes,
      hostNotes
    }

    next(infos);
  }

  const showSameDayWarning = () => {
    if (isSameDay(sessionDate, alterSessionDate)) {
      toast.warning(t("sessionrequest.second-date-msg"), 10000);
    }
  }

  const getDayLimitationString = () => {
    const limitations = providerData["Day Limitations"];
    switch (limitations.length) {
      case 0:
        return "";
      case 1:
        return limitations[0];
      default:
        const subs = limitations.slice(0, limitations.length - 1);
        return subs.join(", ") + " and " + limitations[limitations.length - 1];
    }
  }

  const canNext = () => {
    if (loading) return false;
    if (!status) return false;
    if (isStartTimeValid !== "valid") return false;
    if (showAlternativeDate && isAlterTimeValid !== "valid") return false;
    return true;
  }

  const renderTeacherCreditInfo = (teacher) => {
    var creditCalc;
    var creditCost;
    const providerGroups = session["Indigenous Group(s)"];
    const schoolGroups = teacher["School Indigenous Group(s)"];

    if (providerGroups && schoolGroups && Array.isArray(providerGroups) && providerGroups.length > 0 && providerGroups.some(group => schoolGroups.includes(group))) {
      creditCalc = teacher['Credit Balance'] - session['Discounted Credits'];
      creditCost = session['Discounted Credits'];
    } else {
      creditCalc = teacher['Credit Balance'] - session['Credits'];
      creditCost = session['Credits'];
    }

    if (creditCalc >= 0) {
      return (
        <div className='credit-status'>
          <span className='text-left'>
            {teacher["Teacher Name"]}'s Available Credits: {teacher['Credit Balance']}<br></br>
            {t("session-credits")}: {creditCost}<br></br>
          </span>
          <span>
            {t("remaining-credits")}: {creditCalc}
          </span>
        </div>
      )
    }
  }

  return (
    <Fragment>
      <div className="step-content">
        {(!!providerData && dayLimitations.length > 0) && (
          <div className="day-limitations-msg">
            <span>{providerData.Name} is only available for bookings on {getDayLimitationString()}</span>
          </div>
        )}
        {(!!providerData && providerData['Other Limitations']) && (
          <div className="day-limitations-msg">
            <span>{providerData['Other Limitations']}</span>
          </div>
        )}
        {teachers.length === 1 && (renderTeacherCreditInfo(teachers[0]))}
        <Form>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <label>Status</label>
                <Input
                  id="status"
                  type="select"
                  className="form-control"
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                  disabled={loading}
                >
                  <option></option>
                  <option>Booked</option>
                  <option>Wish List</option>
                  <option>In Planning</option>
                  <option>Pending Provider Response</option>
                  <option>Requested</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <label>Session Date & Time</label>
                <div className={classNames({ "is-invalid": errors.sessionDate ? true : false })}>
                  <InputGroup className="input-group-alternative">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                    <ReactDatetime
                      value={sessionDate}
                      inputProps={{
                        placeholder: t("sessionrequest.prefer-time"),
                        style: {
                          color: 'black',
                          fontSize: 14
                        },
                        disabled: loading
                      }}
                      timeConstraints={{
                        minutes: { step: 5 }
                      }}
                      timeFormat={true}
                      isValidDate={isDateValid}
                      onChange={(e) => {
                        if (typeof e !== 'string') {
                          setSessionDate(e.toDate());
                          showSameDayWarning();
                          setPreferredTimeChanged(true);
                        }
                      }}
                    />
                  </InputGroup>
                </div>
                {!!session && !!providerData && (
                  <SessionValidationChecker
                    time={sessionDate}
                    session={session}
                    provider={providerData}
                    teachers={teachers}
                    changeValidation={v => setIsStartTimeValid(v)}
                  />
                )}
              </FormGroup>
            </Col>

            {(status === 'Requested' || status === 'Wish List' || status === 'In Planning' || status === 'Pending Provider Response') && (
              <Col sm={6}>
                <FormGroup>
                  <label>
                    <Input
                      type="checkbox"
                      checked={showAlternativeDate}
                      onChange={() => setShowAlternativeDate(!showAlternativeDate)}
                      disabled={loading}
                    />{' '}
                    Add Alternative Session Date & Time
                  </label>
                  {showAlternativeDate && (
                    <div className={classNames({ "is-invalid": errors.alterSessionDate ? true : false })}>
                      <InputGroup className="input-group-alternative">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                        <ReactDatetime
                          value={alterSessionDate}
                          inputProps={{
                            placeholder: "Alternative Session Date & Time",
                            style: {
                              color: 'black',
                              fontSize: 14
                            },
                            disabled: loading
                          }}
                          timeConstraints={{
                            minutes: { step: 5 }
                          }}
                          timeFormat={true}
                          isValidDate={isDateValid}
                          onChange={(e) => {
                            if (typeof e !== 'string') {
                              setAlterSessionDate(e.toDate());
                              showSameDayWarning();
                            }
                          }}
                        />
                      </InputGroup>
                      {errors.alterSessionDate && (
                        <div className="invalid-feedback">{errors.alterSessionDate}</div>
                      )}
                    </div>
                  )}
                  {!!session && !!providerData && showAlternativeDate && (
                    <SessionValidationChecker
                      time={alterSessionDate}
                      session={session}
                      provider={providerData}
                      teachers={teachers}
                      changeValidation={v => setIsAlterTimeValid(v)}
                    />
                  )}
                </FormGroup>
              </Col>
            )}
          </Row>

          <Row>
            <Col sm={6}>
              <FormGroup>
                <label>{t("length")}</label>
                <div className={classNames({ "is-invalid": errors.length ? true : false })}>
                  <InputGroup>
                    <Input
                      id="length"
                      type="number"
                      value={length}
                      onChange={(e) => {
                        const res = parseInt(e.target.value);
                        let err = { ...errors };
                        if (isNaN(res)) {
                          setLength(0);
                          err.length = "Invalid number";
                        } else {
                          err.length = false;
                          delete err.length
                          setLength(res)
                        }
                        setErrors(err);
                      }}
                      // invalid={errors.length ? true : false}
                      disabled={loading}
                    />
                    <InputGroupText>minutes</InputGroupText>
                  </InputGroup>
                </div>
                <div className="invalid-feedback">{errors.length}</div>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <label>How many students do you expect to attend?</label>
                <div className={classNames({ "is-invalid": errors.studentNum ? true : false })}>
                  <Input
                    type="number"
                    className="form-style"
                    value={studentNum}
                    onChange={e => {
                      const res = parseInt(e.target.value);
                      let err = { ...errors };
                      if (isNaN(res)) {
                        setStudentNum(0);
                        err.studentNum = "Invalid number";
                      } else {
                        err.studentNum = false;
                        delete err.studentNum
                        setStudentNum(res)
                      }
                      setErrors(err);
                    }}
                    // invalid={errors.s  tudentNum ? true : false}
                    disabled={loading}
                  />
                </div>
                <div className="invalid-feedback">{errors.studentNum}</div>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <label>{t("grades")}</label>
            <div className={classNames({
              "is-invalid": errors.grades ? true : false
            })}>
              <Select
                isMulti
                className="form-style"
                value={grades.map(g => ({ value: g, label: g }))}
                onChange={(selectedOptions) => {
                  const gs = selectedOptions.map(option => option.value)
                  setGrades(gs)

                  let err = { ...errors };
                  if (gs.length) delete err.grades;
                  else err.grades = "Grade(s) field is required."
                  setErrors(err);
                }}
                options={GradeLevels.map(g => ({ value: g, label: g }))}
                styles={SELECTBOX_STYLE}
                isDisabled={loading}
              />
            </div>
            <div className="invalid-feedback">{errors.grades}</div>
          </FormGroup>

          <FormGroup>
            <label>{t("subjects")}</label>
            <div className={classNames({ "is-invalid": errors.subjects ? true : false })}>
              <Select
                isMulti
                className="form-style"
                value={subjects}
                onChange={(selectedOptions) => {
                  setSubjects(selectedOptions)

                  let err = { ...errors };
                  if (!selectedOptions.length) err.subjects = 'no-subjects'
                  else if (selectedOptions.length > MAX_SUBJECT_NUM) err.subjects = 'over'
                  else delete err.subjects;
                  setErrors(err);
                }}
                options={totalSubjects.map(subject => ({
                  value: subject.id,
                  label: getSubjectName(subject)
                }))}
                styles={SELECTBOX_STYLE}
                isDisabled={loading}
              />
            </div>
            <div className="invalid-feedback">
              {errors.subjects === 'over' ? `Please select a maximum of ${MAX_SUBJECT_NUM} Subject areas most closely aligned to this Session.` : "Subject field is required."}
            </div>
          </FormGroup>

          {(session["CILC?"] && session["CILC?"][0]) ? (
            <FormGroup>
              <label>CILC Request ID</label>
              <Input
                type="text"
                className="form-style"
                value={cilcReqId}
                onChange={e => setCilcReqId(e.target.value)}
                disabled={loading}
              />
            </FormGroup>
          ) : null}

          <FormGroup>
            <label>Session Host (optional)</label>
            <Select
              className="form-style"
              value={host}
              onChange={(selectedOption) => setHost(selectedOption)}
              options={hosts.map(host => ({ value: host.id, label: host.Name }))}
              styles={SELECTBOX_STYLE}
              isDisabled={loading}
            />
          </FormGroup>

          <FormGroup>
            <label>{t("well-being-links")}</label>
            <Select
              isMulti
              className="form-style"
              value={wbLinks}
              onChange={(selectedOptions) => setWBLinks(selectedOptions)}
              options={WellBeingLinks.map(link => ({
                value: link,
                label: t(link)
              }))}
              styles={SELECTBOX_STYLE}
              isDisabled={loading}
            />
          </FormGroup>

          {presenters && presenters.length ? (
            <FormGroup>
              <label>
                Presenter {session["Default Presenter"]
                  ? "(The default presenter is selected, only change if directed by Provider)"
                  : "(if known)"}
              </label>
              <Select
                className="form-style"
                value={presenter}
                onChange={(selectedOption) => setPresenter(selectedOption)}
                options={presenters.map(p => ({ value: p.id, label: p.Name }))}
                styles={SELECTBOX_STYLE}
                isDisabled={loading}
              />
            </FormGroup>
          ) : null}

          <div style={{ height: 10 }} />
          <Row>
          <Col sm={6}>
            <FormGroup>
              <label htmlFor="notes">Notes for Provider</label>
              <Input
                id="notes"
                name="notes"
                type="textarea"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                className="optional-textarea"
                disabled={loading}
              />
            </FormGroup>
          </Col>
          <Col sm={6}>
          <FormGroup>
              <label htmlFor="notes">Notes/Context for Host</label>
              <Input
                id="hostnotes"
                name="hostnotes"
                type="textarea"
                value={hostNotes}
                onChange={(e) => setHostNotes(e.target.value)}
                className="optional-textarea"
                disabled={loading}
              />
            </FormGroup>
            </Col>
          </Row>
          {session["Supplies"] && session["Supplies"].length ? (
            <FormGroup check>
              <Input type='checkbox' id="suppliesShipment" onChange={(e) => setSuppliesShipment(e.target.checked)} disabled={loading} />
              <Label check for="suppliesShipment" style={{ fontSize: 16 }}>If this is a rebooking of a session where the class already has the required materials, and/or you do not want this booking to generate a request for supplies to be mailed, please check this box!</Label>
            </FormGroup>
          ) : null}
        </Form>
      </div>
      <div className="step-actions">
        <Button
          outline
          onClick={previous}
          disabled={loading}
        >Back</Button>
        <Button
          color="primary"
          onClick={onNext}
          disabled={!canNext()}
        >{loading && <Loading size={20} />}&nbsp;Book</Button>
      </div>
    </Fragment>
  )
}

export default StepFillInformation;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import airtable from "../../../airtables";
import supabase from "../../../database";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { createFormResponse } from "../../PrincipalReporting/mutations/create-form-response";
import { createInitialValues } from "../../PrincipalReporting/mutations/create-initial-values";
import { getAllFieldsFromLayout } from "../../PrincipalReporting/util/get-all-fields-from-layout";
import { fetchFormFields } from "../../PrincipalReporting/queries/fetch-form-fields";

export const useSchools = () => {
  const { userType, userInfo } = useSelector(state => state.appInfo);
  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [school, setSchool] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    initialize();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (schools && schools.length) setSchool(
      {...schools[0]
      });
  }, [schools]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickReport = (reportId) => {
		history.push(`principal-reporting/${reportId}`);
	}

  const handleCreateReport = async (schoolId, activeForm) => {
    await createFormResponse(activeForm.id, userInfo.id, [schoolId]).then(async(response) => {
      const pages = await fetchFormFields(activeForm.template_id);
      const fields = getAllFieldsFromLayout({pages: pages});
      await createInitialValues(response.id, fields).then((values) => {
        handleClickReport(response.id);
      }
    )}
  )};

	const initialize = async () => {
		let sIds = null;
		if (userType === "Team") {
			if (!userInfo.Administrator) {
				sIds = userInfo["Schools Managed"];
			}
		} else if (userType === "Teacher") {
			if (userInfo["Cluster Leadership"]) {
				const cluster = await airtable.clusters.select(
					userInfo["Cluster Leadership"][0],
				);
				sIds = cluster["Schools"];
			} else {
				sIds = userInfo["School Name"];
			}

      if (!sIds || !sIds.length) {
        setSchools([]);
        setLoading(false)
        return;
      }
    }
    const activeForm = await supabase.forms.selectActive();

    airtable.schools.getAllSchools(sIds).then(async (ss) => {
      
      const schoolsWithResponses = await Promise.all(
        ss.map(async (school) => {
          
          const formResponses = await supabase.formResponses.listAllBySchoolId(school.id);
    
          
          const processedResponses = await Promise.all(
            formResponses.map(async (response) => {
              
              // Fetch the form's name using form_id
              const parentFormName = await supabase.forms?.getFormById(response.form_id) || "Unknown Form";

              const user_info = await airtable.teachers.select(response.user_id) || {};
              const dea_info = await airtable.teachers.select(response.dea_user_id) || {};
              return {
                ...response,
                month: parentFormName,
                status: response.status,
                submitted_at: formatDate(response.submitted_at),
								last_saved_at: formatDate(
									response.last_saved_at,
								),
								created_at: formatDate(response.created_at),
                user_name: user_info["Teacher Name"] || null,
                dea_name: dea_info["Teacher Name"] || null,
                report_label: 'Enter Report',
                onClick: function() {
                  handleClickReport(response.id);
                },
              };
            })
          );

          if(processedResponses.filter(response => response.form_id === activeForm.id).length === 0) {
            processedResponses.push({
              status: "not-started",
              month: activeForm.name,
              form_id: activeForm.id,
              report_label: 'Create Report',
              onClick: function() {
                handleCreateReport(school.id, activeForm);
              }
            });
          } else {
            console.log('Already has a response for this month', processedResponses);
          }
          return {
            ...school,
            reports: processedResponses,
          };
        })
      );
      setSchools(schoolsWithResponses);
      setLoading(false);
    });
    
  }

	const formatDate = (timestamp) => {
		const date = new Date(timestamp);
		return date.toLocaleDateString("en-CA");
	};
	const getTitle = () => {
		if (loading) return t("principal.heading");
		if (schools && schools.length > 1) return t("principal.heading-multi");
		return t("principal.heading");
	};

	const addNewTeacher = async (name, email) => {
		let t = await airtable.teachers.selectTeacherByEmail(email);

		if (t) {
			throw Error(`A teacher with ${email} already registered.`);
		}

		const cluster = await airtable.clusters.selectBySchool(school.School);
		const defaultCredits =
			cluster && cluster["Default New Teacher Credits"]
				? cluster["Default New Teacher Credits"]
				: 15;

		const res = await airtable.teachers.create({
			"Teacher Name": name,
			Email: email,
			"School Name": [school.id],
			"Platform Logins": 0,
			Status: "Active",
			"Created by": "Principal through Platform",
			"E-mail Reminders": ["24 hours before"],
		});

		await airtable.credit.create({
			Teacher: [res.id],
			Credits: defaultCredits,
			Action: "Credits Issued by Program",
			"Display Note":
				"Introductory Credits - Teacher joined the Connected North Platform",
		});
		return res;
	};

	return {
		loading,
		school,
		setSchool,
		schools,
		getTitle,
		addNewTeacher,
		refresh: initialize,
	};
};

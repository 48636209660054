import { useEffect, useState } from "react"
import airtable from '../../../airtables';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export function useUpcoming() {
  const { t } = useTranslation()
  const { userInfo, teamSchools } = useSelector((state) => state.appInfo);
  const [fresh, setfresh] = useState(0);
  const [upcoming, setUpcoming] = useState({
    loading: true,
    sessions: [],
    type: "Upcoming",
    name: "upcoming",
    label: t('my-upcoming'),
    noMessage: t("no-upcoming-sessions")
  });
  const [past, setPast] = useState({
    loading: true,
    sessions: [],
    type: "Past",
    name: "past",
    label: t('view-past-sessions'),
    noMessage: t("no-past-sessions")
  });

  const [filterConditions, setFilterConditions] = useState({
    filterKeyword: "",
    filterDate: null,
    filterCILC: false,
    filterSchool: null
  })

  const refresh = () => setfresh(fresh + 1);

  useEffect(() => {
    refreshPage();
  }, [fresh]) // eslint-disable-line react-hooks/exhaustive-deps

  const refreshPage = async () => {
    const {
      filterKeyword,
      filterDate,
      filterCILC,
      filterSchool
    } = filterConditions;
    let fSchool = null;
    if (filterSchool && filterSchool.value) {
      fSchool = teamSchools.find(ts => filterSchool.value === ts.id)
    }

    airtable.sessions.getTeamUpcomingSessions(
      userInfo.id,
      userInfo['Name'],
      {
        fSchool,
        filterKeyword,
        filterDate,
        filterCILC
      }
    ).then(uts => {
      setUpcoming({
        ...upcoming,
        loading: false,
        sessions: uts
      })
    });

    airtable.sessions.getTeamUpcomingSessions(
      userInfo.id,
      userInfo['Name'],
      {
        fSchool,
        filterKeyword,
        filterDate,
        filterCILC
      },
      true
    ).then(pts => {
      setPast({
        ...past,
        loading: false,
        sessions: pts
      })
    });
  }

  return {
    schools: teamSchools,
    data: [upcoming, past],
    filterConditions,
    setFilterConditions,
    refresh
  }
}
import { VALUES_KEYS } from "../constants/response-keys";

export const transformResponseValues = (rawValues) => {
	const { ID, RESPONSE_ID, FIELD_ID, INSTANCE_NUMBER, VALUE, COMPLETED } =
		VALUES_KEYS;
	const { id, response_id, field_id, value, instance_number, completed } =
		rawValues || {};

	return {
		[ID]: id,
		[RESPONSE_ID]: response_id,
		[FIELD_ID]: field_id,
		[VALUE]: value,
		[INSTANCE_NUMBER]: instance_number,
		[COMPLETED]: completed,
	};
};

import airtable from "../../../airtables/PDAirtable";

const updateTeacherCertificate = async (id, params) => {
	try {
		const response = await airtable.nunavutTeachers.update(id, params);
		return response;
	} catch (e) {
		console.error("Error updating Certificate: ", e);
		return null;
	}
};

export { updateTeacherCertificate };

import { RepeatableAccordion } from "./RepeatableAccordion";
import React from "react";

export const Instance = (props) => {
  const {
    heading,
    repeatable = false,
    children,
    disableDelete,
    onDeleteEvent,
    id,
    expanded,
    onChange,
  } = props;

  if(repeatable) {
    return (
      <RepeatableAccordion
        id={id}
        header={heading}
        disableDelete={disableDelete}
        onDeleteEvent={onDeleteEvent}
        expanded={expanded}
        onChange={onChange}
      >
        {children}
      </RepeatableAccordion>
    )
  } else {
    return (
      <>
        {children}
      </>
    )
  }
}
  
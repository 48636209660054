import SupabaseBase from './SupabaseBase';
import {
  FieldGroupsTable,
  FieldsTable,
  FormResponsesTable,
  FormResponseValuesTable,
  FormsTable,
  GroupPagesTable
} from './tables';

class Supabase extends SupabaseBase {
  constructor(apiKey, baseUrl) {
    super(apiKey, baseUrl);

    // Initialize the tables with a consistent approach
    this.fieldGroups = new FieldGroupsTable('field_groups', this.instance);
    this.fields = new FieldsTable('fields', this.instance);
    this.formResponses = new FormResponsesTable('form_responses', this.instance);
    this.formResponseValues = new FormResponseValuesTable('form_response_values', this.instance);
    this.forms = new FormsTable('forms', this.instance);
    this.groupPages = new GroupPagesTable('group_pages', this.instance);
    this.bucket = 'form_uploads';
    
    console.log('Bucket initialized:', this.bucket);
  }

  /**
   * Delegates the `.from()` method to the Supabase client instance.
   * @param {string} tableName - Name of the table to query.
   * @returns {SupabaseQueryBuilder}
   */
  from(tableName) {
    return this.instance.from(tableName);
  }

  /**
   * Uploads a file to the Supabase bucket.
   * @param {File} file - The file to upload.
   * @param {string} path - Path in the bucket where the file will be stored.
   * @returns {Promise<{ data: object, error: object }>}
   */

  async uploadFile(file, path = `${file.name}`, bucketName = this.bucket) {
    console.log('Uploading to bucket:', bucketName); // Debugging bucket usage
    try {
      const { data, error } = await this.instance.storage
        .from(bucketName)
        .upload(path, file);

      if (error) {
        throw new Error(error.message);
      }

      return { data, error: null };
    } catch (error) {
      console.error('Upload Error:', error.message);
      return { data: null, error };
    }
  }

}

const SUPABASE_URL = 'https://vlvmzuxmyrekgmwxgtfb.supabase.co';
const SUPABASE_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZsdm16dXhteXJla2dtd3hndGZiIiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mjc5Njk1MTcsImV4cCI6MjA0MzU0NTUxN30.JgO2FSAMlC0p4M5HcqgG2mV3isQpEhCwoDuSK_LVHeM';

const supabase = new Supabase(SUPABASE_KEY, SUPABASE_URL);

export default supabase;
import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { OverviewPage } from "./OverviewPage";
import { FormPage } from "./FormPage";
import { SideBar } from "./SideBar";
import { useProgress } from "./ProgressContext";
import { disabledSections, errorSections } from "../constants/mock-sections";
import { Group } from "./Group";
import { GROUP_KEYS, INSTANCE_KEYS } from "../constants/form-keys";
import { Instance } from "./Instance";
import { RepeatableButtons } from "./RepeatableButtons";
import { debounce } from "lodash";
import { convertTypeToString, convertStringToType } from "../util/convert-input-value-type";
import { useTranslation } from "react-i18next";
import { InputField } from "./inputs";
import { option_types, response_status } from "../constants/enumerated-types";
import { uploadFile } from "../mutations/upload-file";
import { removeFile } from "../mutations/remove-file";


export const FormLayout = ({editMode, submitted, isParamResponse}) => {
	const { t } = useTranslation();
	const {
		response,
		activeRoute,
		routes,
		handleRouteNext,
		handleRouteChange,
		handleRoutePrev,
		handleFormResponseCreation,
		handleUpdateResponse,
		handleUpdateField,
		handleAddInstance,
		handleDeleteInstance,
    handleFinishEvent,
	} = useProgress();

	const isLastRoute = routes.indexOf(activeRoute) === routes.length - 1;

  const debouncedUpdateField = debounce((responseId, updatedValue, type) => {
    const stringValue = convertTypeToString(updatedValue, type);
    handleUpdateField(responseId, stringValue);
    console.log('Saving value:', {responseId, updatedValue, stringValue, type}); // For debugging
  }, 600);
  
  const handleInputChange = (responseId, updatedValue, type) => {
    const valueToSave = type === 'multi select' ? 
      (Array.isArray(updatedValue) ? updatedValue : []) : 
      updatedValue;
    debouncedUpdateField(responseId, valueToSave, type);
  };

	const handleUpdateResponseStatus = (status) => {
    if(response_status.includes(status)) {
			handleUpdateResponse({
				status,
        submitted_at: 'NOW()'
      })
		}
  }

  const [expandedAccordions, setExpandedAccordions] = useState({});
  const [finishIcons, setFinishIcons] = useState({});

  const handleAccordionToggle = (groupId, instanceIndex) => {
    setExpandedAccordions(prevState => ({
      ...prevState,
      [groupId]: {
        ...prevState[groupId],
        [instanceIndex]: prevState[groupId]?.[instanceIndex] ? false : true
      }
    }));
  };

  const handleFinishToggle = (groupId) => {
    setFinishIcons(prevState => ({
      ...prevState,
      [groupId]: !prevState[groupId]
    }));
  };

  const handleInstanceAddition = async (group) => {
    handleAddInstance(group);
    const instanceNumber = group[GROUP_KEYS.INSTANCES].length;
    setExpandedAccordions(prevState => ({
      ...prevState,
      [group.id]: {
        ...prevState[group.id],
        [instanceNumber]: true
      }
    }));
  };

  const handleFinishEventAddition = (group) => {
    handleFinishEvent(group);
    setExpandedAccordions(prevState => ({
      ...prevState,
      [group.id]: {}
    }));
    handleFinishToggle(group.id);
  };

	const debouncedUpdateResponse = debounce((data) => {
		handleUpdateResponse(data);
	}, 600);

	// Function to dynamically resolve headings for instances
	const getInstanceHeading = (firstInstance, fields) => {
		if (!fields) return firstInstance.value;

    const field = fields.find((field) => field.id === firstInstance.field_id);
		if (!field) return firstInstance.value;

		// Handle MultiSelectInput and SingleSelectInput with translations
    if (
      field.type === "multi select" ||
      field.type === "single select"
    ) {
			// Get translated options using `translation_id`
			const translatedOptions = t(
				`db_fields.${field.translation_id || "default"}.options`,
        { returnObjects: true, defaultValue: {} }
			);

			// Find the label for the current value
			const label =
				typeof firstInstance.value === "string"
					? translatedOptions[firstInstance.value]
					: firstInstance.value
							.map((val) => translatedOptions[val])
							.filter(Boolean)
							.join(", ");

			return label || firstInstance.value;
		}

		// Default behavior for non-select inputs
		return firstInstance.value;
	};

	return (
		<Grid
			container
			spacing={4}
			sx={{
				display: "flex",
				flexDirection: { xs: "column-reverse", md: "row" },
			}}
		>
      
			<Grid item xs={12} md={8}>
				<Box>
					{activeRoute ? (
						activeRoute?.id === "overview" ? (
							<>
								<OverviewPage
									response={response}
									handleRouteNext={handleRouteNext}
									handleFormStart={handleFormResponseCreation}
                  handleUpdateResponse={debouncedUpdateResponse}
								/>
							</>

						) : (
							<>
								<FormPage
									translation_id={`activeRoute?.translation_id`}
                  heading={t(`db_group_pages.${activeRoute.translation_id || 'default'}.heading`)}
                  description={t(`db_group_pages.${activeRoute.translation_id || 'default'}.description`)}
									handleRouteNext={handleRouteNext}
									handleRoutePrev={handleRoutePrev}
									hasNext={!isLastRoute}
								>
									{activeRoute?.groups?.map((group) => {
                    const {
                      fields
                    } = group;

										return (
											<Group
												key={group.id}
                      heading={t(`db_field_groups.${group.translation_id || 'default'}.heading`)}
                      buttons={group.repeatable ?
														<RepeatableButtons
															disabled={!editMode}
                              onAddEvent={() => handleInstanceAddition(group)}
                              onFinishAdding={() => handleFinishEventAddition(group)}
                          addLabel={t("form_buttons.add")}
                          finishIcons={finishIcons[group.id]}
                          finishLabel={finishIcons[group.id] ? t("form_buttons.finished_repeatable") : t("form_buttons.finish_repeatable")}                    
                        /> :
                        null
												}
											>
                      {group[GROUP_KEYS.INSTANCES]?.map((instance, i) => {
                        const firstInstance = instance?.fields[0];
                        const instanceHeading = getInstanceHeading(firstInstance, fields);
                        

													return (
														<Instance
															key={i}
                            heading={instanceHeading} 
                            repeatable={group[GROUP_KEYS.REPEATABLE]}
                            disableDelete={group[GROUP_KEYS.INSTANCES].length <= 1 || !editMode}
														onDeleteEvent={() => {
                            handleDeleteInstance(group, instance[INSTANCE_KEYS.INSTANCE_NUMBER])
														}}
                            expanded={expandedAccordions[group.id]?.[i] || false}
                            onChange={() => handleAccordionToggle(group.id, i)}
                            >
                            {instance[INSTANCE_KEYS.FIELDS]?.map((response, j) => {
                              const field = fields.find((field) => field.id === response.field_id);
                              const value = convertStringToType(response.value, field.type);
                              const hasOptions = option_types.includes(field.type);
                              const translatedOptions = hasOptions ? t(`db_fields.${field.translation_id || 'default'}.options`, { returnObjects: true, defaultValue: {}}) : []; 
                              const options = Object.entries(translatedOptions).map(([value, label]) => ({
																				value,
                                label
                              }));

																	// translation handling for dynamic teacher lists.
                              let translationID = field.function_option === 'inuktutTeachersBySchoolId' ? 'langTeacherNames' : field.function_option === 'teachersBySchoolId' ? 'langOtherTeacherNames' : field.translation_id;

																	return (
																		<InputField
                                  id={response.id}
                                  key={response.id}
                                  type={field.type}
                                  value={value}
                                  heading={t(`db_fields.${translationID || 'default'}.heading`)}
                                  description={t(`db_fields.${field.translation_id || 'default'}.description`)}
                                  helperText={t(`db_fields.${field.translation_id || 'default'}.helperText`)}
                                  placeholder={t(`db_fields.${field.translation_id || 'default'}.placeholder`)}
                                  options={ field.function_option ? field.function_option : options}
                                  unit={field.unit}
                                  max_value={field.max_value}
                                  min_value={field.min_value}
                                  required={field.required}
                                  disabled={!editMode}
                                  error={false}
                                  onChange={(updatedValue) =>
																				handleInputChange(
																					response.id,
																					updatedValue,
                                      field.type
																				)
																			}
                                  onFileUpload={async(file) => {
																		const upload = await uploadFile(file, response.id);
																		return upload;
																	}}
																	onFileRemove={async(file) => {
																		const removal = await removeFile(file, response.id);
																		return removal;
																	}}
												                        
																/>
                            
                              )})}

														</Instance>
                          )
                        }
                          
                      )}
                    
											</Group>
                  )})}
								</FormPage>
							</>
						)
					) : null}
				</Box>
			</Grid>
			<Grid
				item
				xs={12}
				md={3}
				sx={{
					position: "sticky",
					top: 0,
					zIndex: 1,
					backgroundColor: "#f0f7fe",
				}}
			>
				<Box
					sx={{
						position: "sticky",
						top: { xs: 0, md: "5.9375rem" },
					}}
				>
					<SideBar
						sections={routes}
						currentSection={activeRoute}
						errorSections={errorSections}
						disabledSections={disabledSections}
						handleRouteChange={handleRouteChange}
						buttonText={
							submitted
								? t("principal.sidebar.edit-btn-text")
								: t("principal.sidebar.submit-btn-text")
						}
            buttonHidden={!isLastRoute}
						buttonDisabled={isParamResponse}
						onSubmit={() =>
							handleUpdateResponseStatus(
								submitted ? "draft" : "submitted",
							)
						}
					/>
				</Box>
			</Grid>
		</Grid>
	);
}


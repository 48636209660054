import React, { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box,  CircularProgress, Typography } from "@mui/material";

import { useFormData } from "./hooks/use-form-data.hook";

import { ProgressProvider } from "./components/ProgressContext";
import { FormLayout } from "./components/FormLayout";

const Screen = () => {
	const { t } = useTranslation();

	const { 
		form, 
		loading,
		restricted,
		submitted,
		isParamResponse,
		editMode
	} = useFormData();

	const getCurrentMonthYear = () => {
		return new Date().toLocaleDateString("default", {
			month: "long",
			year: "numeric"
		});
	};

	useLayoutEffect(() => {
		const monthYear = getCurrentMonthYear();
		window.document.title = `${monthYear} - ${t("principal.report-heading")} - Connected North`;
	}, [t]);
	
	return (
		
		<Box sx={{ m: 3, display: "flex", flexDirection: "column", gap: 3 }}>
			<Typography
				variant="h1"
				sx={{
					fontSize: "2.5rem",
					fontFamily: "Effra, sans-serif",
					fontWeight: "400",
				}}
			>
				{"Monthly School Report"}
			</Typography>
			{!loading 
			? 	!restricted && form
				?
					<ProgressProvider form={form}>
						<FormLayout editMode={editMode} submitted={submitted} isParamResponse={isParamResponse}/>
					</ProgressProvider>
				:
					<p>Restricted</p>
			: (
				<CircularProgress />
			)}
		</Box>
	);
};

export default Screen;

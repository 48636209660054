import React, { useState, useEffect } from "react";
import airtable from "../../../airtables/PDAirtable";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { Box } from "@mui/material";
import AdsClickRoundedIcon from "@mui/icons-material/AdsClickRounded";
import SmsRoundedIcon from "@mui/icons-material/SmsRounded";
import AddIcon from "@mui/icons-material/Add";
import { PLAN_FIELDS, REVIEW_FIELDS } from "../constants/pd-fields";
import { Paper } from "./Paper";
import { PlanGoal } from "./PlanGoal";
import Review from "./Review";
import { toast } from "react-toastify";
import { ToastMessage } from "./ToastMessage";
import { PDFManager } from "../../../libs/pdf";
import { getStandardOptions } from "../util/get-pd-data";

const { GOALS } = PLAN_FIELDS;
const addGoalId = "add-new-goal";

const Plan = ({
	pd,
	editMode,
	reviewMode,
	addGoal,
	addReview,
	deleteGoal,
	updateGoal,
	loading,
	userInfo,
	reviewTeacherId,
	...props
}) => {
	const { t } = useTranslation();
	const [expandedGoal, setExpandedGoal] = useState(false);
	const [expandedReview, setExpandedReview] = useState(false);
	const [newGoalId, setNewGoalId] = useState(null);
	const [standardOptions, setStandardOptions] = useState([]);

	const pdGoals = pd[GOALS] || [];

	const onToggleGoal = (panel) => (_event, isExpanded) => {
		setExpandedGoal(isExpanded ? panel : false);
	};

	const onToggleReview = (panel) => (_event, isExpanded) => {
		setExpandedReview(isExpanded ? panel : false);
	};

	const onDeleteGoal = async (goalId) => {
		const deleteComfirmation = window.confirm(
			t("pd-plan.delete-confirm"),
		);

		if (goalId && deleteComfirmation) {
			const deleted = await deleteGoal(goalId);
			document.getElementById(addGoalId).focus();

			if (deleted) {
				toast.success(
					<ToastMessage>{t("pd-plan.alerts.success.delete-goal")}</ToastMessage>,
				);
			} else {
				toast.error(
					<ToastMessage>
						{t("pd-plan.alerts.error.delete-goal")}
					</ToastMessage>,
				);
			}
		}
	};

	const onAddGoal = async () => {
		const added = await addGoal();

		if (added) {
			toast.success(
				<ToastMessage>{t("pd-plan.alerts.success.add-goal")}</ToastMessage>,
			);
			setNewGoalId(added.id); // Set the new goal's ID
		} else {
			toast.error(
				<ToastMessage>
					{t("pd-plan.alerts.error.add-goal")}
				</ToastMessage>,
			);
		}
	};

	useEffect(() => {
		if (newGoalId) {
			setExpandedGoal(newGoalId);
			setNewGoalId(null);
		}
	}, [newGoalId]);

	useEffect(() => {
		const fetchStandardOptions = async () => {
			const response = await getStandardOptions(userInfo, reviewTeacherId);
			const options = response.map((standard) => ({
				value: standard.id,
				label: `${standard.code} - ${standard.focusArea}`,
			}));
			setStandardOptions(options);
		};

		fetchStandardOptions();
	}, [userInfo, reviewTeacherId]);

	const handleDownloadPdf = async () => {
		const pdfGenerator = new PDFManager();
		let pdfUserInfo = userInfo;
		if (reviewTeacherId) {
			try {
				const teacherInfo = await airtable.nunavutTeachers.getTeacherByRecordId(reviewTeacherId);
				if (teacherInfo) {
					pdfUserInfo = teacherInfo;
				}
			} catch (error) {
				console.error("Error fetching teacher info:", error);
				// Fallback to userInfo if there's an error
			}
		}

		pdfGenerator.generateGoalsPDF(pdGoals, standardOptions, pdfUserInfo);
	}

	return (
		<>
			<Paper
				introText={t("pd-plan.goals-paragraph")}
				title={
					<div className="d-flex justify-content-between align-items-center w-100 flex-wrap-container">
						<div
							className="d-flex align-items-center"
							style={{
								paddingBottom: "1rem",
							}}>
							<AdsClickRoundedIcon fontSize="1.5rem" paddingRight="1rem" />
							<Box ml="0.5rem">{t("pd-plan.goals-heading")}{" "}</Box>
						</div>
						{pdGoals.length > 0 && (
							<Button
								onClick={handleDownloadPdf}
								outline
								color="primary"
								style={{
									marginBottom: "0.5rem",
									padding: "0.5rem 1rem",
									fontSize: "1rem",
								}}
							>
								{t("pd-plan.download-pdf")}
							</Button>
						)}
					</div>
				}
				{...props}
			>
				{pdGoals.map((goal) => (
					<Box key={goal.id}>
						<PlanGoal
							goal={goal}
							readOnly={loading || !editMode}
							onUpdate={updateGoal}
							onDelete={onDeleteGoal}
							onToggleExpand={onToggleGoal(goal.id)}
							expanded={expandedGoal === goal.id}
							userInfo={userInfo}
							reviewTeacherId={reviewTeacherId}
							standardOptions={standardOptions}
						/>
					</Box>
				))}
				{editMode && (
					<Box mt="2rem">
						<Button
							outline
							color="primary"
							id={addGoalId}
							onClick={onAddGoal}
							disabled={loading}
							style={{
								paddingLeft: "1rem",
								display: "flex",
								alignItems: "center",
								gap: "0.5rem",
							}}
						>
							<AddIcon /> {t("pd-plan.add-new-goal")}
						</Button>
					</Box>
				)}
			</Paper>
			<Paper
				title={
					<>
						<SmsRoundedIcon fontSize="1.5rem" />
						{t("pd-plan.reviews-heading")}
					</>
				}
				introText={reviewMode ? t("pd-plan.reviews-paragraph-reviewer") : t("pd-plan.reviews-paragraph")}
				{...props}
			>
				{REVIEW_FIELDS.map(
					({ LABEL, NAME, DATE, COMMENT, TEACHER_COMMENT, TEACHER_COMMENT_DATE }) => (
						<div key={LABEL}>
							<Review
								review={{
									label: LABEL,
									name: pd[NAME],
									date: pd[DATE],
									comment: pd[COMMENT],
									teacher_comment: pd[TEACHER_COMMENT],
									teacher_comment_date: pd[TEACHER_COMMENT_DATE],
								}}
								editMode={editMode}
								reviewMode={reviewMode}
								addReview={addReview}
								expanded={expandedReview === `panel-${LABEL}`}
								onChange={onToggleReview(`panel-${LABEL}`)}
							/>
						</div>
					),
				)}
			</Paper>
		</>
	);
};

export default Plan;

import airtable from "../../../airtables/PDAirtable";

const createPDLog = async (params) => {
	try {
		const response = await airtable.pdLog.create(params);
		return response;
	} catch (e) {
		console.error("Error creating PD Log: ", e);
		return null;
	}
};

const createMultiplePDLog = async (paramsList) => {
	try {
		// Break the paramsList into chunks of 10
		const chunkSize = 10;
		const chunks = [];
		for (let i = 0; i < paramsList.length; i += chunkSize) {
			chunks.push(paramsList.slice(i, i + chunkSize));
		}

		// Process each chunk and combine results
		const responses = await Promise.all(
			chunks.map(chunk => airtable.pdLog.createMultiple(chunk))
		);
		
		// Flatten the responses array
		return responses.flat();
	} catch (e) {
		console.error("Error creating PD Logs: ", e);
		return null;
	}
};

const updatePDLog = async (id, params) => {
	try {
		const response = await airtable.pdLog.update(id, params);
		return response;
	} catch (e) {
		console.error("Error updating PD Log: ", e);
		return null;
	}
};

const deletePDLog = async (id) => {
	try {
		const response = await airtable.pdLog.delete(id);
		return response;
	} catch (e) {
		console.error("Error deleting PD Log: ", e);
		return null;
	}
};

export { createPDLog, createMultiplePDLog, updatePDLog, deletePDLog };

import React, { useState } from 'react';
import InputWrapper from './InputWrapper';
import { NumberMuiInput } from './NumberMui';

const NumberInput = ({
  id,
  max_value,
  min_value = 0,
  disabled,
  value,
  onChange = () => { },
  error = false,
  unit,
  sx = {},
  heading,
  description,
  placeholder,
  helperText, 
}) => {
  
  const [inputValue, setInputValue] = useState(value);
  const [inputError, setInputError] = useState(error);

  const filledInput = inputValue > 0;

  const handleInputChange = (e, val) => { 
    setInputValue(val);
    setInputError(false);
    onChange(val);
  }
 
  return (
    <InputWrapper
      title={heading}
      sx={{
        maxWidth: '22rem',
        ...sx
      }}
      id={id}
      inputError={inputError}
      filledInput={filledInput}
      helperText={helperText}
    >
      <NumberMuiInput
        id={id}
        name={id}
        aria-describedby='helper-text'
        min={min_value}
        max={max_value}
        value={inputValue}
        placeholder={placeholder}
        onInputChange={(e) => handleInputChange(e, e.target.value)}
        error={inputError}
        filledInput={filledInput}
        unit={unit}
        disabled={disabled}
      />
    </InputWrapper>
  );
};

export default NumberInput;
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import logoImg from '../../assets/img/logo1.png';
import Loading from "../../components/Loading";
import airtable from '../../airtables';
import { setSession as saveSessionStorage } from '../../utils/session';
import { CONNECTEDNORTH_SIGNINFO, CONNECTEDNORTH_SIGNTYPE } from "../../config";
import { useDispatch } from "react-redux";
import { setAppUserInfo, setAppUserType } from "../../redux/actions";
import { useTranslation } from "react-i18next";

const height = window.innerHeight;

const ExternalTeacherAcceptEvent = (props) => {
  const { match: { params } } = props;
  const { teacherId, eventsessionId } = params;
  const [registered, setRegistered] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [event, setEvent] = useState(null);
  const [teacher, setTeacher] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const teacher = await airtable.teachers.select(teacherId);
      if (teacher) {
        setTeacher(teacher);
        await saveSessionStorage(CONNECTEDNORTH_SIGNINFO, teacher);
        await saveSessionStorage(CONNECTEDNORTH_SIGNTYPE, "Teacher");
        dispatch(setAppUserInfo(teacher));
        dispatch(setAppUserType("Teacher"));

        const sess = await airtable.sessions.select(eventsessionId);
        setEvent(sess);
        if (sess.Teacher && sess.Teacher.indexOf(teacherId) >= 0) {
          setRegistered(true);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onConfirm = async () => {
    setSaving(true);
    await airtable.events.create({
      Name: teacher["Teacher Name"],
      "E-mail Address": teacher["Email"],
      School: [teacher["School Name"][0]],
      Session: [event.id]
    })
    setRegistered(true);
    setSaving(false);
  }

  const renderContent = () => {
    if (loading) {
      return (
        <div className="event-confirm-content">
          <Loading size={24} />
        </div>
      )
    }

    return (
      <div align="center">
        <div className="event-confirm-content">
          <div style={{ height: 10 }} />
          <span>
            Please confirm you would like to register for the event "{event['Custom Session Title'] ? event['Custom Session Title'] : event['Session Title Text']}."
          </span>
        </div>
        <div className="rate-action">
          <Button
            className="ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={() => window.location.href = "/cn"}
          >{t("cancel")}</Button>
          <Button
            color="success"
            type="button"
            onClick={() => onConfirm()}
          >{saving && <Loading size={14} />}{` Confirm`}</Button>
        </div>
      </div>
    )
  }

  if (registered) {
    return (
      <Redirect to="/cn/events" />
    )
  }

  return (
    <main>
      <section className="section section-shaped section-lg" style={{ height: height, background: 'rgb(85, 197, 242, 0.7)' }}>
        <div className="shape shape-style-1 bg-gradient-default">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <Container className="pt-lg-8">
          <Row className="justify-content-center align-items-center" style={{ height: '100%', paddingBottom: 70 }}>
            <Col lg="8">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="rate-logo">
                    <img src={logoImg} style={{ padding: '0 20px', maxWidth: 300, marginBottom: 10 }} alt="" />
                  </div>
                  {renderContent()}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  )
}

export default ExternalTeacherAcceptEvent;
import React from 'react';
import { Modal } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';

const PhoneNumberPopup = ({ isOpen, toggle, phoneNumber, name }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-dialog-centered">
      <div className="modal-header">
        <h5 className="modal-title">Contact Phone Number</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={toggle}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body text-center">
        <p>Phone number for {name}:</p>
        <h3 className="mb-4">{phoneNumber}</h3>
        <div className="d-flex justify-content-center gap-3">
          <CopyToClipboard 
            text={phoneNumber}
            onCopy={() => toast.info("Phone number copied to clipboard!")}
          >
            <button className="btn btn-primary">
              Copy to Clipboard
            </button>
          </CopyToClipboard>
          <a 
            href={`tel:${phoneNumber}`}
            className="btn btn-primary"
          >
            Call with Computer
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default PhoneNumberPopup;

export const isSubarray = (subarray, array) => {
  if (!subarray.length || !array.length) {
    return false;
  }

  if (subarray.length > array.length) {
    return false;
  }

  for (let i = 0; i <= array.length - subarray.length; i++) {
    let found = true;
    for (let j = 0; j < subarray.length; j++) {
      if (subarray[j] !== array[i + j]) {
        found = false;
        break;
      }
    }
    if (found) {
      return true;
    }
  }
  return false;
}

export const getCommonItems = (array1, array2) => {
  if (!array1 || !array1.length) return [];
  if (!array2 || !array2.length) return [];

  return array1.filter(item => array2.includes(item));
}

export const hasCommonItems = (array1, array2) => {
  const ca = getCommonItems(array1, array2);
  if (ca && ca.length) return true;
  return false;
}

export const distinctArray = (arr) => {
  const distinctSet = new Set(arr);
  return Array.from(distinctSet);
}

export const generate6DigitPasskey = () => {
  const ran = Math.floor(100000 + Math.random() * 900000);
  return ran.toString().padStart(6, '0');
}
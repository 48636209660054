import React from "react";
import { MoreHoriz } from "@mui/icons-material";
import { Chip } from "@mui/material";
import { useTranslation } from 'react-i18next';

const getLabelColor = (label) => {
	switch (label) {
		case "Upcoming":
		case "In progress":
			return "#9DF8C7";
		case "Needs review":
			return "#C59DF8";
		case "Complete":
			return "#9DE0F8";
		case "Reviewed":
			return "#A8E6CF";
		case "Overdue":
			return "#EB57574A";
		default:
			return "#F2F2F2";
	}
};

export const StatusLabel = ({ label, logged, unacknowledged, variant = "filled", ...props }) => {
	const { t } = useTranslation();

	if (logged !== undefined && unacknowledged !== undefined) {
		const isGreen = unacknowledged === 0 && logged > 0;
		const isRed = unacknowledged > 0;
		const labelColor = isGreen ? "#9DF8C7" : isRed ? "#EB5757" : "#F2F2F2";
		const labelText = logged > 0 ? t("pd-plan.hours", { count: logged }) : "";

		return (
			<Chip
				label={labelText}
				variant={variant}
				sx={{
					backgroundColor: labelColor,
					color: isGreen ? "#000000" : isRed ? "#FFFFFF" : "#000000",
					"& .MuiChip-label": {
						fontFamily: "Effra, Roboto, sans-serif",
					},
					visibility: logged > 0 ? 'visible' : 'hidden',
				}}
				{...props}
			/>
		);
	}

	const labelColour = getLabelColor(label);

	return (
		<Chip
			label={!label ? <MoreHoriz /> : label}
			variant={variant}
			sx={{
				backgroundColor: labelColour,
				"& .MuiChip-label": {
					fontFamily: "Effra, Roboto, sans-serif",
				},
			}}
			{...props}
		/>
	);
};

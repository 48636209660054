import supabase from "../../../database";
import { transformResponseValues } from "../util/transform-response-values";

export const updateFormResponseValue = async ( id, value, completed ) => {
	try {
		const response = await supabase.formResponseValues.update(id, {
			value,
			completed,
		});
		const singleResponse = response[0];
		return transformResponseValues(singleResponse);
	} catch (e) {
		console.log("Error:", e);
		return {};
	}
};


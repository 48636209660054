import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTeacher } from './hooks';
import { Card } from '../../components/Card';
import { getClusterLeadName } from '../../utils/string';
import { useTranslation } from 'react-i18next';
import { TeacherList } from './components/TeacherList';
import { CircularProgress } from '@mui/material';
import EditTeacherModal from "../modals/EditTeacher";

const School = ({ school, isSchoolCredit, refresh, refresher }) => {
  const {
    loading,
    teachers,
    leaders,
    schoolInfo,
    removeTeacher,
    updateTeacher,
  } = useTeacher(school.id, refresh);
  const { t } = useTranslation();
  const { userType, userInfo } = useSelector(state => state.appInfo);
  const schoolCredits = userInfo["School Current Balance"];
  const myCredits = userInfo["Credit Balance"];

  const [allocationMode, setAllocationMode] = useState(false);
  const [showEditTeacher, setShowEditTeacher] = useState(false);
  const [editTeacher, setEditTeacher] = useState(null);

  const getSchoolCreditHead = () => {

    if (isSchoolCredit) {
      return t("principal.available-credits.school-credits-head", {
        displayname: schoolInfo ? schoolInfo.School : "--",
        schoolCredits: schoolInfo ? schoolInfo["School Credit Balance"] : 0
      })
    }

    return t(getClusterLeadName(userInfo)
      ? "principal.available-credits.districtcredits"
      : "principal.available-credits.schoolcredits", {
      displayname: getClusterLeadName(userInfo),
      schoolCredits: Array.isArray(schoolCredits)
        ? schoolCredits.reduce((a, b) => a + b, 0) // Sum the values if it's an array
        : schoolCredits // Display the value if it's not an array
    })
  }

  const getSchoolCreditMsg = () => {

    if (isSchoolCredit) {
      return t("principal.available-credits.school-credits-msg")
    }

    return t(getClusterLeadName(userInfo)
      ? "principal.available-credits.districtcredits-msg"
      : "principal.available-credits.teachercredits", {
      displayname: getClusterLeadName(userInfo),
    })
  }

  const onRemoveTeacher = async teacherId => {
    await removeTeacher(teacherId);
    refresher();
  }

  return (
    <div className="school-container">
      {(userType === 'Teacher' || userType === 'Team') && (
        <Fragment>
          <section className="section">
            <Card className="bg-white availableCredits">
              {loading ? (
                <div className="loadingContainer-sm">
                  <CircularProgress />
                </div>
              ) : (
                <div>
                  <h2>{getSchoolCreditHead()}</h2>
                  <p>{getSchoolCreditMsg()}</p>
                </div>
              )}
            </Card>
          </section>
          {!userInfo["District Leader?"] && userType !== 'Team' && (
            <>
              <section className="section">
                <Card className="bg-white availableCredits">
                  <div>
                    <h2>
                      {t("principal.available-credits.heading", { myCredits })}
                    </h2>
                    <p>
                      {myCredits > 0
                        ? (
                          getClusterLeadName(userInfo)
                            ? t("principal.available-credits.text-district", { displayname: userInfo["Cluster Lead Display Name"][0] })
                            : t("principal.available-credits.text")
                        )
                        : t("principal.available-credits.none")}
                    </p>
                  </div>
                  {myCredits > 0 && (
                    <button
                      className="btn btn-secondary"
                      onClick={() => setAllocationMode(true)}
                    >
                      {t("principal.available-credits.button")}
                    </button>
                  )}
                </Card>
              </section>
            </>
          )}
        </Fragment>
      )}

      <section className="teachers section">
        <Card className="bg-white">
          <TeacherList
            allocate={allocationMode}
            onAllocateOff={() => setAllocationMode(false)}
            teachers={teachers}
            leaders={leaders}
            loading={loading}
            removeTeacher={onRemoveTeacher}
            editTeacher={(teacher) => {
              setEditTeacher(teacher);
              setShowEditTeacher(true)
            }}
          />
        </Card>
      </section>

      {showEditTeacher && !!editTeacher && (
        <EditTeacherModal
          onToggle={() => {
            setEditTeacher(null);
            setShowEditTeacher(false);
          }}
          teacher={editTeacher}
          onSave={async (tId, info) => {
            await updateTeacher(tId, info);
          }}
        />
      )}
    </div>
  )
}

export default School;
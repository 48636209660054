import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { getPDFFiles } from "../constants/pdf-files";
import { StatusLabel } from "./StatusLabel";
import Menu from "./Menu";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Link } from 'react-router-dom';

export const PDPlanLayout = ({
	children,
	onSubmitPlan = () => {},
	disableSubmit = true,
	renderSubmit = false,
	reviewerMode = false,
	teacherName = "",
	planStatus = "",
	userInfo,
}) => {
	const { t } = useTranslation();
	const pdfFiles = getPDFFiles(userInfo);

	return (
		<Box
			className="main-container"
			id="pd-plan"
			sx={{
				fontFamily: "inherit",
				color: "#0D1120",
				paddingBottom: "3rem",
				h2: {
					fontWeight: "bold",
					fontSize: "1.8rem",
					lineHeight: "1.3",
				},
				"*, h1, h2, h3, h4, p, button": {
					fontFamily: "inherit",
				},
				"i, i::before": {
					fontFamily: "FontAwesome",
				},
				"> .MuiPaper-root": {
					margin: "3.5rem 0",
				},
				"button:focus, a[href]:focus": {
					textDecoration: "underline",
					outline: "solid 0.15rem #0e6ba8",
					outlineOffset: "0.1rem",
				},
			}}
		>
			<Box component="section">
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						marginBottom: "3rem",
						gap: "1rem",
						flexWrap: "wrap",
					}}
				>
					<Typography
						variant="h3"
						component="h1"
						sx={{
							fontSize: "2.5rem",
							lineHeight: "1.5",
						}}
					>
						{ reviewerMode ? t("pd-plan.teachers-pd-plan", { name: teacherName }) : t("pd-plan.my-pd-plan")}
					</Typography>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-start",
							gap: "1rem",
						}}
					>
						{ !reviewerMode &&
						<Link to="/cn/pd-log">
							<Button color="primary">
								<i className="fa fa-pencil" />&nbsp;&nbsp;{t("pd-plan.pd-log")}
							</Button>
						</Link>
						}
						<Menu
							menuItems={pdfFiles}
							title={t("pd-plan.download-pdfs")}
						/>
					</Box>
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "flex-start",
						justifyContent: "space-between",
						marginBottom: "3rem",
						gap: "2rem",
						flexWrap: 'wrap',
					}}
				>
					<Box maxWidth="48rem">
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-start",
								marginBottom: "1rem",
								gap: "3rem",
							}}
						>
							<Typography variant="h5" component="h2">
								2024-2025
							</Typography>
							<StatusLabel label={planStatus} />
						</Box>
						<Typography variant="body1" component="p" sx={{ marginBottom: '1rem' }}>
							{t("pd-plan.pd-description1")}
						</Typography>
						<Typography variant="body1" component="p">
						{t("pd-plan.pd-description2")}
						</Typography>
					</Box>
					<Box>
						{renderSubmit && planStatus !== "Needs review" && planStatus !== "Complete" && (
							<Button
								color="primary"
								onClick={() => {
									confirmAlert({
										title: t("pd-plan.alert-title"),
										message: t("pd-plan.alert-message"),
										buttons: [
											{
												label: t("pd-plan.alert-submit-btn"),
												onClick: onSubmitPlan
											},
											{
												label: t("pd-plan.alert-cancel-btn"),
												onClick: () => {}
											}
										]
									});
								}}
								disabled={disableSubmit}
							>
								{t("pd-plan.submit-plan")}
							</Button>
						)}
					</Box>
				</Box>
			</Box>
			{children}
		</Box>
	);
};

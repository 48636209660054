import React, { Fragment, useState } from "react";
import { Button, Input, Modal } from "reactstrap";
import { culturalGroups } from "../../utils/constants";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import styles from "./styles.module.scss";
import StepSlider from "../../components/StepSliderSearch";
import classNames from "classnames";

const Filter = ({
	condition,
	update,
	visibleModal,
	toggleModal,
	applyFilter
}) => {
	const { t } = useTranslation();
	const {
		communities,
		appLocale,
		userType
	} = useSelector(state => state.appInfo);
	const [subjectsExpanded, setSubjectsExpanded] = useState(true);
	const [statusExpanded, setStatusExpanded] = useState(false);
	const [gradesExpanded, setGradesExpanded] = useState(false);
	const [wellBeingLinksExpanded, setWellBeingLinksExpanded] = useState(false);
	const [lengthExpanded, setLengthExpanded] = useState(false);
	const [languageExpanded, setLanguageExpanded] = useState(false);
	const [igsExpanded, setIgsExpanded] = useState(false);
	const [culturalGroupExpanded, setCulturalGroupExpanded] = useState(true);
	const [communityExpanded, setCommunityExpanded] = useState(false);
	const [supExpanded, setSupExpanded] = useState(false);
	const [creditsExpanded, setCreditsExpanded] = useState(false);
	const [fieldsOfStudyExpanded, setFieldsOfStudyExpanded] = useState(false);

	const [isGradesExpanded, setIsGradesExpanded] = useState(false);
	const [isSubjectsExpanded, setIsSubjectsExpanded] = useState(false);
	const [applyButton, showApplyButton] = useState(false);
	const MAX_CREDITS = 60;

	const toggleSearchStatus = (status) => {
		if (searchStatus.indexOf(status) === -1) {
			update("searchStatus", [...searchStatus, status])
		} else {
			const st = searchStatus.filter((item) => item !== status);
			update("searchStatus", [...st])
		}
		showApplyButton(true);
	};

	const toggleLength = async (len) => {
		if (length.indexOf(len) >= 0) {
			update("length", length.filter((l) => l !== len));
		} else {
			update("length", [...length, len]);
		}

		showApplyButton(true);
	};

	const toggleLanguage = async (lang) => {
		if (language.indexOf(lang) >= 0) {
			update("language", language.filter((l) => l !== lang));
		} else {
			update("language", [...language, lang]);
		}
		showApplyButton(true);
	};

	const toggleNoSupplies = async (nosup) => {
		update("nosupplies", !nosupplies);  // This will toggle the state between true and false
		showApplyButton(true);
	};

	const makeApplyFilter = () => {
		showApplyButton(false);
		applyFilter();
		toggleModal(false);
	}

	if (!condition) return null;

	const {
		subjects,
		searchStatus,
		grades,
		wellBeingLinks,
		length,
		language,
		indigenousGroups,
		culturalGroup,
		indigenousCommunity,
		nosupplies,
		filterCredits,
		fieldsOfStudy
	} = condition;

	const renderFilterConetent = () => {
		const SLICE_VALUE = 6;
		const subjectsList = !isSubjectsExpanded
			? subjects.slice(0, SLICE_VALUE)
			: subjects;
		const gradesList = !isGradesExpanded
			? grades.slice(0, SLICE_VALUE)
			: grades;

		var combinedCommunityOptions = [];
		for (var i = 0; i < indigenousGroups.length; i++) {
			if (indigenousGroups[i].checked)
				combinedCommunityOptions.push(indigenousGroups[i].group);
		}

		var communityOptionsForMenu = combinedCommunityOptions.length
			? combinedCommunityOptions.flat(1)
			: communities;

		return (
			<div className={styles.filterConditions}>
				<div className="search-accordian-group">
					<Accordion
						expanded={subjectsExpanded}
						onChange={(_, expanded) =>
							setSubjectsExpanded(expanded)
						}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("subject-areas")}
						</AccordionSummary>
						<AccordionDetails>
							{subjectsList.map((subject, index) => {
								const subjectName =
									appLocale === "fr"
										? subject["Subject (FR)"]
										: subject["Subject"];
								if (!subjectName) return null;
								return (
									<div key={index}>
										<FormControlLabel
											control={
												<Checkbox
													sx={{
														paddingTop: 0,
														paddingBottom: 0,
													}}
													checked={
														subject.checked
															? true
															: false
													}
													onClick={() => {
														if (
															!subject.checked
														)
															subject.checked = false;
														let sbs = [
															...subjects,
														];
														sbs[index].checked =
															!subject.checked;
														update("subjects", sbs);
														showApplyButton(
															true,
														);
													}}
												/>
											}
											label={subjectName}
										/>
									</div>
								);
							})}
							<Button
								color="link"
								className="more-btn"
								onClick={() =>
									setIsSubjectsExpanded(!isSubjectsExpanded)
								}
							>
								{!isSubjectsExpanded
									? `+ ${subjects.length - SLICE_VALUE} more`
									: "show less"}
							</Button>
						</AccordionDetails>
					</Accordion>
				</div>

				<div className="search-accordian-group">
					{userType === 'Team' && (
						<Accordion
							expanded={statusExpanded}
							onChange={(_, expanded) =>
								setStatusExpanded(expanded)
							}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								Status (Team-only)
							</AccordionSummary>
							<AccordionDetails>
								{["Consider Another Option", "In Development", "Private"].map((item, i) => (
									<div key={i}>
										<FormControlLabel
											control={
												<Checkbox
													sx={{
														paddingTop: 0,
														paddingBottom: 0,
													}}
													checked={searchStatus.indexOf(item) >= 0}
													onClick={() => toggleSearchStatus(item)}
												/>
											}
											label={item}
										/>
									</div>
								))}
							</AccordionDetails>
						</Accordion>
					)}

					<Accordion
						expanded={gradesExpanded}
						onChange={(_, expanded) => setGradesExpanded(expanded)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("grade-levels")}
						</AccordionSummary>
						<AccordionDetails>
							{gradesList.map((grade, index) => (
								<div key={index}>
									<FormControlLabel
										control={
											<Checkbox
												checked={
													grade?.checked
														? true
														: false
												}
												sx={{
													paddingTop: 0,
													paddingBottom: 0,
												}}
												onClick={() => {
													if (!grade.checked)
														grade.checked = false;
													let gs = [...grades];
													gs[index].checked =
														!grade.checked;
													update("grades", gs)
													showApplyButton(true);
												}}
											/>
										}
										label={grade.name}
									/>
								</div>
							))}
							<Button
								color="link"
								className="more-btn"
								onClick={() =>
									setIsGradesExpanded(!isGradesExpanded)
								}
							>
								{!isGradesExpanded
									? `+ ${grades.length - SLICE_VALUE} more`
									: "show less"}
							</Button>
						</AccordionDetails>
					</Accordion>

					<Accordion
						expanded={wellBeingLinksExpanded}
						onChange={(_, expanded) =>
							setWellBeingLinksExpanded(expanded)
						}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("well-being-links")}
						</AccordionSummary>
						<AccordionDetails>
							{wellBeingLinks.map((linkItem, index) => (
								<div key={index}>
									<FormControlLabel
										control={
											<Checkbox
												sx={{
													paddingTop: 0,
													paddingBottom: 0,
												}}
												checked={
													linkItem?.checked
														? true
														: false
												}
												onClick={() => {
													if (!linkItem.checked)
														linkItem.checked = false;
													let ws = [
														...wellBeingLinks,
													];
													ws[index].checked =
														!linkItem.checked;
													update("wellBeingLinks", ws);
													showApplyButton(true);
												}}
											/>
										}
										label={t(linkItem["name"])}
									/>
								</div>
							))}
						</AccordionDetails>
					</Accordion>

					<Accordion
						expanded={lengthExpanded}
						onChange={(_, expanded) => setLengthExpanded(expanded)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("length")}
						</AccordionSummary>
						<AccordionDetails>
							<div>
								<FormControlLabel
									control={
										<Checkbox
											sx={{
												paddingTop: 0,
												paddingBottom: 0,
											}}
											checked={length.indexOf(30) >= 0}
											onClick={() => toggleLength(30)}
										/>
									}
									label="30 minutes"
								/>
							</div>
							<div>
								<FormControlLabel
									control={
										<Checkbox
											sx={{
												paddingTop: 0,
												paddingBottom: 0,
											}}
											checked={length.indexOf(45) >= 0}
											onClick={() => toggleLength(45)}
										/>
									}
									label="45 minutes"
								/>
							</div>
							<div>
								<FormControlLabel
									control={
										<Checkbox
											sx={{
												paddingTop: 0,
												paddingBottom: 0,
											}}
											checked={length.indexOf(60) >= 0}
											onClick={() => toggleLength(60)}
										/>
									}
									label="60 minutes"
								/>
							</div>
						</AccordionDetails>
					</Accordion>

					<Accordion
						expanded={languageExpanded}
						onChange={(_, expanded) =>
							setLanguageExpanded(expanded)
						}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("language")}
						</AccordionSummary>
						<AccordionDetails>
							<div>
								<FormControlLabel
									control={
										<Checkbox
											sx={{
												paddingTop: 0,
												paddingBottom: 0,
											}}
											checked={language.indexOf("English") >= 0}
											onClick={() => toggleLanguage("English")}
										/>
									}
									label="English"
								/>
							</div>
							<div>
								<FormControlLabel
									control={
										<Checkbox
											sx={{
												paddingTop: 0,
												paddingBottom: 0,
											}}
											checked={language.indexOf("French") >= 0}
											onClick={() => toggleLanguage("French")}
										/>
									}
									label="French"
								/>
							</div>
						</AccordionDetails>
					</Accordion>

					<Accordion
						expanded={igsExpanded}
						onChange={(_, expanded) => setIgsExpanded(expanded)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("indigenous-groups")}
						</AccordionSummary>
						<AccordionDetails>
							{!!indigenousGroups &&
								indigenousGroups.length > 0 &&
								indigenousGroups.map((igroup, index) => (
									<div key={index}>
										<FormControlLabel
											control={
												<Checkbox
													sx={{
														paddingTop: 0,
														paddingBottom: 0,
													}}
													checked={
														igroup?.checked
															? true
															: false
													}
													onClick={() => {
														if (!igroup.checked) igroup.checked = false;
														let igs = [...indigenousGroups];
														igs[index].checked = !igroup.checked;
														update("indigenousGroups", igs)
														showApplyButton(true);
													}}
												/>
											}
											label={igroup["name"]}
										/>
									</div>
								))}
						</AccordionDetails>
					</Accordion>

					{(!!indigenousGroups &&
						indigenousGroups.length > 0 &&
						indigenousGroups[0].checked) && ( // if 'First Nation' group is checked
							<Accordion
								expanded={culturalGroupExpanded}
								onChange={(_, expanded) =>
									setCulturalGroupExpanded(expanded)
								}
							>
								<AccordionSummary
									expandIcon={<ExpandMoreIcon />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									{t("cultural-group")}
								</AccordionSummary>
								<AccordionDetails>
									<Input
										type="select"
										id="culturalGroup"
										value={culturalGroup}
										className="filter-select"
										onChange={(e) => {
											update("culturalGroup", e.target.value)
											showApplyButton(true);
										}}
									>
										<option value="">{t("all")}</option>

										{culturalGroups.map((g, index) => (
											<option
												className="filter-dropdown"
												key={index}
												value={g}
											>
												{g}
											</option>
										))}
									</Input>
								</AccordionDetails>
							</Accordion>
						)}

					<Accordion
						expanded={communityExpanded}
						onChange={(_, expanded) =>
							setCommunityExpanded(expanded)
						}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="indigenousCommunity-content"
							id="indigenous-community-header"
						>
							{t("community")}
						</AccordionSummary>
						<AccordionDetails>
							<Input
								type="select"
								className="filter-select"
								id="indigenousCommunity"
								value={indigenousCommunity}
								onChange={(e) => {
									update("indigenousCommunity", e.target.value)
									showApplyButton(true);
								}}
							>
								<option value="">{t("none")}</option>
								{communityOptionsForMenu.map(
									(com, index) => (
										<option
											key={index}
											value={com.Name}
										>
											{com.Name}
										</option>
									),
								)}
							</Input>
						</AccordionDetails>
					</Accordion>

					<Accordion
						expanded={supExpanded}
						onChange={(_, expanded) => setSupExpanded(expanded)}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("supplies-required")}
						</AccordionSummary>
						<AccordionDetails>
							<div>
								<FormControlLabel
									control={
										<Checkbox
											sx={{
												paddingTop: 0,
												paddingBottom: 0,
											}}
											checked={nosupplies}
											onChange={toggleNoSupplies}
										/>
									}
									label={t("supplies-required-check")}
								/>
							</div>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={creditsExpanded}
						onChange={(_, expanded) =>
							setCreditsExpanded(expanded)
						}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="credits-content"
							id="credits-header"
						>
							{t("credits-required")}
						</AccordionSummary>
						<AccordionDetails>
							<div style={{ paddingBottom: "2rem" }}>
								<StepSlider
									max={MAX_CREDITS}
									onChange={(e) => {
										update("filterCredits", e)
										showApplyButton(true);
									}}
									value={filterCredits}
								/>
							</div>
						</AccordionDetails>
					</Accordion>

					<Accordion
						expanded={fieldsOfStudyExpanded}
						onChange={(_, expanded) =>
							setFieldsOfStudyExpanded(expanded)
						}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1a-content"
							id="panel1a-header"
						>
							{t("fields-of-study")}
						</AccordionSummary>
						<AccordionDetails>
							{fieldsOfStudy.map((field, index) => (
								<div key={field.id}>
									<FormControlLabel
										control={
											<Checkbox
												checked={field.checked}
												sx={{
													paddingTop: 0,
													paddingBottom: 0,
												}}
												onClick={() => {
													const fos = [...fieldsOfStudy];
													const fId = fos.findIndex(f => f.id === field.id);
													fos[fId].checked = !fos[fId].checked;

													update("fieldsOfStudy", fos);
													showApplyButton(true);
												}}
											/>
										}
										label={field.localizedName}
									/>
								</div>
							))}
						</AccordionDetails>
					</Accordion>
				</div>
			</div>
		)
	}

	return (
		<Fragment>
			<div className={styles.filterPanel}>
				{renderFilterConetent()}
				<Button
					color="primary"
					className={classNames({
						"not-activated": !applyButton,
						"desktop-filter-apply-btn": true
					})}
					onClick={() => makeApplyFilter()}
				>
					{t("apply-filter")}
				</Button>
			</div>
			<Modal
				className="modal-dialog-centered"
				isOpen={visibleModal}
				toggle={() => toggleModal(false)}
			>
				<div className="modal-header">
					<h5 className="modal-title" id="modal-title-default">Filter</h5>
					<button
						aria-label="Close"
						className="close"
						data-dismiss="modal"
						type="button"
						onClick={() => toggleModal(false)}
					>
						<span aria-hidden={true}>×</span>
					</button>
				</div>
				<div className="modal-body modal-filter-body">
					{renderFilterConetent()}
				</div>
				<div className="modal-footer">
					<Button
						className="ml-auto"
						color="default"
						data-dismiss="modal"
						type="button"
						onClick={() => toggleModal(false)}
					>Cancel</Button>
					<Button
						color="primary"
						type="button"
						disabled={!applyButton}
						onClick={() => makeApplyFilter()}
					>Apply</Button>
				</div>
			</Modal>
		</Fragment>
	)
}

export default Filter;
import AirtableTable from "../AirtableTable";

export class PresenterTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Presenters");

    this.fields = {
      "Name": "fldP66sflLBPL5Ggo",
      "Email": "fldCfJKs5LbVWLpbi",
      "Phone": "fldYfDkTjJbqzceNY",
      "Provider": "fldazYPY8UKYETigf",
      "Archived": "fldWcVxm9z0Fuv56p"
    }
  }

  getPresenterByEmail = (email) => {
    const em = email.toUpperCase();
    return this.selectOneByCondition({
      filterByFormula: `"${em}" = UPPER({Email})`
    });
  }

  createPresenter = (provider, email, name, phone) => {
    return this.create({
      "Name": name,
      "Email": email,
      "Phone": phone,
      "Provider": [provider],
      "Self Signup": true,
      "Platform Logins": 1
    });
  }

  presentersForProvider = (pId, pName) => {
    return this.list({
      filterByFormula: `FIND(";${pName};", CONCATENATE(";", SUBSTITUTE(ARRAYJOIN({Provider}), ", ", ";"), ";"))`,
      sort: [
        { field: 'Name', direction: 'asc' }
      ]
    }, (p) => (p["Provider"] || []).indexOf(pId) >= 0);
  }
}
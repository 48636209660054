export const detectOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (/iphone|ipad|ipod/.test(userAgent)) return "iOS";
  if (/android/.test(userAgent)) return "Android";
  if (/mac/.test(userAgent)) return "macOS";
  if (/win/.test(userAgent)) return "Windows";
  return "Unknown";
};

export const isMobileDevice = () => {
  const os = detectOS();
  return os === "iOS" || os === "Android";
};
import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import airtable from '../../airtables';

const NotesModal = ({ isOpen, toggle, session, onSuccess }) => {
  const { t } = useTranslation();
  const [notes, setNotes] = useState(session.Notes || '');
  const [hostNotes, setHostNotes] = useState(session["Session Host Notes"] || '');
  const [saving, setSaving] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    
    try {
      await airtable.sessions.update(session.id, {
        'Notes': notes,
        'Session Host Notes': hostNotes
      });
      
      if (onSuccess) {
        onSuccess();
      }
      toggle();
    } catch (error) {
      console.error('Error saving notes:', error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{t("edit-notes")}</ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          <FormGroup>
            <Label for="notes">{t("provider-notes")}</Label>
            <Input
              type="textarea"
              name="notes"
              id="notes"
              rows="4"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="hostNotes">{t("session-host-notes")}</Label>
            <Input
              type="textarea"
              name="hostNotes"
              id="hostNotes"
              rows="4"
              value={hostNotes}
              onChange={(e) => setHostNotes(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle} disabled={saving}>
            {t("cancel")}
          </Button>
          <Button color="primary" type="submit" disabled={saving}>
            {saving ? t("saving") : t("save")}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default NotesModal;

import React, { useLayoutEffect } from "react";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { Alert, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next'
import { STATUS_LABELS } from "./util/review-dates";
import { PLAN_FIELDS } from "./constants/pd-fields";
import { usePDData } from "./hooks/use-pd-data.hook";
import { PDPlanLayout } from "./components/PDPlanLayout";
import { ToastMessage } from "./components/ToastMessage";
import Plan from "./components/Plan";
import { useSelector } from "react-redux";

const isSubmitEnabled = (activePD) => {
	if (!activePD) return false;

	const goals = activePD.Goals || [];
	const trackedGoals = activePD[PLAN_FIELDS.TRACKED_GOALS] || 0;
	const submitted = activePD[PLAN_FIELDS.COMPLETE_FOR_REVIEW] || false;

	if (goals.length === 0) return false;
	if (submitted && goals.length === trackedGoals) {
		return false;
	}

	return true;
};

const isSubmitVisible = (activePD) => {
	if (!activePD) return false;

	const status = activePD[PLAN_FIELDS.COMPLETION_STATUS];
	const { COMPLETE, NOT_STARTED } = STATUS_LABELS;

	return status && status !== NOT_STARTED && status !== COMPLETE;
};

const Screen = () => {
	const {
		activePD,
		addGoal,
		addPlan,
		savePlan,
		addTeacherReview,
		deleteGoal,
		updateGoal,
		isLoading,
	} = usePDData();

	const { t } = useTranslation();
	const planStatus = activePD ? activePD[PLAN_FIELDS.COMPLETION_STATUS] : "";
	const submitVisible = isSubmitVisible(activePD);
	const submitEnabled = isSubmitEnabled(activePD) && !isLoading;

	const onSavePlan = async () => {
		const savedPlan = await savePlan();
		if (savedPlan) {
			toast.success(
				<ToastMessage>
					{ t("pd-plan.alerts.success.save-plan") }
				</ToastMessage>,
			);
		} else {
			toast.error(
				<ToastMessage>
					{ t("pd-plan.alerts.error.save-plan") }
				</ToastMessage>,
			);
		}
	};

	useLayoutEffect(() => {
		window.document.title = t("pd-plan.page-title");
	});

	const { userInfo } = useSelector((state) => state.appInfo);

	return (
		<PDPlanLayout
			planStatus={planStatus}
			onSubmitPlan={onSavePlan}
			disableSubmit={!submitEnabled}
			renderSubmit={submitVisible}
			userInfo={userInfo}
		>
			{isLoading && <CircularProgress />}
			{!isLoading && !activePD && (
				<Alert
					severity="info"
					sx={{
						justifyContent: "center",
						alignItems: "center",
						fontFamily: "inherit",
						".MuiAlert-message": {
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							gap: "1rem",
						},
					}}
				>
					<Typography variant="body1" component="p">
						{t("pd-plan.no-plan-message")}
					</Typography>
					<Button color="primary" onClick={addPlan}>
						{t("pd-plan.create-plan-button")}
					</Button>
				</Alert>
			)}
			{activePD && (
				<Plan
					pd={activePD}
					editMode={true}
					addGoal={addGoal}
					addReview={addTeacherReview}
					deleteGoal={deleteGoal}
					updateGoal={updateGoal}
					loading={isLoading}
					userInfo={userInfo}
					reviewTeacherId={null}
				/>
			)}
		</PDPlanLayout>
	);
};

export default Screen;

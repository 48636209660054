import React, { createContext, useState, useContext } from 'react';
import { useFormNavigation } from '../hooks/use-form-navigation.hook';
import { useResponseHandler } from '../hooks/use-response-handler';
import { useGroupHandler } from '../hooks/use-group-handler';

const ProgressContext = createContext();

export const ProgressProvider = ({ form, children }) => {

  const {
    response_status,
    response_id,
    last_saved_at,
    created_at,
    dea_user_id,
    submitted_at,
    dea_comment,
    dea_id,
    user_comment,
  } = form;

  const responseData = {
    response_id,
    last_saved_at,
    created_at,
    dea_user_id,
    submitted_at,
    dea_comment,
    dea_id,
    user_comment,
    status: response_status
  }

  const {
		routes,
		activeRoute,
		handleRouteChange,
		handleRouteNext,
		handleRoutePrev,
	} = useFormNavigation(form);

  const {
    handleFormResponseCreation,
    handleUpdateResponse
  } = useResponseHandler(form);

  const {
    handleAddInstance,
    handleDeleteInstance,
    handleUpdateField,
  } = useGroupHandler(form);

  const [progress, setProgress] = useState(null);
  
  const updateProgress = (value) => {
    setProgress(value);
  };

  const handleFinishEvent = () => {
    // Your logic here
    console.log('Finish event triggered');
  };
  
  return (
    <ProgressContext.Provider 
      value={{ 
        form, 
        responseStatus: response_status,
        response: responseData,
        progress, 
        updateProgress,
        activeRoute,
        routes,
        handleRouteNext,
        handleRoutePrev,
        handleRouteChange,
        handleFormResponseCreation,
        handleUpdateResponse,
        handleAddInstance,
        handleDeleteInstance,
        handleUpdateField,
        handleFinishEvent,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};

export const useProgress = () => {
  return useContext(ProgressContext);
};
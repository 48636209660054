import { Box, Button, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";

const ButtonText = styled("span")({
	overflow: "hidden",
	textOverflow: "ellipsis",
	whiteSpace: "nowrap",
	textAlign: "center",
	fontFamily: "Effra, sans-serif",
	fontSize: "1rem",
	fontWeight: "700",
	textTransform: "none",
});

export const RepeatableButtons = ({
	onAddEvent,
	onFinishAdding,
	addLabel,
	finishLabel,
	disabled,
	icons = true,
  finishIcons = false,
}) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: { xs: "column", md: "row" },
				gap: { xs: "1rem", md: "1.875rem" },
			}}
		>
			<Button
				variant="contained"
				sx={{
					background: "#1094ce",
					width: "100%",
					padding: "0.625rem",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					gap: "0.5rem",
  
					"&:hover": {
						borderWidth: "0.15rem",
						transform: "translateY(-2px)",
						background: "#1094ce",
					},
				}}
				disabled={disabled}
				onClick={onAddEvent}
				aria-label={addLabel}
			>
				{icons ? <AddIcon /> : null}
				<ButtonText>{addLabel}</ButtonText>
			</Button>
			<Button
				variant="outlined"
				sx={{
					width: "100%",
					padding: "0.625rem",
					borderRadius: "0.875rem",
					border: "1px solid #0e6ba8",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					gap: "0.5rem",
          background: finishIcons ? "#1094ce" : "transparent",
          color: finishIcons ? "#fff" : "#0e6ba8",
					"&:hover": {
						background: "#1094ce",
						color: "#fff",
						transform: "translateY(-2px)",
					},
				}}
				disabled={disabled}
				onClick={onFinishAdding}
				aria-label={finishLabel}
			>
				{finishIcons ? <CheckIcon /> : null}
				<ButtonText>{finishLabel}</ButtonText>
			</Button>
		</Box>
	);
};

import React from "react";

const SVGIcon = ({ name, color }) => {
  switch (name) {
    case "setting":
      return (
        <svg fill={color || "#000000"} viewBox="0 0 54 54">
          <g>
            <path d="M51.22,21h-5.052c-0.812,0-1.481-0.447-1.792-1.197s-0.153-1.54,0.42-2.114l3.572-3.571
		c0.525-0.525,0.814-1.224,0.814-1.966c0-0.743-0.289-1.441-0.814-1.967l-4.553-4.553c-1.05-1.05-2.881-1.052-3.933,0l-3.571,3.571
		c-0.574,0.573-1.366,0.733-2.114,0.421C33.447,9.313,33,8.644,33,7.832V2.78C33,1.247,31.753,0,30.22,0H23.78
		C22.247,0,21,1.247,21,2.78v5.052c0,0.812-0.447,1.481-1.197,1.792c-0.748,0.313-1.54,0.152-2.114-0.421l-3.571-3.571
		c-1.052-1.052-2.883-1.05-3.933,0l-4.553,4.553c-0.525,0.525-0.814,1.224-0.814,1.967c0,0.742,0.289,1.44,0.814,1.966l3.572,3.571
		c0.573,0.574,0.73,1.364,0.42,2.114S8.644,21,7.832,21H2.78C1.247,21,0,22.247,0,23.78v6.439C0,31.753,1.247,33,2.78,33h5.052
		c0.812,0,1.481,0.447,1.792,1.197s0.153,1.54-0.42,2.114l-3.572,3.571c-0.525,0.525-0.814,1.224-0.814,1.966
		c0,0.743,0.289,1.441,0.814,1.967l4.553,4.553c1.051,1.051,2.881,1.053,3.933,0l3.571-3.572c0.574-0.573,1.363-0.731,2.114-0.42
		c0.75,0.311,1.197,0.98,1.197,1.792v5.052c0,1.533,1.247,2.78,2.78,2.78h6.439c1.533,0,2.78-1.247,2.78-2.78v-5.052
		c0-0.812,0.447-1.481,1.197-1.792c0.751-0.312,1.54-0.153,2.114,0.42l3.571,3.572c1.052,1.052,2.883,1.05,3.933,0l4.553-4.553
		c0.525-0.525,0.814-1.224,0.814-1.967c0-0.742-0.289-1.44-0.814-1.966l-3.572-3.571c-0.573-0.574-0.73-1.364-0.42-2.114
		S45.356,33,46.168,33h5.052c1.533,0,2.78-1.247,2.78-2.78V23.78C54,22.247,52.753,21,51.22,21z M52,30.22
		C52,30.65,51.65,31,51.22,31h-5.052c-1.624,0-3.019,0.932-3.64,2.432c-0.622,1.5-0.295,3.146,0.854,4.294l3.572,3.571
		c0.305,0.305,0.305,0.8,0,1.104l-4.553,4.553c-0.304,0.304-0.799,0.306-1.104,0l-3.571-3.572c-1.149-1.149-2.794-1.474-4.294-0.854
		c-1.5,0.621-2.432,2.016-2.432,3.64v5.052C31,51.65,30.65,52,30.22,52H23.78C23.35,52,23,51.65,23,51.22v-5.052
		c0-1.624-0.932-3.019-2.432-3.64c-0.503-0.209-1.021-0.311-1.533-0.311c-1.014,0-1.997,0.4-2.761,1.164l-3.571,3.572
		c-0.306,0.306-0.801,0.304-1.104,0l-4.553-4.553c-0.305-0.305-0.305-0.8,0-1.104l3.572-3.571c1.148-1.148,1.476-2.794,0.854-4.294
		C10.851,31.932,9.456,31,7.832,31H2.78C2.35,31,2,30.65,2,30.22V23.78C2,23.35,2.35,23,2.78,23h5.052
		c1.624,0,3.019-0.932,3.64-2.432c0.622-1.5,0.295-3.146-0.854-4.294l-3.572-3.571c-0.305-0.305-0.305-0.8,0-1.104l4.553-4.553
		c0.304-0.305,0.799-0.305,1.104,0l3.571,3.571c1.147,1.147,2.792,1.476,4.294,0.854C22.068,10.851,23,9.456,23,7.832V2.78
		C23,2.35,23.35,2,23.78,2h6.439C30.65,2,31,2.35,31,2.78v5.052c0,1.624,0.932,3.019,2.432,3.64
		c1.502,0.622,3.146,0.294,4.294-0.854l3.571-3.571c0.306-0.305,0.801-0.305,1.104,0l4.553,4.553c0.305,0.305,0.305,0.8,0,1.104
		l-3.572,3.571c-1.148,1.148-1.476,2.794-0.854,4.294c0.621,1.5,2.016,2.432,3.64,2.432h5.052C51.65,23,52,23.35,52,23.78V30.22z"/>
            <path d="M27,18c-4.963,0-9,4.037-9,9s4.037,9,9,9s9-4.037,9-9S31.963,18,27,18z M27,34c-3.859,0-7-3.141-7-7s3.141-7,7-7
		s7,3.141,7,7S30.859,34,27,34z"/>
          </g>
        </svg>
      )
    case "faqs":
      return (
        <svg fill={color || "#000000"} viewBox="0 0 502 502">
          <g>
            <g>
              <g>
                <path
                  d="M428.483,73.516C381.076,26.108,318.045,0,251,0C183.956,0,120.924,26.108,73.516,73.516S0,183.956,0,251
                    s26.108,130.076,73.516,177.484C120.924,475.892,183.956,502,251,502c67.045,0,130.076-26.108,177.483-73.516
                    C475.892,381.076,502,318.044,502,251S475.892,120.924,428.483,73.516z M251,482C123.626,482,20,378.374,20,251
                    S123.626,20,251,20s231,103.626,231,231S378.374,482,251,482z"
                />
                <path
                  d="M251,41.36c-71.131,0-129,57.869-129,129c0,19.299,15.701,35,35,35s35-15.701,35-35c0-32.533,26.467-59,59-59
                    c32.532,0,59,26.467,59,59c0,32.809-26.468,59.5-59,59.5c-19.299,0-35,15.701-35,35v68.781c0,19.299,15.701,35,35,35
                    s35-15.702,35-34.999v-38.638c25.275-7.181,48.08-22.162,64.871-42.763C369.655,229.195,380,200.116,380,170.36
                    C380,99.229,322.131,41.36,251,41.36z M273.893,277.422c-4.605,0.992-7.893,5.064-7.893,9.775v46.443c0,8.271-6.729,15-15,15
                    s-15-6.729-15-15v-68.781c0-8.271,6.729-15,15-15c43.561,0,79-35.664,79-79.5c0-43.561-35.439-79-79-79c-43.561,0-79,35.44-79,79
                    c0,8.271-6.729,15-15,15s-15-6.729-15-15c0-60.103,48.897-109,109-109c60.103,0,109,48.897,109,109
                    C360,221.641,323.787,266.667,273.893,277.422z"
                />
                <path
                  d="M251,386.641c-19.299,0-35,15.701-35,35v4c0,19.299,15.701,35,35,35s35-15.702,35-35v-4
                  C286,402.342,270.299,386.641,251,386.641z M266,425.641c0,8.271-6.729,15-15,15s-15-6.729-15-15v-4c0-8.271,6.729-15,15-15
                  s15,6.729,15,15V425.641z"
                />
                <path
                  d="M59.077,290.972C56.372,277.911,55,264.462,55,251c0-5.523-4.477-10-10-10s-10,4.477-10,10
                  c0,14.822,1.512,29.636,4.492,44.028c0.979,4.724,5.14,7.974,9.782,7.974c0.672,0,1.355-0.068,2.039-0.21
                  C56.721,301.672,60.197,296.38,59.077,290.972z"
                />
                <path
                  d="M172.982,430.859c-43.999-19.113-79.407-53.593-99.701-97.088c-2.335-5.005-8.287-7.169-13.29-4.834
                  c-5.005,2.335-7.169,8.285-4.834,13.29c22.36,47.925,61.375,85.916,109.856,106.976c1.297,0.564,2.649,0.831,3.979,0.831
                  c3.862,0,7.54-2.25,9.178-6.019C180.37,438.949,178.047,433.059,172.982,430.859z"
                />
              </g>
            </g>
          </g>
        </svg>
      )
    case "nav-up":
      return (
        <svg fill={color || "#000000"} viewBox="0 0 56 56">
          <path d="M 28 48.6602 C 29.2188 48.6602 30.1094 47.7930 30.1094 46.5742 L 30.1094 22.9024 L 29.9219 17.8867 L 36.9766 25.5976 L 42.2266 30.7773 C 42.6250 31.1524 43.1641 31.3633 43.7266 31.3633 C 44.9219 31.3633 45.7656 30.4727 45.7656 29.3008 C 45.7656 28.7383 45.5547 28.2227 45.0859 27.7305 L 29.5703 12.2149 C 29.2422 11.8633 28.8672 11.6524 28.4688 11.5352 L 43.7734 11.5352 C 45.0156 11.5352 45.8594 10.6680 45.8594 9.4492 C 45.8594 8.2305 45.0156 7.3398 43.7734 7.3398 L 12.1797 7.3398 C 10.9610 7.3398 10.1406 8.2305 10.1406 9.4492 C 10.1406 10.6680 10.9610 11.5352 12.1797 11.5352 L 27.5312 11.5352 C 27.1328 11.6524 26.7578 11.8633 26.4297 12.2149 L 10.9375 27.7305 C 10.4688 28.2227 10.2344 28.7383 10.2344 29.3008 C 10.2344 30.4727 11.1016 31.3633 12.2734 31.3633 C 12.8359 31.3633 13.3984 31.1524 13.7734 30.7773 L 19.0234 25.5976 L 26.0781 17.8633 L 25.9141 22.9024 L 25.9141 46.5742 C 25.9141 47.7930 26.7812 48.6602 28 48.6602 Z" />
        </svg>
      )
    case "nav-down":
      return (
        <svg fill={color || "#000000"} viewBox="0 0 56 56">
          <path d="M 45.7773 50.1367 C 45.7773 48.9180 44.9336 48.0508 43.7383 48.0508 L 28.2227 48.0508 C 28.7149 48.0039 29.1836 47.7461 29.5586 47.3476 L 45.1211 31.7617 C 45.5664 31.2929 45.7773 30.8008 45.7773 30.2382 C 45.7773 29.0898 44.9102 28.1992 43.7383 28.1992 C 43.1992 28.1992 42.6367 28.3867 42.2617 28.7617 L 36.9180 34.0117 L 29.9102 41.7226 L 30.0976 36.7070 L 30.0976 5.8164 C 30.0976 4.6211 29.2071 3.7539 27.9883 3.7539 C 26.7695 3.7539 25.9024 4.6211 25.9024 5.8164 L 25.9024 36.7070 L 26.0898 41.6992 L 19.0586 34.0117 L 13.7383 28.7617 C 13.3633 28.3867 12.8008 28.1992 12.2383 28.1992 C 11.0898 28.1992 10.2227 29.0898 10.2227 30.2382 C 10.2227 30.8008 10.4336 31.2929 10.8789 31.7617 L 26.4414 47.3476 C 26.8164 47.7461 27.2851 48.0039 27.7773 48.0508 L 12.3086 48.0508 C 11.0898 48.0508 10.2227 48.9180 10.2227 50.1367 C 10.2227 51.3555 11.0898 52.2461 12.3086 52.2461 L 43.7383 52.2461 C 44.9336 52.2461 45.7773 51.3555 45.7773 50.1367 Z" />
        </svg>
      )
    case "pull-right":
      return (
        <svg fill={color || "#000000"} viewBox="0 0 512 512">
          <g>
            <g>
              <path
                d="M297.141,251.424L173.731,5.623C172,2.177,168.473,0,164.616,0H31.006c-3.537,0-6.821,1.834-8.68,4.845
                s-2.023,6.768-0.435,9.931L143.005,256L21.891,497.224c-1.587,3.162-1.424,6.92,0.434,9.931S27.468,512,31.006,512h133.61
                c3.858,0,7.385-2.177,9.115-5.622l123.41-245.801C298.588,257.697,298.588,254.303,297.141,251.424z M158.324,491.602H47.539
                l115.992-231.024c1.446-2.88,1.446-6.275,0-9.154L47.539,20.398h110.785L276.615,256L158.324,491.602z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M490.108,251.424L366.698,5.623C364.967,2.177,361.44,0,357.583,0h-133.61c-3.538,0-6.822,1.834-8.681,4.845
                c-1.858,3.011-2.023,6.768-0.435,9.931L335.971,256L214.858,497.224c-1.587,3.162-1.424,6.92,0.435,9.931
                c1.858,3.011,5.142,4.845,8.681,4.845h133.61c3.857,0,7.384-2.177,9.115-5.622l123.41-245.801
                C491.555,257.697,491.555,254.303,490.108,251.424z M351.291,491.602H240.506l115.992-231.024c1.446-2.88,1.446-6.275,0-9.154
                L240.506,20.398h110.785L469.58,256L351.291,491.602z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M370.959,93.335l-3.242-6.456c-2.528-5.033-8.657-7.065-13.691-4.538c-5.033,2.528-7.066,8.657-4.538,13.691l3.242,6.456
			          c1.791,3.566,5.387,5.625,9.122,5.625c1.538,0,3.101-0.35,4.569-1.087C371.455,104.498,373.488,98.369,370.959,93.335z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                d="M450.33,251.423l-62.985-125.449c-2.527-5.032-8.654-7.063-13.691-4.538c-5.033,2.527-7.066,8.657-4.538,13.691
                l62.985,125.449c1.79,3.566,5.387,5.625,9.122,5.625c1.538,0,3.1-0.349,4.569-1.087
                C450.826,262.587,452.859,256.457,450.33,251.423z"
              />
            </g>
          </g>
        </svg>
      )
    case "file-import":
      return (
        <svg fill={color || "#000000"} viewBox="0 0 512 512" height="20" width="20">
          <path d="M128 64c0-35.3 28.7-64 64-64L352 0l0 128c0 17.7 14.3 32 32 32l128 0 0 288c0 35.3-28.7 64-64 64l-256 0c-35.3 0-64-28.7-64-64l0-112 174.1 0-39 39c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l39 39L128 288l0-224zm0 224l0 48L24 336c-13.3 0-24-10.7-24-24s10.7-24 24-24l104 0zM512 128l-128 0L384 0 512 128z"/>
        </svg>
      )
    default:
      return null;
  }
}

export default SVGIcon;

import { checkBestPractices } from "../pages/Best-Practices/2024/utils";
import { isMobileDevice } from "../utils/deviceDetection";

export const getValidUrl = (url) => {
  if (!url || typeof url !== 'string') return "";
  if (url.startsWith("http:") || url.startsWith("https:")) return url;
  return "https://" + url;
}

export const getVimeoIDFromUrl = (url) => {
  const params = url.split("/");
  const vimeoInfo = params[params.length - 1];

  const infos = vimeoInfo.split("?");
  return infos[0];
}

export const openURL = (url, newWindow = false) => {
  if (!url || typeof url !== 'string') return false;

  if (url.startsWith('tel:') && !isMobileDevice()) {
    return false;
  }

  if (newWindow) {
    window.open(url, "_blank");
  } else {
    window.location.href = url;
  }
  return true;
};

export const isImage = (type) => {
  if (!type) return false;
  if (type.indexOf("image") === 0) return true;
  return false;
}

export const getDefaultUrl = (userType, userInfo) => {
  const isSessionHost = userInfo["Status"] === "Session Host";
  if (userType === "Teacher") return "/dashboard";
  if (
    userType === "Team" &&
    isSessionHost &&
    !userInfo["Host Training Approved"]
  ) {
    return "/session-host/best-practices/introduction"
  }

  if (userType !== "Provider") return "/upcoming";
  if (userType === "Provider" &&
    userInfo["24-25 Provider Reviewed"]
  ) return "/upcoming";

  const redir = checkBestPractices(
    userInfo,
    userType,
    "",
  );
  return redir
}
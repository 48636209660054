import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";

const uploadFile = async (file) => {
  try {
    const parallelUploads3 = new Upload({
      client: new S3Client({
        region: "ca-central-1",
        credentials: {
          accessKeyId: "AKIAZFLASWORJ657E5O2",
          secretAccessKey: "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",
        },
      }),
      leavePartsOnError: false,
      params: {
        ACL: "public-read",
        Bucket: "risingyouth",
        Key: file.name,
        Body: file
      },
    });

    const response = await parallelUploads3.done();
    return response.Location;
  } catch (e) {
    console.log("Error:", e);
  }
}

export default uploadFile;
import supabase from "../../../database";
import { consolidateFormLayout } from "../util/consolidate-form-layout";
import { transformGroupPage } from "../util/transform-group-page";
import { transformField } from "../util/transform-field";
import { transformFieldGroups } from "../util/transform-field-group";

export const fetchFormFields = async (formId) => {
	try {
		const pagesResponse = await supabase.groupPages.listByForm(formId);
		const transformedPages = pagesResponse.map(transformGroupPage);
		const pageIds = transformedPages.map((page) => page.id);

		const groupsResponse = await supabase.fieldGroups.listByPages(pageIds);
		const transformedGroups = groupsResponse.map(transformFieldGroups);
		const groupIds = transformedGroups.map((group) => group.id);

		const fieldsResponse = await supabase.fields.listByGroups(groupIds);
		const transformedFields = fieldsResponse.map(transformField);

		const pages = consolidateFormLayout(
			transformedPages,
			transformedGroups,
			transformedFields,
		);

		return pages || [];
	} catch (e) {
		console.log("Error:", e);
		return [];
	}
};

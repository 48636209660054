import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import OverlayLoading from "../../../components/OverlayLoading";
import PresenterFormModal from "../../modals/PresenterForm";
import airtable from "../../../airtables";
import { setPresentersForProvider } from "../../../redux/actions";

const PresenterListTab = () => {
  const dispatch = useDispatch();
  const { userInfo, presentersForProvider } = useSelector(state => state.appInfo);
  const [processingTitle, setProcessingTitle] = useState("");
  const [processing, setProcessing] = useState(false);
  const [activePresenter, setActivePresenter] = useState(null);
  const [showPresenterModal, setShowPresenterModal] = useState(false);

  const archivePresenter = async (presenter, no) => {
    setProcessingTitle("Archiving...");
    setProcessing(true);
    await airtable.presenters.update(presenter.id, {
      Archived: true
    });
    let newPs = [...presentersForProvider];
    newPs[no].Archived = true;
    dispatch(setPresentersForProvider(newPs))
    setProcessingTitle("");
    setProcessing(false);
  }

  const unarchivePresenter = async (presenter, no) => {
    setProcessingTitle("Unarchiving...");
    setProcessing(true);
    await airtable.presenters.update(presenter.id, {
      Archived: false
    });
    let newPs = [...presentersForProvider];
    newPs[no].Archived = false;
    dispatch(setPresentersForProvider(newPs))
    setProcessingTitle("");
    setProcessing(false);
  }

  const editPresenter = async (presenter, no) => {
    setActivePresenter(presenter);
    setShowPresenterModal(true)
  }

  const addPresenter = () => {
    setActivePresenter(null);
    setShowPresenterModal(true);
  }

  const updatePresenterFromModal = async (email, name, phone) => {
    setShowPresenterModal(false);

    setProcessing(true);
    if (activePresenter) {
      setProcessingTitle("Updating...");
      const presenterInfo = await airtable.presenters.update(activePresenter.id, {
        "Name": name,
        "Email": email,
        "Phone": phone,
      });

      let newPs = [...presentersForProvider];
      const no = presentersForProvider.findIndex(p => p.id === activePresenter.id);
      newPs[no] = presenterInfo;
      dispatch(setPresentersForProvider(newPs));
    } else {
      setProcessingTitle("Adding...");
      const params = {
        Email: email,
        Name: name,
        Phone: phone,
        Provider: [userInfo.id]
      };
      const pInfo = await airtable.presenters.create(params);
      let newPs = [...presentersForProvider, pInfo];
      dispatch(setPresentersForProvider(newPs));
    }
    setProcessingTitle("");
    setProcessing(false);
    setActivePresenter(null);
  }

  const deletePresenter = async (presenter, i) => {
    if (window.confirm("Are you sure you want to delete this presenter?")) {
      setProcessingTitle("Deleting...");
      setProcessing(true);
      await airtable.presenters.delete(presenter.id);

      let newPs = [...presentersForProvider];
      newPs.splice(i, 1);
      dispatch(setPresentersForProvider(newPs));

      setProcessingTitle("");
      setProcessing(false);
    }
  }

  return (
    <div className="tab-container">
      <div className="payment-form-section">
        <div className="top-actions">
          <Button
            color="primary"
            size="sm"
            onClick={() => addPresenter()}
          >
            <i className="fa fa-plus" />&nbsp;Add Presenter
          </Button>
        </div>
        <div>
          {presentersForProvider.map((presenter, i) => (
            <div className="presenter-container" key={i}>
              <div style={{ flex: 1, display: 'flex' }}>
                <div style={{ flex: 1 }}><span>{presenter.Name}{
                  presenter.Archived ? (<span>(Archived)</span>) : null
                }</span></div>
                <div style={{ flex: 1 }}><span>{presenter.Email}</span></div>
                <div style={{ flex: 1 }}><span>{presenter.Phone}</span></div>
              </div>
              <UncontrolledDropdown>
                <DropdownToggle color="light" tag="span" className="btn-more">
                  <i className="fa fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => editPresenter(presenter, i)}>Edit</DropdownItem>
                  {
                    !presenter.Archived ? (
                      <DropdownItem onClick={() => archivePresenter(presenter, i)}>Archive</DropdownItem>
                    ) : (
                      <DropdownItem onClick={() => unarchivePresenter(presenter, i)}>Unarchive</DropdownItem>
                    )
                  }
                  {
                    (!presenter["Sessions Delivered"]) ? (
                      <DropdownItem onClick={() => deletePresenter(presenter, i)}>Delete</DropdownItem>
                    ) : null
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          ))}
        </div>
        <OverlayLoading visible={processing} title={processingTitle} />
      </div>
      {showPresenterModal && (
        <PresenterFormModal
          presenter={activePresenter}
          onToggle={() => {
            setShowPresenterModal(false);
            setActivePresenter(null);
          }}
          onSave={updatePresenterFromModal}
        />
      )}
    </div>
  )
}

export default PresenterListTab;
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { useSchools } from "./hooks";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingBar from "../../components/LoadingBar";
import Select from "react-select";
import School from "./School";
import TransferCreditModal from "../modals/TransferCredit";
import SchoolCharts from "./SchoolCharts";
import { Button } from "reactstrap";
import AddTeacherModal from "../modals/AddTeacher";
import { hideLoading, showLoading } from "../../utils/loading";
import { toast } from "react-toastify";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import { NUNAVUT_CLUSTER } from "../PDPlan/constants/pd-fields";

const Screen = () => {
	const { t } = useTranslation();
	const { userType, userInfo } = useSelector((state) => state.appInfo);
	const {
		loading: schoolLoading,
		schools,
		school,
		setSchool,
		addNewTeacher,
		refresh,
		getTitle,
	} = useSchools();
	const [showTransferCreditModal, setShowTransferCreditModal] =
		useState(false);
	const [newTeacherModal, showNewTeacherModal] = useState(false);
	const [refreshSchool, setRefreshScool] = useState(1);
	const [activeTab, setActiveTab] = useState(() => {
		return userInfo["District Leader?"]
			? "School Dashboard"
			: "Teachers & Staff";
	});

	const history = useHistory();

	useEffect(() => {
		if (
			userType === "Teacher" &&
			!userInfo["School Leader?"] &&
			!userInfo["District Leader?"]
		) {
			window.location.replace("/cn/dashboard");
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		let isMulti = false;
		if (userType === "Team" || (schools && schools.length > 1))
			isMulti = true;

		const schoolTitle = isMulti
			? t("principal.heading-multi")
			: t("principal.heading");

		window.document.title = `${schoolTitle} - Connected North`;
	}, [schools]); // eslint-disable-line react-hooks/exhaustive-deps

	const onAddNewTeacher = async (name, email) => {
		try {
			showNewTeacherModal(false);
			showLoading("Adding...");
			const newTeacher = await addNewTeacher(name, email);
			setRefreshScool(refreshSchool + 1);
			hideLoading();

			if (newTeacher) {
				toast.success("A new teacher was created successfully!");
			}
		} catch (error) {
			const errorMsg = error?.message || "Unknown Error";
			toast.error(errorMsg);
			hideLoading();
		}
	};

	const handleKeyDown = (e, tab) => {
		if (e.key === "Enter") {
			setActiveTab(tab);
		}
	};



	const getTabs = () => {
		const isNunavut = userInfo?.["School P/T Text"] === "NU" || 
						  (userInfo?.Cluster && userInfo.Cluster.includes(NUNAVUT_CLUSTER));

		const isDistrictLeader = userInfo["District Leader?"];

		let availableTabs = [];

		if (isDistrictLeader) {
			availableTabs.push("School Dashboard");
		}

		if (isNunavut) {
			availableTabs.push("Monthly Reports");
		}

		availableTabs.push("Teachers & Staff");

		return availableTabs;
	};

	const tabs = getTabs();

	useEffect(() => {
		if (!tabs.includes(activeTab)) {
			setActiveTab(tabs[0]);
		}
	}, [tabs, activeTab]);

	return (
		<div className="main-container">
			<div className="schools-header">
				<h1>{getTitle()}</h1>
				{!!schools && schools.length > 1 && (
					<div className="school-selector">
						<Select
							className="form-style"
							value={
								school
									? { value: school.id, label: school.School }
									: null
							}
							placeholder="Select a School"
							onChange={(selectedOption) => {
								const sc = schools.find(
									(s) => s.id === selectedOption.value,
								);
								if (sc) {
									setSchool(sc);
									setRefreshScool((prev) => prev + 1);
								}
							}}
							options={schools
								.filter(
									(sc) =>
										!sc.School.includes("Mobile Unit") &&
										!sc.School.includes("Floor"),
								)
								.map((sc) => ({
									value: sc.id,
									label: sc.School,
								}))}
						/>
					</div>
				)}
			</div>
			{tabs.length > 1 && (
				<Nav tabs className="my-school-tabs">
					{tabs.map((tabData, i) => (
						<NavItem key={i} className="tabs-name">
							<NavLink
								className={classNames({
									active: activeTab === tabData,
								})}
								onClick={() => setActiveTab(tabData)}
								role="tab"
								aria-selected={activeTab === tabData}
								tabIndex="0"
								onKeyDown={(e) => handleKeyDown(e, tabData)}
							>
								{tabData}
							</NavLink>
						</NavItem>
					))}
				</Nav>
			)}
			<TabContent activeTab={activeTab} className="tab-content">
				{tabs.map((tabData, i) => (
					<TabPane tabId={tabData} key={i}>
						{tabData === "School Dashboard" && (
							<div>
								{!!school &&
									(userType === "Team" ||
										userInfo["District Leader?"]) && (
										<SchoolCharts school={school} />
									)}
							</div>
						)}

						{tabData === "Monthly Reports" && (
							<div className="reports-table-container">
								<h2>
									Monthly School Reports
									<span
										style={{
											color: "red",
											marginLeft: "8px",
											fontSize: "0.8em",
										}}
									>
										BETA
									</span>
								</h2>
								{school && school.reports.length > 0 ? (
									<>
										<div className="no-reports-container">
											<p>
												Note: This feature is currently
												in testing in the first month of
												submissions, and we are
												collecting feedback and bug
												reports. If you notice any
												issues, please e-mail{" "}
												<a href="mailto:connectednorth@takingitglobal.org">
													connectednorth@takingitglobal.org
												</a>{" "}
												so they can be addressed. Thank
												you!
											</p>
										</div>
									<table className="reports-table">
										<thead className="table-head">
											<tr className="table-row">
												<th>Report</th>
												<th>Status</th>
												<th>Created By</th>
												<th>Reviewed By</th>
												<th>Submitted On</th>
												<th>Last Updated On</th>
												<th>Report Link</th>
											</tr>
										</thead>
										<tbody>
									{school.reports.map((report, index) => (
										<tr
											key={index}
											className="table-row"
											
										>
											<td className="table-data">
												<span>
													{report.month}
												</span>
											</td>
											<td className="table-data">
												<span className={`table-data-status status-${report.status}`}>
													{t(`form_status.${report.status || 'not-started'}`)}
												</span>
											</td>
											<td className="table-data">
												<span>{report.user_name}</span>
											</td>
											<td className="table-data">
												<span>{report.dea_name}</span>
											</td>
											<td className="table-data">
												<span>
													{report.submitted_at && 
													 new Date(report.submitted_at).getTime() > 0 ?
														new Date(report.submitted_at).toLocaleDateString('en-US', {
															year: 'numeric',
															month: 'short',
															day: 'numeric'
														})
														: '-'
													}
												</span>
											</td>
											<td className="table-data">
												<span>
													{report.last_saved_at ? new Date(report.last_saved_at).toLocaleDateString('en-US', {
														year: 'numeric',
														month: 'short',
														day: 'numeric'
													}) : ''}
												</span>
											</td>
											<td className="table-data" role="button">
												<span className="text-underline" onClick={report.onClick}>{report.report_label}</span>
											</td>
										</tr>
										))}
										</tbody>
									</table>
									</>
								) : (
									<div className="no-reports-container">
										<p>
											Through this platform, school
											leaders can complete monthly reports
											to collect and visualize data on
											their school's progress and key
											activities. Click the button below
											to create your first report.
										</p>
										<p>
											Note: This feature is currently in
											testing in the first month of
											submissions, and we are collecting
											feedback and bug reports. If you
											notice any issues, please e-mail{" "}
											<a href="mailto:connectednorth@takingitglobal.org">
												connectednorth@takingitglobal.org
											</a>{" "}
											so they can be addressed. Thank you!
										</p>
										<Button
											className="btn btn-primary"
											onClick={() =>
												history.push(
													`/cn/principal-reporting/`,
												)
											}
										>
											Create Report
										</Button>
									</div>
								)}
							</div>
						)}
						{(tabData === "My Teachers" ||
							tabData === "Teachers & Staff") && (
							<div>
								<div>
									{userType === "Teacher" &&
										userInfo["School Leader?"] &&
										!userInfo["District Leader?"] && (
											<Button
												className="btn btn-secondary"
												onClick={() =>
													showNewTeacherModal(true)
												}
											>
												Add Teacher
											</Button>
										)}
									{userType === "Teacher" &&
										!userInfo["District Leader?"] && (
											<a
												className="btn btn-primary"
												href="/cn/credits/earn-more"
											>
												{t("principal.buy-credits")}
											</a>
										)}
									{userType === "Team" && (
										<button
											className="btn btn-primary"
											onClick={() =>
												setShowTransferCreditModal(true)
											}
										>
											Credit Transfer
										</button>
									)}
								</div>
								{!!school && (
									<School
										school={{
											id: school.id,
											name: school.School,
										}}
										isSchoolCredit={
											(userType === "Teacher" ||
												userType === "Team") &&
											schools.length > 1
										}
										refresh={refreshSchool}
										refresher={() =>
											setRefreshScool(refreshSchool + 1)
										}
									/>
								)}

								{showTransferCreditModal && (
									<TransferCreditModal
										onToggle={() =>
											setShowTransferCreditModal(false)
										}
										onPost={() => refresh()}
									/>
								)}
								{newTeacherModal && (
									<AddTeacherModal
										confirmBtnLabel="Save Teacher"
										onToggle={() =>
											showNewTeacherModal(
												!newTeacherModal,
											)
										}
										addTeacher={onAddNewTeacher}
									/>
								)}
							</div>
						)}
					</TabPane>
				))}
			</TabContent>

			{schoolLoading && <LoadingBar />}
		</div>
	);
};

export default Screen;

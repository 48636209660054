import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CarouselProvider = ({ children, responsive, title, className, infinite=true }) => {
	return (
		<div className="carousel-wrap">
			<h2
				style={{
					fontWeight: 700,
					fontSize: 26,
					lineHeight: 1.3,
					marginBottom: -40,
					marginRight: 94,
				}}
			>
				{title}
			</h2>
			<Carousel
				responsive={responsive}
				renderButtonGroupOutside={true}
				infinite={infinite}
				partialVisible={true}
				containerClass="carousel-container"
				itemClass={className ? className : "carouselItem"}
			>
				{children}
			</Carousel>
		</div>
	);
};
export default CarouselProvider;

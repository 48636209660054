import React, { useEffect, useState } from 'react';
import {
  Nav,
  NavItem,
  NavLink as TabNavLink,
  TabContent,
  TabPane
} from 'reactstrap'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import "./styles.scss";
import SessionList from './SessionList';
import { useTeamPendingData } from './useTeamPendingData';
import FitlerConditionModal from '../modals/FilterCondition';

const Screen = () => {
  const { t } = useTranslation();
  const { userInfo } = useSelector(state => state.appInfo);
  const isAdmin = userInfo["Administrator"] ? true : false;

  const {
    schools,
    requestedSessions,
    setRequestedSessions,
    planningSessions,
    setplanningSessions,
    devSessions,
    setDevSessions,
    wishSessions,
    setWishSessions,
    adminSessions,
    setAdminSessions,
    rLoading,
    ipLoading,
    dLoading,
    aLoading,
    wLoading,
    refresh,
    pendingTeacherSessions,
    setPendingTeacherSessions,
    ptLoading,

    filterConditions,
    setFilterConditions,
  } = useTeamPendingData();
  const [activeTab, setActiveTab] = useState('requested');

  useEffect(() => {
    window.document.title = "My Requests - Connected North"
  }, [])

  const [showFilter, setShowFilter] = useState(false);

  let count = 0;
  if (filterConditions.filterKeyword) count++;
  if (
    filterConditions.filterSchool &&
    filterConditions.filterSchool.value
  ) count++;
  if (filterConditions.filterDate) count++;
  if (filterConditions.filterCILC) count++;

  return (
    <div className="main-container">
      <div className="upcoming-title">
        <h1>{t("pending_session_requests")}</h1>
        <div className="filter" onClick={() => setShowFilter(true)}>
          <i className="fa fa-filter" />
          <span className="lbl-filter">Filter</span><span>({count})</span>
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        <Nav tabs className="pending-tabs">
          <NavItem>
            <TabNavLink
              onClick={() => setActiveTab('requested')}
              className={classNames({ active: activeTab === 'requested' })}
            >
              Requested {rLoading ? '' : `(${requestedSessions.length})`}
            </TabNavLink>
          </NavItem>
          <NavItem>
            <TabNavLink
              onClick={() => setActiveTab('planning')}
              className={classNames({ active: activeTab === 'planning' })}
            >
              In Planning {ipLoading ? '' : `(${planningSessions.length})`}
            </TabNavLink>
          </NavItem>
          <NavItem>
            <TabNavLink
              onClick={() => setActiveTab('development')}
              className={classNames({ active: activeTab === 'development' })}
            >
              Pending Provider Response {dLoading ? '' : `(${devSessions.length})`}
            </TabNavLink>
          </NavItem>
          <NavItem>
            <TabNavLink
              onClick={() => setActiveTab('pendingTeacher')}
              className={classNames({ active: activeTab === 'pendingTeacher' })}
            >
              Pending Teacher Response {ptLoading ? '' : `(${pendingTeacherSessions.length})`}
            </TabNavLink>
          </NavItem>
          <NavItem>
            <TabNavLink
              onClick={() => setActiveTab('wishlist')}
              className={classNames({ active: activeTab === 'wishlist' })}
            >
              Wish List {wLoading ? '' : `(${wishSessions.length})`}
            </TabNavLink>
          </NavItem>
          {isAdmin && (
            <NavItem>
              <TabNavLink
                onClick={() => setActiveTab('admin')}
                className={classNames({ active: activeTab === 'admin' })}
              >
                All pending {aLoading ? '' : `(${adminSessions.length})`}
              </TabNavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="requested">
            <SessionList
              loading={rLoading}
              sessionInfo={{ sessions: requestedSessions, updateFunc: setRequestedSessions }}
              onRefresh={refresh}
            />
          </TabPane>
          <TabPane tabId="planning">
            <SessionList
              loading={ipLoading}
              sessionInfo={{ sessions: planningSessions, updateFunc: setplanningSessions }}
              onRefresh={refresh}
            />
          </TabPane>
          <TabPane tabId="development">
            <SessionList
              loading={dLoading}
              sessionInfo={{ sessions: devSessions, updateFunc: setDevSessions }}
              onRefresh={refresh}
            />
          </TabPane>
          <TabPane tabId="pendingTeacher">
            <SessionList
              loading={ptLoading}
              sessionInfo={{ sessions: pendingTeacherSessions, updateFunc: setPendingTeacherSessions }}
              onRefresh={refresh}
            />
          </TabPane>
          <TabPane tabId="wishlist">
            <SessionList
              loading={wLoading}
              sessionInfo={{ sessions: wishSessions, updateFunc: setWishSessions }}
              onRefresh={refresh}
            />
          </TabPane>
          {isAdmin && (
            <TabPane tabId="admin">
              <SessionList
                loading={aLoading}
                sessionInfo={{ sessions: adminSessions, updateFunc: setAdminSessions }}
                onRefresh={refresh}
              />
            </TabPane>
          )}
        </TabContent>
      </div>

      {showFilter && (
        <FitlerConditionModal
          schools={schools}
          initFilter={filterConditions}
          onToggle={() => setShowFilter(!showFilter)}
          onChange={cond => {
            setFilterConditions(cond);
            refresh();
            setShowFilter(false);
          }}
        />
      )}
    </div>
  )
}

export default Screen;

import { useMutation } from "@tanstack/react-query";
// import { createFormResponse } from "../mutations/create-form-response";
// import { createInitialValues } from "../mutations/create-initial-values";
// import { getAllFieldsFromLayout } from "../util/get-all-fields-from-layout";
// import { useSelector } from "react-redux";
import { updateResponse } from "../mutations/update-response";


export const useResponseHandler = (form) => {

    // const { userInfo } = useSelector((state) => state.appInfo);

    // const userId = userInfo.id;
    // const schoolIds = [...userInfo.Cluster,...userInfo["School Name"]];

    // const queryClient = useQueryClient();
    
    // const createInitialValuesMutation = useMutation({
    //     mutationFn: ({ responseId, fields }) => createInitialValues(responseId, fields),
    //     onSuccess: (newValues) => {
    //         form.updateWithResponseValues(newValues);
    //     },
    //     onError: (error) => {
    //         console.error("Error in createInitialValues mutation:", error);
    //     },
    // });
    
    // const createInitialValuesForResponse = async (response) => {
    //     const fields = getAllFieldsFromLayout(form);
    //     console.log('fields', fields);
    //     if (fields) {
    //         createInitialValuesMutation.mutate({ responseId: response.id, fields });
    //     }
    // };
    
    // const createNewFormResponse = useMutation({
    //     mutationFn: () => createFormResponse(form.id, userId, schoolIds),
    //     onSuccess: (newResponse) => {
    //         console.log("Form response created:", newResponse);
    //         queryClient.invalidateQueries(["form_response", form.id, form.user_id]);
    //         console.log('response', newResponse);
    //         form.updateWithResponse(newResponse);
    //         createInitialValuesForResponse(newResponse);
    //     },
    //     onError: (error) => {
    //         console.error("Error creating form response:", error);
    //     },
    // });
    
    const handleFormResponseCreation = () => {
        console.log('not Here');
        // if (form.response_status === "not-started") {
        //     console.log('no response: ', form);
        //     createNewFormResponse.mutate();
        // } else {
        //     console.log('form response already exists: ', form);
        // }
    };
    
    const updateFormResponse = useMutation({
        mutationFn: (data) => updateResponse(form.response_id, data),
        onSuccess: (newResponse) => {
            console.log('new Response', newResponse);
            form.updateWithResponse(newResponse);
            window.location.reload();
        },
        onError: (error) => {
            console.error("Error creating form response:", error);
        },
    });

    const handleUpdateResponse = (data) => {
        updateFormResponse.mutate(data)
    }
    return {
        handleFormResponseCreation,
        handleUpdateResponse,
    };
};
import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
	getPDLog
} from "../util/get-pd-log";
import {
	getTeacherBySyncedId
} from "../util/get-pd-data"
import {
	createPDLog,
	createMultiplePDLog,
	updatePDLog,
	deletePDLog,
} from "../util/post-pd-log";
import {
	updateTeacherCertificate
} from "../util/post-certificate";

import {
	TEACHER_FIELDS,
} from "../constants/pd-fields";
import airtable from "../../../airtables";
import PDAirtable from "../../../airtables/PDAirtable";

const { PD_LOGS, CLUSTER_RECORD_IDS, PD_WEEK_SESSIONS } = TEACHER_FIELDS;

export const usePDLog = () => {
	const { teacherId: reviewTeacherId } = useParams();
	const { userType, userInfo } = useSelector((state) => state.appInfo);
	const teacherId = reviewTeacherId || userInfo.id;

	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [pdLogs, setPdLogs] = useState(null);
	const [nunavutPrincipalId, setNunavutPrincipalId] = useState(null);
	const [nunavutTeacherId, setNunavutTeacherId] = useState(null);
	const [teacher, setTeacher] = useState(null);
	const [pdHours, setPdHours] = useState(0);
	const [teachers, setTeachers] = useState([]);
	const [teachersLoading, setTeachersLoading] = useState(false);
	const [hasSession, setHasSession] = useState(false);
	const [sessionsLoading, setSessionsLoading] = useState(true);
	const [sessions, setSessions] = useState([]);
	const hasInitiallyFetched = useRef(false);

	//Fetch the PD Log for the teacher once the teacherId is set
	useEffect(() => {
		if (error) {
			console.error(error);
			return;
		}

		fetchDataAsync();
		fetchTeachersAsync();
		// eslint-disable-next-line
	}, [userInfo, teacherId, error, setError]);

	useEffect(() => {
		const getTeacherName = async () => {
			const teacher = await getTeacherBySyncedId(userInfo.id);
			if (teacher && teacher["id"]) {
				setNunavutPrincipalId(teacher["id"]);
			}
		};

		if(userInfo.id && !nunavutPrincipalId && reviewTeacherId) {
			getTeacherName();
		}
	}, [userInfo, nunavutPrincipalId, reviewTeacherId])

	useEffect(() => {
		async function fetchSessions() {
			if (hasSession && !reviewTeacherId && sessions.length === 0 && !hasInitiallyFetched.current) {
				hasInitiallyFetched.current = true;
				setSessionsLoading(true);
				try {
					const newSessions = await airtable.sessions.getPDWeekSessionsForTeacher(userInfo.id, userInfo['Teacher Name']);
					const existingSessions = pdLogs ? pdLogs.map(p => p["SessionID"]).filter(Boolean) : [];
					const filteredSessions = newSessions.filter(ses => !existingSessions.includes(ses["SessionID"].toString()));
					setSessions(filteredSessions);
				} catch (error) {
					console.error('Error fetching sessions:', error);
				} finally {
					setSessionsLoading(false);
				}
			}
		}

		fetchSessions();
	}, [hasSession, pdLogs, userInfo, reviewTeacherId, sessions.length]);

	async function fetchTeachersAsync() {
		try {
			setTeachersLoading(true);
			let teachers = []
			if (userType === 'Teacher' && userInfo["Cluster Leadership"]) {
				teachers = await airtable.teachers.getClusterTeachers(userInfo["Cluster Lead Text"][0])
			} else if (userType === 'Teacher') {
				teachers = await airtable.teachers.getSchoolTeachers(userInfo["School Name Text"][0])
			}
			let teacherIds = teachers.map(t => t.id);
			let nunavutTeachers = await PDAirtable.nunavutTeachers.getTeachersBySyncedId(teacherIds)

			nunavutTeachers.sort((a, b) => a["Teacher Name"].localeCompare(b["Teacher Name"]));

			setTeachers(nunavutTeachers);
		} catch (err) {
			setError(err);
		} finally {
			setTeachersLoading(false);
		}
	}

	async function fetchDataAsync() {
		if (teacherId === null) return;
		try {
			setIsLoading(true);

			const data = await getPDLog(teacherId);

			if (!data) {
				setError("No data found for this teacher");
				return;
			}

			if (userInfo["District Leader?"]) {
				const clusterIds = data[CLUSTER_RECORD_IDS]?.split(", ") || [];
				if (
					!userInfo["Cluster Leadership"].some((clusterId) =>
						clusterIds.includes(clusterId),
					)
				) {
					setError(
						"You are not authorized to view this page",
					);
					return;
				}
			}

			if (data.id) {
				setNunavutTeacherId(data.id);
				setTeacher(data);
				setHasSession(data[PD_WEEK_SESSIONS] > 0);
				setPdLogs(data[PD_LOGS] || []);
			} else {
				setError("Invalid teacher data received");
			}
		} catch (err) {
			console.error("Error fetching PD Log:", err);
			setError(err);
		} finally {
			setIsLoading(false);
		}
	};

	async function updatePdLog(logId, params) {
		setIsLoading(true);
		const updatedLog = await updatePDLog(logId, params);
		await fetchDataAsync();
		setIsLoading(false);
		return updatedLog;
	}

	async function deletePdLog(logId) {
		setIsLoading(true);
		await deletePDLog(logId);
		await fetchDataAsync();
		setIsLoading(false);
		return true;
	}

	async function updateAndApprovePdLog(logId, params) {
		if (!nunavutPrincipalId) return;
		setIsLoading(true);
		params["Approved"] = true;
		params["Approved by"] = [nunavutPrincipalId];
		const updatedLog = await updatePDLog(logId, params);
		if(updatedLog) {
			setPdLogs(prevPdLogs => prevPdLogs.map(log => log.id === logId ? { ...log, ...updatedLog } : log))
		}
		setIsLoading(false);
		return updatedLog;
	}

	async function addPdLog() {
		let addedBy = reviewTeacherId ? nunavutPrincipalId : nunavutTeacherId;
 		if (!addedBy) return;
		setIsLoading(true);
		const newPD = await createPDLog({Teacher: [nunavutTeacherId], "Activity Name": `New Activity ${(pdLogs?.length || 0) + 1}`, "Added by": [addedBy]});
		pdLogs && pdLogs.length > 0 ? setPdLogs([...pdLogs, newPD]) : setPdLogs([newPD])
		setIsLoading(false);
		return newPD;
	}

	async function approvePDLog(logId) {
		if (!nunavutPrincipalId) return;
		setIsLoading(true);
		const updatedLog = await updatePDLog(logId, {
			"Approved": true,
			"Approved by": [nunavutPrincipalId]
		});

		setPdLogs(prevPdLogs => prevPdLogs.map(log => log.id === logId ? { ...log, ...updatedLog } : log))
		setIsLoading(false);
		return updatedLog;
	}

	async function updateCertificate(params) {
		if (!nunavutTeacherId) return;
		setIsLoading(true);
		const res = await updateTeacherCertificate(nunavutTeacherId, params);
		fetchDataAsync();
		setIsLoading(false);
		return res;
	}

	async function addSessionsToPDLog(sessionIds) {
		let data = sessionIds.map(id => {
			let session = sessions.find(s => s["SessionID"] === id)
			return {
				SessionID: id.toString(),
				Teacher: [nunavutTeacherId],
				"Added by": [nunavutTeacherId],
				"Activity Name": session["Custom Session Title"] || `PD Week - ${session["Session Title Raw"]} with ${session["Session Provider Name Raw"]}`,
				Description: session["Session Description"][0],
				Hours: Math.round(( session["Length (Minutes)"] / 60) * 2) / 2,
				"Start Date": new Date(session["Session Start Date/Time"]).toISOString().split('T')[0],
			}
		})
		setIsLoading(true);
		const newPDs = await createMultiplePDLog(data);

		if(newPDs) {
			const filteredSessions = sessions.filter(ses => !sessionIds.includes(ses["SessionID"]))
			setSessions(filteredSessions);
			await fetchDataAsync();
		}
		setIsLoading(false);
		return newPDs;
	}

	useEffect( ()=> {
		if (!teacher) return;
		const keyPrefix = !!teacher["Principal?"] ? "Principal " : "";
		setIsLoading(true);

		const hours = teacher["PD Log Activities"]?.reduce((sum, activity) => {
			const { "Start Date": startDate, Hours } = activity;
			if (isDateInRange(startDate, teacher[`${keyPrefix}License Issued`], teacher[`${keyPrefix}License Expiry`])) {
				const hours = parseFloat(Hours) || 0;
				return sum + hours;
			}
			return sum;
		}, 0) || 0;

		setPdHours(parseFloat((hours || 0).toFixed(1))); // Handle potential undefined case
		setIsLoading(false);
	}, [teacher])

	const isDateInRange = (date, date1, date2) => {
		return new Date(date) >= new Date(date1) && new Date(date) <= new Date(date2);
	};

	return {
		teacher,
		teachers,
		nunavutTeacherId,
		nunavutPrincipalId,
		pdLogs,
		pdHours,
		hasSession,
		sessions,

		addPdLog,
		updatePdLog,
		deletePdLog,
		approvePDLog,
		updateAndApprovePdLog,
		updateCertificate,
		addSessionsToPDLog,

		error,
		isLoading: isLoading || teachersLoading,
		sessionsLoading
	}
};

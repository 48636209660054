import supabase from "../../../database";
import { transformResponse } from "../util/transform-response";

export const updateResponse = async ( id, data ) => {
	try {
		const response = await supabase.formResponses.update(id, {
			...data
		});
		const singleResponse = response[0];
		return transformResponse(singleResponse);
	} catch (e) {
		console.log("Error:", e);
		return null;
	}
};


import { create, get } from "@github/webauthn-json";
import * as jose from 'jose'
import { useEffect } from "react";
import {
  finishServerPasskeyLogin,
  finishServerPasskeyRegistration,
  startServerPasskeyLogin,
  startServerPasskeyRegistration
} from "../apis";

const usePasskey = () => {
  useEffect(() => {

  }, [])

  const createPasskey = async (userId, userEmail, userName) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (window.isMobile) {
          window.finishServerPasskeyRegistration = async () => {
            resolve();
          }
          window.ReactNativeWebView.postMessage(`PasskeyRegistration-${JSON.stringify({
            userId,
            userEmail,
            userName
          })}`);
        } else {
          const createOptions = await startServerPasskeyRegistration(userId, userEmail, userName);
          const credential = await create(createOptions);
          await finishServerPasskeyRegistration(credential);
          resolve();
        }
      } catch (error) {
        reject(error)
      }
    })
  }

  const signinWithPasskey = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (window.isMobile) {
          window.finishServerPasskeyLogin = async (token) => {
            const payload = await jose.decodeJwt(token);
            resolve(payload.sub);
          }
          window.ReactNativeWebView.postMessage(`PasskeyLogin`);
        } else {
          const loginOptions = await startServerPasskeyLogin();
          const options = await get(loginOptions);
          const jwtToken = await finishServerPasskeyLogin(options);
          const payload = await jose.decodeJwt(jwtToken.token);
          resolve(payload.sub);
        }
      } catch (error) {
        reject(error);
      }
    })
  }

  return {
    createPasskey,
    signinWithPasskey
  }
}

export default usePasskey;
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import TeacherSetting from './teacher';
import TeamSettings from './team';

const Screen = () => {
  const { userType } = useSelector(state => state.appInfo);

  useEffect(() => {
    window.document.title = "My Settings - Connected North"
  }, [])

  if (userType === 'Teacher') return <TeacherSetting />
  if (userType === 'Team') return <TeamSettings />
  return null;
}

export default Screen;
import { useEffect, useState } from "react";
import { FieldsOfStudy, SearchableGrades, sortlist, WellBeingLinks } from "../../utils/constants";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import airtable from "../../airtables";
import { toast } from "react-toastify";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

const MAX_CREDITS = 60;

const useSearch = (loading) => {
  const {
    totalSubjects,
    communities,
    userInfo,
    appLocale
  } = useSelector((state) => state.appInfo);
  const location = useLocation();
  const [processing, setProcessing] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [filterCondition, setFilterCondition] = useState(null);
  const [appliedFilterCondition, setAppliedFilterCondition] = useState(null);
  const [sortInfo, setSortInfo] = useState(sortlist[0]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState();
  const [hasFilter, setHasFilter] = useState(false);
  const [visibleFilterModal, setVisibleFilterModal] = useState(false);
  const [tagQuery, setTagQuery] = useState("");

  useEffect(() => {
    if (loading) return;
    const cond = {};
    const queryParams = new URLSearchParams(location.search);
    var firstNationCommunities = communities.filter(
      (community) => community.Type === "First Nations",
    );
    var inuitCommunities = communities.filter(
      (community) => community.Type === "Inuit",
    );
    var metisCommunities = communities.filter(
      (community) => community.Type === "Métis",
    );

    let ingGroups = [
      { name: "First Nations", group: firstNationCommunities },
      { name: "Inuit", group: inuitCommunities },
      { name: "Métis", group: metisCommunities },
    ];
    cond["indigenousGroups"] = ingGroups;

    const q = queryParams.get("q");
    if (q) setSearch(q);

    let sbs = totalSubjects.filter(
      sub => (sub["Subject"] !== "Premium Session" && sub["Subject"] !== "Therapy (SLP/OT)")
    ).map((s) => ({ ...s }))

    const subs = queryParams.getAll("subjects");
    if (subs && subs.length) {
      sbs.forEach((sub, i) => {
        if (subs.indexOf(sub["Subject"]) >= 0) {
          sbs[i].checked = true;
        }
      });
    }
    cond["subjects"] = sbs;

    const grades = SearchableGrades.map((g) => ({ name: g }));
    const gds = queryParams.getAll("grades");
    if (gds && gds.length) {
      let gs = [...grades];
      gs.forEach((grade, i) => {
        if (gds.indexOf(grade.name) >= 0) {
          gs[i].checked = true;
        }
      });
      cond["grades"] = gs;
    } else {
      cond["grades"] = grades;
    }

    const wellBeingLinks = WellBeingLinks.map((l) => ({ name: l }))
    const wbls = queryParams.getAll("wellbeinglinks");
    if (wbls && wbls.length) {
      let wbs = [...wellBeingLinks];
      wbs.forEach((w, i) => {
        if (wbls.indexOf(w.name) >= 0) {
          wbs[i].checked = true;
        }
      });
      cond["wellBeingLinks"] = wbs;
    } else {
      cond["wellBeingLinks"] = wellBeingLinks;
    }

    let lens = queryParams.getAll("length");
    if (lens && lens.length) {
      lens = lens.map((l) => parseInt(l));
      cond["length"] = lens;
    } else {
      cond["length"] = [];
    }

    let searchStatus = queryParams.getAll("searchStatus");
    if (searchStatus && searchStatus.length) {
      cond["searchStatus"] = searchStatus
    } else {
      cond["searchStatus"] = [];
    }

    let nosup = queryParams.getAll("nosupplies");
    cond["nosupplies"] = (nosup && nosup.length) ? true : false;

    let langs = queryParams.getAll("language");
    if (langs && langs.length) {
      cond["language"] = langs
    } else {
      cond["language"] = [];
    }

    let credits = queryParams.get("credits");
    if (credits) {
      cond["filterCredits"] = parseInt(credits);
    } else {
      cond["filterCredits"] = 60;
    }

    let igs = queryParams.getAll("indigenousGroups");
    if (igs && igs.length) {
      let ings = [...ingGroups];
      if (igs.indexOf("First Nations") >= 0) {
        ings[0].checked = true;
      }
      if (igs.indexOf("Inuit") >= 0) {
        ings[1].checked = true;
      }
      if (igs.indexOf("Métis") >= 0) {
        ings[2].checked = true;
      }
      cond["indigenousGroups"] = ings;
    } else {
      cond["indigenousGroups"] = ingGroups;
    }

    cond["culturalGroup"] = "";
    if (ingGroups[0].checked) {
      let cg = queryParams.get("culturalGroup");
      if (cg) {
        cond["culturalGroup"] = cg;
      }
    }

    let ic = queryParams.get("indigenousCommunity");
    if (ic) {
      cond["indigenousCommunity"] = ic;
    } else {
      cond["indigenousCommunity"] = "";
    }

    let pn = queryParams.get("pageno");
    if (pn) setCurrentPage(parseInt(pn) - 1);

    cond["fieldsOfStudy"] = FieldsOfStudy.map(field => ({
      id: field.id,
      name: field.en,  // Always use English name
      localizedName: appLocale === 'fr' ? field.fr : field.en,  // Use this for display
      checked: false
    }))

    const fos = queryParams.getAll("fieldsOfStudy");
    if (fos && fos.length) {
      cond["fieldsOfStudy"] = cond["fieldsOfStudy"].map(field => ({
        ...field,
        checked: fos.includes(field.id)
      }));
    }
    setFilterCondition(cond);
    setAppliedFilterCondition(cond);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!appliedFilterCondition) return;

    const {
      subjects,
      searchStatus,
      grades,
      wellBeingLinks,
      length,
      language,
      indigenousGroups,
      culturalGroup,
      indigenousCommunity,
      nosupplies,
      filterCredits,
      fieldsOfStudy
    } = appliedFilterCondition;

    let filter = false;
    if (search) filter = true;

    const checkedSubjects = subjects.filter(s => s.checked);
    if (checkedSubjects && checkedSubjects.length) filter = true;

    const checkedGrades = grades.filter(g => g.checked);
    if (checkedGrades && checkedGrades.length) filter = true;

    const checkedWBLs = wellBeingLinks.filter(w => w.checked);
    if (checkedWBLs && checkedWBLs.length) filter = true;

    if (length && length.length) filter = true;
    if (searchStatus && searchStatus.length) filter = true;
    if (nosupplies && nosupplies.length) filter = true;
    if (language && language.length) filter = true;
    if (filterCredits !== MAX_CREDITS) filter = true;

    const checkedIGs = indigenousGroups.filter(g => g.checked);
    if (checkedIGs && checkedIGs.length) filter = true;

    if (culturalGroup) filter = true;
    if (indigenousCommunity) filter = true;

    const checkedFieldsOfStudy = fieldsOfStudy.filter(f => f.checked);
    if (checkedFieldsOfStudy && checkedFieldsOfStudy.length) filter = true;

    setHasFilter(filter);
    if (filter) {
      onSearch();
    } else {
      // setSubjectsExpanded(true);
      loadUpcomingSessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilterCondition, sortInfo, search])

  useEffect(() => {
    if (currentPage && hasFilter) {
      updateQueryParams(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  const updateQueryParams = (pn) => {
    const params = new URLSearchParams();

    const {
      subjects,
      grades,
      wellBeingLinks,
      length,
      language,
      indigenousGroups,
      culturalGroup,
      indigenousCommunity,
      nosupplies,
      filterCredits,
      fieldsOfStudy
    } = appliedFilterCondition;

    if (search) params.append("q", search);

    let sbs = subjects.filter((sub) => sub.checked);
    if (sbs && sbs.length) {
      sbs.forEach((s) => params.append("subjects", s["Subject"]));
    }

    let gs = grades.filter((g) => g.checked);
    if (gs && gs.length) {
      gs.forEach((g) => params.append("grades", g.name));
    }

    let wbs = wellBeingLinks.filter((w) => w.checked);
    if (wbs && wbs.length) {
      wbs.forEach((w) =>
        params.append("wellbeinglinks", w.name)
      );
    }

    if (length.length) {
      length.forEach((l) =>
        params.append("length", l)
      );
    }

    if (language.length) {
      language.forEach((l) =>
        params.append("language", l)
      );
    }

    let igs = indigenousGroups
      .filter((ig) => ig.checked)
      .map((g) => g.name);
    if (igs && igs.length) {
      igs.forEach((g) =>
        params.append("indigenousGroups", g)
      );
    }

    if (igs.indexOf("First Nations") >= 0 && culturalGroup)
      params.append("culturalGroup", culturalGroup);

    if (indigenousCommunity)
      params.append("indigenousCommunity", indigenousCommunity);

    if (nosupplies)
      params.append("nosupplies", 'true');

    if (filterCredits < MAX_CREDITS) {
      params.append("credits", filterCredits);
    }

    let fos = fieldsOfStudy.filter((f) => f.checked).map((f) => f.id);
    if (fos && fos.length) {
      fos.forEach((f) => params.append("fieldsOfStudy", f));
    }

    // Only append pageno if it's not a new search
    if (pn !== undefined && pn !== 0) {
      params.append("pageno", pn + 1);
    }
    setTagQuery(params.toString())

    const paramString = params.toString();
    if (paramString) {
      window.history.replaceState(null, "", `/cn/search?${paramString}`);
    } else {
      window.history.replaceState(null, "", `/cn/search`);
    }
  }

  const applyFilter = () => {
    setAppliedFilterCondition(filterCondition);
  }

  const loadUpcomingSessions = async (sort) => {
    try {
      const si = sort ? sort : sortInfo;
      setProcessing(true);
      const sessList = await airtable.providerSessions.getUpcomingSessions(si.sort);
      setSessions(sessList);
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      toast.error(error.toString());
    }
  };

  const onSearch = async () => {
    setCurrentPage(0);  // Reset to the first page
    setProcessing(true);

    const {
      subjects,
      searchStatus,
      grades,
      wellBeingLinks,
      length,
      language,
      indigenousGroups,
      culturalGroup,
      indigenousCommunity,
      nosupplies,
      filterCredits,
      fieldsOfStudy
    } = appliedFilterCondition;

    // Collecting filter data
    const filterGrades = grades.filter((grade) => grade.checked);
    const filterSubjects = subjects.filter((subject) => subject.checked);
    const filterWellBeingLinks = wellBeingLinks.filter((linkItem) => linkItem.checked);
    const filterIndigenousGroups = indigenousGroups.filter((ig) => ig.checked);
    const filterFieldsOfStudy = fieldsOfStudy.filter((field) => field.checked);

    const searchStr = search ? search.trim() : "";

    const params = {
      search: searchStr ? searchStr : null,
      language: language,
      length: length,
      filterGrades: filterGrades.length === 0 ? null : filterGrades,
      filterSubject: filterSubjects.length === 0 ? null : filterSubjects,
      filterWellBeingLink: filterWellBeingLinks.length === 0 ? null : filterWellBeingLinks,
      filterCredits: filterCredits === MAX_CREDITS ? null : filterCredits,
      nosupplies: nosupplies,
      searchStatus: searchStatus ? searchStatus : null,
      filterIndigenousGroups: filterIndigenousGroups.length === 0 ? null : filterIndigenousGroups,
      culturalGroup: culturalGroup,
      indigenousCommunity: indigenousCommunity,
      filterFieldsOfStudy: filterFieldsOfStudy.length === 0 ? null : filterFieldsOfStudy,
    };

    try {
      // Fetching filtered sessions
      const { results, searchTerm } = await airtable.providerSessions.filterProviderSessions(
        params.search,
        params.language,
        params.length,
        params.filterGrades,
        params.filterSubject,
        params.filterWellBeingLink,
        filterIndigenousGroups,
        indigenousCommunity,
        culturalGroup,
        params.filterCredits,
        params.nosupplies,
        params.searchStatus,
        sortInfo.sort,
        userInfo.Cluster,
        params.filterFieldsOfStudy,
      );

      // Prioritize results if there's a search term
      const prioritizedResults = searchTerm
        ? airtable.providerSessions.prioritizeResults(results, searchTerm)
        : results;

      // Logging the search event to Firebase
      logEvent(analytics, "search", {
        query: searchStr,
        filters: {
          grades: filterGrades.map((g) => g.name),
          subjects: filterSubjects.map((s) => s.Subject),
          wellbeingLinks: filterWellBeingLinks.map((l) => l.name),
          indigenousGroups: filterIndigenousGroups.map((g) => g.name),
          fieldsOfStudy: filterFieldsOfStudy.map((f) => f.name),
          language: language,
          length: length,
          searchStatus: searchStatus,
          noSupplies: nosupplies,
          culturalGroup: culturalGroup,
          indigenousCommunity: indigenousCommunity,
          credits: filterCredits,
        },
        result: prioritizedResults.length,
      });

      updateQueryParams();
      setSessions(prioritizedResults);
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      toast.error(error.toString());
    }
  };

  const pageChanged = (pn) => {
    setCurrentPage(pn);
  }

  const updateFilterCondition = (field, value) => {
    const newCond = { ...filterCondition };
    newCond[field] = value;
    setFilterCondition(newCond);
  }

  return {
    searching: processing,
    sessions,
    filterCondition,
    updateFilterCondition,
    sortInfo,
    setSortInfo,
    search,
    setSearch,
    hasFilter,
    tagQuery,
    visibleFilterModal,
    setVisibleFilterModal,
    applyFilter,
    pageChanged
  }
}

export default useSearch;
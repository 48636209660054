import React, { useState, useEffect } from 'react';
import { Box, Select, MenuItem } from '@mui/material';
import Chip from '@mui/material/Chip';
import InputWrapper from './InputWrapper';
import { useSchoolTeacherData } from "../../hooks/use-school-teachers-data.hook";

const MultiSelectInput = ({
	id,
	value = [],
  onChange = () => { },
	error = false,
  options=[],
	disabled,
	sx = {},
	heading,
	helperText,
}) => {
  const { inuktutTeachersBySchoolId, teachersBySchoolId } = useSchoolTeacherData();
	const [selectOptions, setSelectOptions] = useState([]);
  
    
	const [inputValue, setInputValue] = useState(value);

	const [inputError, setInputError] = useState(error);
	const filledInput = inputValue.length > 0;

	useEffect(() => {
		const fetchFilteredTeachersData = async () => {
			let teacherOptions;

			switch (options) {
        case 'inuktutTeachersBySchoolId':
					teacherOptions = await inuktutTeachersBySchoolId();
					setSelectOptions(teacherOptions);
					return;
        case 'teachersBySchoolId':
					teacherOptions = await teachersBySchoolId();
					setSelectOptions(teacherOptions);
					return;
				default:
					teacherOptions = options;
					setSelectOptions(teacherOptions);
					return;
			}
		};

		fetchFilteredTeachersData();
	}, [inuktutTeachersBySchoolId, options, teachersBySchoolId]);

	const handleInputChange = (event) => {
		const value = event.target.value;
		setInputValue(value);
		setInputError(false);

		onChange(value);
	};

  // Determines priority of sorting by grade
  const gradeOrder = ['K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

  const getSortedChips = (selected) => {
    return selected
      .map((value) => {
        const option = selectOptions.find(opt => opt.value === value);
        return {
          value,
          label: option?.label
        };
      })
      .sort((a, b) => {
        const indexA = gradeOrder.indexOf(a.label);
        const indexB = gradeOrder.indexOf(b.label);
        return indexA - indexB;
      });
  };
  
	return (
		<InputWrapper
			title={heading}
			sx={{
				maxWidth: "32rem",
				...sx,
			}}
			id={id}
			inputError={inputError}
			filledInput={filledInput}
			helperText={helperText}
		>
			<Box>
				<Select
					id={id}
					name={id}
					aria-describedby="helper-text"
					error={inputError}
					onChange={handleInputChange}
					value={inputValue}
					disabled={disabled}
					fullWidth
					displayEmpty
					multiple
					renderValue={(selected) => (
						<Box
							sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
						>
							{Array.isArray(selected) &&
								getSortedChips(selected).map(
									({ value, label }) => (
										<Chip key={value} label={label} />
									),
								)}
						</Box>
					)}
				>
					{selectOptions.map(({ value, label }) => (
						<MenuItem key={value} value={value}>
							{label}
						</MenuItem>
					))}
				</Select>
			</Box>
		</InputWrapper>
	);
};

export default MultiSelectInput;

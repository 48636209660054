import React, { useEffect, useState, useLayoutEffect } from "react";
import { Button } from "reactstrap";
import { Alert, CircularProgress, Typography, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { usePDLog } from "./hooks/use-pd-log.hook";
import { usePDData } from "./hooks/use-pd-data.hook";
import { PlanLog } from "./components/PlanLog";
import { Paper } from "./components/Paper";
import { toast } from "react-toastify";
import { ToastMessage } from "./components/ToastMessage";
import { PLAN_FIELDS } from "./constants/pd-fields";
import { getTeacherById } from "./util/get-pd-data";
import { PDFManager } from "../../libs/pdf";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

const { GOALS } = PLAN_FIELDS;

const PDLog = () => {
	const { teacher, teachers, pdLogs, addPdLog, approvePDLog, updatePdLog, updateAndApprovePdLog, deletePdLog, nunavutTeacherId, nunavutPrincipalId: principalId, isLoading: isLogLoading } = usePDLog();
	const { activePD, isLoading: isPDLoading } = usePDData();
	const [expandedLog, setExpandedLog] = useState(false);
	const [goals, setGoals] = useState([]);
	const [schoolTeachers, setSchoolTeachers] = useState([]);
	const [teacherName, setTeacherName] = useState("");
	const { t } = useTranslation();

	let isLoading = isLogLoading || isPDLoading;

	const sortLogsByDate = (logs) => {
		return logs.sort((a, b) => {
			const dateA = new Date(a["Start Date"]);
			const dateB = new Date(b["Start Date"]);
			return dateB - dateA; // For descending order
		});
	};

	let approvedLogs = []
	let reviewLogs = []

	if (!isLogLoading && pdLogs && Array.isArray(pdLogs)) {
		const validLogs = pdLogs.filter(log => log["Hours"] && parseFloat(log["Hours"]) > 0);
		approvedLogs = sortLogsByDate(validLogs.filter((log) => log["Approved"]));
		reviewLogs = sortLogsByDate(validLogs.filter((log) => !log["Approved"]));
	}

	useEffect(() => {
		if(!isPDLoading && activePD && activePD[GOALS]) {
			const goalsData = activePD[GOALS].map(goal => ({
				value: goal.id,
				label: goal.Specific
			}))
			setGoals(goalsData);
		}
	}, [activePD, isPDLoading])


	useEffect(() => {
		if(!isLogLoading && teachers && teachers.length > 0) {
			const st = teachers
			.filter(t => t.id !== teacher.id)
			.map(t => ({value: t.id, label: t["Teacher Name"]}))
			.sort((a, b) => a.label.localeCompare(b.label)); // Sort by label (Teacher Name)
			setSchoolTeachers(st);
		}
	}, [teachers, isLogLoading, teacher])

    useEffect(() => {
      const getTeacherName = async () => {
        if (teacherName === "") {
          const teacher = await getTeacherById(nunavutTeacherId);
          if (teacher && teacher["Teacher Name"]) {
            setTeacherName(teacher["Teacher Name"]);
          }
        }
      };

      if (nunavutTeacherId) {
        getTeacherName();
      }
    }, [nunavutTeacherId, teacherName]);

    const userInfo = useSelector((state) => state.appInfo.userInfo);

    const onToggleLog = (panel) => (_event, isExpanded) => {
			setExpandedLog(isExpanded ? panel : false);
    };

		const onAddLog = async () => {
			const added = await addPdLog();

			if (added) {
				toast.success(
					<ToastMessage>{t("pd-plan.logs.success.add")}</ToastMessage>
				);
				setExpandedLog(added.id);
			} else {
				toast.error(
					<ToastMessage>{t("pd-plan.logs.error.add")}</ToastMessage>
				);
			}
		};

		const onDeleteLog = async (logId) => {
			const deleteConfirmation = window.confirm(
				t("pd-plan.logs.delete-confirm")
			);

			if (logId && deleteConfirmation) {
				const log = pdLogs.find(log => log.id === logId)
				let res;
				if (log && log["Teacher"] && log["Teacher"].length > 1) {
					let params = {}
					params.Teacher = log.Teacher.filter(t => t !== teacher.id);
					res = await updatePdLog(logId, params);
				}
				else {
					res = await deletePdLog(logId);
				}

				if (res) {
					toast.success(
						<ToastMessage>{t("pd-plan.logs.success.delete")}</ToastMessage>
					);
				} else {
					toast.error(
						<ToastMessage>{t("pd-plan.logs.error.delete")}</ToastMessage>
					);
				}
			}
		};

    const onApproveLog = async (logId) => {
        if (logId) {
            const log = pdLogs.find(log => log.id === logId);
            
            if (log && log.Teacher && log.Teacher.length > 1) {
                const otherTeachersCount = log.Teacher.length - 1;
                const confirmMessage = window.confirm(
                    t("pd-plan.logs.approve-confirm", {
                        count: otherTeachersCount,
                        educators: otherTeachersCount === 1 ? 'educator' : 'educators'
                    })
                );
                if (!confirmMessage) return;
            }

            const approved = await approvePDLog(logId);

            if (approved) {
                setExpandedLog(false);
                toast.success(
                    <ToastMessage>{t("pd-plan.logs.success.approve")}</ToastMessage>
                );
            } else {
                toast.error(
                    <ToastMessage>{t("pd-plan.logs.error.approve")}</ToastMessage>
                );
            }
        }
    };

		const handleDownloadPdf = async () => {
			const pdfGenerator = new PDFManager();

			pdfGenerator.generateLogsPDF(pdLogs, goals, teacher);
		}

	const formatTeacherName = (name) => {
		if (name.endsWith('s')) {
			return `${name}' PD Log`;
		} else {
			return `${name}'s PD Log`;
		}
	};

	useLayoutEffect(() => {
		window.document.title = t("pd-plan.log-reviewer.page-title");
	});

	return (
		<Box
			className="main-container"
			id="pd-log"
			sx={{
				fontFamily: "inherit",
				color: "#0D1120",
				paddingBottom: "3rem",
				h2: {
					fontWeight: "bold",
					fontSize: "1.8rem",
					lineHeight: "1.3",
				},
				"*, h1, h2, h3, h4, p, button": {
					fontFamily: "inherit",
				},
				"> .MuiPaper-root": {
					margin: "3.5rem 0",
				},
				"button:focus, a[href]:focus": {
					textDecoration: "underline",
					outline: "solid 0.15rem #0e6ba8",
					outlineOffset: "0.1rem",
				},
			}}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					marginBottom: "3rem",
					gap: "2rem",
				}}
			>
				<Typography
					variant="h3"
					component="h1"
					sx={{
						fontSize: "2.5rem",
						lineHeight: "1.5",
					}}
				>
					{formatTeacherName(teacherName)}
				</Typography>
				<Box sx={{ display: "flex", gap: "1rem" }}>
					<Button
						outline
						color="primary"
						onClick={onAddLog}
						disabled={isLoading}
						style={{
							paddingLeft: "1rem",
							display: "flex",
							alignItems: "center",
							gap: "0.5rem",
						}}
					>
						<AddIcon /> {t("pd-plan.log-reviewer.add-activity")}
					</Button>
				</Box>
			</Box>
			{isLoading && <CircularProgress />}
			{ !isLoading && !(pdLogs && pdLogs.length > 0) &&
				<Alert
					severity="info"
					sx={{
						justifyContent: "center",
						alignItems: "center",
						fontFamily: "inherit",
						".MuiAlert-message": {
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							gap: "1rem",
						},
					}}
				>
					<Typography variant="body1" component="p">
              {t("pd-plan.log-reviewer.no-logs")}
					</Typography>
				</Alert>
        }
        {
          (!isLoading && reviewLogs && reviewLogs.length > 0) &&
          <Paper
            title={
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="d-flex align-items-center">
                  <Box>{t("pd-plan.log-reviewer.confirmation-queue")}</Box>
                </div>
              </div>
            }
            className="mt-3"
          >
            {reviewLogs && reviewLogs.map((log) => (
              <Box key={log.id}>
                <PlanLog
                  log={log}
                  goals={goals}
									/* eslint-disable */
                  readOnly={isLoading && log["Added by"] != principalId}
								onUpdate={log["Added by"] == principalId && updateAndApprovePdLog}
								onDelete={log["Added by"] == principalId && onDeleteLog}
								isAuthor={log["Added by"] == principalId}
									/* eslint-enable */
                  onApprove={onApproveLog}
                  onToggleExpand={onToggleLog(log.id)}
                  expanded={expandedLog === log.id}
                  userInfo={userInfo}
									isReviewer={true}
									isPrincipal={true}
									teachers={schoolTeachers}
                />
              </Box>
            ))}
          </Paper>
        }
				{
          (!isLoading && approvedLogs && approvedLogs.length > 0) &&
          <Paper
            title={
              <div className="d-flex justify-content-between align-items-center w-100">
                <div className="d-flex align-items-center">
                  <Box>{t("pd-plan.log-reviewer.confirmed-activities")}</Box>
                </div>
								<Button
									onClick={handleDownloadPdf}
									outline
									color="primary"
									style={{
										padding: "0.5rem 1rem",
										fontSize: "1rem",
									}}
								>
									{t("pd-plan.download-pdf")}
								</Button>
              </div>
            }
            className="mt-3"
          >
            {approvedLogs && approvedLogs.map((log) => (
              <Box key={log.id}>
                <PlanLog
                  log={log}
                  goals={goals}
                  readOnly={true}
                  onToggleExpand={onToggleLog(log.id)}
                  expanded={expandedLog === log.id}
                  userInfo={userInfo}
                  isReviewer={true}
                />
              </Box>
            ))}
          </Paper>
        }
		</Box>
	);
};

export default PDLog;

import airtable from '../../../airtables';
import { useSelector } from "react-redux";

export const useSchoolTeacherData = () => {
  const { userInfo } = useSelector((state) => state.appInfo);
  const schoolId = userInfo["School Name Text"][0];

  const fetchTeachersBySchool = async () => {
    return await airtable.teachers.getSchoolTeachers(schoolId);
  };

  const formattedTeachersAsOptions = async (teachers) => {
    const formattedTeachers = teachers.map((teacher) => { 
      return {
        label: teacher['Teacher Name'],
        value: teacher['Teacher Name']
      }
    })
    return formattedTeachers
  };

  const teachersBySchoolId = async () => { 
    const listOfTeachers = await fetchTeachersBySchool();
    const filteredTeachers = listOfTeachers.filter((teacher) => { 
      return teacher["Indigenous Group(s)"]?.includes('Inuit') ? null : teacher;
    })

    const formattedTeachers = formattedTeachersAsOptions(filteredTeachers);
    return formattedTeachers;
  }
  
  const inuktutTeachersBySchoolId = async () => { 
    const listOfTeachers = await fetchTeachersBySchool();
    const filteredTeachers = listOfTeachers.filter((teacher) => { 
      return teacher["Indigenous Group(s)"]?.includes('Inuit') ? teacher : null;
    })

    const formattedTeachers = formattedTeachersAsOptions(filteredTeachers);
    return formattedTeachers;
  }

  return {
    inuktutTeachersBySchoolId,
    teachersBySchoolId,
  };
};
import React, { useEffect, useState } from 'react';
import { Col, Row } from "reactstrap";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import LoadingBar from '../../../components/LoadingBar'
import SessionDetail from '../../../pages/modals/Session';
import airtable from '../../../airtables';
import Gap from '../../../components/Gap';
import SessionInfo from '../../../components/SessionInfo';
import { getActionUpcomingMenus, getDisplayInfosUpcoming } from '../../SessionInfoDisplay';

const Screen = () => {
  const { userType, presenterUpcoming, presenterPast, presenterLoading } = useSelector(state => state.appInfo);
  const [showPastSessions, setShowPastSessions] = useState(false);
  const [activeSession, setActiveSession] = useState(null);
  const [showSessionDetailModal, setShowSessionDetailModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setShowPastSessions((presenterPast && presenterPast.length) ? true : false);
  }, [presenterLoading, presenterUpcoming, presenterPast])

  const openSessionDetail = async (e, id) => {
    e.preventDefault();
    if (!id) return;

    setShowSessionDetailModal(true);

    const session = await airtable.providerSessions.select(id);

    setActiveSession(session);
  }

  return (
    <div className="main-container">
      <Row>
        <Col>
          <h1>{t('upcoming_sessions')}</h1>
        </Col>
        {showPastSessions && (
          <Col align="right">
            <Link to="upcoming/past">
              <h1 className="title-link">{t("view-past-sessions")} <i className='fa fa-arrow-right' /></h1>
            </Link>
          </Col>
        )}
      </Row>
      <div style={{ marginTop: 10 }}>
        {presenterLoading ? <LoadingBar /> : null}
        <Gap height={20} />
        <div className="general-container">
          {
            (presenterUpcoming && presenterUpcoming.length > 0) ? presenterUpcoming.map((session, index) => (
              <SessionInfo
                key={index}
                displayInfos={getDisplayInfosUpcoming(session, userType, "Upcoming")}
                session={session}
                actionMenu={getActionUpcomingMenus(session, userType)}
                openSessionDetail={openSessionDetail}
              />
            )) : (
              presenterLoading ? null : <div className="nolist-placeholder" align="center">
                <span>{t("no-upcoming-sessions")}</span>
              </div>
            )
          }
        </div>
      </div>

      {(showSessionDetailModal && activeSession !== null) && (
        <SessionDetail
          session={activeSession}
          userType={userType}
          onToggle={() => setShowSessionDetailModal(!showSessionDetailModal)}
        />
      )}
    </div>
  )
}

export default Screen;
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RequestForm from './modals/Request';
import { setRequestSessionModal } from '../redux/modalActions';
import airtable from '../airtables';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';

const ModalList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    requestSession
  } = useSelector(state => state.modal);
  const { userInfo } = useSelector(state => state.appInfo);
  const [prerequisiteSession, setPrerequisiteSession] = useState(null);
  const [suppliesOption, setSuppliesOption] = useState("");

  useEffect(() => {
    if (!requestSession.providerSession) {
      setPrerequisiteSession(null);
      return;
    }

    if (
      !requestSession.isEdit &&
      requestSession.providerSession["School-provided Supplies Option?"] && 
      requestSession.providerSession["School-provided Supplies Option?"][0]
    ) {
      let x, y;

      const providerGroups = requestSession.providerSession["Indigenous Group(s)"];
      const schoolGroups = userInfo["School Indigenous Group(s)"];
      if (providerGroups && schoolGroups && Array.isArray(providerGroups) && providerGroups.length > 0 && providerGroups.some(group => schoolGroups.includes(group))) {
        x = Math.floor(requestSession.providerSession["Discounted Credits"] - (requestSession.providerSession["Credits for Supplies"] * 0.75));
        y = requestSession.providerSession["Discounted Credits"];
      } else {
        x = requestSession.providerSession["Credits"] - requestSession.providerSession["Credits for Supplies"];
        y = requestSession.providerSession["Credits"];
      }

      confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Do you need a shipment of supplies for this Session?</h1>
              <p>This Session requires the following supplies: {requestSession.providerSession["Supplies Name"]}</p>
              <p>We know some schools may have these supplies available, so we can offer a discounted Session cost if shipment is not required. Please let us know how you'd like to proceed:</p>
              <div className="confirm-alert-actions">
                <Button
                  color="outline-primary"
                  type="button"
                  onClick={() => {
                    setSuppliesOption(`not-required-${x}`);
                    onClose();
                  }}
                >
                  Book without Supplies for {x} Credits
                </Button>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    setSuppliesOption("agreed")
                    onClose();
                  }}
                >
                  Book with Supplies for {y} Credits
                </Button>
              </div>
            </div>
          );
        }
      })
    }

    if (requestSession.providerSession.Title) {
      airtable.providerSessions.selectPrerequisiteSession(requestSession.providerSession.Title).then(res => {
        if (res) setPrerequisiteSession(res);
      })
    } else {
      setPrerequisiteSession(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestSession])

  const renderRequestSession = () => {
    const {
      visible,
      providerSession,
      onSubmitted,
      onClose,
      dayRestrictionConfig,
      isEdit,
      prefillSession,
    } = requestSession;

    if (!visible || !providerSession) return null;

    const onToggle = () => {
      setSuppliesOption("");
      if (onClose) onClose();
      dispatch(setRequestSessionModal({}))
    }

    const onDone = () => {
      if (isEdit) onSubmitted();
      else {
        if (prerequisiteSession) {
          const session = { ...prerequisiteSession };
          onSubmitted();

          confirmAlert({
            title: t("prerequisite-dyk"),
            message: t("prerequisite", { sessiontitle: session["Title"] }),
            buttons: [{
              label: t("request-session"),
              onClick: () => {
                dispatch(setRequestSessionModal({
                  providerSession: session,
                  visible: true,
                  onSubmitted: () => dispatch(setRequestSessionModal({}))
                }))
              },
            }, {
              label: t("session-details"),
              onClick: () => window.open(`/cn/session/${session.id}`, "_blank")
            }, {
              label: t("close"),
              onClick: () => { },
            }],
          });
        } else onSubmitted();
      }
    }

    if (!isEdit && providerSession["School-provided Supplies Option?"] && requestSession.providerSession["School-provided Supplies Option?"][0] && !suppliesOption) {
      return null;
    }

    return (
      <RequestForm
        session={providerSession}
        suppliesOption={suppliesOption}
        dayRestrictionConfig={dayRestrictionConfig}
        onSubmitted={onDone}
        edit={isEdit}
        onToggle={onToggle}
        requestedSession={prefillSession}
      />
    )
  }

  return (
    <Fragment>
      {renderRequestSession()}
    </Fragment>
  )
}

export default ModalList;

import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Modal,
  FormGroup,
  Input,
  Col,
  Form,
  Label,
  FormFeedback,
  InputGroup,
  InputGroupText
} from 'reactstrap';
import { GradeLevels, MAX_SUBJECT_NUM } from '../../utils/constants';
import uploadFile from '../../libs/aws/uploadfile';
import CustomFile from '../../components/CustomFile';
import Loading from '../../components/Loading';
import airtable from '../../airtables';
import { saveAs } from 'file-saver';
import Axios from 'axios';
import { logEvent } from 'firebase/analytics';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { analytics } from '../../firebase';
import classNames from 'classnames';

const descPlaceholder = {
  fr: `Format suggéré: 4 à 8 phrases qui incluent: 
- 1 à 2 phrases d’introduction (qui vous êtes, contexte, travail, compétences, etc.)
- 1 à 2 phrases: ce que les élèves vont apprendre (but de la session)
- 1 à 2 phrases: ce que les élèves vont faire pendant la session (envisagez une composante interactive)`,
  en: `Suggested format: 4-8 sentences which include:
- 1-2 sentences introduction (who you are/background/work/skills, etc.)
- 1-2 sentences: what the students will be learning (the goal of the session)
- 1-2 sentences: what will the students be doing during the session (consider an interactive component)`
}

const ProviderSessionModal = ({ userInfo, session, onToggle, onChange, disableField }) => {
  const { totalSubjects, appLocale, presentersForProvider } = useSelector(state => state.appInfo);
  const [title, setTitle] = useState("");
  const [cost, setCost] = useState(null);
  const [isCostDisabled, setIsCostDisabled] = useState(false);
  const initialCost = useRef(null);
  const [language, setLanguage] = useState("English");
  const [subjects, setSubjects] = useState({});
  const [grades, setGrades] = useState([]);
  const [length, setLength] = useState();
  const [minLength, setMinLength] = useState();
  const [description, setDescription] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef();
  const imgInputRef = useRef();
  const [saving, setSaving] = useState(false);
  const [materialInfo, setMaterialInfo] = useState("");
  const [sessionImg, setSessionImg] = useState(null);
  const [customQuestion, setCustomQuestion] = useState("");
  const [relatedLinkTitle, setRelatedLinkTitle] = useState("")
  const [relatedLinkURL, setRelatedLinkURL] = useState("");
  const [defaultPresenter, setDefaultPresenter] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    if (session) {
      initialCost.current = session['Cost'];
      setTitle(session['Session Title']);
      setCost(session['Cost']);
      setLanguage(session['Language']);
      setGrades(session['Grade(s)']);
      setLength(session['Length (Minutes)']);
      setMinLength(session['Minimum Length']);
      setDescription(session['Description']);
      setMaterialInfo(session["Materials Info"]);
      setRelatedLinkTitle(session["Link Title"]);
      setRelatedLinkURL(session["Link URL"]);
      setCustomQuestion(session["Custom Teacher Question"]);
      setDefaultPresenter(session['Default Presenter'] ? session['Default Presenter'][0] : '')
      if (session['Resource Sheets']) {
        setAttachments(session['Resource Sheets'].map((file) => ({
          file: {
            name: file.filename,
            type: file.type,
            url: file.url
          },
          thumbs: file?.thumbnails?.large,
          uploaded: true
        })));
      } else {
        setAttachments([]);
      }

      if (session['Image/Photo']) {
        setSessionImg({
          file: {
            name: session['Image/Photo'][0].filename,
            type: session['Image/Photo'][0].type,
            url: session['Image/Photo'][0].url
          },
          thumbs: session['Image/Photo'][0]?.thumbnails?.large,
          uploaded: true
        })
      } else setSessionImg(null);

      let obj = {}
      if (session.Subject) {
        session.Subject.forEach(sId => {
          obj[sId] = true;
        })
      }
      setSubjects(obj);
      if (initialCost.current !== null && initialCost.current > 0) {
        setIsCostDisabled(true);
      }
    } else {
      setTitle("");
      setCost();
      setLanguage("English");
      setSubjects([]);
      setGrades([]);
      setLength();
      setMinLength();
      setDescription("");
      setMaterialInfo("");
      setAttachments([]);
      setSessionImg(null);
      setRelatedLinkTitle("");
      setRelatedLinkURL("");
      setCustomQuestion("");
      setDefaultPresenter('')
    }
  }, [session])

  const disabled = () => {
    if (session && disableField) return true;
    return false;
  }

  const onAddAttachFile = (file) => {
    if (file) {
      var atts = [...attachments];
      atts.push({
        file,
        uploaded: false
      })
      setAttachments(atts);
      fileInputRef.current.value = '';
    }
  }

  const onAddSessionImage = (file) => {
    if (file && ["image/jpeg", "image/png"].includes(file.type)) {
      setSessionImg({
        file,
        uploadFile: false
      })
    }
    imgInputRef.current.value = '';
  }

  const checkValidation = () => {
    let errorList = {};
    if (!title) errorList.title = true;
    if (!cost && cost !== 0) errorList.cost = true;

    const selectedKeys = Object.keys(subjects).filter(k => subjects[k]);
    if (!selectedKeys.length) errorList.subjects = 'no-subjects'
    else if (selectedKeys.length > MAX_SUBJECT_NUM) errorList.subjects = 'over';

    if (!grades || !grades.length) errorList.grades = true;
    if (!length) errorList.length = true;
    if (!description) errorList.description = true;
    if (!sessionImg) errorList.sessionImg = true;

    return errorList;
  }

  const onSave = async () => {
    if (saving) return;

    const errorList = checkValidation();

    if (Object.keys(errorList).length) {
      setErrors(errorList);
      return;
    }

    setSaving(true);
    var params = {
      'Session Title': title,
      'Cost': cost,
      'Language': language,
      'Length (Minutes)': length,
      'Minimum Length': minLength,
      'Description': description,
      'Subject': [],
      'Grade(s)': grades,
      'Provider': [userInfo.id],
      'Provider Updates to Review': true,
      'Materials Info': materialInfo,
      'Link Title': relatedLinkTitle,
      'Link URL': relatedLinkURL,
      'Custom Teacher Question': customQuestion,
      'Default Presenter': defaultPresenter ? [defaultPresenter] : null
    };

    const sKeys = Object.keys(subjects);
    sKeys.forEach(k => {
      if (subjects[k]) params['Subject'].push(k);
    })

    if (attachments && attachments.length) {
      for (var i = 0; i < attachments.length; i++) {
        if (!attachments[i].uploaded) {
          const file = attachments[i].file;
          const uRes = await uploadFile(file)
          attachments[i].file = {
            filename: file.name,
            type: file.type,
            url: uRes
          }
          attachments[i].uploaded = true;
        }
      }
      params['Resource Sheets'] = attachments.map(attach => ({ url: attach.file.url }));
    }

    if (sessionImg) {
      if (!sessionImg.uploaded) {
        const uRes = await uploadFile(sessionImg.file);
        params['Image/Photo'] = [{ url: uRes }];
      } else {
        params['Image/Photo'] = [{ url: sessionImg.file.url }];
      }
    } else {
      params['Image/Photo'] = null;
    }

    var tmpSessions = null;
    if (session) {
      await airtable.providerSessions.update(session.id, params);
    } else {
      tmpSessions = await airtable.providerSessions.create(params);
    }

    logEvent(analytics, session ? "edit_session" : "add_session", {
      userId: userInfo.id,
      sessionId: session ? session.id : tmpSessions.id
    })

    onChange();
    onToggle();
  }

  const fileDownload = (url, name) => {
    const config = { responseType: 'blob' };
    Axios.get(url, config).then(response => {
      saveAs(response.data, name)
    });
  }

  const renderSubject = (sub, i) => {
    const subjectName = appLocale === 'fr' ? sub["Subject (FR)"] : sub["Subject"];
    if (!subjectName) return;

    return (
      <Button
        key={i}
        outline={subjects[sub.id] ? false : true}
        color="primary"
        size="sm"
        type="button"
        style={{ marginBottom: 5 }}
        onClick={() => {
          if (disabled()) return
          const obj = { ...subjects };
          obj[sub.id] = obj[sub.id] ? false : true;
          setSubjects(obj);
          const selectedKeys = Object.keys(obj).filter(k => obj[k]);
          let err = { ...errors };
          if (!selectedKeys.length) err.subjects = 'no-subjects'
          else if (selectedKeys.length > MAX_SUBJECT_NUM) err.subjects = 'over'
          else delete err.subjects;
          setErrors(err);
        }}
        disabled={disabled()}
      >{subjectName}</Button>
    )
  }

  return (
    <Modal
      className="modal-dialog-centered session-modal"
      isOpen={true}
      toggle={onToggle}
      backdrop="static"
      size='lg'
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">{session ? t("edit-session") : t("add-session")}</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={onToggle}
        ><span aria-hidden={true}>×</span></button>
      </div>
      <div className="modal-body">
        <div className="session-info">
          <Form>
            <FormGroup row>
              <Label sm={3} for="title">{t("title")}:</Label>
              <Col sm={9}>
                <Input
                  invalid={errors.title}
                  id="title"
                  type="text"
                  value={title}
                  onChange={(e) => {
                    let err = { ...errors };
                    err.title = e.target.value.length ? false : true;
                    if (e.target.value.length) delete err.title;
                    setErrors(err);
                    setTitle(e.target.value)
                  }}
                  disabled={disabled()}
                />
                <FormFeedback>Title field is required.</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={3} for="cost">{t("cost")}:</Label>
              <Col sm={9}>
                <InputGroup>
                  <InputGroupText>$</InputGroupText>
                  <Input
                    invalid={errors.cost}
                    id="cost"
                    type="number"
                    placeholder="in dollars, for example: 100, enter 0 if free"
                    defaultValue={cost}
                    style={{ paddingLeft: 15 }}
                    onChange={(e) => {
                      const res = e.target.value.trim() === '' ? null : parseInt(e.target.value);
                      let err = { ...errors };
                      if (res === null || isNaN(res)) {
                        err.cost = 'Cost must be a number';
                      } else {
                        delete err.cost;
                        setCost(res);
                      }
                      setErrors(err);
                    }}
                    disabled={isCostDisabled}
                  />
                </InputGroup>
                <FormFeedback>Please provide a whole number value only for the typical cost of this session.</FormFeedback>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={3} for="language">{t("language")}:</Label>
              <Col sm={9}>
                <Input
                  type="select"
                  id="language"
                  className="form-control"
                  value={language}
                  disabled={disabled()}
                  onChange={e => setLanguage(e.target.value)}
                >
                  <option>English</option>
                  <option value="French">français</option>
                </Input>
              </Col>
            </FormGroup>

            <FormGroup>
              <Label>{t("subjects")}:</Label>
              <Col>
                <div className={classNames({
                  "is-invalid": errors.subjects,
                  "flex-row": true
                })}>
                  {
                    totalSubjects.map((subject, i) => renderSubject(subject, i))
                  }
                </div>
                <div className="invalid-feedback">
                  {errors.subjects === 'over' ? `Please select a maximum of ${MAX_SUBJECT_NUM} Subject areas most closely aligned to this Session.` : "Subject field is required."}
                </div>
              </Col>
            </FormGroup>

            <FormGroup>
              <Label>{t("grades")}:</Label>
              <Col>
                <div className={classNames({ "flex-row": true, "is-invalid": errors.grades })}>
                  {
                    GradeLevels.map((grade, i) => (
                      <Button
                        key={i}
                        outline={grades && grades.indexOf(grade) >= 0 ? false : true}
                        color="primary"
                        size="sm"
                        type="button"
                        style={{ marginBottom: 5 }}
                        onClick={() => {
                          const gs = grades ? [...grades] : [];
                          const id = gs.indexOf(grade);
                          if (id >= 0) {
                            gs.splice(id, 1);
                          } else {
                            gs.push(grade);
                          }
                          setGrades(gs);

                          let err = { ...errors };
                          err.grades = gs.length ? false : true;
                          if (gs.length) delete err.grades;
                          setErrors(err);
                        }}
                        disabled={disabled()}
                      >{grade}</Button>
                    ))
                  }
                </div>
                <div className="invalid-feedback">Grade(s) field is required.</div>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={3} for="length">{t("standard-length")}:</Label>
              <Col sm={9}>
                <InputGroup>
                  <Input
                    id="length"
                    type="number"
                    defaultValue={length}
                    onChange={(e) => {
                      const res = parseInt(e.target.value);
                      let err = { ...errors };
                      if (isNaN(res)) {
                        err.slength = true;
                      } else {
                        err.slength = false;
                        delete err.slength
                        setLength(res)
                      }
                      setErrors(err);
                    }}
                    invalid={errors.slength}
                    disabled={disabled()}
                  />
                  <InputGroupText>minutes</InputGroupText>
                  <FormFeedback>Please provide a whole number value only for the typical length of this session.</FormFeedback>
                </InputGroup>
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={3} for="minLength">{t("minimum-length")}:</Label>
              <Col sm={9}>
                <InputGroup>
                  <Input
                    id="minLength"
                    type="number"
                    defaultValue={minLength}
                    onChange={(e) => {
                      const res = parseInt(e.target.value);
                      setMinLength(isNaN(res) ? null : res);
                    }}
                  />
                  <InputGroupText>minutes</InputGroupText>
                </InputGroup>
                <small className="text-muted">
                  The minimum length of time that you allow educators to request for this Session to fit into class schedules. By default, educators can request up to 10 minutes shorter. If you require the full session time, please set this to the same value as the standard length above.
                </small>
              </Col>
            </FormGroup>

            <FormGroup>
              <Label for="description">{t("description")}:</Label>
              <Input
                invalid={errors.description}
                id="description"
                type="textarea"
                defaultValue={description}
                style={{ height: 120 }}
                onChange={(e) => {
                  let err = { ...errors };
                  err.description = e.target.value.length ? false : true;
                  if (e.target.value.length) delete err.description;
                  setErrors(err);
                  setDescription(e.target.value)
                }}
                disabled={disabled()}
                placeholder={descPlaceholder[appLocale]}
              />
              <FormFeedback>Description field is required.</FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label for="materialsInfo">{t("materials-required")}</Label>
              <Input
                invalid={errors.materialsinfo ? true : false}
                id="materialsInfo"
                type="text"
                defaultValue={materialInfo}
                onChange={(e) => setMaterialInfo(e.target.value)}
                disabled={disabled()}
              />
              <FormFeedback>{errors.materialsinfo}</FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label>{t("support-handouts")}:</Label>
              <div className="flex-row">
                {
                  attachments.map((attach, i) => (
                    <CustomFile
                      key={i}
                      attachment={attach}
                      onRemove={() => {
                        if (window.confirm("Are you sure you want to delete this file?")) {
                          const newAttachments = [...attachments];
                          newAttachments.splice(i, 1);
                          setAttachments(newAttachments);
                        }
                      }}
                      onDownload={() => fileDownload(attach.file.url, attach.file.name)}
                    />
                  ))
                }
              </div>
              <div style={{ paddingTop: 10 }}>
                <Button size="sm" onClick={() => fileInputRef.current.click()} type="button" color="secondary">Attach File</Button>
              </div>
              <input
                type="file"
                accept="*/*"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={e => onAddAttachFile(e.target.files[0])}
              />
            </FormGroup>

            <FormGroup>
              <Label>{t("session-image")}:</Label>
              <div className={classNames({ "flex-row": true, "is-invalid": errors.sessionImg })}>
                {
                  sessionImg ? (
                    <CustomFile
                      attachment={sessionImg}
                      onRemove={() => {
                        if (window.confirm("Are you sure you want to delete this image")) {
                          setSessionImg(null)
                        }
                      }}
                      onDownload={() => fileDownload(sessionImg.file.url, sessionImg.file.name)}
                    />
                  ) : null
                }
              </div>

              <span>{t("provider-session-desc1")}</span>
              <div style={{ paddingTop: 10 }}>
                <Button
                  size="sm"
                  onClick={() => imgInputRef.current.click()}
                  type="button"
                  color="secondary"
                >{sessionImg ? "Change Session Image" : "Add Session Image"}</Button>
              </div>
              <input
                type="file"
                accept="image/*"
                ref={imgInputRef}
                style={{ display: 'none' }}
                onChange={e => {
                  if (e.target.files && e.target.files.length) {
                    if (e.target.files[0].size < 6144000 /** 6M **/) {
                      onAddSessionImage(e.target.files[0])
                    } else {
                      setErrors({
                        ...errors,
                        sessionImg: "Your file is too large! Please upload a JPG or PNG file smaller than 6 MB."
                      })
                    }
                  }
                }}
              />
              {errors.sessionImg && (
                <div className="invalid-feedback">
                  {((typeof errors.sessionImg) === "string") ? errors.sessionImg : "Please provide a related image or photo for display purposes."}
                </div>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="customQuestion">{t("custom-teacher-question")}:</Label>
              <div style={{ marginBottom: '0.5rem' }}>
                <span>{t("custom-teacher-question-detail")}</span>
              </div>
              <Col style={{ padding: 0 }}>
                <Input
                  id="customQuestion"
                  type="text"
                  defaultValue={customQuestion}
                  onChange={(e) => setCustomQuestion(e.target.value)}
                />
              </Col>
            </FormGroup>

            <FormGroup>
              <Label for="relatedLink">{t("related-link")}:</Label>
              <Col style={{ padding: 0 }}>
                <Input
                  id="relatedLinkTitle"
                  type="text"
                  placeholder={t("related-link-title")}
                  defaultValue={relatedLinkTitle}
                  onChange={(e) => setRelatedLinkTitle(e.target.value)}
                />
                <div style={{ height: 10 }} />
                <Input
                  id="relatedLinkUrl"
                  type="text"
                  placeholder={t("related-link-url")}
                  defaultValue={relatedLinkURL}
                  onChange={(e) => setRelatedLinkURL(e.target.value)}
                />
              </Col>
            </FormGroup>

            {
              (presentersForProvider && presentersForProvider.length) ? (
                <FormGroup row>
                  <Label sm={3} for="defaultPresenter">{t("default-presenter")}:</Label>
                  <Col sm={9}>
                    <Input
                      type="select"
                      name="defaultpresenter"
                      id="defaultPresenter"
                      className="form-control"
                      value={defaultPresenter}
                      onChange={e => setDefaultPresenter(e.target.value)}
                    >
                      <option value=""></option>
                      {
                        presentersForProvider.map((presenter, i) => (
                          <option value={presenter.id} key={i}>{presenter.Name}</option>
                        ))
                      }
                    </Input>
                  </Col>
                </FormGroup>
              ) : null
            }
          </Form>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="success"
          type="button"
          onClick={() => onSave()}
        >{saving && (<Loading size={20} />)}{` `}{t("save-change")}</Button>
        <Button
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={onToggle}
        >{t("close")}</Button>
      </div>
    </Modal>
  )
}

export default ProviderSessionModal;

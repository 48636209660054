import React, { useState } from "react";
import {
	Box,
	List,
	ListItem,
	Typography,
	Button,
	styled,
	Collapse,
	useMediaQuery,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
// import { ProgressBar } from "./ProgressBar"; TODO: Uncomment when ProgressBar is ready to be used

const StyledButton = styled(Button)({
	border: "1px solid #0E6BA8",
	width: "100%",
	backgroundColor: "#0E6BA8",
	color: "#FFF",
	borderRadius: "1rem",
	textTransform: "none",
	fontFamily: "Effra, sans-serif",
	fontSize: "1rem",
	fontWeight: "400",
	padding: "0.625rem",
	borderWidth: "0.15rem",
	position: "relative",
	"&:hover": {
		backgroundColor: "#085492",
		borderWidth: "0.15rem",
		transform: "translateY(-2px)",
	},
});

export const SideBar = ({
	sections,
	currentSection,
	handleRouteChange,
	buttonText,
	scrollBehavior = { duration: 500, offset: 0 },
	errorSections = [],
	disabledSections = [],
	onSubmit,
	buttonDisabled,
  buttonHidden,
	sx = {},
}) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);
	const isMobile = useMediaQuery("(max-width:900px)");
	const { t } = useTranslation();

	const handleClick = (sectionId) => {
		if (disabledSections.includes(sectionId)) {
			return;
		}
		handleRouteChange(sectionId);
		setIsSidebarOpen(false);
	};

	return (
		<Box sx={{ ...sx }}>
			<Box
				sx={{
					width: "100%",
					marginBottom: buttonHidden ? "" : "1.25rem",
				}}
			>
				{/* TODO: Add button functionality. Will be disabled until form is 100% complete */}
				<StyledButton variant="contained" onClick={onSubmit} disabled={buttonDisabled} hidden={buttonHidden}>
					{buttonText}
				</StyledButton>
			</Box>

			{isMobile && (
				<Button
					onClick={() => setIsSidebarOpen(!isSidebarOpen)}
					sx={{
						width: "100%",
						backgroundColor: "#FFF",
						border: "1px solid #0E6BA8",
						borderRadius: "1rem",
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						padding: "0.875rem",
						marginBottom: "1rem",
					}}
				>
					<Typography
						variant="body1"
						sx={{
							fontWeight: "700",
							fontFamily: "Effra, sans-serif",
							color: "#0D1120",
							textTransform: "none",
						}}
					>
						{t("principal.sidebar.heading")}
					</Typography>
					<KeyboardArrowDownIcon
						sx={{
							color: "#0D1120",
							transform: isSidebarOpen
								? "rotate(180deg)"
								: "rotate(0deg)",
							transition: "transform 0.3s ease",
						}}
					/>
				</Button>
			)}

			<Collapse in={!isMobile || isSidebarOpen}>
				<Box
					sx={{
						border: "1px solid #0E6BA8",
						borderRadius: "1rem",
						backgroundColor: "#FFF",
						overflow: "hidden",
					}}
				>
					<List sx={{ padding: "0" }}>

						{sections.map((section, index) => {
							const isActive = currentSection?.id === section.id;
							const isError = errorSections.includes(section.id);
							const isDisabled = disabledSections.includes(
								section.id,
							);

							return (
								<ListItem
									key={section.id}
									tabIndex={isDisabled ? -1 : 0}
									role="button"
									aria-label={`Go to ${section.label}`}
									aria-current={isActive ? "true" : undefined}
									aria-disabled={
										isDisabled ? "true" : undefined
									}
									onClick={() => handleClick(section.id)}
									onKeyDown={(e) => {
										if (
											e.key === "Enter" ||
											e.key === " "
										) {
											handleClick(section.id);
										}
									}}
									sx={{
										borderTop:
											index === 0
												? "none"
												: "1px solid #9ce0f8",
										backgroundColor: isError
											? "#fdeeee"
											: isActive
											? "#afe4f7"
											: "#FFF",
										borderLeft: isActive
											? isError
												? "5px solid #eb5757"
												: "5px solid #3c87b7"
											: "5px solid transparent",
										opacity: isDisabled ? 0.5 : 1,
										cursor: isDisabled
											? "default"
											: "pointer",
										display: "flex",
										alignItems: "center",
										gap: "1rem",
										padding: "0.5rem",
										"&:focus": isDisabled
											? {}
											: {
													backgroundColor: "#e0f7ff",
											  },
										"&:hover": isDisabled
											? {}
											: { backgroundColor: "#e3f4fe" },
									}}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											width: "100%",
										}}
									>
										<Box
											sx={{
												textDecoration: "none",
												color: "#0D1120",
												fontWeight: "400",
												fontSize: "1rem",
												display: "flex",
												alignItems: "center",
												width: "100%",
												justifyContent: "flex-start",
												gap: "1rem",
												cursor: isDisabled
													? "default"
													: "pointer",
												"&:hover": isDisabled
													? ""
													: {
															color: "#0e6ba8",
													  },
											}}
										>
											<Typography
												variant="body1"
												sx={{
													fontFamily:
														"Effra, sans-serif",
														fontWeight: "700",
												}}
											>
												{`${String(index + 1).padStart(2, '0')}`}
											</Typography>
											<Typography
												variant="body1"
												sx={{
													fontFamily: "Effra, sans-serif",
													whiteSpace: "nowrap",
													overflow: "hidden",
													textOverflow: "ellipsis",
													maxWidth: "100%",
												}}
											>
												{t(`db_group_pages.${section.translation_id}.shortText`)}
											</Typography>
										</Box>
										{/* TODO: Properly connected ProgressBar without hardcoded values */}
										{/* <ProgressBar percentage={70} /> */}
									</Box>
								</ListItem>
							);
						})}
					</List>
				</Box>
			</Collapse>
		</Box>
	);
};

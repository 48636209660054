import React, { useState } from 'react';
import DatePicker from '../../../components/DatePicker';
import { ToastMessage } from "./ToastMessage";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const Certificate = ({ heading, issueDate, expiryDate, certificateNumber, onUpdate, isPrincipal = false }) => {
  const { t } = useTranslation();

  const keyPrefix = isPrincipal ? "Principal " : "";

  const [certificate, setCertificate] = useState({
    [`${keyPrefix}License Issued`]: issueDate,
    [`${keyPrefix}License Expiry`]: expiryDate,
    [`${keyPrefix}Certificate Number`]: certificateNumber
  });

  const [isEditing, setIsEditing] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCertificate((prev) => ({ ...prev, [`${keyPrefix}${name}`]: value }));
  };

  const toggleEdit = () => setIsEditing(!isEditing);

  const saveCertificate = async () => {
    const updated = await onUpdate(certificate);
    if (updated) {
      toggleEdit();
      toast.success(
        <ToastMessage>{t("pd-plan.certificate.success-message")}</ToastMessage>
      );
    } else {
      toast.error(
        <ToastMessage>
          {t("pd-plan.certificate.error-message")}
        </ToastMessage>,
      );
    }
  };

  const handleDate = (field, value) => {
    const date = value ? new Date(value).toISOString().split('T')[0] : undefined;
    setCertificate(prev => ({...prev, [field]: date}));
  };

  return (
    <section
      className="p-4 w-max"
      style={{
        backgroundColor: "#0E6BA8",
        borderRadius: "15px",
        boxShadow: "10px 12px 24px 0px rgba(206, 222, 238, 0.47)",
      }}
    >
      <h3 className="text-white h5 font-weight-bold">{heading}</h3>
      {isEditing ? (
        <div>
          <div className="my-2">
            <label className="d-block text-white">{t("pd-plan.certificate.issue-date")}:</label>
            <DatePicker
              value={certificate[`${keyPrefix}License Issued`] ? new Date(certificate[`${keyPrefix}License Issued`]) : null}
              inputProps={{
                placeholder: t("pd-plan.certificate.date-placeholder-issue"),
              }}
              timeFormat={false}
              onChange={(e) => {
                if (typeof e !== 'string') handleDate(`${keyPrefix}License Issued`, e.toDate());
              }}
              clearDate={() => handleDate(`${keyPrefix}License Issued`, undefined)}
            />
          </div>
          <div className="my-2">
            <label className="d-block text-white">{t("pd-plan.certificate.expiry-date")}:</label>
            <DatePicker
              value={certificate[`${keyPrefix}License Expiry`] ? new Date(certificate[`${keyPrefix}License Expiry`]) : null}
              inputProps={{
                placeholder: t("pd-plan.certificate.date-placeholder-expiry"),
              }}
              timeFormat={false}
              onChange={(e) => {
                if (typeof e !== 'string') handleDate(`${keyPrefix}License Expiry`, e.toDate());
              }}
              clearDate={() => handleDate(`${keyPrefix}License Expiry`, undefined)}
            />
          </div>
          <div className="my-2">
            <label className="d-block text-white">{t("pd-plan.certificate.certificate-number")}:</label>
            <input
              type="text"
              name="Certificate Number"
              value={certificate[`${keyPrefix}Certificate Number`]}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
          <button
            className="btn mt-3"
            style={{ backgroundColor: "white", color: "#2B4F70" }}
            onClick={saveCertificate}
          >
            {t("pd-plan.certificate.save-button")}
          </button>
        </div>
      ) : (
        <div>
          <div className="visible-print-inline my-2">
            <h6 className="text-white font-weight-bold d-inline">
              {t("pd-plan.certificate.issue-date")}:&nbsp;
            </h6>
            <p className="d-inline text-white">{issueDate}</p>
          </div>
          <div className="visible-print-inline my-2">
            <h6 className="text-white font-weight-bold d-inline">
              {t("pd-plan.certificate.expiry-date")}:&nbsp;
            </h6>
            <p className="d-inline text-white">{expiryDate}</p>
          </div>
          <div className="visible-print-inline my-2">
            <h6 className="text-white font-weight-bold d-inline">
              {t("pd-plan.certificate.certificate-number")}:&nbsp;
            </h6>
            <p className="d-inline text-white">{certificateNumber}</p>
          </div>
          <button
            className="btn mt-3"
            style={{ backgroundColor: "white", color: "#2B4F70" }}
            onClick={toggleEdit}
          >
            {t("pd-plan.certificate.update-button")}
          </button>
        </div>
      )}
    </section>

  );
};

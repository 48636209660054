import supabase from "../../../database";
import { transformResponseValues } from "../util/transform-response-values";

export const createInitialValues = async (responseId, fields) => {
	try {
		const newValues = fields.map(field => {
			return{
			response_id: responseId,
			field_id: field.id,
			value: '',
			instance_number: 1,
			completed: false,
		}})
		const response = await supabase.formResponseValues.createMultiple(newValues);

		return response.map(transformResponseValues) || []
	} catch (error) {
		console.error("Error:", error);
		return [];
	}
};

import React, { useState } from "react";
import { Button, Col, Input, Modal, Row } from "reactstrap";
import Select from "react-select";
import { FILTER_SELECTBOX_STYLE } from "../../../utils/constants";
import DatePicker from "../../../components/DatePicker";
import { useTranslation } from "react-i18next";
import { FormControlLabel, Checkbox } from "@mui/material";

const FitlerConditionModal = ({
  initFilter,
  onToggle,
  onChange,
  schools
}) => {
  const [filterKeyword, setFilterKeyword] = useState(initFilter.filterKeyword)
  const [filterDate, setFilterDate] = useState(initFilter.filterDate);
  const [filterCILC, setFilterCILC] = useState(initFilter.filterCILC);
  const [filterSchool, setFilterSchool] = useState(initFilter.filterSchool);
  const { t } = useTranslation();

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={true}
      toggle={() => onToggle()}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">Filter</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col className="filter-col-in-modal">
            <Input
              type="text"
              className="form-style"
              placeholder={t("search-by-name")}
              value={filterKeyword}
              onChange={e => setFilterKeyword(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col className="filter-col-selector">
            <Select
              value={filterSchool}
              placeholder="Select School"
              styles={FILTER_SELECTBOX_STYLE}
              className="form-style"
              onChange={(selectedOption) => setFilterSchool(selectedOption)}
              options={[
                { label: "All Schools", value: "" },
                ...schools.map(sc => ({ value: sc.id, label: sc.School }))
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col className="filter-col-in-modal">
            <DatePicker
              value={filterDate}
              inputProps={{
                placeholder: "Session Date",
              }}
              timeFormat={false}
              onChange={(e) => {
                if (typeof e !== "string") setFilterDate(e.toDate());
              }}
              clearDate={() => setFilterDate(null)}
            />
          </Col>
        </Row>
        <Row>
          <Col className="filter-col-in-modal">
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterCILC ? true : false}
                  onChange={e => setFilterCILC(e.target.checked)}
                />
              }
              label="CILC"
            />
          </Col>
        </Row>
      </div>
      <div className="modal-footer" style={{ justifyContent: 'center', paddingTop: 0 }}>
        <Button
          color="primary"
          onClick={() => onChange({
            filterKeyword,
            filterDate,
            filterCILC,
            filterSchool
          })}
        >
          Apply Filter
        </Button>
      </div>
    </Modal>
  )
}

export default FitlerConditionModal;
export const IS_WISHALL = false;

export const GradeLevels = [
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  'Professional Development',
  'After School',
]

export const CurriculumGradeLevels = [
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12'
]

export const CurrentGradeLevels = [
  'JK',
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  'Administrator',
  'SSA',
  'SST',
  'Learning Coach'
]

export const SearchableGrades = [
  'JK',
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  'Professional Development'
]

export const WellBeingLinks = [
  'Arts & Recreation',
  'Culture, Language & Identity',
  'Food & Nutrition',
  'Hope For the Future',
  'Relationship with Staff & Authority',
  'Relationship with Students/Peers',
  'Parent & Family Involvement',
]

export const FBUSEREMAIL = 'connectednorth@user.com'
export const FBUSERPASSWORD = 'connectednorthuser'

export const BestPracticeLen = 5

export const BestPracticeLabels = {
  introduction: {
    name: 'Introduction',
    locale: 'best-practices-content.intro.title',
  },
  'meaningful-land-acknowledgement': {
    name: 'Meaningful Land Acknowledgement',
    locale: 'best-practices-content.mla.title',
  },
  'facilitation-of-student-interactivity': {
    name: 'Facilitation of Student Interactivity',
    locale: 'best-practices-content.fsi.title',
  },
  'indigenous-histories-contributions': {
    name: 'Indigenous Histories & Contributions',
    locale: 'best-practices-content.ihc.title',
  },
  'trauma-informed-approaches': {
    name: 'Trauma-informed Approaches',
    locale: 'best-practices-content.tfa.title',
  },
  'creatively-customized-content': {
    name: 'Creatively Customized Content',
    locale: 'best-practices-content.ccc.title',
  },
  'recording-practices': {
    name: 'Recording Practices',
    locale: 'best-practices-content.rp.title',
  },
}

export const BestPracticeReflectionFieldNames = {
  'meaningful-land-acknowledgement': 'ReflectionMLA',
  'facilitation-of-student-interactivity': 'ReflectionFSI',
  'indigenous-histories-contributions': 'ReflectionIHC',
  'trauma-informed-approaches': 'ReflectionTIA',
  'creatively-customized-content': 'ReflectionCCC',
}

export const culturalGroups = [
  'Algonquin',
  'Anishinaabe',
  'Cayuga',
  'Coast Salish',
  'Dakota',
  'Dene',
  'Haida',
  'Kaska',
  'Lakota',
  "Mi'kmaq",
  'Mohawk',
  'Nakoda',
  'North Tutchone',
  'Odawa',
  'Oji-Cree',
  'Ojibwe',
  'Oneida',
  'Onondaga',
  'Plains Cree',
  'Potawatomi',
  'Saulteaux',
  'Seneca',
  'Sioux',
  'South Tutchone',
  'Swampy Cree',
  'Tagish',
  'Tlingit',
  'Tuscarora',
  'Woodlands Cree',
]

export const sortlist = [
  {
    id: 0,
    label: 'Indigenous Providers First',
    label_fr: 'Fournisseurs autochtones en premier',
    icon: 'sort-alpha-down',
    sort: [
      { field: 'Indigenous Provider?', direction: 'desc' },
      { field: 'Average Rating', direction: 'desc' },
    ],
  },
  {
    id: 1,
    label: 'Alphabetical (a-z)',
    label_fr: 'Ordre alphabétique (a-z)',
    icon: 'sort-alpha-down',
    sort: [{ field: 'Session Title', direction: 'asc' }],
  },
  {
    id: 2,
    label: 'Alphabetical (z-a)',
    label_fr: 'Ordre alphabétique (z-a)',
    icon: 'sort-alpha-up',
    sort: [{ field: 'Session Title', direction: 'desc' }],
  },
  {
    id: 3,
    label: 'Least Credits (0-9)',
    label_fr: 'Crédits requis (0-9)',
    icon: 'sort-numeric-up',
    sort: [{ field: 'Credits', direction: 'asc' }],
  },
  {
    id: 4,
    label: 'Most Credits (9-0)',
    label_fr: 'Crédits requis (9-0)',
    icon: 'sort-numeric-down',
    sort: [{ field: 'Credits', direction: 'desc' }],
  },
]

export const cancellationReasons = [
  "Class Absence",
  "Class Behaviour",
  "Materials Delay",
  "Provider Illness / Absence",
  "Provider Reschedule",
  "School Closure - Emergency",
  "School Closure - Funeral",
  "School Closure - Holiday",
  "School Closure - Strike",
  "School Closure - Weather",
  "School Schedule Change",
  "Teacher Illness / Absence",
  "Teacher Reschedule",
  "Technical Issues (GN)",
  "Technical Issues (Provider)",
  "Technical Issues (School)",
  "Technical Issues (TIG)",
  "Other"
];

export const MAX_SUBJECT_NUM = 4;

export const DAY_LIMITATIONS = ["Mondays", "Tuesdays", "Wednesdays", "Thursday", "Fridays"];

export const FILTER_SELECTBOX_STYLE = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: '#9de0f8',
    height: 43,
    borderRadius: 10,
  }),
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: '#ffffff',
      color: '#0e6ba8',
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#0e6ba8',
        color: '#ffffff'
      }
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#0e6ba8'
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#ffffff'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    backgroundColor: '#0e6ba8',
    color: '#ffffff',
    ':hover': {
      backgroundColor: '#084269'
    },
  }),
  placeholder: (styles) => ({
    ...styles,
    color: '#adb5bd',
  }),
  menuList: (styles) => ({
    ...styles,
    zIndex: 999,
  }),
}

export const SELECTBOX_STYLE = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: '#9de0f8'
  }),
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: '#ffffff',
      color: '#0e6ba8',
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#0e6ba8',
        color: '#ffffff'
      }
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: '#0e6ba8'
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#ffffff'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    backgroundColor: '#0e6ba8',
    color: '#ffffff',
    ':hover': {
      backgroundColor: '#084269'
    },
  }),
}

export const SEARCH_SORT_SELECTBOX_STYLE = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    borderColor: '#0e6ba8',
    borderRadius: 16,
    fontSize: 16,
    paddingLeft: 12,
    paddingTop: 4,
    paddingBottom: 4
  }),
  option: (styles) => {
    return {
      ...styles,
      backgroundColor: '#ffffff',
      color: 'black',
      fontSize: 16,
      ':hover': {
        ...styles[':hover'],
        backgroundColor: '#0e6ba8',
        color: '#ffffff'
      }
    };
  },
}

export const DAY_LIMITATIONS_MAP = {
  "Mondays": 1,
  "Tuesdays": 2,
  "Wednesdays": 3,
  "Thursday": 4,
  "Fridays": 5
}

export const getDayRestrictionConfig = (supply) => {
  if (!supply) return 0;

  // Default Quantity Remaining to 0 if undefined or empty
  const quantityRemaining = supply["Quantity Remaining"] || 0;

  if (supply["Essential?"] === "Yes") {
    if (
      (supply.Status === "Sioux Lookout Storage" ||
        supply.Status === "Toronto Office Storage")
    ) {
      // Apply 30-day restriction if conditions are met
      if (
        (supply["Supplies Type"] === "Kit per Class" && quantityRemaining !== 0) ||
        ((!supply["Supplies Type"] || supply["Supplies Type"] === "Kit per Student") && quantityRemaining >= 15)
      ) {
        return 60;
      }
      // Otherwise, apply a 90-day restriction
      return 60;
    }
    // If it doesn't match any storage location, apply a default 30-day restriction
    return 60;
  }

  return 0;
};

export const DEFAULT_SESSION_FOR_GREET_SECTION = "recrMwvSKZ6Z6N5sU";
export const DEFAULT_SESSION_FOR_REVIEW_SECTION = "recqiRA1e0oD5f6ax";

export const FieldsOfStudy = [
//  { id: "recf7e0RNLIv80IWZ", en: "Accounting", fr: "Comptabilité" },
  { id: "rec2NzZT5dlSEYLdN", en: "Agriculture", fr: "Agriculture" },
  { id: "rechfqDX2G3lQZfzR", en: "Animal Sciences", fr: "Sciences animales" },
  { id: "recuVO6w49mhufKlG", en: "Arts", fr: "Arts" },
  { id: "recDFIjew2L2bOKu3", en: "Astronomy", fr: "Astronomie" },
  { id: "rec4s68jKJKb62NYD", en: "Athletics", fr: "Athlétisme" },
  { id: "receTXl8RavWVgK2E", en: "Aviation and Aerospace", fr: "Aviation et aérospatiale" },
  { id: "recABCr8R9LWlkEBd", en: "Biology", fr: "Biologie" },
  { id: "recZaUsPdrlcvexm5", en: "Business and Administration", fr: "Affaires et administration" },
  { id: "recJrcpRtjjvRy3zx", en: "Chemistry", fr: "Chimie" },
  { id: "recM4JCr0relJr4fh", en: "Childcare", fr: "Garde d'enfants" },
  { id: "reci1JwAd53rxfSM6", en: "Communications and Media", fr: "Communications et médias" },
  { id: "recrJVRhrqf5EQ2GN", en: "Community Development", fr: "Développement communautaire" },
  { id: "recFFk3S8cqk5FDYE", en: "Computer Sciences", fr: "Sciences informatiques" },
  { id: "rec6QNS9RyBvemsqJ", en: "Construction & Skilled Trades", fr: "Construction et métiers spécialisés" },
  { id: "recnojuconar5Q3KO", en: "Criminology", fr: "Criminologie" },
  { id: "recsIAbHQf2eKVn0q", en: "Dentistry", fr: "Dentisterie" },
  { id: "rec40zOYPUJUt8oz8", en: "Earth Sciences and Natural Resources", fr: "Sciences de la Terre et ressources naturelles" },
  { id: "reczJlhtzYPqozFcH", en: "Economics and Statistics", fr: "Économie et statistiques" },
  { id: "recFvSwKMm69exRva", en: "Education", fr: "Éducation" },
  { id: "recCO8e4jvKXxNvyq", en: "Engineering and Architecture", fr: "Ingénierie et architecture" },
  { id: "reckkfW1ZaoD6vkQz", en: "Environmental Studies", fr: "Études environnementales" },
  { id: "recJbR1QVwNq3mNQR", en: "Finance", fr: "Finance" },
  { id: "recyPKscxGqun9yKG", en: "Geology", fr: "Géologie" },
//  { id: "recsG0vGdTf3FyU5m", en: "Graduate and Postdoctoral Studies", fr: "Études supérieures et postdoctorales" },
  { id: "recm81IvQ5xG3zWvQ", en: "Health Careers and Services", fr: "Carrières et services de santé" },
  { id: "rec6pXjGo9JvhKDvE", en: "History", fr: "Histoire" },
  { id: "recOPH5SSnvmdiEXc", en: "Hotel Industry and Tourism", fr: "Industrie hôtelière et tourisme" },
 // { id: "reczJi2QrrjJFPBD5", en: "Human Resources", fr: "Ressources humaines" },
  { id: "recLM5Xmmc7NmuXTF", en: "Human Sciences", fr: "Sciences humaines" },
  { id: "recvYG1js2b6mLPOF", en: "Indigenous History", fr: "Histoire autochtone" },
  { id: "recQStmOszdefags7", en: "Indigenous Studies", fr: "Études autochtones" },
  { id: "recXIIQNbEpvv5rAB", en: "Information Technology", fr: "Technologie de l'information" },
  { id: "recM6o8OP3TQiS5Bl", en: "Journalism", fr: "Journalisme" },
  { id: "recQNvMss7leHpimz", en: "Kinesiology", fr: "Kinésiologie" },
  { id: "recZYiS1rg6R7spG6", en: "Languages", fr: "Langues" },
  { id: "recGUaVZuipV2N4e7", en: "Law", fr: "Droit" },
//  { id: "rec4WlUvtvBdVH7FL", en: "Library and Archival Studies", fr: "Bibliothéconomie et archivistique" },
  { id: "recyQoVtSTKOyDnum", en: "Management", fr: "Gestion" },
  { id: "recwNQVXkE80IkKoT", en: "Marketing", fr: "Marketing" },
  { id: "recN5kChEB2HP3Llt", en: "Mathematics", fr: "Mathématiques" },
  { id: "rec2Y1cEo9dgVU5xI", en: "Medicine", fr: "Médecine" },
  { id: "recjPY7aq1Kbt7rYS", en: "Music", fr: "Musique" },
  { id: "reclsZxnV4fxEy8yk", en: "Natural Science", fr: "Sciences naturelles" },
  { id: "recTUMRGBJnalPEZM", en: "Northern Studies", fr: "Études nordiques" },
  { id: "recrLKqUgpJX8EfCP", en: "Nursing", fr: "Sciences infirmières" },
  { id: "recmWGwfOB0zx7D3Z", en: "Nutrition", fr: "Nutrition" },
//  { id: "recc2ZLb0ClGiTXT5", en: "Pharmacy", fr: "Pharmacie" },
//  { id: "recmLl3zp1Yz8oFPV", en: "Philosophy", fr: "Philosophie" },
  { id: "recc7tpc4fJa1nj3k", en: "Physics", fr: "Physique" },
//  { id: "recR4GsRCxNADR58P", en: "Police and Security", fr: "Police et sécurité" },
  { id: "recj9ZU1O9269ASYP", en: "Political Science", fr: "Sciences politiques" },
  { id: "recPM0YzvMtKxEqMJ", en: "Psychology", fr: "Psychologie" },
  { id: "recCMgEqZ8m7WaTxV", en: "Public Policy", fr: "Politique publique" },
  { id: "recMo2IxB0p8XqRaZ", en: "Recreation and Leisure", fr: "Loisirs et récréation" },
  { id: "rect1UYU49IVoPUes", en: "Sciences", fr: "Sciences" },
  { id: "recaAdjqxlhCzjRwS", en: "Social Sciences", fr: "Sciences sociales" },
  { id: "recN55qyBbiW0rQHs", en: "Social Services", fr: "Services sociaux" },
  { id: "rec8npWVOptDtaOb8", en: "Theology", fr: "Théologie" },
//  { id: "recgfGtFYu8hnVSdP", en: "Transportation", fr: "Transport" },
  { id: "recnAOwoae7WKFBOk", en: "Women's Studies", fr: "Études féminines" }
];

export const WEEK_NAMES = {
  "Monday": 0,
  "Tuesday": 1,
  "Wednesday": 2,
  "Thursday": 3,
  "Friday": 4
}

export const PASSKEY_COUNT = 8;
import supabase from "../../../database";
import { transformResponseValues } from "../util/transform-response-values";

export const fetchFormResponseValues = async (responseId) => {
	try {
		const response = await supabase.formResponseValues.listByResponse(
			responseId,
		);

		if (!response) return [];
		return response.map(transformResponseValues) || [];
	} catch (e) {
		console.log("Error:", e);
		return [];
	}
};

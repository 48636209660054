import AirtableTable from "../../AirtableTable";

export class PDLogTable extends AirtableTable {
	constructor(instance) {
		super(instance, "PD Log");

		this.fields = {
			ID: "fld52jzw6qBsSX0p4",
			Teacher: "fld5NBG6z0ElwSSbF",
			"Activity Name": "fldP8Yq8Oz3rwUXic",
			Description: "fldIzzlpkK8cu761c",
			"Start Date": "fldrirNBjYVaIrcLh",
			"End Date": "fldWr1lH2Mnjj2wUV",
			Hours: "fldERHn3blz6unp8k",
			"Added by": "fldTMDFtebgvmUl7I",
			Approved: "fldAeXxq88gd5APvo",
			"Approved by": "fldzCTNu5iFhOUIKw",
			"Related PD Plan Goal": "fldyt7zNzTTHGyYYo",
			"SessionID": "fldBE3ysSj11ORe8E",
		};
	}

	getLogsByTeacherId = async (teacherId) => {
		const condition = {
			filterByFormula: `{Teacher} = "${teacherId}"`,
			sort: [{ field: this.fields["Start Date"], direction: "desc" }]
		};

		return this.list(condition);
	}
}

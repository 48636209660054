export const getAllFieldsFromLayout = (form) => {
	const { pages = [] } = form || {};
	const fields = pages.reduce((acc, page) => {
		const { groups = [] } = page || {};
		const groupFields = groups.reduce((acc, group) => {
			const { fields = [] } = group || {};
			return acc.concat(fields);
		}, []);
		return acc.concat(groupFields);
	}, []);
	return fields;
};

import supabase from "../../../database";

export const uploadFile = async (file, fieldId) => {
  try {
    const filePath = `${fieldId}/${file.name}`;

		const response = await supabase.formResponseValues.uploadFile(filePath, file);

    if(response) {
      return response;
    }
		return null;
	} catch (e) {
		console.log("Error:", e);
		return null;
	}
};
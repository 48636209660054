import { Box, Typography } from "@mui/material";
import React from "react";

export const Group = (props) => {
	const {
		heading,
		buttons,
		children
	} = props;
	
	return (
		<Box
			sx={{
				borderBottom: "2px solid #9ce0f8",
				paddingBottom: { xs: "1.25rem", md: "1.875rem" },
				margin: { xs: "1.25rem 0", md: "1.875rem 0" },
			}}
			aria-labelledby={
				heading ? `${heading}-group` : "NonRepeatable group section"
			}
		>
			{heading && (
				<Typography
					variant="h3"
					component="h3"
					id={heading}
					sx={{
						fontFamily: "Effra, sans-serif",
						fontSize: "1.5625rem",
						fontWeight: "700",
						color: "#0D1120",
						marginBottom: { xs: "1.25rem", md: "1.875rem" },
					}}
				>
					{heading}
				</Typography>
			)}
			<Box>
				{children}
			</Box>
			{buttons}
		</Box>
	);
};



export default class SupabaseTable {
  constructor(tableName, supabaseClient) {
    this.tableName = tableName;
    this.supabase = supabaseClient;
  }

  create = async (createData) => {
    try {
      const { data, error }  = await this.supabase
        .from(this.tableName)
        .insert({...createData})
        .select();
      if (error) {
        throw new Error(error.message);
      }
      return data;
    } catch (error) {
      throw error;
    }
  }

  createMultiple = async (createData) => {
    try {
      const { data, error }  = await this.supabase
        .from(this.tableName)
        .insert(createData)
        .select();
      if (error) {
        throw new Error(error.message);
      }
      return data;
    } catch (error) {
      throw error;
    }
  }
  delete = async (column, value) => {
    try {
      const { error } = await this.supabase
        .from(this.tableName)
        .delete()
        .eq(column, value);
      if (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      throw error;
    }
  }
  listAll = async () => {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select('*');
      if (error) {
        throw new Error(error.message);
      }
      return data;
    } catch (error) {
      throw error;
    }
  }

  select = async (id) => {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select('*')
        .eq('id', id)
        .single();
      if (error) {
        throw new Error(error.message);
      }
      return data;
    } catch (error) {
      throw error;
    }
  }

  update = async (id, updateData) => {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .update(updateData)
        .eq('id', id)
        .select();
      if (error) {
        throw new Error(error.message);
      }
      return data;
    } catch (error) {
      throw error;
    }
  }
}

import SupabaseTable from "../SupabaseTable";

export class FieldGroupsTable extends SupabaseTable {

  listByPage = async (pageId) => {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select("*")
        .eq("page_id", pageId);

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (error) {
      throw error;
    }
  };

  listByPages = async (pageIds) => {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select("*")
        .in("page_id", pageIds);

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (error) {
      throw error;
    }
  };
}
import React, {  useState } from 'react';
import { MuiFileInput } from 'mui-file-input';
import { Typography, Button, Box, FormHelperText, FormControl } from '@mui/material';
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { styled } from '@mui/material/styles';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const VisuallyHiddenInput = styled(MuiFileInput)({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const FileInput = ({
  id,
  value = [],
  buttonText = "Upload File",
  acceptedFileTypes,
  max_file_size = 10485760,
  onFileSelect = () => { },
  onFileRemove = async () => {},
  onFileUpload = async () => {},
  onChange = () => { },
  error = false,
  disabled,
  sx = {},
  heading,
  description,
  helperText, 
}) => {
  const [files, setFiles] = useState(value);

  const [inputError, setInputError] = useState(error);
  const [errorMessages, setErrorMessages] = useState(helperText);
  const fileLargeError = "Upload is over {x} size, please compress and try again.";
  
  const handleSelect = async (newFiles) => { 
    newFiles.forEach( async (newFile) => { 
      if (newFile.size > max_file_size) {
        setInputError(true);
        setErrorMessages(`${fileLargeError} ${helperText}`)
      } else { 
        setInputError(false);
        onFileSelect(newFile);

        const uploadResult = await onFileUpload(newFile);

        if (uploadResult?.error) {
          console.log('uploadResult.error: ', uploadResult.error);
          setInputError(true);
          setErrorMessages(uploadResult.error.message);
        } else {
          const changedFiles = [newFile, ...files]
          setFiles(changedFiles);
          onChange(changedFiles);
        }
        
      }
    })
  }

  const handleRemove = async (removedFile) => {
    const fileredFiles = files.filter((file) => file.name !== removedFile.name);
    const removeResult = await onFileRemove(removedFile);

    if (removeResult?.error) {
      console.log('removeResult.error: ', removeResult.error);
      setInputError(true);
      setErrorMessages(removeResult.error.message);
    } else {
      const changedFiles = [...fileredFiles]
      setFiles(changedFiles);
      onChange(changedFiles);
      setInputError(false);
    }
    
  }
  
  return (

      <FormControl
        sx={{
        marginBottom: '1.88rem',
        alignItems: "flex-start",
        ...sx,
      }}>
  
        <Box mb="0.5rem">
            <Typography
              variant="h4"
              component="label"
              htmlFor={id}
              fontFamily="Effra, sans-serif"
              fontSize="1.125rem"
              fontWeight="700"
              color="#000">
                {heading}
            </Typography>
        </Box>

      <Typography
        variant="body1"
        color="#000"
        component="p"
        marginBottom="0.625rem">
          {description}
      </Typography>
      <Button
        component="label"
        disabled={disabled}
        variant="contained"
        tabIndex={-1}
        startIcon={<FileUploadIcon />}
        sx={{
          display: 'inline-flex',
          minWidth: '0',
          textTransform: 'none',
          paddingY: '0.625rem',
          borderRadius: '0.875rem',
          backgroundColor: inputError ? "#EB5757" : "#0E6BA8",
        }}
      >
        <Typography variant="body" component="span">{buttonText}</Typography>
        <VisuallyHiddenInput
          multiple
          id={id}
          disabled={disabled}
          error={inputError}
          inputProps={{ accept: acceptedFileTypes }}
          value={files || []}
          onChange={handleSelect} />
      </Button>

      {files.length > 0 && (
        <Box
          component="ul"
          sx={{
            listStyle: "none",
            paddingTop: "0.625rem",
          }}>
          {files.map((file) => {
            const { name } = file;
            if (!name) {
              console.error('Invalid file object:', file);
              return null;
            }
            return (
              <Box
                key={name.split(' ').join('-')}
                component="li"
                sx={{
                  display: 'flex',
                  gap: '0.625rem'
                }}>
                <IconButton onClick={() => handleRemove(file)}>
                  <ClearIcon />
                </IconButton>
                <Typography
                  variant="body1"
                  color="#000"
                  marginTop="0.625rem"
                  marginBottom="0.625rem">
                  {name}
                </Typography>
              </Box>
            )
          })}
        </Box>
      )}
      
      {inputError && (
        <FormHelperText
          id="helper-text"
          sx={{
            margin: '0',
            paddingTop: '0.625rem',
          }}
        >
          <Typography variant="body2" color="#CE2222" component="span">
            {errorMessages}
          </Typography>
        </FormHelperText>
      )}
    </FormControl>
  )
};

export default FileInput;
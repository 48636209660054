import React, { useState } from "react";
import { Button, FormGroup, Input, Label, Modal } from "reactstrap";
import Select from "react-select";
import { GradeLevels } from "../../../utils/constants";
import classNames from "classnames";
import Loading from "../../../components/Loading";

const AdditionalQuestionModal = ({
  event,
  onToggle,
  onFinish
}) => {
  const [processing, setProcessing] = useState(false);
  const [students, setStudents] = useState(0);
  const [grades, setGrades] = useState([]);
  const [errors, setErrors] = useState({});

  const registerEvent = async () => {
    let mErrors = {}
    if (!students || students === 0) {
      mErrors.students = "Please share an estimated number of students attending.";
    }

    if (!grades || grades.length === 0) {
      mErrors.grades = "Please select the grade(s) of students that will be attending.";
    }

    if (Object.keys(mErrors).length > 0) {
      setErrors(mErrors);
      return;
    } else {
      setErrors({});
    }

    setProcessing(true);
    await onFinish(parseInt(students), grades.map(g => g.value));
    setProcessing(false);
    onToggle();
  }

  return (
    <Modal
      isOpen={true}
      className="modal-dialog-centered"
      toggle={onToggle}
    >
      <div className="modal-header">
        <h5 className="modal-title" id="modal-title-default">Additional Information</h5>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <FormGroup>
          <Label for="students">Number of Students Attending</Label>
          <Input
            type="number"
            id="students"
            onChange={e => setStudents(e.target.value)}
            invalid={errors.students ? true : false}
          />
          <span className="invalid-feedback">{errors.students}</span>
        </FormGroup>

        <FormGroup>
          <Label>Which grade(s) will be attending?</Label>
          <div className={classNames({
            "is-invalid": errors.grades ? true : false
          })}>
            <Select
              className="form-style"
              isMulti
              value={grades}
              onChange={(selectedOption) => setGrades(selectedOption)}
              options={GradeLevels.map(v => ({ value: v, label: v }))}
            />
          </div>
          <div className="invalid-feedback">{errors.grades}</div>
        </FormGroup>
      </div>
      <div className="modal-footer">
        <Button
          className="ml-auto"
          color="default"
          data-dismiss="modal"
          type="button"
          onClick={() => onToggle()}
        >Cancel</Button>
        <Button
          color="primary"
          type="button"
          onClick={() => registerEvent()}
        >{processing && <Loading size={20} />}{` `}Register</Button>
      </div>
    </Modal>
  )
}

export default AdditionalQuestionModal;

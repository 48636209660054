import React, { useEffect, useState } from 'react';
import airtable from '../../airtables';
import LoadingBar from '../../components/LoadingBar';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SessionInfo from '../../components/SessionInfo';
import { getDisplayInfosPast, getActionPastMenus } from '../SessionInfoDisplay';
import { Pagination } from '../../components/Pagination';
import RateSessionModal from '../modals/Rate';
import RequestPaymentModal from '../modals/RequestPayment';
import SessionResultModal from '../modals/SessionResult';
import CancelSessionModal from '../modals/CancelSession';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { delayTime } from '../../utils/time';
import { getProviderSessionIDFromSession } from '../../utils/session';
import DuplicateSessionModal from '../modals/DuplicateSession';

const SessionListComponent = ({ type, params }) => {
  const { userInfo, userType } = useSelector(state => state.appInfo);

  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [pageNo, setPageNo] = useState(0);

  const [showRateSessionModal, setShowRateSessionModal] = useState(false);
  const [activeRateSession, setActiveRateSession] = useState(null);

  const [showRequestPaymentModal, setShowRequestPaymentModal] = useState(false);
  const [activeRequestPaymentSession, setActiveRequestPaymentSession] = useState(null);

  const [showSessionResultModal, setShowSessionResultModal] = useState(false);
  const [activeSRSession, setActiveSRSession] = useState(null);

  const [activeCancelSession, setActiveCancelSession] = useState(null);
  const [showSessionCancelModal, setShowSessionCancelModal] = useState(false);

  //duplicate session
  const [activeDuplicateSession, setActiveDuplicateSession] = useState(null);
  const [showDuplicateSessionModal, setShowDuplicateSessionModal] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    airtable.sessions.getPastSessions(params, (ps) => {
      setLoading(false)
      setPages(ps);
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pages || !pages.length) return;
    if (sessions && sessions.length) return;
    if (pageNo < 0) return;

    if (pages.length && sessions.length === 0 && pages[pageNo]) {
      setSessions(pages[pageNo].items)
    }
  }, [pages]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!pages || pages.length === 0 || pageNo < 0) setSessions([])
    else {
      const pageData = pages[pageNo];
      setSessions([...pageData.items])
    }
  }, [pageNo]) // eslint-disable-line react-hooks/exhaustive-deps

  const getPlaceholderString = () => {
    if (userType === 'Team') {
      switch (type) {
        case 'booked':
          return t("no-booked-sessions");
        case 'unrated':
          return t("no-completed-sessions");
        case 'unpaid':
          return t("no-unpaid-sessions");
        case 'completed':
          return t("no-finish-sessions");
        default:
          return '';
      }
    } else {
      return t("no-sessions-for-non-team");
    }
  }

  const onRateSession = (e, session) => {
    e.preventDefault();
    setShowRateSessionModal(true);
    setActiveRateSession(session);
  }

  const onRequestPayment = (e, session) => {
    e.preventDefault();
    setShowRequestPaymentModal(true);
    setActiveRequestPaymentSession(session);
  }

  const openSessionResult = (e, session) => {
    e.preventDefault();
    setShowSessionResultModal(true);
    setActiveSRSession(session);
  }

  const startCancelSession = (e, session) => {
    e.preventDefault();
    setActiveCancelSession(session);
    setShowSessionCancelModal(true);
  }

  const removeSessionFromCurrentPage = (session) => {
    const sessList = sessions.filter(sess => sess.id === session.id);
    setSessions(sessList)
    const newPages = [...pages];
    newPages[pageNo].items = sessList;
    setPages(newPages);
  }

  const postRating = () => {
    removeSessionFromCurrentPage(activeRateSession)
    setShowRateSessionModal(false)
    setActiveRateSession(null);
  }

  const postRequestPayment = () => {
    setShowRequestPaymentModal(false)
  }

  const onCancelSession = async (reason, feedback) => {
    try {
      let notes = activeCancelSession?.Notes || '';
      if (notes) notes += `\n`;
      notes += feedback || '';

      await airtable.sessions.update(activeCancelSession.id, {
        "Cause of Cancellation/Reschedule": reason,
        "Notes": notes
      });

      removeSessionFromCurrentPage(activeCancelSession)

      await delayTime(4000);
      toast.success("Cancellation of session was done!")
      setActiveCancelSession(null);
      setShowSessionCancelModal(false);
    } catch (error) {
      throw error;
    }
  }

  const onGenerateInvoice = (e, session) => {
    e.preventDefault();
    Axios.post('https://hooks.zapier.com/hooks/catch/89715/od2te8d/', {
      sessionid: [session.id]
    }, {
      headers: {
        'Content-Type': 'text/plain'
      }
    }).then(res => {
      toast.success(t("successfully-posted"));
    }).catch(error => {
      toast.error(error.toString());
    })
  }

  const onChangeSession = (e, session, status) => {
    e.preventDefault();
    airtable.sessions.update(session.id, {
      'Status': status
    }).then(res => {
      removeSessionFromCurrentPage(session)
    }).catch(error => {
      toast.error(error.toString());
    })
  }

  const duplicateSession = (e, session) => {
    setActiveDuplicateSession(session);
    setShowDuplicateSessionModal(true);
  }

  if (loading) return <LoadingBar />

  return (
    <div className="general-container">
      {sessions.length > 0 ? sessions.map((session, index) => (
        <SessionInfo
          key={index}
          session={session}
          displayInfos={getDisplayInfosPast(session, userType)}
          actionMenu={getActionPastMenus(session, userType)}
          openSessionResult={openSessionResult}
          changeStatus={onChangeSession}
          rateSession={onRateSession}
          requestPayment={onRequestPayment}
          generateInvoice={onGenerateInvoice}
          cancelSession={startCancelSession}
          sessionDetailLink={getProviderSessionIDFromSession(session)}
          duplicateSession={duplicateSession}
        />
      )) : (
        <div className="nolist-placeholder" align="center">
          <span>{getPlaceholderString()}</span>
        </div>
      )}

      <div className="paginate pb-4">
        {pages.length > 1 && (
          <Pagination
            page={pageNo}
            setPage={setPageNo}
            pageCount={pages.length}
          />
        )}
      </div>

      {
        (showRateSessionModal && activeRateSession) ? (
          <RateSessionModal
            showModal={showRateSessionModal}
            session={activeRateSession}
            userType={userType}
            onDone={() => postRating()}
            onToggle={() => setShowRateSessionModal(!showRateSessionModal)}
          />
        ) : null
      }

      {
        (showRequestPaymentModal && activeRequestPaymentSession) ? (
          <RequestPaymentModal
            showModal={showRequestPaymentModal}
            session={activeRequestPaymentSession}
            userInfo={userInfo}
            onDone={() => postRequestPayment()}
            onToggle={() => setShowRequestPaymentModal(!showRequestPaymentModal)}
          />
        ) : null
      }

      {
        (showSessionResultModal && activeSRSession) ? (
          <SessionResultModal
            showModal={showSessionResultModal}
            session={activeSRSession}
            userType={userType}
            onToggle={() => setShowSessionResultModal(!showSessionResultModal)}
          />
        ) : null
      }

      {
        (showSessionCancelModal && activeCancelSession) ? (
          <CancelSessionModal
            session={activeCancelSession}
            onToggle={() => setShowSessionCancelModal(!showSessionCancelModal)}
            onDone={(reason, feedback) => onCancelSession(reason, feedback)}
          />
        ) : null
      }

      {(showDuplicateSessionModal && !!activeDuplicateSession) && (
        <DuplicateSessionModal
          session={activeDuplicateSession}
          onToggle={() => {
            setActiveDuplicateSession(null);
            setShowDuplicateSessionModal(false)
          }}
        />
      )}
    </div>
  )
}

export default SessionListComponent;
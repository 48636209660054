import React from 'react';
import ReactDatetime from 'react-datetime';
import "./styles.scss";

const DatePicker = (props) => {
  const renderInput = (inputProps) => {
    return (
      <div className="datepicker-container">
        <input {...inputProps} />
        {inputProps.value ? (
          <div className="suffix">
            <i className="fa fa-close" style={{fontFamily: "FontAwesome"}} onClick={() => {
              inputProps.onChange({ target: { value: "" } });
              props.clearDate();
            }} />
          </div>
        ) : null}
      </div>
    );
  }

  return <ReactDatetime renderInput={renderInput} {...props} />;
}

export default DatePicker;

import { useState } from "react";
import { Box } from "@mui/material";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useTranslation } from 'react-i18next';
import {
	mapFieldsFromGoal,
	mapGoalFromFields,
	requiredFields,
} from "../util/handle-goal-forms";
import { MEASURABLE_VALUES } from "../constants/pd-fields";
import { Accordion } from "./Accordion";
import { ToastMessage } from "./ToastMessage";
import { GoalSelectField, GoalTextField } from "./GoalFields";
import { format, isValid, parse } from 'date-fns';

export const PlanGoal = ({
	readOnly = false,
	onUpdate,
	onDelete,
	onToggleExpand,
	expanded = false,
	goal = null,
	userInfo,
	reviewTeacherId,
	standardOptions,
	...props
}) => {
	const [goalFields, setGoalFields] = useState(mapFieldsFromGoal(goal));
	const [fieldErrors, setFieldErrors] = useState([]);
	const { t } = useTranslation();

	const onUpdateGoalField = (e) => {
		if (readOnly) return;
		const { name, value } = e.target;
		setGoalFields({ ...goalFields, [name]: value });
	};

	const onSubmitGoal = async (e) => {
		e.preventDefault();
		if (readOnly) return;

		const errors = requiredFields.filter((name) => {
			const testValue =
				typeof goalFields[name] === "string"
					? goalFields[name].trim()
					: goalFields[name];
			return !testValue || testValue.length === 0;
		});

		setFieldErrors(errors);
		if (errors.length > 0) return;

		const updated = await onUpdate(goal.id, mapGoalFromFields(goalFields));
		if (updated) {
			toast.success(
				<ToastMessage>{t("pd-plan.alerts.success.update-goal")}</ToastMessage>,
			);
		} else {
			toast.error(
				<ToastMessage>
					{t("pd-plan.alerts.error.update-goal")}
				</ToastMessage>,
			);
		}
	};

	const formatDate = (dateString) => {
		if (!dateString || dateString.trim() === '') return t("pd-plan.not-set");
		const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());
		if (!isValid(parsedDate)) return t("pd-plan.not-set");
		return format(parsedDate, 'MMMM d, yyyy');
	};

	return (
		<Accordion
			title={goalFields.specific}
			onChange={onToggleExpand}
			expanded={expanded}
			description={`${t("pd-plan.timeframe")}: ${formatDate(goalFields.timePhased)}`}
			{...props}
		>
			<Box onSubmit={onSubmitGoal} component="form">
				<Box>
					<GoalTextField
						name="specific"
						onChange={onUpdateGoalField}
						value={goalFields.specific}
						error={fieldErrors.includes("specific")}
						readOnly={readOnly}
						label={t("pd-plan.labels.specific")}
						description={t("pd-plan.descriptions.specific")}
					/>
					<GoalSelectField
						name="standard"
						onChange={onUpdateGoalField}
						value={goalFields.standard}
						error={fieldErrors.includes("standard")}
						readOnly={readOnly}
						options={standardOptions}
						label={t("pd-plan.labels.standard")}
						description={t("pd-plan.descriptions.standard")}
					/>
					<GoalTextField
						name="timePhased"
						onChange={onUpdateGoalField}
						value={goalFields.timePhased}
						error={fieldErrors.includes("timePhased")}
						readOnly={readOnly}
						label={t("pd-plan.labels.timePhased")}
						description={t("pd-plan.descriptions.timePhased")}
						type="date"
					/>
					<GoalSelectField
						name="measurable"
						onChange={onUpdateGoalField}
						value={goalFields.measurable}
						error={fieldErrors.includes("measurable")}
						readOnly={readOnly}
						options={MEASURABLE_VALUES}
						label={t("pd-plan.labels.measurable")}
						description={t("pd-plan.descriptions.measurable")}
						multiple
					/>
					<GoalTextField
						name="achievable"
						onChange={onUpdateGoalField}
						value={goalFields.achievable}
						error={fieldErrors.includes("achievable")}
						readOnly={readOnly}
						label={t("pd-plan.labels.achievable")}
						description={t("pd-plan.descriptions.achievable")}
						rows="5"
						multiline
					/>
					<GoalTextField
						name="relevant"
						onChange={onUpdateGoalField}
						value={goalFields.relevant}
						error={fieldErrors.includes("relevant")}
						readOnly={readOnly}
						label={t("pd-plan.labels.relevant")}
						description={t("pd-plan.descriptions.relevant")}
						rows="5"
						multiline
					/>
					<GoalTextField
						name="schoolSupports"
						onChange={onUpdateGoalField}
						value={goalFields.schoolSupports}
						error={fieldErrors.includes("timePhased")}
						readOnly={readOnly}
						label={t("pd-plan.labels.supports")}
						description={t("pd-plan.descriptions.supports")}
						rows="5"
						multiline
					/>
				</Box>
				{!readOnly && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							mt: "3rem",
						}}
					>
						<Button
							outline
							color="primary"
							onClick={() => onDelete(goal.id)}
							style={{
								paddingLeft: "1rem",
								display: "flex",
								alignItems: "center",
								gap: "0.5rem",
							}}
						>
							<DeleteForeverIcon /> {t("delete")}
						</Button>
						<Button color="primary" type="submit">
							{t("save")}
						</Button>
					</Box>
				)}
			</Box>
		</Accordion>
	);
};

import * as React from "react";
import { styled } from "@mui/system";
import { Unstable_NumberInput as BaseNumberInput } from "@mui/base/Unstable_NumberInput";

export const NumberMuiInput = React.forwardRef(
	function CustomNumberInput(props, ref) {
		const { unit, filledInput } = props;

		return (
			<BaseNumberInput
				className={`${filledInput ? "filledInput" : ""}`}
				slots={{
					root: StyledInputRoot,
					input: StyledInputElement,
				}}
				endAdornment={
					unit !== "$" ? (
						<InputAdornmentEnd>{unit}</InputAdornmentEnd>
					) : (
						<InputAdornmentEnd aria-hidden={true} />
					)
				}
				startAdornment={
					unit === "$" ? (
						<InputAdornmentStart>{unit}</InputAdornmentStart>
					) : (
						<InputAdornmentEnd aria-hidden={true} />
					)
				}
				{...props}
				ref={ref}
			/>
		);
	},
);

const InputAdornmentEnd = styled("span")({
	margin: "0.5rem",
	display: "inline-flex",
	alignItems: "center",
	justifyContent: "center",
	position: "absolute",
	top: "0",
	bottom: "0",
	right: "0",
});

const InputAdornmentStart = styled("span")({
	margin: "0.5rem",
	display: "inline-flex",
	alignItems: "center",
	justifyContent: "center",
	position: "absolute",
	top: "0",
	bottom: "0",
	left: "0",
});

const StyledInputRoot = styled("div")({
	borderRadius: "0.38rem",
	border: "1px solid #0E6BA8",
	outline: "1px solid transparent",
	background: "#fff",
	overflow: "hidden",
	display: "flex",
	position: "relative",

	"&.base--focused": {
		border: "1px solid #0E6BA8",
		outline: "1px solid #0E6BA8",
		boxShadow: "0.625rem 0.75rem 1.5rem 0 rgba(206, 222, 238, 0.47)",
	},

	"&.base--error:not(.base--focused)": {
		border: "1px solid #EB5757",
		backgroundColor: "#FFF",
		boxShadow: "0.625rem 0.75rem 1.5rem 0 rgba(206, 222, 238, 0.47)",
		input: {
			background: "rgba(235, 87, 87, .1)",
		},
	},

	"&.filledInput:not(.base--focused)": {
		input: {
			background: "#F3F9FF",
		},
	},

	button: {
		position: "absolute",
	},

	// firefox
	"&:focus-visible": {
		outline: "0",
	},
});

const StyledInputElement = styled("input")(
	() => `
    border: none;
    outline: 0;
    width: 100%;
    padding: 1rem 1.25rem;
  `,
);

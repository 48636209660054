import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MapWithAKmlLayer from '../../../components/MapWithAKmlLayer';
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import PersonalInfoTab from "./personal";
import SessionAvailabilityTab from "./session";
import AddressPaymentTab from "./payment";
import PresenterListTab from "./presenters";

const ProviderInfoScreen = () => {
  const { t } = useTranslation();
  const { userInfo } = useSelector(state => state.appInfo);
  const [activeTab, setActiveTab] = useState('personal-info')

  return (
    <div className="main-container">
      {userInfo["Unique Schools"] >= 3 && (
        <div style={{ marginBottom: 20 }}>
          <h1>{userInfo["Unique Schools"]} {t("schools-reached")}</h1>
          <MapWithAKmlLayer recId={userInfo.id} type="provider_schools" />
        </div>
      )}
      <h1>{t("my-info")}</h1>
      <Nav tabs className="myinfo-tabs">
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === "personal-info" })}
            onClick={() => setActiveTab("personal-info")}
          >Personal Information</NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === "session-availability" })}
            onClick={() => setActiveTab("session-availability")}
          >Availability & Requests</NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({ active: activeTab === "address-payment" })}
            onClick={() => setActiveTab("address-payment")}
          >Invoicing & Taxes</NavLink>
        </NavItem>
        {(userInfo["Individual/Org/Post-Sec."] === "Organization" || 
          userInfo["Individual/Org/Post-Sec."] === "Post-Secondary") && (
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === "presenters" })}
              onClick={() => setActiveTab("presenters")}
            >Presenters</NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="personal-info">
          <PersonalInfoTab />
        </TabPane>
        <TabPane tabId="session-availability">
          <SessionAvailabilityTab />
        </TabPane>
        <TabPane tabId="address-payment">
          <AddressPaymentTab />
        </TabPane>
        {(userInfo["Individual/Org/Post-Sec."] === "Organization" || 
          userInfo["Individual/Org/Post-Sec."] === "Post-Secondary") && (
          <TabPane tabId="presenters">
            <PresenterListTab />
          </TabPane>
        )}
      </TabContent>
    </div>
  )
}

export default ProviderInfoScreen;
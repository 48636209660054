import { PAGE_KEYS } from "../constants/form-keys";

export const transformGroupPage = (rawGroupPage) => {
	const { ID, FORM_ID, PAGE_ORDER, TRANSLATION_ID, GROUPS } =
		PAGE_KEYS;

	const { id, form_id, page_order, translation_id } =
		rawGroupPage || {};

	return {
		[ID]: id,
		[FORM_ID]: form_id,
		[PAGE_ORDER]: page_order,
		[TRANSLATION_ID]: translation_id,
		[GROUPS]: [],
	};
};

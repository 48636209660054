import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, InputGroup, InputGroupText } from 'reactstrap';
import ReactDatetime from 'react-datetime';
import { getDateAndTimeString, isValidDateOnPicker } from '../../../utils/time';
import {
  checkProviderWorkingTime,
  checkValidation,
  getEarliestStartTimeFromProviderSession,
  getJustTimeZone
} from '../Request/utils';
import { DAY_LIMITATIONS_MAP } from '../../../utils/constants';
import Axios from 'axios';
import { getOrdinal } from '../../../utils/string';

const SessionBookTimePicker = ({
  startTime,
  updateStartDate,
  provider,
  providerSession,
  nth,
  updateValidation,
  error,
  disabled
}) => {
  const [dayLimitations, setDayLimitations] = useState([]);
  const [startTimeValid, setStartTimeValid] = useState([]);
  const [isCalendarToken] = useState(false);
  // const [isCalendarToken, existCalendarToken] = useState(false);

  const providerTimezone = useRef(providerSession["Provider Timezone"][0]).current;
  const pTimezone = useRef(getJustTimeZone(providerSession["Provider Timezone"][0])).current;
  const earliestStartTime = useRef(getEarliestStartTimeFromProviderSession(providerSession)).current;
  const earliestStartStandardTime = providerSession["Provider Earliest Start Time"] ? providerSession["Provider Earliest Start Time"][0] : "8:30";

  const { t } = useTranslation();

  useEffect(() => {
    if (provider["Day Limitations"]) {
      const dl = provider["Day Limitations"].map(l => DAY_LIMITATIONS_MAP[l])
      setDayLimitations(dl)
    } else {
      setDayLimitations([]);
    }

    // existCalendarToken(provider['Calendar Token'] ? true : false);
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let isValid = true;

    if (checkValidation(startTimeValid) >= 0) isValid = false;

    updateValidation(isValid)
  }, [startTimeValid])// eslint-disable-line react-hooks/exhaustive-deps

  const checkValidationOfSelectedTime = (selectedTime, valids) => {
    const cResult = checkProviderWorkingTime(selectedTime, providerTimezone, earliestStartTime);
    const { isInWorkingTime, providerTime } = cResult;
    if (!isInWorkingTime) {
      valids.push("invalid-working-" + providerTime);
      setStartTimeValid([...valids]);
      return;
    }

    if (isCalendarToken) {
      const providerId = provider[0];
      const dateInfo = getDateAndTimeString(selectedTime);
      Axios.get(`https://research.tigweb.org/connectednorth/provider-availability/events.html?provider=${providerId}&date=${dateInfo.day}&time=${dateInfo.time}`).then(res => {
        if (res.data === "No events found.") {
          valids.push("valid")
        } else {
          valids.push("invalid")
        }

        setStartTimeValid([...valids]);
      })
    }
  }

  const renderTimeValidation = (timeValid) => {
    const renderTimeValidationItem = (item) => {
      if (item.startsWith('invalid-working-')) {
        const providerTime = item.substring(16);
        return (
          <span
            style={{ color: 'red', fontSize: 12 }}
          >{`The provider is in the ${pTimezone} Time Zone and this is ${providerTime} their time. Please request a time after ${earliestStartStandardTime} AM.`}</span>
        )
      }

      if (item === 'valid') {
        return (
          <span
            style={{ color: 'green', fontSize: 12 }}
          >{t("sessionrequest.time-valid-msg")}</span>
        )
      }

      if (item === 'invalid') {
        return (
          <span
            style={{ color: 'red', fontSize: 12 }}
          >{t("sessionrequest.time-invalid-msg")}</span>
        )
      }

      if (item === 'incorrect') {
        return (
          <span
            style={{ color: '#cda535', fontSize: 12 }}
          >{t("sessionrequest.time-warning-msg")}</span>
        )
      }

      if (item === 'past-time') {
        return (
          <span
            style={{ color: 'red', fontSize: 12 }}
          >{t("sessionrequest.past-time")}</span>
        )
      }

      return null;
    }

    return (
      <>
        {
          timeValid.map((item, i) => <div key={i}>
            {renderTimeValidationItem(item)}
          </div>)
        }

        {!!error && (
          <span
            style={{ color: '#d8af3a', fontSize: 12 }}
          >{error}</span>
        )}
      </>
    )
  }

  return (
    <FormGroup>
      <InputGroup className="input-group-alternative">
        <InputGroupText>
          {getOrdinal(nth)}.&nbsp;&nbsp;&nbsp;<i className="ni ni-calendar-grid-58" />
        </InputGroupText>

        <ReactDatetime
          value={startTime}
          inputProps={{
            placeholder: t("sessionrequest.prefer-time"),
            disabled: disabled
          }}
          timeConstraints={{ minutes: { step: 5 } }}
          isValidDate={(currentDate) => isValidDateOnPicker(dayLimitations, currentDate)}
          timeFormat={true}
          onChange={(e) => {
            if (typeof e !== 'string') {
              const valids = [];
              const selectedDate = e.toDate();
              const selectedHour = selectedDate.getHours();
              if (selectedDate < new Date()) valids.push("past-time")
              if (selectedHour < 8 || selectedHour >= 17) { valids.push("incorrect"); }
              setStartTimeValid(valids)
              checkValidationOfSelectedTime(selectedDate, valids);
              updateStartDate(selectedDate);
            }
          }}
        />
      </InputGroup>
      {renderTimeValidation(startTimeValid)}
    </FormGroup>
  )
}

export default SessionBookTimePicker;

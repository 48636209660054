import AirtableTable from "../AirtableTable";

export class CurriculumLinkTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Curriculum Links")

    this.fields = {
      "ID": "fldLq7eswc8HiLm2N",
      "Grade(s)": "fld0QuS2pQzGDi82A",
      "Region": "fld1EurUY0WhOmZZe",
      "Linked Sessions": "fldCCy1ZGJtcbJISY",
      "Subject": "fldQUwGMhfiVOgfhe",
      "Bookings": "fldmmSaXAWOCr1e3k",
      "Strand": "fld8ra2XTE5Mn1ZrR",
      "Topic": "fldqOd0ILclfRsQis",
      "Subject Text": "fldSKVItZ8bcLfsJT",
      "Sessions": "fldy3gEoqUkRwBGQc",
      "UUID": "fldiZcg0Z51NHlYSU",
      "Region Text": "fld6AXUW7dUN21QKj",
      "Region Name": "fldibv6mxkp8wzUix",
      "Core Competencies": "fldKL6dvMBWxM0aPh",
      "Suggested By": "fldaL2MogE65s7wTF",
      "Record ID": "fldcPcsPvFeVXtMAX",
      "Platform Link": "fldiNzJOmFjBH2wo4",
      "Course Code": "fld3IyepFvdGnV4lo"
    }
  }

  getLinkFromKeyword = (keyword, region) => {
    const kWord = keyword.toUpperCase();
    var filterByFormula = `OR(REGEX_MATCH(UPPER({Strand}), '${kWord}'), REGEX_MATCH(UPPER({Topic}), '${kWord}'))`;
    if (!region) {
      filterByFormula = `AND(${filterByFormula}, {Linked Sessions} > 0)`
    } else {
      filterByFormula = `AND(${filterByFormula}, {Linked Sessions} > 0, FIND("${region}", {Region}))`
    }

    return this.list({
      filterByFormula,
      sort: [
        { field: "Subject", direction: 'asc' },
        { field: "ID", direction: 'asc' }
      ]
    })
  }

  filter = (region, subjects) => {
    let filterByFormula = "";
    filterByFormula = `FIND('${region}', {Region})`;
    if (subjects.length) {
      let subjectCondition = "";
      subjects.forEach((subject, i) => {
        if (i > 0) subjectCondition += ", ";
        subjectCondition += `FIND(";${subject};", CONCATENATE(";", SUBSTITUTE(ARRAYJOIN({Subject}), ", ", ";"), ";"))`;
      })
      if (subjects.length > 1) {
        subjectCondition = `OR(${subjectCondition})`;
      }
      filterByFormula = `AND(${filterByFormula}, ${subjectCondition})`
    }
    return this.list({ filterByFormula })
  }

  getCurriculumLinks(
    {
      gradeLevel,
      subjectArea,
      showAll
    },
    region,
    listener
  ) {
    const cons = ["NOT({Linked Sessions} = 0)"];

    if (region && !showAll) {
      const f = region.map((r) => `FIND("${r}", {Region})`).join(",");
      cons.push(`OR(${f})`);
    }

    if (gradeLevel && gradeLevel.length) {
      const f = gradeLevel.map(
        (r) => `FIND(";${r};", CONCATENATE(";", SUBSTITUTE(ARRAYJOIN({Grade(s)}), ", ", ";"), ";"))`
      ).join(",");
      cons.push(`OR(${f})`);
    }

    if (subjectArea && subjectArea.length !== 0) {
      const f = subjectArea.map((s) => s.Subject).map(
        (r) => `FIND(";${r};", CONCATENATE(";", SUBSTITUTE(ARRAYJOIN({Subject}), ", ", ";"), ";"))`
      ).join(",");
      cons.push(`OR(${f})`);
    }

    const filterByFormula = `AND(${cons.join(",")})`;
    return this.listItems({
      pageSize: 20,
      filterByFormula: filterByFormula,
      sort: [{ field: "Strand", direction: "asc" }]
    }, listener)
  }

  getLinksByIdsAndRegions = async (ids, regions) => {
    const linkIDs = ids.map((id) => `RECORD_ID() = "${id}"`).join(",");
    const r = regions.map((region) => `{Region} = "${region}"`).join(",");
    const filterByFormula = `AND(OR(${linkIDs}), OR(${r}))`;

    let links = await this.list({ filterByFormula });
    if (!links.length) links = await this.listByIds(ids);

    return links;
  }
}

import SupabaseTable from '../SupabaseTable';

const bucket = 'form_uploads'

export class FormResponseValuesTable extends SupabaseTable {
  
  deleteInstance = async (responseId, instance_number) => {
    try {
      const { error } = await this.supabase
        .from(this.tableName)
        .delete()
        .eq('response_id', responseId)
        .eq('instance_number', instance_number)
      if (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      throw error;
    }
  }
  listByResponse = async (responseId) => {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select('*')
        .eq('response_id', responseId);

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
  uploadFile = async (filePath, file) => {
    try {
      const { data, error } = await this.supabase
        .storage
        .from(bucket)
        .upload(filePath, file);

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
  removeFile = async (filePath) => {
    try {
      const { data, error } = await this.supabase
        .storage
        .from(bucket)
        .remove([filePath]);

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
}
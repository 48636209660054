import SupabaseTable from "../SupabaseTable";

export class FieldsTable extends SupabaseTable {

  listByPage = async (pageId) => {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select("*")
        .eq("page_id", pageId);

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (error) {
      throw error;
    }
  };

  listByGroup = async (groupId) => {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select("*")
        .eq("group_id", groupId);

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (error) {
      throw error;
    }
  };

  listByGroups = async (groupIds) => {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select("*")
        .in("group_id", groupIds);

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (error) {
      throw error;
    }
  };
}
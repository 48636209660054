export const TEACHER_PDF_FILES = [
	{
		title: "Teacher Handbook",
		pdfUrl: "/PD-Plan/Classroom Teachers Handbook ENG (April 2018)-1.pdf",
		fileName: "classroom-teacher-handbook.pdf",
	},
	{
		title: "Teacher Professional Standards",
		pdfUrl: "/PD-Plan/Classroom Teachers Professional Standards ENG (April 2018).pdf",
		fileName: "teacher-professional-standards.pdf",
	},
	{
		title: "Teacher PD Framework",
		pdfUrl: "/PD-Plan/Classroom Teachers PD Framework ENG (April 2018).pdf",
		fileName: "pd-framework.pdf",
	},
	{
		title: "Teacher PD Framework Milestones",
		pdfUrl: "/PD-Plan/Classroom Teachers Key Milestones ENG (April 2018)-1.pdf",
		fileName: "pd-framework-milestones.pdf",
	},
	{
		title: "Teacher Self-Reflection",
		pdfUrl: "/PD-Plan/Classroom Teachers Self-Reflection ENG (April 2018).pdf",
		fileName: "self-reflection.pdf",
	},
];

export const SCHOOL_LEADER_PDF_FILES = [
	{
		title: "School Leader Handbook",
		pdfUrl: "/PD-Plan/School Leaders Handbook ENG (April 2018).pdf",
		fileName: "school-leader-handbook.pdf",
	},
	{
		title: "School Leader Professional Standards",
		pdfUrl: "/PD-Plan/School Leaders Professional Standards (April 2018).pdf",
		fileName: "school-leader-professional-standards.pdf",
	},
	{
		title: "School Leader PD Framework",
		pdfUrl: "/PD-Plan/School Leaders PD Framework ENG (April 2018).pdf",
		fileName: "school-leader-pd-framework.pdf",
	},
	{
		title: "School Leader PD Framework Milestones",
		pdfUrl: "/PD-Plan/School Leaders PD Framework ENG (April 2018).pdf",
		fileName: "school-leader-pd-framework-milestones.pdf",
	},
	{
		title: "School Leader Self-Reflection",
		pdfUrl: "/PD-Plan/School Leaders Self-Reflection ENG (April 2018).pdf",
		fileName: "school-leader-self-reflection.pdf",
	},
];

export const LEARNING_COACH_PDF_FILES = [
	{
		title: "Learning Coaches Handbook",
		pdfUrl: "/PD-Plan/Learning Coaches Handbook ENG (April 2018).pdf",
		fileName: "learning-coach-handbook.pdf",
	},
	{
		title: "Learning Coaches Professional Standards",
		pdfUrl: "/PD-Plan/Learning Coaches Professional Standards ENG (April 2018).pdf",
		fileName: "learning-coach-professional-standards.pdf",
	},
	{
		title: "Learning Coaches PD Framework",
		pdfUrl: "/PD-Plan/Learning Coaches PD Framework ENG (April 2018).pdf",
		fileName: "learning-coach-pd-framework.pdf",
	},
	{
		title: "Learning Coaches PD Framework Milestones",
		pdfUrl: "/PD-Plan/Learning Coaches Key Milestones ENG (April 2018).pdf",
		fileName: "learning-coach-pd-framework-milestones.pdf",
	},
	{
		title: "Learning Coaches Self-Reflection",
		pdfUrl: "/PD-Plan/Learning Coaches Self-Reflection ENG (April 2018).pdf",
		fileName: "learning-coach-self-reflection.pdf",
	},
];

export const SST_PDF_FILES = [
	{
		title: "SST Handbook",
		pdfUrl: "/PD-Plan/SST Handbook ENG (April 2018).pdf",
		fileName: "sst-handbook.pdf",
	},
	{
		title: "SST Professional Standards",
		pdfUrl: "/PD-Plan/SST Professional Standards ENG (April 2018).pdf",
		fileName: "sst-professional-standards.pdf",
	},
	{
		title: "SST PD Framework",
		pdfUrl: "/PD-Plan/SST PD Framework ENG (April 2018).pdf",
		fileName: "sst-pd-framework.pdf",
	},
	{
		title: "SST PD Framework Milestones",
		pdfUrl: "/PD-Plan/SST Key Milestones ENG (April 2018).pdf",
		fileName: "sst-pd-framework-milestones.pdf",
	},
	{
		title: "SST Self-Reflection",
		pdfUrl: "/PD-Plan/SST Self-Reflection ENG (April 2018).pdf",
		fileName: "sst-self-reflection.pdf",
	},
];

export const getPDFFiles = (userInfo) => {
	const gradesTaught = userInfo["Grade(s) Taught"] || [];

	if (userInfo["School Leader?"] && gradesTaught.includes("Learning Coach")) {
		return [...SCHOOL_LEADER_PDF_FILES, ...LEARNING_COACH_PDF_FILES, ...SST_PDF_FILES, ...TEACHER_PDF_FILES];
	}

	if (userInfo["School Leader?"]) {
		return [...SCHOOL_LEADER_PDF_FILES, ...TEACHER_PDF_FILES];
	}

	if (gradesTaught.includes("Learning Coach")) {
		return [...LEARNING_COACH_PDF_FILES, ...SST_PDF_FILES, ...TEACHER_PDF_FILES];
	}

	if (gradesTaught.includes("SST")) {
		return SST_PDF_FILES;
	}

	return TEACHER_PDF_FILES;
};

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap'
import RenderSchoolSessions from '../SessionList';
import { useUpcoming } from './useUpcoming';
import classNames from 'classnames';
import FitlerConditionModal from '../../modals/FilterCondition';

const Screen = () => {
  const { t } = useTranslation()
  const {
    schools,
    data,
    filterConditions,
    setFilterConditions,
    refresh
  } = useUpcoming();
  const [activeTab, setActiveTab] = useState('upcoming')
  const [showFilter, setShowFilter] = useState(false);

  let count = 0;
  if (filterConditions.filterKeyword) count++;
  if (
    filterConditions.filterSchool &&
    filterConditions.filterSchool.value
  ) count++;
  if (filterConditions.filterDate) count++;
  if (filterConditions.filterCILC) count++;

  return (
    <div className="main-container upcoming">
      <div className="upcoming-title">
        <h1>{t('my-sessions')}</h1>
        <div className="filter" onClick={() => setShowFilter(true)}>
          <i className="fa fa-filter" />
          <span className="lbl-filter">Filter</span><span>({count})</span>
        </div>
      </div>
      <Nav tabs className="upcoming-tabs">
        {data.map((tabData, i) => (
          <NavItem key={i}>
            <NavLink  key={i}
              className={classNames({ active: activeTab === tabData.name })}
              onClick={() => setActiveTab(tabData.name)}
            >
              {tabData.label}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {data.map((tabData, i) => (
          <TabPane tabId={tabData.name} key={i}>
            <RenderSchoolSessions
              loading={tabData.loading}
              sessions={tabData.sessions}
              type={tabData.type}
              noMessage={t("no-upcoming-sessions")}
              refreshPage={refresh}
            />
          </TabPane>
        ))}
      </TabContent>
      {showFilter && (
        <FitlerConditionModal
          schools={schools}
          initFilter={filterConditions}
          onToggle={() => setShowFilter(!showFilter)}
          onChange={cond => {
            setFilterConditions(cond);
            refresh();
            setShowFilter(false);
          }}
        />
      )}
    </div>
  )
}

export default Screen

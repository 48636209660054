import { useEffect, useState } from "react";
import { useMemo } from "react";

const overview = {
	id: "overview",
	translation_id: "overview",
};

export const useFormNavigation = (form) => {
	const {
		pages,
		response_id
	} = form

  const routes = useMemo(() => [overview, ...pages], [pages]);
	const [activeRoute, setActiveRoute] = useState(null);
	

	const handleRouteChange = (id) => {
		setActiveRoute(routes.find((route) => route.id === id));
	};
	const handleRouteNext = () => {
		const index = routes.findIndex((route) => route.id === activeRoute.id);
		if (index < routes.length - 1) {
			handleRouteChange(routes[index + 1].id);
		}
	};
	const handleRoutePrev = () => {
		const index = routes.findIndex((route) => route.id === activeRoute.id);
		if (index > 0) {
			handleRouteChange(routes[index - 1].id);
		}
	};

	useEffect(() => {
		if (routes.length > 1) {
			const storedRoute = localStorage.getItem(response_id);
			const route = routes.find((route) => route.id === storedRoute);
			if (!route && storedRoute) {
				localStorage.removeItem(response_id);
			}
			if (route) {
				setActiveRoute(route);
			} else {
				setActiveRoute(routes[0]);
			}
		}
	}, [response_id, routes]);

	useEffect(() => {
		if (activeRoute) {
			localStorage.setItem(response_id, activeRoute.id);
		}
		const container = document.getElementById("main-frame");
		if (container) {
			container.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		}
	}, [activeRoute, response_id]);

	return {
		routes,
		activeRoute,

		handleRouteChange,
		handleRouteNext,
		handleRoutePrev,
	};
};

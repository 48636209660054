import supabase from "../../../database";
import { transformResponse } from "../util/transform-response";

export const fetchFormResponseByFormIdUserId = async (formId, userId) => {
	try {
		const response = await supabase.formResponses.selectByFormIdAndUserId(
			formId,
			userId,
		);
		if (!response) return null;
		return transformResponse(response) || null;
	} catch (e) {
		console.log("Error:", e);
		return null;
	}
};

export const fetchFormResponseById = async (responseId) => {
	try {
		const response = await supabase.formResponses.select(responseId)
		if (!response) return null;
		return transformResponse(response) || null;
	} catch (e) {
		console.log("Error:", e);
		return null;
	}
};
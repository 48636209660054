import React, { useState } from 'react';
import { TextField } from '@mui/material';
import InputWrapper from './InputWrapper';

const ShortTextInput = ({
  id,
  disabled,
  value = "",
  onChange = () => { },
  error = false,
  max_length,
  sx = {},
  heading,
  placeholder,
  helperText,
}) => {
 
  const [inputValue, setInputValue] = useState(value);
  const [inputError, setInputError] = useState(error);

  const filledInput = inputValue.length > 0;
  
	const handleInputChange = (e) => { 
    const { value } = e.target;

    setInputValue(value);
    setInputError(false);

    onChange(value);
  }

  return (
    <InputWrapper
      title={heading}
      sx={sx}
      id={id}
      inputError={inputError}
      filledInput={filledInput}
      helperText={helperText}
    >
      <TextField
        id={id}
        name={id}
        aria-describedby="helper-text"
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => handleInputChange(e)}
        error={inputError}
        disabled={disabled}
        inputProps={{ maxLength: max_length }}
      />
    </InputWrapper>
  );
};

export default ShortTextInput;
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
	getPDData,
	getPDPlanById,
	getTeacherBySyncedId,
} from "../util/get-pd-data";
import {
	createPDGoal,
	createPDPlan,
	deletePDGoal,
	updatePDGoal,
	updatePDPlan,
} from "../util/post-pd-data";
import {
	PLAN_FIELDS,
	TEACHER_FIELDS,
	REVIEW_FIELDS,
} from "../constants/pd-fields";

const { STATUS } = PLAN_FIELDS;
const { PD_PLANS, CLUSTER_RECORD_IDS } = TEACHER_FIELDS;

export const usePDData = () => {
	const { teacherId: reviewTeacherId } = useParams();
	const { userInfo } = useSelector((state) => state.appInfo);

	const teacherId = reviewTeacherId || userInfo.id;

	const [nunavutTeacherId, setNunavutTeacherId] = useState(null);
	const [activePD, setActivePD] = useState(null);
	const [pastPDs, setPastPDs] = useState(null);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	async function getUpdatedPlan() {
		setIsLoading(true);
		const updatedPDPlan = await getPDPlanById(activePD.id);
		setActivePD(updatedPDPlan);
		setIsLoading(false);
		return updatedPDPlan;
	}

	async function addPlan() {
		if (!nunavutTeacherId) return;
		setIsLoading(true);
		const newPD = await createPDPlan(nunavutTeacherId);
		setActivePD(newPD);
		setPastPDs([]);
		setIsLoading(false);
		return newPD;
	}

	async function savePlan() {
		setIsLoading(true);
		const updatedPDPlan = await updatePDPlan(activePD.id, {
			[PLAN_FIELDS.COMPLETE_FOR_REVIEW]: true,
		});
		await getUpdatedPlan();
		setIsLoading(false);
		return updatedPDPlan;

	}

	async function addTeacherReview(index, params) {
		setIsLoading(true);
		const field = REVIEW_FIELDS[index].TEACHER_COMMENT;
		const updatedPDPlan = await updatePDPlan(activePD.id, {
			[field]: params.teacher_comment,
		});
		await getUpdatedPlan();
		setIsLoading(false);
		return updatedPDPlan;
	}

	async function addAdminReview(index, params) {
		setIsLoading(true);
		const fields = REVIEW_FIELDS[index];
		const adminUser = await getTeacherBySyncedId(userInfo.id);
		
		// Format date as YYYY-MM-DD
		const today = new Date();
		const dateString = today.toISOString().split('T')[0];
		
		const updatedPDPlan = await updatePDPlan(activePD.id, {
			[fields.COMMENT]: params.comment,
			[fields.DATE]: dateString,
			[fields.NAME]: [adminUser.id],
		});
		await getUpdatedPlan();
		setIsLoading(false);
		return updatedPDPlan;
	}

	async function addGoal() {
		setIsLoading(true);
		const goalCount = activePD[PLAN_FIELDS.GOALS]?.length || 0;
		const newGoal = await createPDGoal(
			activePD.id,
			`New Goal ${goalCount + 1}`,
		);
		await getUpdatedPlan();
		setIsLoading(false);
		return newGoal;
	}

	async function updateGoal(goalId, params) {
		setIsLoading(true);
		const updatedGoal = await updatePDGoal(goalId, params);
		await getUpdatedPlan();
		setIsLoading(false);
		return updatedGoal;
	}

	async function deleteGoal(goalId) {
		setIsLoading(true);
		await deletePDGoal(goalId);
		await getUpdatedPlan();
		setIsLoading(false);
		return true;
	}

	//Fetch the PD data for the teacher once the teacherId is set
	useEffect(() => {
		if (error) {
			console.error(error);
			return;
		}

		const fetchDataAsync = async () => {
			if (teacherId === null) return;
			try {
				setIsLoading(true);

				const data = await getPDData(teacherId);

				if (!error && data) {
					if (userInfo["District Leader?"]) {
						const clusterIds = data[CLUSTER_RECORD_IDS].split(", ");
						if (
							!userInfo["Cluster Leadership"].some((clusterId) =>
								clusterIds.includes(clusterId),
							)
						) {
							setError(
								"You are not authorized to view this page",
							);
							return;
						}
					}
					//Find the active PD plan and pop it from plans.
					setNunavutTeacherId(data.id);
					let active = null;
					let plans = data[PD_PLANS] || [];

					for (let i = 0; i < plans.length; i++) {
						if (plans[i][STATUS] === "Active") {
							active = plans[i];
							plans.splice(i, 1);
							break;
						}
					}

					//If there is an active PD plan, set it as activePD and set the rest as pastPDs
					if (active) {
						setActivePD(active);
						setPastPDs(plans);
					} else {
						setActivePD(null);
						setPastPDs([]);
					}
				} else {
					setError(error);
				}
			} catch (err) {
				setError(err);
			} finally {
				setIsLoading(false);
			}
		};

		fetchDataAsync();
	}, [userInfo, teacherId, error, setError]);

	return {
		activePD,
		pastPDs,

		addGoal,
		addTeacherReview,
		addAdminReview,
		addPlan,
		savePlan,
		updateGoal,
		deleteGoal,

		error,
		isLoading,
	};
};

import React, { useState } from 'react';
import { Button, FormGroup, Modal } from 'reactstrap';
import { Checkbox, FormControlLabel, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

const PDWeekSession = ({ sessions, loading, showModal, onToggle, onAddToPDLog }) => {
	const [selectedSessions, setSelectedSessions] = useState([]);
	const { t } = useTranslation();

	const handleCheckboxChange = (sessionId) => {
		setSelectedSessions((prevSelected) =>
			prevSelected.includes(sessionId)
				? prevSelected.filter((id) => id !== sessionId)
				: [...prevSelected, sessionId]
		);
	};

	const handleAddToPDLog = () => {
		onAddToPDLog(selectedSessions);
		setSelectedSessions([]);
	};

	return (
		<Modal
			className="modal-dialog-centered"
			isOpen={showModal}
			toggle={onToggle}
		>
			<div className="modal-header">
				<h5 className="modal-title" id="modal-title-default">
					{t("pd-plan.logs.import-modal.title")}
				</h5>
				<button
					aria-label="Close"
					className="close"
					data-dismiss="modal"
					type="button"
					onClick={onToggle}
				>
					<span aria-hidden={true}><i className="fa fa-times" /></span>
				</button>
			</div>
			<div className="modal-body pd-session-modal" style={{ overflowY: "auto", maxHeight: "300px" }} >
			{ loading
				? <CircularProgress />
				: sessions.length > 0 ? (
						<FormGroup>
							{sessions.map((session) => (
								<FormControlLabel
									key={session.id}
									sx={{
										display: "inline-flex",
										alignItems: "center",
										width: "100%",
										"> .MuiFormControlLabel-label": {
											overflow: "hidden",
											whiteSpace: "nowrap",
											textOverflow: "ellipsis"
										}
									}}
									control={
										<Checkbox
											checked={selectedSessions.includes(session["SessionID"])}
											onChange={() => handleCheckboxChange(session["SessionID"])}
										/>
									}
									label={session["Custom Session Title"] || session["Session Title Raw"]}
								/>
							))}
						</FormGroup>
					) : (
						<p>{t("pd-plan.logs.import-modal.empty-sessions")}</p>
					)
			}
			</div>
			<div className="modal-footer">
				<Button
					className="mr-auto"
					color="link"
					data-dismiss="modal"
					type="button"
					onClick={onToggle}
				>
					{t("cancel")}
				</Button>
				<Button
					color="primary"
					type="button"
					onClick={handleAddToPDLog}
					disabled={!selectedSessions || selectedSessions.length <= 0}
				>
					{t("pd-plan.logs.import-modal.add-btn")}
				</Button>
			</div>
		</Modal>
	);
};

export default PDWeekSession

import { REVIEW_FIELDS } from "../constants/pd-fields";

const today = new Date();

export const STATUS_LABELS = {
	NOT_STARTED: "Not started",
	IN_PROGRESS: "In progress",
	NEEDS_REVIEW: "Needs review",
	REVIEWED: "Reviewed",
	COMPLETE: "Complete",
	OVERDUE: "Overdue",
};

export const REVIEW_DATES = [
	{
		LABEL: REVIEW_FIELDS[0].LABEL,
		OPEN_DATE: "2024-10-26",
		DUE_DATE: "2024-11-30",
	},
	{
		LABEL: REVIEW_FIELDS[1].LABEL,
		OPEN_DATE: "2024-12-01",
		DUE_DATE: "2024-12-31",
	},
	{
		LABEL: REVIEW_FIELDS[2].LABEL,
		OPEN_DATE: "2025-05-01",
		DUE_DATE: "2025-06-30",
	},
];

export const getReviewIndexFromLabel = (label) =>
	REVIEW_FIELDS.findIndex(({ LABEL }) => {
		return LABEL === label;
	});

export const getReviewStatus = ({ comment, teacher_comment, label }) => {
	const reviewIndex = getReviewIndexFromLabel(label);
	const reviewDates = REVIEW_DATES[reviewIndex];

	const TEST_MODE = process.env.REACT_APP_TEST_MODE === "true" || false;
	const hasAdminReview = comment && comment !== "";
	const hasTeacherReview = teacher_comment && teacher_comment !== "";

	const returnInfo = {
		hasAdminReview,
		hasTeacherReview,
		reviewDates,
		reviewIndex,
		reviewStatus: null,
	};

	if (hasAdminReview && hasTeacherReview) {
		return { ...returnInfo, reviewStatus: STATUS_LABELS.COMPLETE };
	}

	const dueDate = new Date(reviewDates.DUE_DATE);
	const openDate = new Date(reviewDates.OPEN_DATE);

	// Past due date
	if (today > dueDate) {
		return { ...returnInfo, reviewStatus: STATUS_LABELS.OVERDUE };
	}

	// Past open date but before due date
	if (today >= openDate || TEST_MODE) {
		if (hasAdminReview) {
			return { ...returnInfo, reviewStatus: STATUS_LABELS.IN_PROGRESS };
		}
		return { ...returnInfo, reviewStatus: STATUS_LABELS.NEEDS_REVIEW };
	}

	return returnInfo;
};

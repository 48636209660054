import { FORM_KEYS } from "../constants/form-keys";
import { RESPONSE_KEYS } from "../constants/response-keys";
export const transformForm = (rawForm) => {
	const { 
		ID, 
		TEMPLATE_ID, 
		TRANSLATION_ID, 
		STATUS, 
		RESPONSE_ID, 
		PAGES, 
		RESPONSE_STATUS, 
		SCHOOL_IDS, 
		USER_ID,
		DEA_COMMENT,
		DEA_ID,
		USER_COMMENT,
		SUBMITTED_AT,
		CREATED_AT,
		LAST_SAVED_AT 
	} = FORM_KEYS;
	const { id, template_id, translation_id, status } = rawForm || {};


	return {
		[ID]: id,
		[TEMPLATE_ID]: template_id,
		[TRANSLATION_ID]: translation_id,
		[STATUS]: status,	
		[PAGES]: [],
		[RESPONSE_ID]: '',
		[RESPONSE_STATUS]: "not-started",
		[SCHOOL_IDS]: [],
		[USER_ID]: '',
		[DEA_COMMENT]: null,
		[DEA_ID]: null,
		[USER_COMMENT]: null,
		[SUBMITTED_AT]: null,
		[CREATED_AT]: null,
		[LAST_SAVED_AT]: null,

		updateWithPages: function(pages) {
			if(!pages) return;
			this[PAGES] = pages
		},

		updateWithResponse: function(response) {
			if(!response) return;
			this[RESPONSE_STATUS] = response[RESPONSE_KEYS.STATUS]
			this[RESPONSE_ID] = response[RESPONSE_KEYS.ID]
			this[SCHOOL_IDS] = response[RESPONSE_KEYS.SCHOOL_IDS]
			this[USER_ID] = response[RESPONSE_KEYS.USER_ID]

			this[DEA_COMMENT] = response[RESPONSE_KEYS.DEA_COMMENT]
			this[DEA_ID] = response[RESPONSE_KEYS.DEA_ID]
			this[USER_COMMENT] = response[RESPONSE_KEYS.USER_COMMENT]
			this[SUBMITTED_AT] = response[RESPONSE_KEYS.SUBMITTED_AT]
			this[CREATED_AT] = response[RESPONSE_KEYS.CREATED_AT]
			this[LAST_SAVED_AT] = response[RESPONSE_KEYS.LAST_SAVED_AT]
		},

		updateWithResponseValues: function(responses) {
				if (!responses || !Array.isArray(responses)){
					return;
				};
				this[FORM_KEYS.PAGES].forEach((page) => {
					if (!page.groups || !Array.isArray(page.groups)) return;
				 
					page.groups.forEach((group) => {
						if (!group.fields || !Array.isArray(group.fields)) return;
			
						const groupFieldIds = group.fields.map((field) => field.id);
						const groupResponses = responses.filter((response) =>
								groupFieldIds.includes(response.field_id)
						);
			
						const instanceMap = new Map();
			
						groupResponses.forEach((response) => {
							if (!instanceMap.has(response.instance_number)) {
								instanceMap.set(response.instance_number, {
									instance_number: response.instance_number,
									fields: [],
								});
							}
			
							const instance = instanceMap.get(response.instance_number);
							instance.fields.push(response);
						});
			
						instanceMap.forEach((instance) => {
							instance.fields.sort((a, b) => {
								const fieldA = group.fields.find((field) => field.id === a.field_id);
								const fieldB = group.fields.find((field) => field.id === b.field_id);
								return (fieldA.field_order || 0) - (fieldB.field_order || 0);
							});
						});
			
						group.instances = Array.from(instanceMap.values());
					});
				});
			},

		updateField: function(id, value) {
			this.pages.forEach((page) => {
        page.groups.forEach((group) => {
            group.instances.forEach((instance) => {
                const field = instance.fields.find((field) => field.id === id);
                if (field) {
                    field.value = value;
                }
            });
        });
    	});
		}
	};
};

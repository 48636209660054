import SupabaseTable from '../SupabaseTable';

export class GroupPagesTable extends SupabaseTable {

  async listByForm(formId) {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select('*')
        .eq('form_id', formId);

      if (error) throw new Error(error.message);
      return data;
    } catch (error) {
      throw error;
    }
  }
  async listAll() {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select('*');

      if (error) throw new Error(error.message);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormGroup, Input, Label, Progress } from 'reactstrap';
import airtable from '../../airtables';
import { setAppUserInfo } from '../../redux/actions';
import { setSession } from '../../utils/session';
import { CONNECTEDNORTH_SIGNINFO } from '../../config';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import TwoFactorAuthConfirmModal from '../modals/TwoFactorAuthConfirm';
import usePasskey from '../../libs/hooks/use-passkey';

const TeamSettings = () => {
  const { userInfo } = useSelector(state => state.appInfo);
  const [updating, setUpdating] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [language, setLanguage] = useState(userInfo["Preferred Language"] || 'English')
  const [authMethod, setAuthMethod] = useState("None");
  const [_2FAVerificationInfo, set2FAVerificationInfo] = useState(null);
  const [phoneError, setPhoneError] = useState("");
  const [phoneDisabled, setPhoneDisabled] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { createPasskey } = usePasskey();

  useEffect(() => {
    setPhoneNumber(userInfo['Phone']);
    setLanguage(userInfo["Preferred Language"] || 'English');
    setAuthMethod(userInfo["2FA"] || "None");
    setPhoneDisabled(userInfo["2FA"] === "SMS");
  }, [userInfo])

  const updateInfo = async () => {
    if (authMethod === "SMS" && !phoneNumber) {
      alert("Please input a mobile phone number that can receive text messages to enable SMS verification.")
      return;
    }

    if (phoneNumber && phoneNumber.replace(/\D/g, '').length < 10) {
      setPhoneError(t("mysettings.phone-number-error-length"));
      return;
    }
    setPhoneError("");

    let phoneVerified = userInfo["Phone Number SMS Verified"] ? true : false;
    if (phoneNumber && phoneNumber !== userInfo["Phone"]) {
      phoneVerified = false;
    }

    if (authMethod === "SMS" && !phoneVerified) {
      set2FAVerificationInfo({
        type: "sms",
        phone: phoneNumber,
        uInfo: {}
      });
    } else if (authMethod === "App" && !userInfo["2FA Secret"]) {
      set2FAVerificationInfo({
        type: "app",
        uInfo: { userEmail: userInfo["Email"] }
      });
    } else if (authMethod === "Passkeys" && userInfo["2FA"] !== authMethod) {
      try {
        await createPasskey(userInfo.id, userInfo["Email"], userInfo["Name"]);
      } catch (error) {
        console.log("Error:", error);
        return ;
      }
    }

    setUpdating(true);
    try {
      let params = {
        "Phone": phoneNumber,
        "Preferred Language": language,
        "2FA": authMethod,
      };

      if (authMethod !== "App" && userInfo["2FA"] !== authMethod) {
        params["2FA Secret"] = "";
      }

      const result = await airtable.teams.update(userInfo.id, params);
      await setSession(CONNECTEDNORTH_SIGNINFO, result);
      dispatch(setAppUserInfo(result));
      toast.success(t("my-settings-saving-success"));
    } catch (error) {
      console.log("Error:", error);
    }
    setUpdating(false);
  }

  const verifyPost = async (type, secret) => {
    let nUserInfo = null;
    if (type === "sms") {
      nUserInfo = await airtable.teams.update(userInfo.id, {
        "Phone": phoneNumber,
        "Phone Number SMS Verified": true,
        "2FA": "SMS"
      });
    } else if (type === "app") {
      nUserInfo = await airtable.teams.update(userInfo.id, {
        "2FA Secret": secret,
        "2FA": "App"
      });
    }

    if (nUserInfo) {
      await setSession(CONNECTEDNORTH_SIGNINFO, nUserInfo);
      dispatch(setAppUserInfo(nUserInfo));
      set2FAVerificationInfo(null);
      toast.success(t("mysettings.login-security-enabled"));
    }
  }

  return (
    <div className="main-container">
      <h1>{t("my-settings")}</h1>
      {updating && <Progress />}
      <div style={{ marginTop: 10 }} align="center">
        <div style={{ maxWidth: 600, minHeight: 500, padding: '0 20px', paddingTop: 40 }} align="left">
          <FormGroup row>
            <Label for="email" sm={4}>Preferred Language</Label>
            <Col sm={8} className="v-center">
              <Input
                type="select"
                className='pronouns form-control'
                value={language}
                onChange={(e) => setLanguage(e.target.value)}
              >
                <option value="English">English</option>
                <option value="French">French</option>
              </Input>
            </Col>
          </FormGroup>

          <div className="user-phone">
            <FormGroup row>
              <Label for="phone" sm={4}>{t("phone-number")}</Label>
              <Col sm={8}>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={e => {
                    setPhoneNumber(e.target.value);
                    setPhoneError("");
                  }}
                  disabled={phoneDisabled}
                />
                {phoneError && <div className="text-danger mt-1">{phoneError}</div>}
              </Col>
            </FormGroup>
            {phoneDisabled && (
              <p className="phone-error">To edit your phone number, first disable Login Security below and save changes.</p>
            )}
            {(!userInfo["Phone"] && authMethod === "SMS") ? (
              <p className="phone-error">Please add a phone number and save your settings to enable SMS login security.</p>
            ) : (!userInfo["Phone Number SMS Verified"] && authMethod === "SMS") && (
              <p className="phone-error">This phone number is not verified yet. Please <span
                onClick={() => {
                  set2FAVerificationInfo({
                    type: "sms",
                    phone: phoneNumber,
                  });
                }}
              >verify it here</span>.</p>
            )}
          </div>

          <div className="user-auth">
            <FormGroup row>
              <Label for="phone" sm={4}>Login Security</Label>
              <Col sm={8} className="v-center">
                <Input
                  type="select"
                  className='_2fa-selector form-control'
                  value={authMethod}
                  onChange={(e) => setAuthMethod(e.target.value)}
                >
                  <option value="SMS">SMS (Text Message)</option>
                  <option value="App">Authenticator App</option>
                  <option value="Passkeys">Passkeys</option>
                </Input>
              </Col>
            </FormGroup>
            {(!userInfo["2FA Secret"] && authMethod === "App") && (
              <p className="_2fa-error"><span
                onClick={() => {
                  set2FAVerificationInfo({
                    type: "app",
                    uInfo: { userEmail: userInfo["Email"] }
                  });
                }}
              >Click here to set up an authenticator app</span> to receive passcodes.</p>
            )}
          </div>
          <span style={{ fontSize: '0.85rem' }}>When logging in, to provide additional security you can verify your login request with a SMS Text Message to your mobile device, or a smartphone-based authentication app, such as Google Authenticator or Microsoft Authenticator.</span>
        </div>
      </div>

      <div className="ms-action" data-intercom-target="Save">
        <Button
          color="primary"
          size="sm"
          onClick={() => updateInfo()}
        >{t("save-change")}</Button>
      </div>

      {!!_2FAVerificationInfo && (
        <TwoFactorAuthConfirmModal
          type={_2FAVerificationInfo.type}
          phone={_2FAVerificationInfo.phone}
          uInfo={_2FAVerificationInfo.uInfo}
          onDismiss={() => set2FAVerificationInfo(null)}
          onVerifiedPost={verifyPost}
        />
      )}
    </div>
  )
}

export default TeamSettings;
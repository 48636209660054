import { Card } from "../../../components/Card";
import { Stepper } from "../../../components/Stepper";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export function MyPDHours({ hours, issueDate, expiryDate, isPrincipal = false }) {
	const labels = [0, 20, 40, 60, 80, 100, 120];
	if (isPrincipal) labels.push(140, 160);

	const requirement = isPrincipal ? 160 : 120;
	const activeStep = hours ? Math.floor(hours / 20) : 0;
	const { t } = useTranslation();

	// Function to format hours
	const formatHours = (hours) => {
		if (hours === undefined || hours === null) return "0";
		const roundedHours = Math.round(hours * 2) / 2; // Round to nearest 0.5
		return roundedHours % 1 === 0 ? roundedHours.toFixed(0) : roundedHours.toFixed(1);
	};

	return (
		<Card
			className="bg-white h-100"
		>
			<div className="d-flex justify-content-between align-items-center pb-2">
				<h3 className="h5 font-weight-bold">
					{t("pd-plan.pd-hours.title")}
				</h3>
			</div>
			<Typography variant="body1" component="p" className="pb-4">
				{
					issueDate && expiryDate ?
					(t('pd-plan.pd-hours.progress-message', { hours: formatHours(hours), requirement }))
					: (t('pd-plan.pd-hours.enter-info-message'))
				}
			</Typography>

			<Stepper
				style={{
					width: "110%",
					transform: "translateX(-5%)",
				}}
				step={activeStep}
				labels={labels}
			/>
		</Card>
	);
}

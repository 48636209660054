import AirtableTable from "../AirtableTable";
import { getSchoolYear } from "../../utils/time";

export class SessionTable extends AirtableTable {
  constructor(instance) {
    super(instance, "Sessions")

    this.fields = {
      "Session": "fldf74KQnGd9Hhi7z",
      "School(s)": "fldICwvVj9wP7oFl4",
      "Session Start Date/Time": "fldTxemUA68O9yTF0",
      "Length (Minutes)": "fldypmgRpZa1OJmbU",
      "Session Title": "fldBYhszqNCSRy6Ob",
      "Provider": "fldG5qq2KNt63GUIx",
      "Status": "fld4jT2epPcGjGCyh",
      "School Lead": "fldv5YQpUBcRdXsVN",
      "Teacher": "fld1tvpcGG6Rm3Tkp",
      // "WebEx Session": "fldvHXpHXc7K304tj",
      "Bridge Address / SIP URI": "fldIsBeZQjb5mY9xk",
      "Well-being Link": "fldIk2x6gUbnKxTkp",
      "Grade(s)": "fldQ11BY5Ma9EPaBN",
      "Students": "fldY7a0BRphXBuHR7",
      // "Recording Link": "flddH6UIV2zGFMMvn",
      "Cost": "fldVPmwc6VzLpAlIg",
      "Subject/Curriculum": "fld2F4sBO5v0vx9D9",
      "Type of Session": "fld3x19eRLfZ0xzz6",
      // "Estimated Time to Book Request": "fldhEQCptZT34Lo9Q",
      // "Technical/Quality Issues & Notes": "fldtORyNOteukXeVo",
      "Notes": "fldMDNcvmJkKnXbGn",
      // "Planned": "fldz0mPs2mGtC3fqO",
      // "Booked": "fldmZQE1bpcPRcSJT",
      // "Completed": "fldT1Lw7SyzNLyGt2",
      // "School P/T": "fld30q7GRPCV1kD2g",
      // "Provider Recording": "fldq93BX3Nuj2NcVX",
      // "Send Meeting Invite to:": "fldjIr4mGspio2gOH",
      // "Indigenous Provider?": "fldfXXkUtB37CHY5c",
      // "Resources Ordered": "fldFNCI24sDneYycm",
      // "Payments": "fldJa5q68oqygyXRI",
      "CILC?": "fldJamGu1gv0pshb3",
      // "Provider Country of Origin": "fldDbaKMzcTSSfsni",
      "Highlight Photos": "fldBmt42ldt4C5dZO",
      "Credit Cost": "fldI6jt5IkKBCEygw",
      "School Lead Text": "fldbqhqkVLiwG0Tj4",
      "School Lead Email": "fld47gr3qPqFu81Ol",
      "School Lead Phone": "fldFuItz0iuiC7MDW",
      "Provider Phone": "fld6UZvaB3CpUsJvO",
      "School Name Text": "fldIBo2jwg3LddfGt",
      // "School Lead Location": "fldC1b6yDnI6In2Wx",
      "Provider Name": "fld6y1FR85xWjuJlg",
      // "Provider Contact Name": "fldHumY8QRWcLQu5m",
      "Provider Email": "fldUErfcms1DqMoU2",
      "Teacher Name": "fldAHTyXY9f5Wg2vT",
      // "Rescheduled": "fldM5Wm43wrdoeFbP",
      // "Unfulfilled": "fldcghjxk3gOq8rNE",
      // "Cancelled": "fldV7aL2SCKFJjmxw",
      "Teacher Email": "fldNIllJLY2tYFnAb",
      "Teacher Phone": "fldaNhHaPV5vyLMp0",
      "Session Title Text": "fldi0DIJROqwy0IBz",
      "Alternative Date/Time": "fldctbeRsjXvdoIHI",
      "Rating": "fldJCj0oPJhOsmjSE",
      "Feedback": "fldpF9jkgjKlHi1By",
      "Team Rating": "fldUtf71ah0rSdoqK",
      "Team Feedback": "fldjyovjVJYMhdk5H",
      // "Standard Session Cost": "fldn8u4TbFfSQSImt",
      "Primary Subject Text": "fldVKwhFFwDCqMAkt",
      // "TIGUser": "fld02Dm3Vm83geB1V",
      // "Booked Date": "fld7lw0vMz7QVSku4",
      "Session Description": "fld5Vc14dFFZav35T",
      // "District": "fld93FRWrtXgDQI1v",
      // "EMT": "fldwXpEuP9P8vYF0Q",
      // "CFP": "fld4cl9dDcCJeOghl",
      // "School Funder": "flddt9rP2apG90Th2",
      "Language": "fldBALtp5jdr0MZim",
      // "School School Lead": "fld5dDf0Dppr10o4L",
      "Call Quality Rating": "fld5P2g6Z86iE2QzB",
      // "School Lead E-mail Text": "fldvENkIIvTvuoYZj",
      "Payment Type": "fldudGRDFZtRVoliJ",
      "School Community": "fldKaHN35nQKHaa8i",
      // "First Name": "fldpOVVNW88qKtPze",
      // "Provider Type": "fldd1DJ2xBVR0LdTq",
      // "Nice Date": "fldlGUgbXlGTshxAc",
      // "Provider E-mail Text": "fld8ericLoHEEMKYU",
      "Session Host(s)": "fldPIQiRtlZ22PLg6",
      // "Hold for Duplicating": "fldCjtEK5xQjEaruG",
      // "Provider Bio": "fld6PbeuqyD5Gf8lJ",
      // "PPPP": "fldq1vQVf64kfDUWM",
      // "Provider Address": "fldvAKtO3PYIqf2vx",
      // "Provider Payment Method": "fldZ954MJVmHhprmC",
      // "Provider Payment Type": "fld8d7IDE6qam4HtR",
      "SessionID": "fldgfpsMIqAQ909Yf",
      "Provider Completed Sessions": "fldwUtohB5xReKp5I",
      "Teacher Completed Sessions": "fldJPLtyrcqDYKtou",
      // "Teacher Completed This Year": "fldJPLtyrcqDYKtou",
      // "Provider HST Number": "fldRhRteX1zx64nLY",
      // "Provider HST Rate": "fldQO2d302aWJpqLj",
      // "Provider Invoice Name": "fldD9Iys19dhxS3bC",
      // "School Allows Ratings?": "fldvHArBgtgxHhMYU",
      // "Teacher Rating Optout?": "fldDw4skKIsybnx5c",
      "WebEx/Zoom Link": "fldc75uy5LBDFwDn8",
      // "Provider Auto-Notified": "fldSMhpt2rRZXlwGA",
      // "School Address": "fld6TLDN1YHmuRwaA",
      // "School Postal Code": "fldwX8Yw0jrMDcXlj",
      // "Provider Booking Response": "flduEIsjRCtXN0R2h",
      // "# of Sessions Linked": "fldVBsqsgtmeJoots",
      // "Hosted?": "fldiOJ2n99lYdlQ3e",
      "Host Phone": "fldrLlEUHzH3Vv15i",
      // "Month Year": "fldckldIMMgfMPzq8",
      "Session Host Text": "fld7J9DjAKUZ6OmGR",
      "Session Host Email": "fldvuL98KZUH2yn2Q",
      // "School District": "fldF9JsfBHo0cF9H9",
      // "Session Funder": "fldULa9z0CpE5vadj",
      "# Schools": "fldOBFX5OCNGmNU9G",
      // "Provider Rating Opt-out": "fldp9eEPxoiURMlXl",
      // "School Timezone": "fld9iqYOEa6HUkI2f",
      "Capacity (Premium Sessions)": "fld2BZWIfLFDb5nji",
      // "Registrations": "fld1qk7QNxHy501E6",
      // "Automatic Registration": "fldhdLUrfETpDohil",
      // "Self-Requested through Platform": "fldTxpM4Ey4ZqTxZ3",
      // "Append to Name": "fldT35Fl69DCJhO4M",
      // "Pools": "fldhzQp0L4LjIE0FJ",
      // "Feedback Follow-up": "fld3snNQm4lJzDqFM",
      // "First Lead Email": "fldyMZNyj7bzDZCUV",
      // "# NU Schools": "fldhuDyjvbfBgdxJI",
      "Handouts": "fldrNDGqGlTbh2kaP",
      // "Automation Processed": "fldXc3Ld6WmfvpQsV",
      // "Last Modified": "fldgzrrwviZlCK3no",
      // "Provider Recordings": "fldrkXSNJIclhmVx9",
      // "Tags": "fldZSrVvpSHs7LdPc",
      // "Time": "fldXq77rakWmW1Pvm",
      "Session Title Raw": "fldkhfZmUEKJBrNBf",
      // "Connection Text": "fldRna4nkdYIiqvXT",
      // "Who's Responsible?": "fld00s6T4E8WQA6vt",
      // "Lead Notified of Request": "fldrRayZL7a3p8fvJ",
      // "Rating Request Sent": "fldeM6IPaucU5bBlx",
      "Premium Registration Closed": "fldtxENPbR8pr3e4t",
      // "Date Changed": "fldGle4q2YYJOUqM1",
      // "CalendarID": "fldqItj5sew9OQq07",
      // "Status Changed": "fld5uMEkYSDsJCz7U",
      // "Session Provider Name": "fldTq84xYweWfM1Zs",
      // "Provider Name Mismatch": "flddwejWg0EV4KPww",
      "# Providers": "fldekRqonSPt7rJa1",
      // "Session Rating Opt-out": "fldP09AOrPoTxloCF",
      // "Time (Central)": "fldClu0tCcDJWYBiE",
      // "Automation Processed Time": "fldk9AQIxj0hObpdo",
      // "Time Changed after Automation Processed?": "fldI9uSegv1f12R4H",
      // "Created time": "fldB5nlvnDSx89Ku5",
      // "Provider Status": "fldA5CqPkPymERgQT",
      // "Recording Link Modified": "fldVlvK3DtRhHtAPy",
      // "Early Morning Warning": "fld9C2qlStYHUYL31",
      // "Link Changed": "fldQerXvbpZemuSmT",
      // "Provider Changed": "fldDZqvjJmUgNar1m",
      // "Host Changed": "fldutLcLidyWnWqhe",
      "# Teachers": "fldsSowDxEaOE7mI8",
      // "# Registrations": "fldPprKwEiz7baIUQ",
      // "CILC autobook": "fldQ3nSQjQZpauT2i",
      // "Lead Verified": "fldUo89dx3OiLaESU",
      // "School Connection Type": "fldtkPactCpL8jKCc",
      // "Provider Recording Forbidden": "fld1gHx5YJ0hAoyak",
      // "Session Recording Forbidden": "fld6xPPui2Gk8FIVO",
      // "TMS/Zoom Verified": "fldD5FvHKL0XCKBbX",
      // "Provider Invoice E-mail": "fldTkhS97yyeNyaAk",
      // "Presenter Added after Automation Processed": "fld89b3ndvuA4d1B8",
      "Presenter Name": "fldyzo9E71KUxSgXJ",
      // "Presenter E-mail": "fld8NWffHshPMYCnt",
      // "Presenter Added": "fldusYAHGakNop7dZ",
      // "Surveys": "fldubArUOoGOBVTHZ",
      "Supplies Required": "fldlVNNxtXDbKNevY",
      "School P/T Text": "fldKGLtYxMDOwy5Xc",
      // "Days Away": "fldwyaVXTXhxIUnH4",
      // "Alternative Date Nice": "flddVAniP5vnGQC2d",
      // "School (from School(s))": "fldczzHQNxkEQm6lO",
      // "Provider Advance Payment Required?": "fldtnXQQswd7fGhTo",
      // "Prepay": "fldp3pCoRyqSVIsA7",
      // "Provider Average Rating": "fldwrmMMRMzZvm6gz",
      "Provider Feedback": "fld93CVKEn7JGyTDk",
      // "Provider Secondary E-mail": "fldAuu5uTzU3riFKh",
      // "Invite Provider Secondary E-mail?": "fldnE2N37iv0tGYHr",
      // "Reviewed Rubric?": "fld4s9fgn8CSZQij7",
      // "Rubric Link": "fldg6E2bvjwB7ZeJu",
      // "Curriculum Links": "fldF3uRFDakBNdp8X",
      // "Provider Resource Calendar": "fldC0frGnpIiMYIJa",
      // "Calendar Link": "fldkUG61RYD2kKEjN",
      // "Provider Group(s)": "fld4WS0caHLZYZ1Tv",
      "Connection Type": "fldMAMwzXbatU6v2W",
      "Zoom Account": "fldVFWWAJKs8PEnkY",
      // "Meeting ID": "fldjWBixt7I9slPiz",
      // "Use PMI": "fldXiYg7oTSTQGfzF",
      // "Meeting Password": "fldP5MCcnaJPA1S3K",
      // "Resource Cost": "fld6iFNPnpmQN5YYZ",
      "Supplies Shipment Not Required": "fldd3JTJ5dpo85rzT",
      // "Feedback Spotlighted": "fldRAYuQPgiPMWOff",
      "Presenters": "fldFg32vnjmYqjZsp",
      // "Rating Changed": "fldp82ldAJnAT2bZs",
      // "Rating Requested": "fldv6v3GIwAOOgrz8",
      // "Rating Time": "fldEpf2FVnnEoNDdO",
      // "School Resource Calendar": "fld2XP2mtv0E9sCP8",
      // "First Lead Name": "fldN84UW8e5IAudH8",
      // "First School Name": "fldoSdiV6v5YeYYZY",
      // "Provider Currency": "fldUt7bOYO5YNQojK",
      // "Rubrics": "fld16OFu4378SYxJN",
      "Rubric Form Link": "fldnluEEjVVxLR38r",
      // "Rubric Assigned To": "flduWUtSCLTnt7BUQ",
      "Provider Internal ID": "fldQWM5gRoWwPrYMQ",
      // "Provider Best Practices": "fldovTQq2pC8Wys4j",
      // "# Supplies Required": "fldfkk6dMR363xQN8",
      // "Calendar Resync": "fldI3GNI7Bu11uL6s",
      // "School Lead Name Encoded": "fldEoxppdf7FkEGiU",
      // "Session Host Name Encoded": "fldw0iw3mPNFyi4RV",
      "Custom Session Title": "fld6pfzVLhl6TeRNU",
      // "Standard Session Cost Total": "fld0uqoKCJEznhm6J",
      // "Capture Review Link": "fldtyIkEoRAWh6Gwe",
      "Provider Session Record ID": "fld8G49yxS6l1T11a",
      // "Teacher Record ID": "fld65ed2G9sWKeBFx",
      "Flyer": "fldUewesT18FpjqaC",
      "Premium Registrations": "fldNvJEmr0sDkvN2M",
      // "GN Ticket ID": "fldP4aYOmckct6exS",
      // "Hide from Events": "fldypgTucHFL9iVWL",
      // "Premium No Per-School Limit": "fldcPikaekIc2I78k",
      // "Pre-session Notified": "flduE2Ahu41wjt0L1",
      // "GN Conference ID": "fld3Pa63fG9l7uePS",
      "Provider Autobook?": "fldy8FCKgjYH0RqFQ",
      // "Host Notification Preference": "fldRUGSRVRpseYhRP",
      // "Lead Notification Preference": "fldzWOqVGKRRxu1bQ",
      // "Host E-mail Text": "fldWHK8Oz6KDZhvj9",
      // "Link Changed after Automation Processed?": "fldceVdUbsNDXU6cE",
      // "Lookup Resource Tracking URL": "fldodnNpaf71aLy7d",
      "Resource Tracking URL": "fldyhPu3kjpWi3aUx",
      // "Zoom Resync": "fldV14N9Wh6VB2jQe",
      // "Record ID": "fldnruxvTeguPhwmW",
      "Resources Ship Date": "fldPG5wIrTdwgar8x",
      "Days since Created": "fldnA9Hi742DsgElA",
      // "Provider Session Rubrics": "fldqHpJaOf2naNH4R",
      // "CILC Requested": "fld6wnn85qEA7MIzp",
      // "TIGUser Text": "fldDksk2D02JIRfCk",
      // "Presenter Record ID": "fld9ZW2EDaUomt33J",
      "Provider Rating": "fldeTYYIJ2wjPDGEM",
      // "Keep Dial-ins When Duplicating": "fldyhuBsQhhKkc4tg",
      // "Coordinator notes": "fldR28tk2fP2robJa",
      // "EIA": "fld1dOFnBk9t74aBP",
      // "Rubric Photos": "fldCNCAbj7YSjxVcN",
      // "Is Science Session": "fldJpLfgFFeePcCWX",
      // "Is Literacy Session": "fldgOLGUJec2pqSyJ",
      // "Provider Rating Request Sent": "fldPUvemTlWyUdkuC",
      // "Provider Session Featured Reviews": "fldMmU2JZy4eoaWcy",
      // "GN Ticket Requested": "fldjJEjzOiox3S5Wf",
      // "# Schools (Indigenous Sessions)": "fldqYjMfGXMs501TB",
      // "School Cluster": "fldso6fzIu6QFMlc9",
      // "Status Update Speed": "fldhN1qUYxT8sSMvZ",
      // "Status Update Reminder Sent": "fldFxqhOWN6RgnNop",
      // "Provider Date Added": "fldHmUpOVQVLlgxgo",
      // "Provider Latitude": "fldMVC7VQfMCWZAZ6",
      // "Provider Longitude": "fldzQr4KWI128OD3b",
      // "School Latitude": "fldHu5pv3yERu3FtK",
      // "School Longitude": "fld3k1euPiiJIBnTH",
      "Teacher Call Quality Rating": "fldswdyHPV0BKolL4",
      "Session Welcome Map": "fldxoZxPAn8aoep9z",
      // "Minutes since Date Changed": "fldZWMMCR0aQcr2ys",
      // "School Lead Record ID": "fldAmWDNIbiD6GIHV",
      // "Session Host Record ID": "fld8EvddbvdUjHDZ8",
      // "# Rubrics": "fldZ6WiEul0yWrnnN",
      // "Rubrics Completed By": "fldmgGI3knTebfrni",
      // "Record Creator": "fldvdKnTCx3DegqFT",
      // "Teacher Name (from Teacher)": "fld9OBj1YOrJdrfU3",
      // "Session End Date/Time": "fldDRM4AYVpz3BCc3",
      "Recording Embed Link": "flddu26Yd0e34ae3p",
      // "CILC Payment Status": "fldMjQEyfnXc7e4nB",
      "CILC Request ID": "fld2xsykGVfTRAB6M",
      // "Notable Feedback": "fldoblsYwkMBn4Jb1",
      // "Notable Feedback Team": "fldk9Qk5SIQmKaevV",
      "School Dial-in": "flds5m3iWGTZOA3WX",
      // "Dependencies": "fldukUGp885SaiKxK",
      "School Photo": "fldIT7yDMnazPOdiF",
      // "Team Contact (from Provider)": "fldEpnBYNWi782uyN",
      "School Profile Link": "fldFevryo6K5Qv3bD",
      "Image/Photo": "fldRRT2K2WvYsCISb",
      // "Provider Reminders": "fld1tSmCZySEIyRts",
      // "Provider Reminded": "fldwNZLyIfDAEBkw3",
      // "School Join Date": "flduPXG7baGYSEuBm",
      // "Account Changed": "fldjezjxVsQbkqeQG",
      // "Account Changed after Automation Processed?": "fldIsSPb9o9EAELjB",
      "Survey Link": "fldMbzGu9hICsBvOD",
      // "Survey QR Code": "fldjWUR1cDsH69R04",
      // "Teacher Reminders": "fld8nv4iOaf4j1ui0",
      // "Teacher Reminded": "fld1hZOJUxggON2oQ",
      // "Meeting ID Mismatch": "fldADpMnScWi536qV",
      // "Support Type": "fldVwRII3NrUxLuQk",
      // "2023 Survey Request": "fldEeREr9AQeH9Xys",
      // "Internal Provider?": "fldlqkxgURUB3K3JL",
      // "Internal Provider": "fldgKVMFLJGrbTThW",
      // "District Text": "fldnRqTkt58BsYRgB",
      // "Rubric Land Acknowledgement": "fldKOl6SLNCkTrAt8",
      "Credits": "fldMLrhOSxWiWILGI",
      // "Indigenous Presenter?": "fldRyAxNVJKfdGoPS",
      "Indigenous?": "fldWD98cuTuMryhT0",
      "Minutes until Session": "fldWfZi93YQz7Z7sn",
      // "Credits Charged": "fldMvriwB2Md1qaVv",
      "Series": "fld1Qy4JKLa7iPivz",
      "Session Provider Name Raw": "fldicbNGJGGHLdiaG",
      // "Special Situations": "fldzdoTNgXeih5Oal",
      // "Provider Free Cancellation Within Minutes": "fldfHzalPpgZi4UBr",
      "PFCWM": "fldQlL64CST2aiZaV",
      // "Credit Cost Without Supplies": "fldS5PUsa4GX0hEdb",
      "CILC Request URL": "fldEXpV4gF5uwTPzz",
      "Multi-class Not Allowed": "fldt3Cs8zocnN2gF1",
      "School Autobook Status": "fldwr55pZYiIBm3Sd",
      "CILC Profile URL": "fldIOi8GT14c8RQjw",
      "CILC Booking URL": "fldK3JCIe7gm6kVpA",
      "AI Activities Link": "fldMerzXc7mLwb0lL",
      "Provider Timezone": "fld9Me25NtyXJgyJZ",
      // "Credits for Supplies": "fldTSc89EiQLYESL2",
      "Provider Special Instructions": "fldvrnhO2qdXm9JMC",
      // "Notify Lead of Provider Response": "fldF9q24sl39fEUGF",
      "Do Not Share Credits Automatically": "fldcGosobldZOsE1M",
      // "Teacher Changed": "fldST1Ka7CX39AHCQ",
      // "Request Notification Hold Override": "fld0ug9yuh5szQrQw",
      // "School Indigenous Group(s)": "fldIVZRJ2umMWm0qb",
      // "Provider Indigenous Group(s)": "fld6t8UI8BbPWm0wl",
      // "Credit Transaction": "fldHQ8ruzIm9YD5LK",
      "Cluster Event Restriction": "fldYBMcoSQfr5mFTM",
      // "Provider Notified of Request": "flducgoEhXNMRm0r6",
      // "Description Override": "fld0NGnCJeedGknyL",
      // "Wishlisted": "fld8ytYnNja3BiYUP",
      // "Band Operated School?": "fldEitXKgBBA8qjNH",
      // "Teacher Credit Balance": "fldlZ5A58iCDEBVoJ",
      // "Student Minutes": "fldIiUcRQsnCbFyCw",
      // "Session Collections": "fld5wsRjgLxVcIMPb",
      // "Manual Registration (Premium/Spotlight Only)": "fldRXYTR5LWNi9fVF",
      "Classroom Photos": "fldXzrzjRbFNc1OyI",
      "Cluster Names": "fld16U7gJ99TtZQAj",
      "Nice Date": "fldlGUgbXlGTshxAc",
      // "Scheduling Attempts": "fld8289vNSn2wtGkk",
      "Provider Pronouns Text": "fldSUXJhFFgjkhcbJ",
      "Coordinating Teacher": "fldwFScV6mEmdLzIM",
      "Fees Waived by Provider": "fldzvQw9MBYU0NdO3",
      "Session Host Notes": "fld0rKcU6uXsQXYnF",
      "Trigger Warning": "fldkuVu9blHLzw632",
      "Payment Invoice": "fldEgzYyFUZuNhYqx",
      "Standard Session Length": "fldkQZ9JHQ36vztwC",
    }
  }

  getSessions = (
    field,
    name,
    fId,
    status,
    isAdmin,
    isPast,
    isTeacherChecking /* # Teachers > 1 */
  ) => {
    var filterByFormula = null;
    var extraCondition = `NOT(FIND('Session Test', {Session Title})), {Session} != ERROR(), `
    if (!isAdmin) {
      if (field === 'School Lead') {
        extraCondition = extraCondition + `OR(FIND("${name}", {School Lead}), FIND("${name}", {Session Host(s)}), FIND("${name}", {School School Lead})), `;
      } else if (field === "Teacher") {
        extraCondition = extraCondition + `OR(FIND("${name}", {Teacher}), FIND("${name}", {Coordinating Teacher})), `;
      } else {
        extraCondition = extraCondition + `FIND("${name}", {${field}}), `;
      }
    }

    if (isTeacherChecking) {
      extraCondition = extraCondition + `{# Teachers} >= 1, `;
    }

    if (status === 'Completed') {
      if (field === 'Teacher') {
        filterByFormula = `AND(${extraCondition}{Session Start Date/Time} < NOW(), {Status} = '${status}', OR({Rating} = BLANK(), {Rating} = 0))`;
      } else if (field === 'School Lead') {
        filterByFormula = `AND(${extraCondition}{Session Start Date/Time} < NOW(), {Status} = '${status}', OR({Team Rating} = BLANK(), {Team Rating} = 0))`;
      }
    } else if (status === 'Booked') {
      if (isPast) {
        filterByFormula = `AND(${extraCondition}{Session Start Date/Time} <= NOW(), {Status} = '${status}')`;
      } else {
        filterByFormula = `AND(${extraCondition}OR({Status} = 'Booked',{Status} = 'Confirmed for Booking'), {Session Start Date/Time} >= DATEADD(NOW(), -1, 'hours'))`;
      }
    } else if (status === 'Requested') {
      filterByFormula = `AND(${extraCondition}{Status} = '${status}')`;
    }

    if (!filterByFormula) return [];

    return this.list({
      filterByFormula,
      sort: [
        { field: 'Session Start Date/Time', direction: status === 'Completed' ? 'desc' : 'asc' }
      ]
    }, (session) => {
      if (!isAdmin) {
        if (field === 'School Lead') {
          return (session["School Lead"] && session["School Lead"].indexOf(fId) >= 0) ||
            (session["Session Host(s)"] && session["Session Host(s)"].indexOf(fId) >= 0) ||
            (session["School School Lead"] && session["School School Lead"].indexOf(fId) >= 0)
        } else if (field === 'Teacher') {
          return (session["Teacher"] || []).indexOf(fId) >= 0 || (session["Coordinating Teacher"] || []).indexOf(fId) >= 0
        } else {
          return session[field].indexOf(fId) >= 0;
        }
      }

      return true;
    });
  }

  getTeamUpcomingSessions = (teamId, teamName, filter, isPast = false) => {
    const { filterSchool, filterKeyword, filterDate, filterCILC } = filter;
    const conditions = [
      `OR(FIND("${teamName}", {School Lead}), FIND("${teamName}", {Session Host(s)}), FIND("${teamName}", {School School Lead}))`
    ]

    if (filterSchool) {
      conditions.push(`FIND('${filterSchool.School}', {School(s)})`);
    }

    if (isPast) {
      conditions.push("{Session Start Date/Time} < NOW()");
      conditions.push("{Status} = 'Completed'")
      conditions.push("OR({Team Rating} = BLANK(), {Team Rating} = 0)")
    } else {
      conditions.push("{Status} = 'Booked'")
      conditions.push("{Session Start Date/Time} >= DATEADD(NOW(), -1, 'hours')")
    }

    if (filterKeyword) {
      const kWord = filterKeyword.trim().toUpperCase();

      const keywordConds = []
      if (kWord.indexOf(" ") > 0) {
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Session Title})), '${kWord}')`);
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Session Description})), '${kWord}')`);
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Provider})), '${kWord}')`);
      } else {

        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Session Title})), '(^| )${kWord}( |$)')`);
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Session Description})), '(^| )${kWord}( |$)')`);
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Provider})), '(^| )${kWord}( |$)')`);
      }

      conditions.push(`OR(${keywordConds.join(",")})`)
    }

    if (filterDate) {
      const dateConds = []
      const year = filterDate.getFullYear(),
        month = filterDate.getMonth() + 1,
        date = filterDate.getDate();

      dateConds.push(`YEAR({Session Start Date/Time}) = ${year}`);
      dateConds.push(`MONTH({Session Start Date/Time}) = ${month}`);
      dateConds.push(`DAY({Session Start Date/Time}) = ${date}`);

      conditions.push(`AND(${dateConds.join(",")})`)
    }

    if (filterCILC) {
      conditions.push(`{CILC?} = TRUE()`)
    }

    return this.list({
      filterByFormula: `AND(${conditions.join(",")})`,
      sort: [
        { field: 'Session Start Date/Time', direction: isPast ? 'desc' : 'asc' }
      ]
    }, (session) => [
      ...(session["School Lead"] || []),
      ...(session["Session Host(s)"] || []),
      ...(session["School School Lead"] || []),
    ].indexOf(teamId) >= 0);
  }

  getPastSessions = ({  // Not solved in same name issue with FIND formula
    type,
    name,
    status,
    pastType,
    isAdmin = false
  }, listener) => {
    const conditions = [
      "NOT(FIND('Session Test', {Session Title}))",
      "{Session} != ERROR()",
      `{Status} = '${status}'`,
      "{Session Start Date/Time} < NOW()"
    ];

    let ratingFieldName = '';
    if (type === 'Teacher') ratingFieldName = "Rating";
    else if (type === 'School Lead') ratingFieldName = "Team Rating";

    if (!isAdmin) {
      if (type === 'School Lead') {
        const subConditions = [
          `FIND("${name}", {School Lead})`,
          `FIND("${name}", {Session Host(s)})`,
          `FIND("${name}", {School School Lead})`
        ]
        conditions.push(`OR(${subConditions.join(",")})`)
      } else if (type === 'Teacher') {
        conditions.push(`OR(FIND("${name}", {Teacher}), FIND("${name}", {Coordinating Teacher}))`);
      } else {
        conditions.push(`FIND("${name}", {${type}})`);
      }
    }
    // if (type === 'Teacher') conditions.push("{# Teachers} <= 1");

    if (pastType === 'unpaid') {
      conditions.push("{Payments} = BLANK()");
      conditions.push("{CILC?} = 0")
      conditions.push("{Cost} > 0")
    } else if (pastType === 'rated') {
      if (ratingFieldName) {
        conditions.push(`{${ratingFieldName}} != BLANK()`);
        conditions.push(`{${ratingFieldName}} != 0`);
      }
    } else if (pastType === 'unrated') {
      if (ratingFieldName) {
        conditions.push(`{${ratingFieldName}} = BLANK()`);
        conditions.push(`{${ratingFieldName}} = 0`);
      }
    }

    return this.listItems({
      filterByFormula: `And(${conditions.join(",")})`,
      sort: [{ field: 'Session Start Date/Time', direction: 'desc' }],
      pageSize: 10
    }, listener);
  }

  getProviderUpcomingSessions = (pId, providerName) => {
    var filterByFormula = null;
    var extraCondition = `NOT(FIND('Session Test', {Session Title})), {Session} != ERROR(), `

    extraCondition = extraCondition + `FIND("${providerName}", {Provider}), `;

    filterByFormula = `AND(${extraCondition}OR({Status} = 'Booked', {Status} = 'Confirmed for Booking'), {Session Start Date/Time} >= DATEADD(NOW(), -1, 'hours'))`;

    return this.list({
      filterByFormula,
      sort: [{ field: 'Session Start Date/Time', direction: 'asc' }]
    }, (session) => (session["Provider"] || []).indexOf(pId) >= 0);
  }

  getStudentEvents = () => {
    return this.list({
      filterByFormula: `AND(
                Status = 'Booked',
                {Session Start Date/Time} >= NOW(),
                OR({Type of Session} = 'Premium Session', {Type of Session} = 'Spotlight Series'),
                {Premium Registration Closed} = FALSE(),
                {Hide from Events} = FALSE()
            )`,
      sort: [
        { field: 'Session Start Date/Time', direction: 'asc' }
      ]
    })
  }

  getProfessionalLearning = () => {
    return this.list({
      filterByFormula: `AND(
                Status = 'Booked',
                {Session Start Date/Time} >= NOW(),
                {Type of Session} = 'Teacher PD',
                {Premium No Per-School Limit} = TRUE(),
                {Hide from Events} = FALSE()
            )`,
      sort: [
        { field: 'Session Start Date/Time', direction: 'asc' }
      ]
    })
  }

  getPDWeekSessions = () => {
    return this.list({
      filterByFormula: `AND(FIND('25 NU PD Week', {Tags}))`,
      sort: [
        { field: 'Session Start Date/Time', direction: 'asc' }
      ]
    })
  }

  getPDWeekSessionsForTeacher = (tId, teacherName) => {
    const conditions = [
      "FIND('PD Week', {Tags})",
      `OR(FIND("${teacherName}", {Teacher}), FIND("${teacherName}", {Coordinating Teacher}))`
    ]

    return this.list({
      filterByFormula: `AND(${conditions.join(",")})`,
      sort: [
        { field: 'Session Start Date/Time', direction: 'desc' }
      ]
    }, (session) => ((session["Teacher"] || []).indexOf(tId) >= 0 || (session["Coordinating Teacher"] || []).indexOf(tId) >= 0))
  }

  getSessionsByTag = (tag) => {
    return this.list({
      filterByFormula: `AND(FIND('${tag}', {Tags}))`,
      sort: [
        { field: 'Session Start Date/Time', direction: 'asc' }
      ]
    })
  }

  getPendingSessionsForTeacher = (tId, teacherName) => {
    const conditions = [
      "NOT(FIND('Session Test', {Session Title}))",
      "{Session} != ERROR()",
      `OR(FIND("${teacherName}", {Teacher}), FIND("${teacherName}", {Coordinating Teacher}))`,
      "OR({Status} = 'Requested', {Status} = 'Wish List', {Status} = 'Pending Teacher Response', {Status} = 'Pending Provider Response', {Status} = 'In Planning')"
    ]
    return this.list({
      filterByFormula: `AND(${conditions.join(",")})`,
      sort: [{ field: 'Session Start Date/Time', direction: 'asc' }]
    }, (session) => ((session["Teacher"] || []).indexOf(tId) >= 0 || (session["Coordinating Teacher"] || []).indexOf(tId) >= 0))
  }

  getPendingSessionsForProvider = (pId, providerName) => {
    const conditions = [
      "NOT(FIND('Session Test', {Session Title}))",
      "{Session} != ERROR()",
      `FIND("${providerName}", {Provider})`,
      "OR({Status} = 'Requested', {Status} = 'Pending Provider Response', {Status} = 'Pending Teacher Response')",
      "{Session Start Date/Time} >= NOW()",
      "OR({School Autobook Status} = 'Immediate (No Lead input)', AND({School Autobook Status} = '48 Hours (Hold for Lead input)', {Self-Requested through Platform} = TRUE(), DATETIME_DIFF(NOW(), {Created time}, 'hours') > 48), {Self-Requested through Platform} = FALSE(), {Request Notification Hold Override} = TRUE())"
    ];
    const filterByFormula = `AND(${conditions.join(",")})`;

    return this.list({
      filterByFormula,
      sort: [{ field: 'Session Start Date/Time', direction: 'asc' }]
    }, (session) => (session["Provider"] || []).indexOf(pId) >= 0)
  }

  getPendingSessionsForTeam = (tId, teamName, sessionStatus, filter) => {
    const { filterSchool, filterKeyword, filterDate, filterCILC } = filter;
    const conditions = [
      "NOT(FIND('Session Test', {Session Title}))",
      "{Session} != ERROR()",
      `FIND("${teamName}", {School Lead})`,
      `{Status} = '${sessionStatus}'`
    ]

    if (filterSchool) {
      conditions.push(`FIND('${filterSchool.School}', {School(s)})`);
    }

    if (filterKeyword) {
      const kWord = filterKeyword.trim().toUpperCase();

      const keywordConds = []
      if (kWord.indexOf(" ") > 0) {
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Session Title})), '${kWord}')`);
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Session Description})), '${kWord}')`);
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Provider})), '${kWord}')`);
      } else {

        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Session Title})), '(^| )${kWord}( |$)')`);
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Session Description})), '(^| )${kWord}( |$)')`);
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Provider})), '(^| )${kWord}( |$)')`);
      }

      conditions.push(`OR(${keywordConds.join(",")})`)
    }

    if (filterDate) {
      const dateConds = []
      const year = filterDate.getFullYear(),
        month = filterDate.getMonth() + 1,
        date = filterDate.getDate();

      dateConds.push(`YEAR({Session Start Date/Time}) = ${year}`);
      dateConds.push(`MONTH({Session Start Date/Time}) = ${month}`);
      dateConds.push(`DAY({Session Start Date/Time}) = ${date}`);

      conditions.push(`AND(${dateConds.join(",")})`)
    }

    if (filterCILC) {
      conditions.push(`{CILC?} = TRUE()`)
    }

    return this.list({
      filterByFormula: `AND(${conditions.join(",")})`,
      sort: [{ field: 'Session Start Date/Time', direction: 'asc' }]
    }, (session) => (session["School Lead"] || []).indexOf(tId) >= 0)
  }

  getPendingSessionsForAdmin = (filter) => {
    const { filterSchool, filterKeyword, filterDate, filterCILC } = filter;
    const conditions = [
      "NOT(FIND('Session Test', {Session Title}))",
      "{Session} != ERROR()",
      "OR({Status} = 'Requested', {Status} = 'Pending Provider Response', {Status} = 'In Planning')"
    ]

    if (filterSchool) {
      conditions.push(`FIND('${filterSchool.School}', {School(s)})`);
    }

    if (filterKeyword) {
      const kWord = filterKeyword.trim().toUpperCase();

      const keywordConds = []
      if (kWord.indexOf(" ") > 0) {
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Session Title})), '${kWord}')`);
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Session Description})), '${kWord}')`);
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Provider})), '${kWord}')`);
      } else {

        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Session Title})), '(^| )${kWord}( |$)')`);
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Session Description})), '(^| )${kWord}( |$)')`);
        keywordConds.push(`REGEX_MATCH(UPPER(ARRAYJOIN({Provider})), '(^| )${kWord}( |$)')`);
      }

      conditions.push(`OR(${keywordConds.join(",")})`)
    }

    if (filterDate) {
      const dateConds = []
      const year = filterDate.getFullYear(),
        month = filterDate.getMonth() + 1,
        date = filterDate.getDate();

      dateConds.push(`YEAR({Session Start Date/Time}) = ${year}`);
      dateConds.push(`MONTH({Session Start Date/Time}) = ${month}`);
      dateConds.push(`DAY({Session Start Date/Time}) = ${date}`);

      conditions.push(`AND(${dateConds.join(",")})`)
    }

    if (filterCILC) {
      conditions.push(`{CILC?} = TRUE()`)
    }

    return this.list({
      filterByFormula: `AND(${conditions.join(",")})`,
      sort: [{ field: 'Session Start Date/Time', direction: 'asc' }]
    })
  }

  searchSessions = (pId, providerName, search = "", states = []) => {
    var searchCondition = "", stateCondition = "", providerCondition, filterByFormula = "";
    if (search && search.trim()) {
      const kWord = search.trim().toUpperCase();

      if (kWord.indexOf(" ") > 0) {
        searchCondition = "OR(";
        searchCondition += `REGEX_MATCH(UPPER(ARRAYJOIN({Session Title})), '${kWord}')`;
        searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({Session Description})), '${kWord}')`;
        searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({School Lead})), '${kWord}')`;
        searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({School(s)})), '${kWord}')`;
        searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({Teacher})), '${kWord}')`;
        searchCondition += ")"
      } else {
        searchCondition = "OR(";
        searchCondition += `REGEX_MATCH(UPPER(ARRAYJOIN({Session Title})), '(^| )${kWord}( |$)')`;
        searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({Session Description})), '(^| )${kWord}( |$)')`;
        searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({School Lead})), '(^| )${kWord}( |$)')`;
        searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({School(s)})), '(^| )${kWord}( |$)')`;
        searchCondition += `, REGEX_MATCH(UPPER(ARRAYJOIN({Teacher})), '(^| )${kWord}( |$)')`;
        searchCondition += ")"
      }
    }

    if (states && states.length) {
      states.forEach((state, i) => {
        if (i > 0) stateCondition += ", ";
        stateCondition += `{Status} = '${state}'`;
      })

      if (states.length > 1) stateCondition = `OR(${stateCondition})`;
    }

    providerCondition = `FIND("${providerName}", {Provider})`;

    if (searchCondition || stateCondition) {
      filterByFormula = `AND(${providerCondition}`;
      if (searchCondition) filterByFormula += `, ${searchCondition}`;
      if (stateCondition) filterByFormula += `, ${stateCondition}`;
      filterByFormula += ')';
    } else filterByFormula = providerCondition;

    return this.list({
      filterByFormula,
      sort: [
        { field: 'Session Start Date/Time', direction: 'desc' }
      ]
    }, (session) => (session["Provider"] || []).indexOf(pId) >= 0)
  }

  getAllUpcomingInTeacherSchools = (teacherId, schoolIds, schoolNames) => {
    const schoolCons = schoolNames.map(school => `FIND("${school}", {School(s)})`);
    const conditions = [
      "{Status} = 'Booked'",
      "{Session Start Date/Time} > NOW()",
      `OR(${schoolCons.join(",")})`
    ]
    return this.list({
      filterByFormula: `AND(${conditions.join(",")})`,
      sort: [{ field: 'Session Start Date/Time', direction: 'asc' }]
    }, (session) => {
      if ((session["Teacher"] || []).indexOf(teacherId) >= 0) return false;

      let forSchool = false;
      for (let i = 0; i < schoolIds.length; i++) {
        if ((session["School(s)"] || []).indexOf(schoolIds[i]) >= 0) forSchool = true;
      }
      return forSchool;
    })
  }

  getAllUpcomingInSameSchool = (teacherId, schoolId, schoolName) => {
    return this.list({
      filterByFormula: `AND(
        FIND("${schoolName}", {School(s)}),
        {Status} = 'Booked',
        {Session Start Date/Time} > NOW()
      )`,
      sort: [{ field: 'Session Start Date/Time', direction: 'asc' }]
    }, (session) => {
      return (
        (session["School(s)"] || []).indexOf(schoolId) >= 0 &&
        (session["Teacher"] || []).indexOf(teacherId) < 0
      )
    })
  }

  getAllSessionsInYearInSchool = (schoolId, schoolName) => {
    const { start, end } = getSchoolYear();
    return this.list({
      filterByFormula: `AND(
                FIND("${schoolName}", {School(s)}),
                OR({status} = 'Completed', {status} = 'Booked', {status} = 'Requested'),
                {Session} != ERROR(),
                IS_BEFORE({Session Start Date/Time},'${end}'),
                IS_AFTER({Session Start Date/Time},'${start}')
            )`,
      sort: [{ field: 'Session Start Date/Time', direction: 'asc' }]
    }, (session) => (session["School(s)"] || []).indexOf(schoolId) >= 0)
  }

  getAllSessionsInYearInCluster = (clusterId, clusterName) => {
    const { start, end } = getSchoolYear();
    return this.list({
      filterByFormula: `AND(
                FIND("${clusterName}", {Cluster Names}),
                OR({status} = 'Completed', {status} = 'Booked', {status} = 'Requested'),
                {Session} != ERROR(),
                IS_BEFORE({Session Start Date/Time},'${end}'),
                IS_AFTER({Session Start Date/Time},'${start}')
            )`,
      sort: [{ field: 'Session Start Date/Time', direction: 'asc' }]
    }, (session) => (session["Cluster Names"] || []).indexOf(clusterName) >= 0)
  }

  //New Query
  getAllSessionsInYear = (
    field,
    name,
    userId,
    isTeacherChecking /* # Teachers > 1 */
  ) => {
    var formula = "";
    const { start, end } = getSchoolYear();
    if (isTeacherChecking) {
      formula = `AND(
                {# Teachers} <= 1,
                NOT(FIND('Session Test', {Session Title})),
                OR({status} = 'Completed', {status} = 'Booked', {status} = 'Requested'),
                IS_BEFORE({Session Start Date/Time},'${end}'),
                IS_AFTER({Session Start Date/Time},'${start}'),
                {Session} != ERROR(),
                FIND("${name}", {${field}})
            )`
    } else {
      formula = `AND(
                NOT(FIND('Session Test', {Session Title})),
                {Session} != ERROR(),
                FIND("${name}", {${field}}),
                OR({status} = 'Completed', {status} = 'Booked', {status} = 'Requested'),
                IS_BEFORE({Session Start Date/Time},'${end}'),
                IS_AFTER({Session Start Date/Time},'${start}')
            )`
    }

    return this.list({
      filterByFormula: formula,
      sort: [
        { field: 'Session Start Date/Time', direction: 'asc' }
      ]
    }, session => (session[field] || []).indexOf(userId) >= 0);
  }

  /**
   *
   * @param {Date} start
   * @param {Date} end
   * @param {string} userType
   * @param {string} userName
   */
  getSessionsInSpecificPeriod = (start, mins, userType, userName, userId) => {
    let fieldName = '';
    if (userType === 'Provider') fieldName = 'Provider'
    else if (userType === 'Teacher') fieldName = 'Teacher'
    else if (userType === 'Team') fieldName = 'School Lead';

    return this.list({
      filterByFormula: `AND(
        FIND('Cancelled', {Status}) = 0,
        FIND("${userName}", {${fieldName}}),
        DATETIME_DIFF('${start.toISOString()}', {Session Start Date/Time}, 'minutes') >= 0,
        DATETIME_DIFF('${start.toISOString()}', {Session Start Date/Time}, 'minutes') < ${mins}
      )`,
      sort: [
        { field: 'Session Start Date/Time', direction: 'asc' }
      ]
    }, (session) => (session[fieldName] || []).indexOf(userId) >= 0);
  }

  getSessionsForSchoolInSpecificPeriod = (start, mins, school) => {
    return this.list({
      filterByFormula: `AND(
        FIND('Cancelled', {Status}) = 0,
        FIND('${school}', {School(s)}),
        DATETIME_DIFF('${start.toISOString()}', {Session Start Date/Time}, 'minutes') >= 0,
        DATETIME_DIFF('${start.toISOString()}', {Session Start Date/Time}, 'minutes') < ${mins}
      )`
    });
  }

  /**
   *
   * @param {string} teacherName
   * @param {string} clusterName
   */
  getAllUpcomingInSameCluster = (teacherId, clusterName) => {
    return this.list({
      filterByFormula: `AND(
                FIND("${clusterName}", {School Cluster}),
                {School Name Text} != 'Sioux Mountain Public School',
                {Status} = 'Booked',
                {Session Start Date/Time} > NOW()
            )`,
      sort: [{ field: 'Session Start Date/Time', direction: 'asc' }]
    }, session => (session["Teacher"] || []).indexOf(teacherId) < 0)
  }

  /**
   *
   * @param {Array<string>} ids
   */
  loadPresenterSessions = (ids) => {
    return new Promise(async (resolve, reject) => {
      const sessions = await this.listByIds(ids);

      const upcoming = [], past = [], other = [];
      sessions.forEach(session => {
        if (session["Status"] === "Booked") upcoming.push(session);
        else if (session["Status"] === "Completed") past.push(session);
        else other.push(session);
      });
      past.sort((a, b) => {
        return b["Session Start Date/Time"].localeCompare(a["Session Start Date/Time"])
      })

      resolve({ upcoming, past, other });
    });
  }

  /**
   *
   * @param {string} seriesName
   */
  getSeriesSessions = (seriesName) => {
    return this.list({
      filterByFormula: `OR(
                AND(
                    {Session Start Date/Time} >= NOW(),
                    {Type of Session} = 'Teacher PD',
                    {Premium No Per-School Limit} = TRUE(),
                    {Hide from Events} = FALSE(),
                    {Series} = "${seriesName}"
                ),
                AND(
                    {Session Start Date/Time} >= NOW(),
                    {Type of Session} = 'Premium Session',
                    {Premium Registration Closed} = FALSE(),
                    {Hide from Events} = FALSE(),
                    {Series} = "${seriesName}"
                )
            )`,
      sort: [
        { field: 'Session Start Date/Time', direction: 'asc' }
      ]
    })
  }

  getTeamSessionsOnDay = (teamId, teamName, sessionDate) => {
    const conds = [];
    const data = new Date(sessionDate);
    conds.push(`FIND("${teamName}", {Session Host(s)})`);
    conds.push(`YEAR({Session Start Date/Time}) = ${data.getFullYear()}`)
    conds.push(`MONTH({Session Start Date/Time}) = ${data.getMonth() + 1}`)
    conds.push(`DAY({Session Start Date/Time}) = ${data.getDate()}`);

    return this.list({
      filterByFormula: `AND(${conds.join(",")})`
    }, (session) => (session["Session Host(s)"] || []).indexOf(teamId) >= 0)
  }

  /**
   *
   * @param {Array<Array<number>>} availability
   */
  getAvailableSessions = (availability, timeZone) => {
    const conditions = [
      "{Status} = 'Booked'",
      "{Session Host(s)} = BLANK()",
      "{Session Start Date/Time} > NOW()",
      "{Internal Provider} = 'false'",
      "{Length (Minutes)} > 0"
    ]

    const timeConditions = [];
    availability.forEach((da, wd) => {
      if (da.length === 0) return;

      const dayAvailability = [];
      let subArray = [da[0]];
      for (let i = 1; i < da.length; i++) {
        if (da[i] - subArray[subArray.length - 1] === 1) {
          subArray.push(da[i])
        } else {
          dayAvailability.push([
            subArray[0],
            subArray[subArray.length - 1] + 1
          ])
          subArray = [da[i]];
        }
      }
      dayAvailability.push([
        subArray[0],
        subArray[subArray.length - 1] + 1
      ])

      const dayConditions = [];
      dayAvailability.forEach(dd => {
        const startTime = `${dd[0] >= 10 ? '' : '0'}${dd[0]}:00`;
        const endTime = `${dd[1] >= 10 ? '' : '0'}${dd[1]}:00`;
        dayConditions.push(`AND(
          DATETIME_PARSE(
            DATETIME_FORMAT(
              SET_TIMEZONE({Session Start Date/Time}, '${timeZone}'),
              'HH:mm'
            ),
            'HH:mm'
          ) >= DATETIME_PARSE('${startTime}', 'HH:mm'),
          DATETIME_PARSE(
            DATETIME_FORMAT(
              SET_TIMEZONE(DATEADD({Session Start Date/Time}, {Length (Minutes)}, 'minutes'), '${timeZone}'),
              'HH:mm'
            ),
            'HH:mm'
          ) <= DATETIME_PARSE('${endTime}', 'HH:mm')
        )`)
      })

      timeConditions.push(`AND(
        WEEKDAY({Session Start Date/Time}) = ${(wd + 1) % 7},
        OR(${dayConditions.join(",")})
      )`)
    })

    conditions.push(`OR(${timeConditions.join(",")})`);
    return this.list({
      filterByFormula: `AND(${conditions.join(",")})`,
      sort: [{ field: 'Session Start Date/Time', direction: 'asc' }]
    })
  }
}

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ProviderInfo from './Provider';
import PresenterInfo from './presenter';
import "./styles.scss";

const Screen = () => {
  const { userType } = useSelector(state => state.appInfo);

  useEffect(() => {
    window.document.title = "My Info - Connected North"
  }, [])

  if (userType === 'Provider') return <ProviderInfo />
  if (userType === 'Presenter') return <PresenterInfo />
  return null;
}

export default Screen;
import SupabaseTable from '../SupabaseTable';

export class FormResponsesTable extends SupabaseTable {

  listAllBySchoolId = async (schoolId) => {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select('*')
        .contains('school_ids', [schoolId])
        .order('created_at', { ascending: false });

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }

  selectByFormIdAndUserId = async (formId, userId) => {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select('*')
        .eq('form_id', formId)
        .eq('user_id', userId)
        .single();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }

  selectLatestByFormIdAndUserId = async (formId, userId) => {
    try {
      const { data, error } = await this.supabase
        .from(this.tableName)
        .select('*')
        .eq('form_id', formId)
        .eq('user_id', userId)
        .order('created_at', { ascending: false })
        .single();

      if (error) {
        throw new Error(error.message);
      }

      return data;
    } catch (error) {
      throw error;
    }
  }
}
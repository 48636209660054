import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAllocation } from "../hooks";
import { CircularProgress } from "@mui/material";
import TeacherSessionsInSchoolModal from "../../modals/TeacherSessionsInSchool";
import { getIsPDPlanUser } from "../../PDPlan/util/get-is-pd-plan-user";
import Teacher from "./Teacher";

/**
 * @typedef {Object} Props
 * @property {boolean} props.allocate
 *
 * @param {Props & import("../hooks/useTeachers").UseTeacherProps} props
 * @returns {React.JSX}
 */
export function TeacherList({
	allocate,
	onAllocateOff,
	loading,
	teachers,
	leaders,
	removeTeacher,
	editTeacher
}) {
	const { userType, userInfo } = useSelector((state) => state.appInfo);
	const { t } = useTranslation();
	// Separate sort states for leaders and teachers
	const [teacherSortKey, setTeacherSortKey] = useState("name");
	const [teacherSortOrder, setTeacherSortOrder] = useState("asc");
	const [leaderSortKey, setLeaderSortKey] = useState("name");
	const [leaderSortOrder, setLeaderSortOrder] = useState("asc");
	const {
		allocatedCredits,
		handleAllocation,
		credit,
		loading: submitLoading,
		handleSubmit,
		error,
	} = useAllocation(teachers, onAllocateOff);
	const [activeTeacherSessionsInfo, openTeacherSessionsModal] = useState(null);

	// Separate handlers for teacher and leader sorting
	const handleTeacherSort = (key) => {
		const isAsc = teacherSortKey === key && teacherSortOrder === "asc";
		setTeacherSortOrder(isAsc ? "desc" : "asc");
		setTeacherSortKey(key);
	};

	const handleLeaderSort = (key) => {
		const isAsc = leaderSortKey === key && leaderSortOrder === "asc";
		setLeaderSortOrder(isAsc ? "desc" : "asc");
		setLeaderSortKey(key);
	};

	// Helper function to compare different data types
	const compareValues = useCallback((key, order = "asc") => {
		return (a, b) => {
			if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
				// property doesn't exist on either object
				return 0;
			}

			const varA =
				typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
			const varB =
				typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

			let comparison = 0;
			if (varA > varB) {
				comparison = 1;
			} else if (varA < varB) {
				comparison = -1;
			}
			return order === "desc" ? comparison * -1 : comparison;
		};
	}, []);

	// Function to sort teachers, memoized with useCallback
	const sortTeachers = useCallback((teachers, key, order) => {
		return [...teachers].sort(compareValues(key, order));
	}, [compareValues]);
	// Separate sorted lists for leaders and teachers
	const [sortedTeachers, setSortedTeachers] = useState(() => 
		sortTeachers(teachers, teacherSortKey, teacherSortOrder)
	);
	const [sortedLeaders, setSortedLeaders] = useState(() => 
		sortTeachers(leaders, leaderSortKey, leaderSortOrder)
	);

	// Separate useEffects for sorting
	useEffect(() => {
		setSortedTeachers(sortTeachers(teachers, teacherSortKey, teacherSortOrder));
	}, [teachers, teacherSortKey, teacherSortOrder, sortTeachers]);

	useEffect(() => {
		setSortedLeaders(sortTeachers(leaders, leaderSortKey, leaderSortOrder));
	}, [leaders, leaderSortKey, leaderSortOrder, sortTeachers]);

	const getTeacherNo = (sortedTeacherNo) => {
		const teacher = sortedTeachers[sortedTeacherNo];
		const teacherIndex = teachers.findIndex(t => t.id === teacher.id);
		return teacherIndex
	}

	const renderLeaderTable = () => (
		<div className="tableRow">
			<h4
				className="heading"
				onClick={() => handleLeaderSort("name")}
			>
				School Leaders
			</h4>
			{getIsPDPlanUser(userInfo) && (
				<>
					<h4 className="heading">
						PD Plan
					</h4>
					<h4 className="heading">
						PD Hours
					</h4>
				</>
			)}
			{userType === 'Team' && userInfo.Administrator && false && (
				<h4
					className="heading"
					onClick={() => handleLeaderSort("daysago")}
				>
					Last E-mailed
				</h4>
			)}
			<h4
				className="heading"
				onClick={() => handleLeaderSort("upcomingSessions")}
			>
				{t("principal.teachers.session-upcoming")}
			</h4>
			<h4
				className="heading"
				onClick={() => handleLeaderSort("completedSessions")}
			>
				{t("principal.teachers.session-completed")}
			</h4>
			<h4
				className="heading"
				onClick={() => handleLeaderSort("percentIndigenous")}
			>
				{t("principal.teachers.percent-indigenous")}
			</h4>
			<h4
				className="heading"
				onClick={() => handleLeaderSort("credits")}
			>
				{t("principal.teachers.credit")}
			</h4>
			<Allocate on={allocate}>
				<h4 className="heading allocateCell">
					{t("principal.teachers.allocate")}
				</h4>
			</Allocate>
		</div>
	);

	const renderTeacherTable = () => (
		<div className="tableRow">
			<h4
				className="heading"
				onClick={() => handleTeacherSort("name")}
			>
				{t("principal.teachers.teacher-name")}
			</h4>
			{getIsPDPlanUser(userInfo) && (
				<>
					<h4 className="heading">
						PD Plan
					</h4>
					<h4 className="heading">
						PD Hours
					</h4>
				</>
			)}
			{userType === 'Team' && userInfo.Administrator && false && (
				<h4
					className="heading"
					onClick={() => handleTeacherSort("daysago")}
				>
					Last E-mailed
				</h4>
			)}
			<h4
				className="heading"
				onClick={() => handleTeacherSort("upcomingSessions")}
			>
				{t("principal.teachers.session-upcoming")}
			</h4>
			<h4
				className="heading"
				onClick={() => handleTeacherSort("completedSessions")}
			>
				{t("principal.teachers.session-completed")}
			</h4>
			<h4
				className="heading"
				onClick={() => handleTeacherSort("percentIndigenous")}
			>
				{t("principal.teachers.percent-indigenous")}
			</h4>
			<h4
				className="heading"
				onClick={() => handleTeacherSort("credits")}
			>
				{t("principal.teachers.credit")}
			</h4>
			<Allocate on={allocate}>
				<h4 className="heading allocateCell">
					{t("principal.teachers.allocate")}
				</h4>
			</Allocate>
		</div>
	);

	return (
		<>
			{leaders && leaders.length > 0 && (
				<section className="school-leaders-section">
					{renderLeaderTable()}
					<ul className="principal-teacher-list">
						{sortedLeaders.map((tt, i) => (
							<Teacher
								key={i}
								teacherInfo={tt}
								even={i % 2 === 0}
								openTeacherSessionsModal={openTeacherSessionsModal}
								allocate={allocate}
								handleAllocation={handleAllocation}
								allocatedCredits={allocatedCredits}
								teacherNo={getTeacherNo(i)}
								removeTeacher={removeTeacher}
								editTeacher={editTeacher}
							/>
						))}
					</ul>
				</section>
			)}

			<section className="teachers-section">
				{renderTeacherTable()}
				{loading ? (
					<div className="loadingContainer">
						<CircularProgress />
					</div>
				) : (
					<ul className="principal-teacher-list">
						{sortedTeachers.map((tt, i) => (
							<Teacher
								key={i}
								teacherInfo={tt}
								even={i % 2 === 0}
								openTeacherSessionsModal={openTeacherSessionsModal}
								allocate={allocate}
								handleAllocation={handleAllocation}
								allocatedCredits={allocatedCredits}
								teacherNo={getTeacherNo(i)}
								removeTeacher={removeTeacher}
								editTeacher={editTeacher}
							/>
						))}
					</ul>
				)}
			</section>

			{((userType === 'Teacher' || userType === 'Team') && !!activeTeacherSessionsInfo) && (
				<TeacherSessionsInSchoolModal
					teacherInfo={activeTeacherSessionsInfo.teacherInfo}
					type={activeTeacherSessionsInfo.sessionsType}
					onToggle={() => openTeacherSessionsModal(null)}
				/>
			)}

			<Allocate on={allocate}>
				<section className="p-action">
					<h3>
						{t("principal.teachers.credit-left")}
						<span>{credit}</span>
					</h3>
					<div>
						<button
							className="btn btn-outline-primary"
							onClick={onAllocateOff}
						>
							{t("principal.teachers.cancel")}
						</button>
						<button
							className="btn btn-primary"
							onClick={handleSubmit}
							disabled={submitLoading}
						>
							{submitLoading ? (
								<>
									{t("principal.teachers.loading")}
									<span>
										<CircularProgress
											size="1em"
											sx={{
												color: "white",
												position: "relative",
												top: 4,
												marginLeft: "10px",
											}}
										/>
									</span>
								</>
							) : (
								t("principal.teachers.save")
							)}
						</button>
					</div>

					{error ? (
						<div>
							<p>{error}</p>
						</div>
					) : (
						<></>
					)}
				</section>
			</Allocate>
		</>
	);
}

/**
 * @param {Object} props
 * @param {boolean} props.on
 * @param {React.ReactNode} props.children
 */
function Allocate({ on, children }) {
	return on ? <>{children}</> : <></>;
}

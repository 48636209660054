import { useDispatch, useSelector } from 'react-redux';
import airtable from '../../../airtables';
import { setGroupTeachers } from '../../../redux/actions';
import { useEffect, useState } from 'react';
import { distinctArray, hasCommonItems } from '../../../utils/array';
import { sendToTeacher } from '../../../libs/apis';
import { toast } from 'react-toastify';
import { CurrentGradeLevels } from '../../../utils/constants';

const useHook = (data, type) => {
  const {
    userInfo,
    userType,
    groupTeachers: {
      loaded: teachersLoaded,
      teachers: groupTeachers
    },
    regionsMap,
    appLocale
  } = useSelector(state => state.appInfo);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [regions, setRegions] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectableTeachers, setSelectableTeachers] = useState([]);

  const [matchedTeachers, setMatchedTeachers] = useState([]);
  const [otherTeachers, setOtherTeachers] = useState([]);

  const [selectedTeachers, setSelectedTeachers] = useState([]);

  const [message, setMessage] = useState("");
  const [ccEmail, setCcEmail] = useState(false);

  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);
  const [selectedGrades, setSelectedGrades] = useState([]);

  const [processing, setProcessing] = useState(false);

  const [allGrades, setAllGrades] = useState([]);

  useEffect(() => {
    if (!data || !type) return;

    let gs = [], ss = [];
    if (type === 'Session') {
      gs = data["Grade(s)"] || [];
      ss = data["Subject"] || []
    } else if (type === 'Event') {
      gs = data["Grade(s)"] || [];
      ss = data["Subject/Curriculum"] || [];
    } else if (type === 'Collection') {
      gs = data["Grades"] || [];
      ss = data["Subjects Covered"] || [];
    } else if (type === "Provider") {
      gs = data["Unique Session Grade(s)"] || [];
      ss = data["Subject/Curriculum"] || [];
    }

    const orderGrades = (grades) => {
      const specialGrades = ['Administrator', 'JK', 'K'];
      const numericGrades = grades.filter(g => !isNaN(parseInt(g))).sort((a, b) => parseInt(a) - parseInt(b));
      const otherGrades = grades.filter(g => !specialGrades.includes(g) && isNaN(parseInt(g)));

      return [
        ...specialGrades.filter(g => grades.includes(g)),
        ...numericGrades,
        ...otherGrades
      ];
    };

    setGrades(orderGrades(distinctArray(gs)));
    setSubjects(distinctArray(ss));
    setAllGrades(orderGrades(distinctArray(CurrentGradeLevels)));
  }, [data, type]);

  useEffect(() => {
    if (!teachersLoaded) {
      if (userType === 'Team') {
        airtable.teachers.getTeachersForTeam(userInfo["Email"]).then(ts => {
          dispatch(setGroupTeachers({
            loaded: true,
            teachers: ts
          }));
        })
      } else if (userType === 'Teacher' && userInfo["Cluster Leadership"]) {
        airtable.teachers.getClusterTeachers(userInfo["Cluster Lead Text"][0]).then(ts => {
          dispatch(setGroupTeachers({
            loaded: true,
            teachers: ts
          }));
        })
      } else if (userType === 'Teacher' && userInfo["In-school Coordinator"]) {
        airtable.teachers.getSchoolTeachers(userInfo["School Name Text"][0]).then(ts => {
          dispatch(setGroupTeachers({
            loaded: true,
            teachers: ts
          }));
        })
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedTeachers([]);
    if (!selectableTeachers || !selectableTeachers.length) {
      setMatchedTeachers([]);
      setOtherTeachers([]);
      return;
    }

    const mts = [], ots = [];
    selectableTeachers.forEach(teacher => {
      if (isMatchedTeacher(teacher)) {
        mts.push(teacher);
      } else {
        ots.push(teacher);
      }
    });
    setMatchedTeachers([...mts]);
    setOtherTeachers([...ots]);
    setLoading(false);
  }, [selectableTeachers, subjects, grades]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const rIds = groupTeachers.map(gt => gt["School PT"] ? gt["School PT"][0] : "").filter(Boolean)
    const regionIds = distinctArray(rIds);
    const rs = regionIds.map(rId => regionsMap[rId]);
    rs.sort((a, b) => a["Long Name"].localeCompare(b["Long Name"]));
    setRegions(rs);
  }, [groupTeachers, regionsMap])

  useEffect(() => {
    let filteredTeachers = [...groupTeachers];

    if (selectedRegions.length > 0) {
      const regionNames = selectedRegions.map(region => region["Name"]);
      filteredTeachers = filteredTeachers.filter(teacher => regionNames.indexOf(teacher["School P/T Text"]) >= 0);
    }

    if (selectedGrades.length > 0) {
      filteredTeachers = filteredTeachers.filter(teacher => 
        hasCommonItems(teacher["Grade(s) Taught"], selectedGrades)
      );
    }

    setSelectableTeachers(filteredTeachers);
  }, [selectedRegions, selectedGrades, groupTeachers]);

  const isMatchedTeacher = (teacher) => {
    const hasMatchingSubject = hasCommonItems(teacher["Subject(s) Taught"], subjects);
    const hasMatchingGrade = hasCommonItems(teacher["Grade(s) Taught"], grades);
    return hasMatchingSubject && hasMatchingGrade;
  }

  const addRecommendationToProviderSession = async (pId, teachers) => {
    const session = await airtable.providerSessions.select(pId);
    let rfsl = session["Recommendations from School Lead"] || [];
    rfsl = [
      ...rfsl,
      ...teachers.map(t => t.id)
    ]
    rfsl = distinctArray(rfsl);
    await airtable.providerSessions.update(session.id, {
      "Recommendations from School Lead": rfsl
    })
  }

  const getUserName = () => {
    return userType === 'Teacher' ? userInfo["Teacher Name"] : userInfo["Name"];
  }

  const sendMessages = async () => {
    if (processing) return;

    const tIds = selectedTeachers.map(t => t.value);
    const teachers = groupTeachers.filter(t => tIds.indexOf(t.id) >= 0);

    if (!teachers || !teachers.length) return;

    setProcessing(true)
    if (type === 'Session') {
      await sendToTeacher(
        teachers.map(t => t.Email),
        "Session",
        getUserName(),
        userInfo["Email"],
        teachers.map(t => t["First Name"]),
        data["Session Title"],
        data["Description"] || "",
        `https://sessions.connectednorth.org/cn/session/${data.id}`,
        message,
        ccEmail,
        "",
        "",
        userType,
        appLocale
      )

      await addRecommendationToProviderSession(data.id, teachers)
    } else if (type === 'Event') {
      let attachments = null;

      if (data["Flyer"]) {
        attachments = data["Flyer"].map(f => ({
          url: f.url,
          filename: f.filename,
          type: f.type,
          disposition: 'attachment'
        }));
      }

      await sendToTeacher(
        teachers.map(t => t.Email),
        "Event",
        getUserName(),
        userInfo["Email"],
        teachers.map(t => t["First Name"]),
        data["Session Title Text"][0],
        data["Session Description"][0] || "",
        `https://sessions.connectednorth.org/cn/events`,
        message,
        ccEmail,
        attachments,
        data["Nice Date"],
        userType,
        appLocale
      )

      await addRecommendationToProviderSession(data["Provider Session Record ID"][0], teachers)
    } else if (type === 'Collection') {
      await sendToTeacher(
        teachers.map(t => t.Email),
        "Collection",
        getUserName(),
        userInfo["Email"],
        teachers.map(t => t["First Name"]),
        data.Tag,
        data.Description || "",
        `https://sessions.connectednorth.org/cn/collection/${data.id}`,
        message,
        ccEmail,
        "",
        "",
        userType,
        appLocale
      )

      await airtable.tags.update(data.id, {
        "For Teacher(s)": teachers.map(t => t.id)
      })
    } else if (type === "Provider") {
      await sendToTeacher(
        teachers.map(t => t.Email),
        "Provider",
        getUserName(),
        userInfo["Email"],
        teachers.map(t => t["First Name"]),
        data.Name,
        data["Introduction/Bio"] || "",
        `https://beta.connectednorth.org/cn/provider/${data.id}`,
        message,
        ccEmail,
        "",
        "",
        userType,
        appLocale
      )
    }

    if (userType === 'Team') {
      const teamInfo = await airtable.teams.select(userInfo.id);
      const stn = teamInfo["Sends to Teachers"] || 0;
      await airtable.teams.update(teamInfo.id, {
        "Sends to Teachers": stn + 1
      })
    }

    if (teachers.length === 1) {
      toast.success(`1 Message was successfully sent to the Teacher you selected!`)
    } else {
      toast.success(`${teachers.length} Messages were successfully sent to the Teachers you selected!`)
    }
    setProcessing(false)
  }

  const changeCurrentGrades = (newGrades) => {
    setSelectedGrades(newGrades);
  };

  return {
    loading,
    totalTeachers: groupTeachers,
    matchedTeachers,
    otherTeachers,
    selectedTeachers,
    setSelectedTeachers,
    message,
    setMessage,
    ccEmail,
    setCcEmail,
    sendMessages,
    processing,

    regions,
    selectedRegions,
    changeCurrentRegions: setSelectedRegions,
    userType,

    grades: allGrades,
    sessionGrades: grades,
    selectedGrades,
    changeCurrentGrades,
  }
}

export default useHook;
